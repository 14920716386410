<h1 mat-dialog-title style="text-align: center;">{{ data.tituloModal }}</h1>
<hr />
<div mat-dialog-content style="margin:0px;">

    <div class="row">
        
        <div class="col-md-4">                                        
        </div>
        <div class="col-md-4">
            <mat-radio-group aria-label="Finalizar como" [(ngModel)]="this.data.estadoFinalizacionPpto">
                <mat-radio-button value="A" style="padding-right:15%;">Aceptado</mat-radio-button>
                <mat-radio-button value="D">Denegado</mat-radio-button>
              </mat-radio-group>
        </div>
        
           

    </div>
    <div class="row"  *ngIf="this.data.estadoFinalizacionPpto == 'D'">
        <div class="col-md-4">            
        </div>
        <div class="col-md-4">
            <br/>

            <div>
                <mat-form-field>
                    <mat-label>Establecer motivo:</mat-label>
                    
                    <mat-select placeholder="Motivo denegación" #singleSelect matInput [(ngModel)]="this.data.motivoDenegacionPpto" (selectionChange)="evaluaCambioMotivoDenegacion($event.value)">
                    <mat-option *ngFor="let item of motivosDenegacion" [value]="item.valor">
                        {{item.valor}}
                    </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
          
 

        </div>
        <div class="col-md-4"></div>
       
    </div>
    <div class="row">
        <div class="col-md-4">
            
        </div>
        <div class="col-md-4">
            <div *ngIf="this.muestraCampoOtrosMotivos">
                <mat-form-field>          
                    <input matInput required placeholder="Introduce motivos" [(ngModel)]="this.data.motivoDenegacionPpto">
                </mat-form-field>     
            </div>
        </div>
        <div class="col-md-4">
            
        </div>
    </div>



</div>
<div mat-dialog-actions>
    <div class="row col-12">
        <div class="col-3">
            <button mat-raised-button color="warn" (click)="cancelaDialogo()">Cancelar</button>
        </div>
        <div class="col-6"></div>
        <div class="col-3">
            <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial [disabled]="guardarDeshabilitado">Aceptar</button>
        </div>
    </div>


</div>