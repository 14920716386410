<h1 mat-dialog-title>{{ data.tituloModal }}</h1>
<div mat-dialog-content>

    <form class="form">

        <input matInput [hidden]="true" value="{{data.usuario.ID_USUARIO}}" [(ngModel)]="data.usuario.ID_USUARIO" name="ID_USUARIO" readonly>

        <div class="row">
            <div class="col-3">
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Nombre" value="{{data.usuario.NOMBRE}}" [(ngModel)]="data.usuario.NOMBRE" (ngModelChange)="evaluaSiEsPermitidoGuardar()" name="NOMBRE">
                </mat-form-field>
            </div>
            <div class="col-1"></div>
            <div class="col-4">
                <mat-form-field class="example-full-width">
                    <input matInput type="password" placeholder="Nueva contraseña" [(ngModel)]="data.usuario.CONTRASENA" autocomplete="new-password" name="CONTRASENA" (focusout)="repetirPwd()" (ngModelChange)="evaluaSiEsPermitidoGuardar()">
                </mat-form-field>
                <div *ngIf="this.repetirPasswordVisible">
                    <mat-form-field>
                        <input matInput type="password" placeholder="Por favor repita.." [(ngModel)]="this.contrasenaRepetida" autocomplete="new-password" name="contrasenaRepetida" (focusout)="evaluaSiEsPermitidoGuardar()" (ngModelChange)="evaluaSiContrasenasCoinciden();evaluaSiEsPermitidoGuardar()">
                    </mat-form-field>
                </div>
                <div *ngIf="!this.nuevasContrasenasCoinciden && this.repetirPasswordVisible">
                    <h3 style="color:red">Las contraseñas no coinciden </h3>
                </div>
            </div>
            <div class="col-1"></div>
            <div class="col-3">
                <mat-checkbox labelPosition="before" [(ngModel)]="data.usuario.ROL" name="ROL" (ngModelChange)="evaluaSiEsPermitidoGuardar()">Privilegios totales
                </mat-checkbox>
            </div>
        </div>
        <div class="row">
            <hr />
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <div class="row col-12">
        <div class="col-3">
            <button mat-raised-button color="warn" (click)="cancelaDialogo()">Cancelar</button>
        </div>
        <div class="col-6"></div>
        <div class="col-3">
            <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial [disabled]="guardarDeshabilitado">Aceptar</button>
        </div>
    </div>


</div>