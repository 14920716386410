<div>
  <mat-horizontal-stepper
    (selectionChange)="stepperChanged($event)"
    [linear]="isLinear"
    #stepper
  >
    <!-- aqui -->
    <mat-step [stepControl]="tipoPptoFormGroup" *ngIf="!this.modoEdicion">
      <table width="100%">
        <tr>
          <td></td>
        </tr>
      </table>

      <form [formGroup]="tipoPptoFormGroup">
        <ng-template matStepLabel>Tipo de presupuesto</ng-template>
        <table width="100%" style="text-align: center">
          <tr>
            <td></td>
            <td>
              <label id="labelTipoPPTO"
                >Seleccione el tipo de presupuesto</label
              >
              <mat-radio-group
                aria-labelledby="labelTipoPPTO"
                aria-label="Tipo de presupuesto"
                class="tipoPpto-radio-group"
                formControlName="tipoPptoCtrl"
                required
                [(ngModel)]="this.tipoPPTO"
                (change)="onChangeTipoPPTO($event.value)"
              >
                <mat-radio-button value="D">Doméstico</mat-radio-button>
                <mat-radio-button value="I">Industrial</mat-radio-button>
              </mat-radio-group>
            </td>
            <td></td>
          </tr>
        </table>
      </form>

      <br /><br />
      <hr />
      <div style="text-align: center">
        <button
          mat-raised-button
          color="primary"
          matStepperNext
        >
          Siguiente
        </button>
      </div>
    </mat-step>
    <!-- fins aqui -->

    <mat-step [stepControl]="firstFormGroup">
      <table width="100%">
        <tr>
          <td></td>
        </tr>
      </table>
      <div [formGroup]="firstFormGroup">
        <ng-template matStepLabel>Datos cliente</ng-template>
        <table width="100%">
          <tr [hidden]="!this.mostrarSelectorClientes">
            <td>
              <mat-form-field [hidden]="!this.mostrarSelectorClientes">
                <mat-label>Cliente existente</mat-label>
                <mat-select
                  placeholder="Cliente existente"
                  style="width: 380px"
                  #singleSelect
                  matInput
                  name="ID_CLIENTE"
                  (selectionChange)="onChangeCliente($event.value)"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="clientesFilterCtrl"
                      noEntriesFoundLabel="No se encontró un cliente que coincida"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngFor="let cliente of clientesFiltrados"
                    [value]="cliente.ID_CLIENTE"
                  >
                    {{ cliente.NOMBRE }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <button
                mat-raised-button
                color="primary"
                (click)="duplicarCliente()"
                [disabled]="!this.clienteSeleccionado"
              >
                Duplicar cliente
              </button>
            </td>
            <td>
              <button
                mat-raised-button
                color="primary"
                (click)="nuevoCliente()"
                [disabled]="this.modoEdicion"
              >
                Nuevo cliente
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <hr />
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Nombre"
                  formControlName="firstCtrl"
                  required
                  [(ngModel)]="this.nombreCliente"
                  name="NOMBRE"
                  (change)="evaluaValidacionClientes()"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Correo"
                  [(ngModel)]="this.correoCliente"
                  formControlName="secondCtrl"
                  required
                  pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                  (change)="evaluaValidacionClientes()"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Teléfono"
                  formControlName="TelefonoCtrl"
                  required
                  [(ngModel)]="this.telefono"
                  name="TELEFONO"
                  (change)="evaluaValidacionClientes()"
                />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Dirección"
                  formControlName="DireccionCtrl"
                  required
                  [(ngModel)]="this.direccion"
                  name="DIRECCION"
                  (change)="evaluaValidacionClientes()"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Población"
                  formControlName="PoblacionCtrl"
                  required
                  [(ngModel)]="this.poblacion"
                  name="POBLACION"
                  (change)="evaluaValidacionClientes()"
                />
              </mat-form-field>
            </td>
            <td>
              <!-- formControlName="viviendaHabitualCtrl"  -->
              <mat-checkbox
                labelPosition="before"
                [(ngModel)]="this.viviendaHabitual"
                [checked]="this.viviendaHabitual"
                (change)="
                  chkViviendaHabitualChange($event.checked);
                  evaluaValidacionClientes()
                "
                name="VIVIENDA HABITUAL"
                ><!--  [disabled]="this.chkViviendaHabitualDeshabilitado" -->
                Vivienda habitual
              </mat-checkbox>
            </td>
          </tr>
          <tr>
            <td [hidden]="this.tipoPPTO == 'I'">
              <mat-form-field>
                <mat-label>Orientación</mat-label>
                <select
                  matNativeControl
                  required
                  [(ngModel)]="this.orientacion"
                  formControlName="orientacionCtrl"
                  (change)="evaluaValidacionClientes()"
                >
                  <option value="O">Orientado</option>
                  <option value="D">Desorientado</option>
                </select>
              </mat-form-field>
            </td>
            <td>
              <!--  
                <mat-form-field style="display:none;">
                  <mat-label>Pico de cubierta</mat-label>
                  <select matNativeControl required  [(ngModel)]="this.picoCubierta" formControlName="picoCubiertaCtrl">
                    <option value="I">Inclinada</option>
                    <option value="P">Plana</option>        
                  </select>
                </mat-form-field>
                -->
            </td>
            <td></td>
          </tr>
        </table>

        <!-- 
        <div style="text-align:center;">
          <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
        </div>
        -->
      </div>

      <br /><br />
      <hr />
      <div style="text-align: center">
        <button
          *ngIf="!this.clienteSeleccionado"
          mat-raised-button
          color="primary"
          matStepperNext
          (click)="obtieneCoordenadasIndustrial()"
        >
          Siguiente
        </button>
        <button
          *ngIf="this.clienteSeleccionado"
          mat-raised-button
          color="primary"
          matStepperNext
          (click)="obtieneCoordenadasIndustrial()"
        >
          Siguiente
        </button>
      </div>
    </mat-step>
    <!-- industrial -->
    <mat-step
      [stepControl]="datosTecnicosIndustrialFormGroup"
      *ngIf="this.tipoPPTO == 'I'"
    >
      <table width="100%">
        <tr>
          <td></td>
        </tr>
      </table>

      <ng-template matStepLabel>Datos técnicos tarifa</ng-template>
      <table width="100%">
        <tr>
          <td>
            <mat-form-field>
              <mat-label>Tramos tarifa</mat-label>
              <mat-select
                placeholder="Tramos tarifa"
                #singleSelect
                matInput
                (selectionChange)="onChangeTramosTarifa($event.value)"
                [(ngModel)]="tramosTarifaIndustrial"
              >
                <mat-option value="3" selected> 3 tramos </mat-option>
                <mat-option value="6"> 6 tramos </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <form [formGroup]="excedentesForm">
              <mat-checkbox
                formControlName="conExcedentesCtrl"
                labelPosition="before"
                [(ngModel)]="this.conExcedentes"
                name="Excendentes"
              >
                Con excedentes
              </mat-checkbox>
            </form>
          </td>
        </tr>
      </table>

      <hr *ngIf="this.tramosTarifaSeleccionados" />
      <br />
      <div *ngIf="this.tramosTarifaSeleccionados">
        <h4>Cálculo automático de consumos</h4>
        <table width="100%">
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <mat-label>Consumo anual</mat-label>
                <input
                  matInput
                  placeholder="kWh"
                  [(ngModel)]="this.consumoAnualIndustrial"
                  (change)="
                    formataFloatsPreciosKWH(); calculaConsumosIndustrial()
                  "
                />
              </mat-form-field>
            </td>
            <td *ngIf="!this.tarifaDe6Canales">
              <mat-form-field class="example-full-width">
                <mat-label>Consumo mensual factura (P1)</mat-label>
                <input
                  matInput
                  placeholder="kWh"
                  [(ngModel)]="this.consumoMensualFacturaP1"
                  (change)="
                    formataFloatsPreciosKWH(); calculaConsumosIndustrial()
                  "
                />
              </mat-form-field>
            </td>
            <td *ngIf="!this.tarifaDe6Canales">
              <mat-form-field class="example-full-width">
                <mat-label>Consumo mensual factura (P2)</mat-label>
                <input
                  matInput
                  placeholder="kWh"
                  [(ngModel)]="this.consumoMensualFacturaP2"
                  (change)="
                    formataFloatsPreciosKWH(); calculaConsumosIndustrial()
                  "
                />
              </mat-form-field>
            </td>
            <td *ngIf="!this.tarifaDe6Canales">
              <mat-form-field class="example-full-width">
                <mat-label>Consumo mensual factura (P3)</mat-label>
                <input
                  matInput
                  placeholder="kWh"
                  [(ngModel)]="this.consumoMensualFacturaP3"
                  (change)="
                    formataFloatsPreciosKWH(); calculaConsumosIndustrial()
                  "
                />
              </mat-form-field>
            </td>
          </tr>
        </table>
        <h4>Precio €/kWh</h4>
        <table width="100%">
          <tr>
            <td>
              <!-- <input placeholder="P1"> -->
              <mat-form-field class="example-full-width">
                <mat-label>P1</mat-label>
                <input
                  matInput
                  placeholder="€/kWh"
                  [(ngModel)]="this.consumosManualesIndustrial[0].PRECIO_KWH_P1"
                  (change)="formataFloatsPreciosKWH()"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="example-full-width">
                <mat-label>P2</mat-label>
                <input
                  matInput
                  placeholder="€/kWh"
                  [(ngModel)]="this.consumosManualesIndustrial[0].PRECIO_KWH_P2"
                  (change)="formataFloatsPreciosKWH()"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="example-full-width">
                <mat-label>P3</mat-label>
                <input
                  matInput
                  placeholder="€/kWh"
                  [(ngModel)]="this.consumosManualesIndustrial[0].PRECIO_KWH_P3"
                  (change)="formataFloatsPreciosKWH()"
                />
              </mat-form-field>
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <mat-form-field class="example-full-width">
                <mat-label>P4</mat-label>
                <input
                  matInput
                  placeholder="€/kWh"
                  [(ngModel)]="this.consumosManualesIndustrial[0].PRECIO_KWH_P4"
                  (change)="formataFloatsPreciosKWH()"
                />
              </mat-form-field>
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <mat-form-field class="example-full-width">
                <mat-label>P5</mat-label>
                <input
                  matInput
                  placeholder="€/kWh"
                  [(ngModel)]="this.consumosManualesIndustrial[0].PRECIO_KWH_P5"
                  (change)="formataFloatsPreciosKWH()"
                />
              </mat-form-field>
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <mat-form-field class="example-full-width">
                <mat-label>P6</mat-label>
                <input
                  matInput
                  placeholder="€/kWh"
                  [(ngModel)]="this.consumosManualesIndustrial[0].PRECIO_KWH_P6"
                  (change)="formataFloatsPreciosKWH()"
                />
              </mat-form-field>
            </td>
          </tr>
        </table>

        <h4>Potencia</h4>
        <table width="100%">
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <mat-label>P1 (kW)</mat-label>
                <input
                  matInput
                  placeholder="Potencia (kW)"
                  [(ngModel)]="this.consumosManualesIndustrial[0].POTENCIA_P1"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="example-full-width">
                <mat-label>P2 (kW)</mat-label>
                <input
                  matInput
                  placeholder="Potencia (kW)"
                  [(ngModel)]="this.consumosManualesIndustrial[0].POTENCIA_P2"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="example-full-width">
                <mat-label>P3 (kW)</mat-label>
                <input
                  matInput
                  placeholder="Potencia (kW)"
                  [(ngModel)]="this.consumosManualesIndustrial[0].POTENCIA_P3"
                />
              </mat-form-field>
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <mat-form-field class="example-full-width">
                <mat-label>P4 (kW)</mat-label>
                <input
                  matInput
                  placeholder="Potencia (kW)"
                  [(ngModel)]="this.consumosManualesIndustrial[0].POTENCIA_P4"
                />
              </mat-form-field>
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <mat-form-field class="example-full-width">
                <mat-label>P5 (kW)</mat-label>
                <input
                  matInput
                  placeholder="Potencia (kW)"
                  [(ngModel)]="this.consumosManualesIndustrial[0].POTENCIA_P5"
                />
              </mat-form-field>
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <mat-form-field class="example-full-width">
                <mat-label>P6 (kW)</mat-label>
                <input
                  matInput
                  placeholder="Potencia (kW)"
                  [(ngModel)]="this.consumosManualesIndustrial[0].POTENCIA_P6"
                />
              </mat-form-field>
            </td>
          </tr>
        </table>

        <hr *ngIf="this.tramosTarifaSeleccionados" />
        <br />
        <h4>Datos de consumo</h4>
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z8"
          style="width: 100%"
          *ngIf="this.tramosTarifaSeleccionados"
        >
          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

          <ng-container matColumnDef="Mes">
            <th mat-header-cell *matHeaderCellDef><b>Mes</b></th>
            <td mat-cell *matCellDef="let element">{{ element.Mes }}</td>
          </ng-container>

          <ng-container matColumnDef="P1">
            <th mat-header-cell *matHeaderCellDef><b>P1 KwH </b></th>
            <td mat-cell *matCellDef="let element">
              <input [(ngModel)]="element.P1" />
            </td>
          </ng-container>

          <ng-container matColumnDef="P2">
            <th mat-header-cell *matHeaderCellDef><b>P2 KwH</b></th>
            <td mat-cell *matCellDef="let element">
              <input [(ngModel)]="element.P2" />
            </td>
          </ng-container>

          <ng-container matColumnDef="P3">
            <th mat-header-cell *matHeaderCellDef><b>P3 KwH</b></th>
            <td mat-cell *matCellDef="let element">
              <input [(ngModel)]="element.P3" />
            </td>
          </ng-container>

          <ng-container matColumnDef="P4">
            <th mat-header-cell *matHeaderCellDef><b>P4 KwH</b></th>
            <td mat-cell *matCellDef="let element">
              <input [(ngModel)]="element.P4" />
            </td>
          </ng-container>

          <ng-container matColumnDef="P5">
            <th mat-header-cell *matHeaderCellDef><b>P5 KwH</b></th>
            <td mat-cell *matCellDef="let element">
              <input [(ngModel)]="element.P5" />
            </td>
          </ng-container>

          <ng-container matColumnDef="P6">
            <th mat-header-cell *matHeaderCellDef><b>P6 KwH</b></th>
            <td mat-cell *matCellDef="let element">
              <input [(ngModel)]="element.P6" />
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="this.displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: this.displayedColumns"></tr>
        </table>
      </div>
      <br /><br />
      <hr />
      <div style="text-align: center">
        <button
          mat-raised-button
          color="primary"
          matStepperNext
          [disabled]="!this.tramosTarifaSeleccionados"
        >
          Siguiente
        </button>
      </div>
    </mat-step>

    <!-- FINS AQUI-->

    <!-- DATOS TÉCNICOS PROYECTO INDUSTRIAL -->
    <mat-step
      [stepControl]="datosTecnicosIndustrialFormGroup"
      *ngIf="this.tipoPPTO == 'I'"
    >
      <table width="100%">
        <tr>
          <td></td>
        </tr>
      </table>

      <form [formGroup]="datosTecnicosProyectoIndustrial">
        <ng-template matStepLabel>Datos proyecto</ng-template>
        <table width="100%">
          <tr>
            <td colspan="3">
              <h3>PROYECTO</h3>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Nº Presupuesto"
                  [(ngModel)]="this.numPresupuesto"
                  formControlName="numPresupuestoCtrl"
                  required
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="example-full-width">
                <input
                  matInput
                  [value]="fechaPpto.value"
                  [(ngModel)]="this.fecha"
                  name="Fecha"
                  formControlName="FechaPPTOCtrl"
                  [matDatepicker]="picker"
                  placeholder="Fecha ppto"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </td>
            <mat-form-field>
              <input
                matInput
                placeholder="Precio final"
                [(ngModel)]="this.totalPresupuestoIndustrial"
                formControlName="totalPresupuestoCtrl"
                required
              />
            </mat-form-field>
          </tr>
          <tr>
            <td colspan="3">
              <h3>PVGIS</h3>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Potencia a instalar (kW)"
                  formControlName="PotenciaCtrl"
                  required
                  [(ngModel)]="this.potenciaPVGISIndustrial"
                  name="POTENCIA"
                  (change)="changePotenciaAInstalarIndustrial()"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Inclinación"
                  formControlName="InclinacionCtrl"
                  required
                  [(ngModel)]="this.inclinacionPVGISIndustrial"
                  name="INCLINACION"
                  (change)="validaInputInclinacionIndustrial()"
                />
                <p
                  *ngIf="
                    this.datosTecnicosProyectoIndustrial.controls[
                      'InclinacionCtrl'
                    ].invalid
                  "
                  style="color: red"
                >
                  Por favor, escribe un valor entre 0 y 90.
                </p>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Azimutal"
                  formControlName="AzimuthCtrl"
                  required
                  [(ngModel)]="this.azimuthPVGISIndustrial"
                  name="POTENCIA"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="% Pérdidas"
                  formControlName="PerdidasCtrl"
                  required
                  [(ngModel)]="this.perdidasPVGISIndustrial"
                  name="PERDIDAS"
                />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Coordenadas (lat)"
                  formControlName="LatCoordCtrl"
                  required
                  [(ngModel)]="this.latCoordIndustrial"
                  name="LAT"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Coordenadas (lon)"
                  formControlName="LonCoordCtrl"
                  required
                  [(ngModel)]="this.lonCoordIndustrial"
                  name="LON"
                />
              </mat-form-field>
            </td>
          </tr>

          <tr>
            <td colspan="3">
              <h3>Materiales</h3>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field>
                <mat-label>Inversores</mat-label>

                <mat-select
                  placeholder="Inversor"
                  formControlName="inversorCtrl"
                  #singleSelect
                  matInput
                  name="inversorIndustrial"
                  [(ngModel)]="this.inversorSeleccionadoIndustrial"
                  (selectionChange)="onChangeInversorIndustrial($event.value)"
                >
                  <mat-option
                    *ngFor="let inversor of inversores"
                    [value]="inversor"
                  >
                    {{ inversor.MODELO }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Número inversores"
                  formControlName="NumInversoresCtrl"
                  required
                  [(ngModel)]="this.numInversoresIndustrial"
                  name="numInversoresIndustrial"
                  (change)="this.inicializaTrabajosARealizar()"
                />
              </mat-form-field>
            </td>
            <td [hidden]="checkSmallerValor(potenciaPVGISIndustrial, 1)">
              <mat-form-field>
                <mat-label>Paneles</mat-label>
                <mat-select
                  placeholder="Panel"
                  formControlName="panelCtrl"
                  [(ngModel)]="this.panelSeleccionadoIndustrial"
                  #singleSelect
                  matInput
                  name="panelIndustrial"
                  (selectionChange)="onChangePanelIndustrial($event.value)"
                >
                  <mat-option *ngFor="let panel of paneles" [value]="panel">
                    {{ panel.MODELO }} ({{ panel.POTENCIA }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td [hidden]="checkSmallerValor(potenciaPVGISIndustrial, 1)">
              <div [hidden]="this.panelSeleccionadoIndustrial.ID_ARTICULO <= 0">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Número paneles"
                    formControlName="NumPanelesCtrl"
                    required
                    [(ngModel)]="this.numPanelesIndustrial"
                    name="numPanelesIndustrial"
                    (change)="this.inicializaTrabajosARealizar()"
                  />
                </mat-form-field>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <mat-checkbox
                formControlName="solarblockIndustrialCtrl"
                labelPosition="before"
                [(ngModel)]="this.solarblockIndustrial"
                (change)="this.inicializaTrabajosARealizar()"
                name="Solarblock"
              >
                Solarblock
              </mat-checkbox>
            </td>
            <td>
              <mat-checkbox
                formControlName="inspeccionPreviaCtrl"
                labelPosition="before"
                [(ngModel)]="this.inspeccionPrevia"
                name="Inspeccion Previa"
              >
                Inspeccion Previa
              </mat-checkbox>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <hr />
              <p>Imagen google maps</p>
              <br />
              <input
                placeholder="Imagen google maps"
                type="file"
                id="FUImage"
                name="FUImage"
                accept=".png, .jpg, .jpeg"
                required
                (change)="handleFileInput($event.target.files)"
              />
              <img
                src="{{ this.imagenGoogleMaps }}"
                alt="Selected Image"
                height="200"
                width="200"
              />
              <button
                mat-fab
                color="primary"
                style="margin-left: 20px"
                *ngIf="!this.modoEdicion"
                mat-raised-button
                (click)="obtenerImagenGoogleMaps()"
              >
                <mat-icon>image_search</mat-icon>
              </button>
            </td>
          </tr>
        </table>
      </form>

      <hr />
      <div style="text-align: center">
        <button
          mat-raised-button
          color="primary"
          matStepperNext
          (click)="this.generarPDFClick()"
        >
          Siguiente
        </button>
      </div>
    </mat-step>

    <mat-step [stepControl]="secondFormGroup" *ngIf="this.tipoPPTO == 'D'">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Datos técnicos</ng-template>

        <table width="100%">
          <tr>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Nº Presupuesto"
                  [(ngModel)]="this.numPresupuesto"
                  formControlName="thirdCtrl"
                  required
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="example-full-width">
                <input
                  matInput
                  [value]="this.fechaPpto?.value"
                  [(ngModel)]="this.fecha"
                  name="Fecha"
                  formControlName="dateCtrl"
                  [matDatepicker]="picker"
                  placeholder="Fecha ppto"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <mat-label>KIT</mat-label>
                <mat-select
                  style="width: 280px"
                  formControlName="kitCtrl"
                  placeholder="Kit"
                  #singleSelect
                  matInput
                  [(ngModel)]="this.tipoKit"
                  name="tipoKit"
                  (selectionChange)="onChangeKit($event.value)"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="kitsFilterCtrl"
                      noEntriesFoundLabel="No se encontró un kit que coincida"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngFor="let tipoContrato of tiposContratoFiltrados"
                    [value]="tipoContrato"
                  >
                    {{ tipoContrato.NOMBRE }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Tipo contrato"
                  [(ngModel)]="this.tipoContrato"
                  formControlName="tipoContratoCtrl"
                  required
                />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Consumo anual (kW)"
                  formControlName="ConsumoAnualCtrl"
                  required
                  [(ngModel)]="this.consumoAnualTotal"
                  name="Consumo anual"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Precio final"
                  [(ngModel)]="this.precioFinal"
                  formControlName="precioFinalCtrl"
                  required
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <mat-label>Batería</mat-label>

                <mat-select
                  formControlName="bateriaCtrl"
                  placeholder="Batería"
                  #singleSelect
                  matInput
                  name="bateriaSeleccionada"
                  [(ngModel)]="this.bateriaSeleccionada"
                  (selectionChange)="
                    onChangeBateria($event.value, this.importeAdicional, true)
                  "
                >
                  <mat-option
                    *ngFor="let bateria of this.bateriasDisponibles"
                    [value]="bateria"
                  >
                    {{ bateria.POTENCIA }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Importe batería"
                  [(ngModel)]="this.importeAdicional"
                  formControlName="precioAdicionalCtrl"
                  (change)="onChangeImporte($event.target.value)"
                />
              </mat-form-field>
            </td>

            <td>
              <!-- 
              <mat-form-field appearance="fill" style="display:none;">
                <mat-label>Descripción importe adicional</mat-label>
                <textarea matInput
                          cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5"
                          placeholder="Descripción" [(ngModel)]="this.textoImporteAdicional"></textarea>
              </mat-form-field>
              -->
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <hr />
              <p>Imagen google maps</p>
              <br />
              <input
                placeholder="Imagen google maps"
                type="file"
                id="FUImage"
                name="FUImage"
                accept=".png, .jpg, .jpeg"
                required
                (change)="handleFileInput($event.target.files)"
              />
              <img
                src="{{ this.imagenGoogleMaps }}"
                alt="Selected Image"
                height="200"
                width="200"
              />
              <button
                mat-fab
                color="primary"
                style="margin-left: 20px"
                *ngIf="!this.modoEdicion"
                mat-raised-button
                (click)="obtenerImagenGoogleMaps()"
              >
                <mat-icon>image_search</mat-icon>
              </button>
            </td>
            <td>
              <mat-checkbox
                labelPosition="before"
                name="INCLUIR FORMA DE PAGO"
                [(ngModel)]="this.incluirFormaDePago"
                [checked]="this.incluirFormaDePago"
                (change)="
                  chkIncluirFormaPagoChange($event.checked);
                  evaluaValidacionClientes()
                "
              >
                Incluir Forma de pago </mat-checkbox
              ><br />
              <mat-checkbox
                labelPosition="before"
                name="INCLUIR TRAMITACIONES"
                [(ngModel)]="this.incluirTramitaciones"
                [checked]="this.incluirTramitaciones"
                (change)="
                  chkIncluirTramitacionesChange($event.checked);
                  onChangeKit(this.tipoKit);
                  evaluaValidacionClientes()
                "
              >
                Incluir Tramitaciones
              </mat-checkbox>
            </td>
          </tr>
        </table>
      </form>

      <br /><br />
      <hr />
      <div style="text-align: center">
        <button
          mat-raised-button
          color="primary"
          matStepperNext
          (click)="this.generarPDFClick()"
        >
          Siguiente
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup">
      <ng-template matStepLabel>Presupuesto</ng-template>
      <form [formGroup]="thirdFormGroup"></form>
      <resumen-ppto
        *ngIf="this.mostrarResumenPDF && this.tipoPPTO == 'D'"
        [poblacion]="this.poblacion"
        [direccion]="this.direccion"
        [consumoAnualTotal]="this.consumoAnualTotal"
        [nombreCliente]="this.nombreCliente"
        [fechaPpto]="this.fecha"
        [correoCliente]="this.correoCliente"
        [idCliente]="this.idCliente"
        [numPresupuesto]="this.numPresupuesto"
        [imagenGoogleMaps]="this.imagenGoogleMaps"
        [tipoContrato]="this.tipoContrato"
        [telefono]="this.telefono"
        [viviendaHabitual]="this.viviendaHabitual"
        [orientacion]="this.orientacion"
        [picoCubierta]="this.picoCubierta"
        [precioFinal]="this.precioFinal"
        [importeAdicional]="this.importeAdicional"
        [textoImporteAdicional]="this.textoImporteAdicional"
        [potenciaBateriaSeleccionada]="this.potenciaBateriaSeleccionada"
        [tipoKit]="this.tipoKit"
        [trabajosARealizar]="this.trabajosARealizar"
        [tipoPPTO]="this.tipoPPTO"
        [incluirFormaDePago]="this.incluirFormaDePago"
        [incluirTramitaciones]="this.incluirTramitaciones"
      ></resumen-ppto>
      <div *ngIf="this.mostrarResumenPDF && this.tipoPPTO == 'I'">
        <resumen-ppto-industrial
          #el
          *ngIf="this.mostrarResumenPDF && this.tipoPPTO == 'I'"
          [poblacion]="this.poblacion"
          [direccion]="this.direccion"
          [nombreCliente]="this.nombreCliente"
          [fechaPpto]="this.fecha"
          [correoCliente]="this.correoCliente"
          [numPresupuesto]="this.numPresupuesto"
          [idCliente]="this.idCliente"
          [imagenGoogleMaps]="this.imagenGoogleMaps"
          [telefono]="this.telefono"
          [viviendaHabitual]="this.viviendaHabitual"
          [orientacion]="this.orientacion"
          [picoCubierta]="this.picoCubierta"
          [precioFinal]="this.precioFinal"
          [importeAdicional]="this.importeAdicional"
          [textoImporteAdicional]="this.textoImporteAdicional"
          [trabajosARealizar]="this.trabajosARealizar"
          [tipoPPTO]="this.tipoPPTO"
          [tarifaDe6Canales]="this.tarifaDe6Canales"
          [consumosManualesIndustrial]="this.consumosManualesIndustrial"
          [potenciaPVGISIndustrial]="this.potenciaPVGISIndustrial"
          [latCoordIndustrial]="this.latCoordIndustrial"
          [lonCoordIndustrial]="this.lonCoordIndustrial"
          [inclinacionPVGISIndustrial]="this.inclinacionPVGISIndustrial"
          [azimuthPVGISIndustrial]="this.azimuthPVGISIndustrial"
          [perdidasPVGISIndustrial]="this.perdidasPVGISIndustrial"
          [panelSeleccionadoIndustrial]="this.panelSeleccionadoIndustrial"
          [numPanelesIndustrial]="this.numPanelesIndustrial"
          [inversorSeleccionadoIndustrial]="this.inversorSeleccionadoIndustrial"
          [numInversoresIndustrial]="this.numInversoresIndustrial"
          [totalPresupuestoIndustrial]="this.totalPresupuestoIndustrial"
          [solarblockIndustrial]="this.solarblockIndustrial"
          [conExcedentes]="this.conExcedentes"
          [inspeccionPrevia]="this.inspeccionPrevia"
          [consumoAnualIndustrial]="this.consumoAnualIndustrial"
          [consumoMensualFacturaP1]="this.consumoMensualFacturaP1"
          [consumoMensualFacturaP2]="this.consumoMensualFacturaP2"
          [consumoMensualFacturaP3]="this.consumoMensualFacturaP3"
        ></resumen-ppto-industrial>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
