import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
    selector: 'creacion-edicion-promotor',
    templateUrl: 'creacion-edicion-promotor.html',
    styleUrls: ['./creacion-edicion-promotor.css'],
  })
  export class CreacionEdicionPromotor {
        
    guardarDeshabilitado=true;    
    estados: interficieLlaveValor[] = [];
    
    rutaImagen:string = "";
        
    constructor(
      public dialogRef: MatDialogRef<CreacionEdicionPromotor>,
      @Inject(MAT_DIALOG_DATA) public data,
      public http:HttpService,
      public dialog: MatDialog,
      private spinner: NgxSpinnerService,
      private notifyService : NotificationService,
      ) {}

    cancelaDialogo(): void {
      this.dialogRef.close();
    }



    ngOnInit()
    {
            
      
        this.spinner.show();

        this.inicializaEstados();
        this.evaluaSiEsPermitidoGuardar();


        this.spinner.hide();

    }

    inicializaEstados()
    {

        let i: interficieLlaveValor = {
          key: "I",
          value: "Interesado"
        }
        this.estados.push(i);

        let q: interficieLlaveValor = {
          key: "N",
          value: "No interesado"
        }
        this.estados.push(q);

        let w: interficieLlaveValor = {
          key: "P",
          value: "Pendiente contacto"
        }
        this.estados.push(w);

        let z: interficieLlaveValor = {
          key: "U",
          value: "No definido"
        }
        this.estados.push(z);

     

    }
   
    evaluaSiEsPermitidoGuardar()
    {

        if ((this.data.promotor.NOMBRE.toString().trim().length > 0) &&  (this.data.promotor.ESTADO.toString().trim().length > 0))       
            this.guardarDeshabilitado = false;
        else 
            this.guardarDeshabilitado = true;


  }

  }
  interface interficieLlaveValor {
    key:string; 
    value: string
  }