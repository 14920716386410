import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Rutas
import { APP_ROUTES } from './app.routes';

// Modulos
import { PagesModule } from './pages/pages.module';

// Servicios
import { ServiceModule } from './services/service.module';

// Temporal
import { FormsModule } from '@angular/forms';

//GRIDS
import { AngularSlickgridModule } from 'angular-slickgrid';

//HTTP

// Interceptors
import { AuthInterceptorService } from './services/auth/auth-interceptor.service';

import { NgApexchartsModule } from "ng-apexcharts";

//import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/mat-spinner-overlay.component';

//import { UsuariosIndexComponent } from './pages/usuarios/index/index.component';

//import { HTTP_INTERCEPTORS } from '@angular/common/http';
//import { TokenInterceptor } from './services/auth/token.interceptor';

import { ToastrModule } from 'ngx-toastr';
// import { NgxProgressModule } from '@kken94/ngx-progress';
import { NgxSpinnerModule } from "ngx-spinner";
import { ComponentsModule } from './components/components.module';

import { NgxEchartsModule } from 'ngx-echarts';


import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
// Import the Canvas renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer
} from 'echarts/renderers';
import 'echarts/theme/macarons.js';

import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './shared/header/header.component';



echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer]
);


// @dynamic
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
        APP_ROUTES,
    PagesModule,
    FormsModule,
    ServiceModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    ComponentsModule,
    MatBadgeModule,
    AngularSlickgridModule.forRoot({
      // add any Global Grid Options/Config you might want
      // to avoid passing the same options over and over in each grids of your App
      enableAutoResize: true,
      autoResize: {
        containerId: 'demo-container',
        sidePadding: 10
      }
    }),
    NgxEchartsModule.forRoot({ echarts }),
    NgApexchartsModule,
    FormsModule,
    RouterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
