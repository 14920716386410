import { Input, OnChanges, SimpleChanges } from "@angular/core";
import { ViewChild } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { HttpService } from "src/app/services/http/http.service";
import { NotificationService } from "src/app/services/notification/notification.service";
import { CreacionPresupuestoComponent } from "./creacion-presupuesto.component";

import { VTR_ConsumoEstimado } from "@shared/virtual/VTR.consumo_estimado.entity";

import { PPTOS_PreciosKWHIndustrial } from "@shared/PPTOS.precios_kwh_industrial.entity";
import { MNT_Articulos } from "@shared/MNT.articulos.entity";
import { MNT_Marcas } from "@shared/MNT.marcas.entity";

import { EstadoPresupuesto, PPTOS } from "@shared/pptos.entity";
import * as moment from "moment";

const momenta = require("moment-business-days");

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from "ng-apexcharts";

// @ts-ignore
import ApexCharts from "apexcharts";
import { ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import {
  BalancesFinancierosIndustrial,
  ConsumosManualesIndustrial,
  SharedService,
} from "src/app/services/shared/shared.service";
import { PPTOS_ConsumosIntroduccionManualIndustrial } from "@shared/PPTOS.consumos_introduccion_manual_industrial.entity";
import { PPTOS_GeneracionConsumosIndustrial } from "@shared/PPTOS.generacion_consumos_industrial.entity";
import {
  CRM_Clientes,
  EstadoClientes,
  ProcedenciaClientes,
} from "@shared/CRM.clientes.entity";
import { DefinicionAlarmaPresupuesto } from "./definicion-alarma-ppto.component";
import { MatDialog } from "@angular/material/dialog";
import { PPTOS_Alarmas, TiposAlarmas } from "@shared/PPTOS.alarmas.entity";
import { CRM_AccionesRealizadas } from "@shared/CRM.acciones_realizadas.entity";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  colors: string[];
  legend: ApexLegend;
  fill: ApexFill;
};

@Component({
  selector: "resumen-ppto-industrial",
  templateUrl: "./resumen-ppto-industrial.component.html",
  styleUrls: ["./resumen-ppto-industrial.component.css"],
})
export class ResumenPptoIndustrialComponent implements OnInit, OnChanges {
  public ngOnChanges(changes: SimpleChanges) {
    if ("numPresupuesto" in changes) {
      if (!changes["numPresupuesto"]["firstChange"]) {
        this.numPresupuesto == changes["numPresupuesto"]["currentValue"];
        this.generaNombreDocumentoPDF();
      }
    } else if ("direccion" in changes) {
      if (!changes["direccion"]["firstChange"]) {
        this.direccion == changes["direccion"]["currentValue"];
        this.generaNombreDocumentoPDF();
      }
    } else if ("nombreCliente" in changes) {
      if (!changes["nombreCliente"]["firstChange"]) {
        this.nombreCliente == changes["nombreCliente"]["currentValue"];
        this.generaNombreDocumentoPDF();
      }
    } else if ("potenciaKit" in changes) {
      if (!changes["potenciaKit"]["firstChange"]) {
        this.potenciaKit = changes["potenciaKit"]["currentValue"];
        this.generaNombreDocumentoPDF();
      }
    } else if ("poblacion" in changes) {
      if (!changes["poblacion"]["firstChange"]) {
        this.poblacion == changes["poblacion"]["currentValue"];
        this.generaNombreDocumentoPDF();
      }
    } else if ("fechaPpto" in changes) {
      if (!changes["fechaPpto"]["firstChange"]) {
        this.fechaPpto = changes["fechaPpto"]["currentValue"];
        this.formataFecha();
      }
    } else if ("idCliente" in changes) {
      if (!changes["idCliente"]["firstChange"]) {
        this.idCliente = changes["idCliente"]["currentValue"];
      }
    } else if ("correoCliente" in changes) {
      if (!changes["correoCliente"]["firstChange"]) {
        this.correoCliente = changes["correoCliente"]["currentValue"];
      }
    } else if ("imagenGoogleMaps" in changes) {
      if (!changes["imagenGoogleMaps"]["firstChange"]) {
        this.imagenGoogleMaps = changes["imagenGoogleMaps"]["currentValue"];
      }
    } else if ("tipoContrato" in changes) {
      if (!changes["tipoContrato"]["firstChange"]) {
        this.tipoContrato = changes["tipoContrato"]["currentValue"];
      }
    } else if ("telefono" in changes) {
      if (!changes["telefono"]["firstChange"]) {
        this.telefono = changes["telefono"]["currentValue"];
      }
    } else if ("viviendaHabitual" in changes) {
      if (!changes["viviendaHabitual"]["firstChange"]) {
        this.viviendaHabitual = changes["viviendaHabitual"]["currentValue"];
      }
    } else if ("orientacion" in changes) {
      if (!changes["orientacion"]["firstChange"]) {
        this.orientacion = changes["orientacion"]["currentValue"];
      }
    } else if ("picoCubierta" in changes) {
      if (!changes["picoCubierta"]["firstChange"]) {
        this.picoCubierta = changes["picoCubierta"]["currentValue"];
      }
    } else if ("precioFinal" in changes) {
      if (!changes["precioFinal"]["firstChange"]) {
        this.precioFinal = changes["precioFinal"]["currentValue"];
        this.formataValoracionEconomica();
      }
    } else if ("importeAdicional" in changes) {
      if (!changes["importeAdicional"]["firstChange"]) {
        this.importeAdicional = changes["importeAdicional"]["currentValue"];
        this.formataValoracionEconomica();
      }
    } else if ("textoImporteAdicional" in changes) {
      if (!changes["textoImporteAdicional"]["firstChange"]) {
        this.textoImporteAdicional =
          changes["textoImporteAdicional"]["currentValue"];
      }
    } else if ("trabajosARealizar" in changes) {
      if (!changes["trabajosARealizar"]["firstChange"]) {
        this.trabajosARealizar = changes["trabajosARealizar"]["currentValue"];
        this.inicializaTrabajosARealizar();
      }
    } else if ("tipoPPTO" in changes) {
      if (!changes["tipoPPTO"]["firstChange"]) {
        this.tipoPPTO = changes["tipoPPTO"]["currentValue"];
      }
    } else if ("tarifaDe6Canales" in changes) {
      if (!changes["tarifaDe6Canales"]["firstChange"]) {
        this.tarifaDe6Canales = changes["tarifaDe6Canales"]["currentValue"];
        this.formataTipoContrato();
        this.calculoAhorroAnual();
      }
    } else if ("consumosManualesIndustrial" in changes) {
      if (!changes["consumosManualesIndustrial"]["firstChange"]) {
        //this.realizaCalculosYInicializaGraficos();
      }
    } else if ("potenciaPVGISIndustrial" in changes) {
      if (!changes["potenciaPVGISIndustrial"]["firstChange"]) {
        this.potenciaPVGISIndustrial =
          changes["potenciaPVGISIndustrial"]["currentValue"];
        this.formataPotenciaCanalesTarifa();
        this.formataConsumoAnual();
        this.generaNombreDocumentoPDF();
        //this.realizaCalculosYInicializaGraficos();
      }
    } else if ("latCoordIndustrial" in changes) {
      if (!changes["latCoordIndustrial"]["firstChange"]) {
        this.latCoordIndustrial = changes["latCoordIndustrial"]["currentValue"];
        //this.realizaCalculosYInicializaGraficos();
      }
    } else if ("lonCoordIndustrial" in changes) {
      if (!changes["lonCoordIndustrial"]["firstChange"]) {
        this.lonCoordIndustrial = changes["lonCoordIndustrial"]["currentValue"];
        //this.realizaCalculosYInicializaGraficos();
      }
    } else if ("inclinacionPVGISIndustrial" in changes) {
      if (!changes["inclinacionPVGISIndustrial"]["firstChange"]) {
        this.inclinacionPVGISIndustrial =
          changes["inclinacionPVGISIndustrial"]["currentValue"];
        //this.realizaCalculosYInicializaGraficos();
      }
    } else if ("azimuthPVGISIndustrial" in changes) {
      if (!changes["azimuthPVGISIndustrial"]["firstChange"]) {
        this.azimuthPVGISIndustrial =
          changes["azimuthPVGISIndustrial"]["currentValue"];
        //this.realizaCalculosYInicializaGraficos();
      }
    } else if ("perdidasPVGISIndustrial" in changes) {
      if (!changes["perdidasPVGISIndustrial"]["firstChange"]) {
        this.perdidasPVGISIndustrial =
          changes["perdidasPVGISIndustrial"]["currentValue"];
        //this.realizaCalculosYInicializaGraficos();
      }
    } else if ("panelSeleccionadoIndustrial" in changes) {
      if (!changes["panelSeleccionadoIndustrial"]["firstChange"]) {
        this.panelSeleccionadoIndustrial =
          changes["panelSeleccionadoIndustrial"]["currentValue"];
        this.inicializaTextosYImagenesSegunPanelYInversores();
      }
    } else if ("numPanelesIndustrial" in changes) {
      if (!changes["numPanelesIndustrial"]["firstChange"]) {
        this.numPanelesIndustrial =
          changes["numPanelesIndustrial"]["currentValue"];
      }
    } else if ("inversorSeleccionadoIndustrial" in changes) {
      if (!changes["inversorSeleccionadoIndustrial"]["firstChange"]) {
        this.inversorSeleccionadoIndustrial =
          changes["inversorSeleccionadoIndustrial"]["currentValue"];
        this.inicializaTextosYImagenesSegunPanelYInversores();
      }
    } else if ("numInversoresIndustrial" in changes) {
      if (!changes["numInversoresIndustrial"]["firstChange"]) {
        this.numInversoresIndustrial =
          changes["numInversoresIndustrial"]["currentValue"];
        this.inicializaTextosYImagenesSegunPanelYInversores();
      }
    } else if ("totalPresupuestoIndustrial" in changes) {
      if (!changes["totalPresupuestoIndustrial"]["firstChange"]) {
        this.totalPresupuestoIndustrial =
          changes["totalPresupuestoIndustrial"]["currentValue"];
        this.inicializaTextosYImagenesSegunPanelYInversores();
        this.formataTotalPresupuesto();
        this.calculoBalanceFinanciero();
        this.calculoRenting();
      }
    } else if ("solarblockIndustrial" in changes) {
      if (!changes["solarblockIndustrial"]["firstChange"]) {
        this.solarblockIndustrial =
          changes["solarblockIndustrial"]["currentValue"];
      }
    } else if ("inspeccionPrevia" in changes) {
      if (!changes["inspeccionPrevia"]["firstChange"]) {
        this.inspeccionPrevia = changes["inspeccionPrevia"]["currentValue"];
      }
    } else if ("conExcedentes" in changes) {
      if (!changes["conExcedentes"]["firstChange"]) {
        this.conExcedentes = changes["conExcedentes"]["currentValue"];
      }
    }
  }

  public onChangeTrabajosARealizar({ editor }) {
    this.trabajosARealizar = editor.getData();
    this.inicializaTrabajosARealizar();
  }

  public model = {
    editorData: "<p>Hello, world!</p>",
  };

  @ViewChild("chart1")
  chart: ChartComponent;

  public chartOptionsComparacionPunta: Partial<ChartOptions>;
  public chartOptionsComparacionLlano: Partial<ChartOptions>;
  public chartOptionsComparacionValle: Partial<ChartOptions>;

  ocultarPDFGenerado = true;

  @ViewChild(CreacionPresupuestoComponent)
  creacionPpto: any;
  //Pasar objetos entre pantallas
  @Input()
  poblacion = "";
  @Input()
  direccion = "";
  @Input()
  nombreCliente = "";
  @Input()
  fechaPpto = "";
  @Input()
  idCliente = "";
  @Input()
  correoCliente = "";
  @Input()
  numPresupuesto = "";
  @Input()
  imagenGoogleMaps = "";
  @Input()
  tipoContrato = "";
  @Input()
  telefono = "";
  @Input()
  viviendaHabitual = "";
  @Input()
  orientacion = "";
  @Input()
  picoCubierta = "";
  @Input()
  precioFinal = "";
  @Input()
  importeAdicional = "";
  @Input()
  textoImporteAdicional = "";
  @Input()
  trabajosARealizar = "";
  @Input()
  tipoPPTO = "";
  @Input()
  tarifaDe6Canales = "";
  @Input()
  consumosManualesIndustrial: ConsumosManualesIndustrial[];
  @Input()
  potenciaPVGISIndustrial = "";
  @Input()
  latCoordIndustrial = "";
  @Input()
  lonCoordIndustrial = "";
  @Input()
  inclinacionPVGISIndustrial = "";
  @Input()
  azimuthPVGISIndustrial = "";
  @Input()
  perdidasPVGISIndustrial = "";
  @Input()
  panelSeleccionadoIndustrial: MNT_Articulos;
  @Input()
  numPanelesIndustrial = "";
  @Input()
  inversorSeleccionadoIndustrial: MNT_Articulos;
  @Input()
  numInversoresIndustrial = "";
  @Input()
  totalPresupuestoIndustrial = 0;
  @Input()
  solarblockIndustrial: boolean;
  @Input()
  inspeccionPrevia: boolean;
  @Input()
  conExcedentes: boolean;
  @Input()
  consumoAnualIndustrial = "";
  @Input()
  consumoMensualFacturaP1 = "";
  @Input()
  consumoMensualFacturaP2 = "";
  @Input()
  consumoMensualFacturaP3 = "";

  importeLegalizacionExcedenteElectrico = 640;

  id_kit = 0;

  initOpts: any;
  initOptsMinimizado: any;
  options: any;
  optionsAmortizacionIndustrial: any;

  arrFormatadoPVGIS = [];
  arrFormatadoSinDecimalesPVGIS = [];
  arrFormatadoValleSinDecimalesPVGIS = [];
  arrFormatadoLlanoSinDecimalesPVGIS = [];
  arrFormatadoPuntaSinDecimalesPVGIS = [];
  arrRepercusionesEconomicasPuntas = [];
  arrRepercusionesEconomicasLlanos = [];
  arrRepercusionesEconomicasValles = [];
  arrTotalRepercusionesEconomicas = [];

  totalPVGIS = 0;
  totalVallePVGIS = 0;
  totalLlanoPVGIS = 0;
  totalPuntaPVGIS = 0;
  totalRepercusionesEconomicasPuntas = 0;
  totalRepercusionesEconomicasLlanos = 0;
  totalRepercusionesEconomicasValles = 0;
  totalRepercusionesEconomicas = 0;
  totalRepercusionesEconomicasFormatado: any;
  totalRepercusiones25a: any;
  cuotaFinanciada84Formatado: any;

  totalPorcentajeAhorroPunta = 0;
  totalPorcentajeAhorroLlano = 0;
  totalPorcentajeAhorroValle = 0;

  articulosKit = [];
  panelKit = new MNT_Articulos();
  marcaKit = new MNT_Marcas();
  rutaImagenPanelKit = "";
  rutaImagenInversorKit = "";
  fitxasTecnicasKits = "";

  fichaTecnica1Panel = false;
  fichaTecnica2Panel = false;
  fichaTecnica1Inversor = false;
  fichaTecnica2Inversor = false;

  PPTOGuardar = new PPTOS();
  accion = "";

  elementosTrabajosARealiar = [];

  consumoAnualTotalFormatado: any;

  valoracionEconomica = "";

  fechaPreFormatada: any;

  consumosEstimados: VTR_ConsumoEstimado = new VTR_ConsumoEstimado();

  @ViewChild("print")
  print: ElementRef;
  public datosFinanciacionSinBateria = Array<
    {
      global: boolean;
      plazo: number;
      importeMultiplicador1: string;
      importeMultiplicador2: string;
    }
  >();
  public datosFinanciacionConBateria = Array<
    {
      global: boolean;
      plazo: number;
      importeMultiplicador1: string;
      importeMultiplicador2: string;
    }
  >();
  public valoresMultiplicador1DatosFinanciadosSinBateria = [];
  public valoresMultiplicador2DatosFinanciadosSinBateria = [];
  public valoresMultiplicador1DatosFinanciadosConBateria = [];
  public valoresMultiplicador2DatosFinanciadosConBateria = [];

  public numeroPanelesSegunKit = 0;
  public potenciaKit = 0;
  public nombreCortoMarca = "";
  public potenciaPanelKit = "";
  public totalPVGISFormatado: any;
  public tipoPanelKit = "";

  public potenciaInversor = "";
  public modeloInversor = "";
  public porcentajeSobreConsumoEstimado: any;

  consumosCargados = false;

  //INDUSTRIAL
  public totalP1ConsumosManualesIndustrial = 0;
  public totalP2ConsumosManualesIndustrial = 0;
  public totalP3ConsumosManualesIndustrial = 0;
  public totalP4ConsumosManualesIndustrial = 0;
  public totalP5ConsumosManualesIndustrial = 0;
  public totalP6ConsumosManualesIndustrial = 0;
  public totalHS_P1ConsumosManualesIndustrial = 0;
  public totalHS_P2ConsumosManualesIndustrial = 0;
  public totalHS_P3ConsumosManualesIndustrial = 0;
  public totalHS_P4ConsumosManualesIndustrial = 0;
  public totalHS_P5ConsumosManualesIndustrial = 0;
  public totalHS_P6ConsumosManualesIndustrial = 0;
  public potenciaCanalesTarifaIndustrial = "P1: 41 kW P2: 41 kW P3: 41 kW";
  public chartOptionsConsumoTotalIndustrial: Partial<ChartOptions>;
  public chartOptionsConsumoHSIndustrial: Partial<ChartOptions>;
  public potenciaPanelSeleccionadoIndustrial = "";
  public potenciaInversoresSeleccionadosIndustrial = "";
  public nombreFormatadoInversorSeleccionadoIndustrial = "";
  public totalAhorroAnualIndustrial = 0;
  public totalAhorroAnualIndustrial25A = 0;
  public totalAhorroAnualIndustrialFormatado = 0;
  public totalAhorroAnualIndustrial25AFormatado = 0;

  public subidaFacturaLuzIndustrial = 3;
  public perdidaEficienciaPanel = 0.07;
  public TIR = 0;
  public VAN = 0;
  public anoRecuperacionInversionBalanceFinancieroIndustrial = 0;
  public totalPresupuestoIndustrialFormatado = 0;
  public totalPresupuestoIndustrialFormatadoSinDecimales = 0;
  public nombreDocumentoPDF = "";
  public totalCalculoCuotaRentingIndustrial = 0;
  public sumaAlgebraica = 0;

  public balanceFinancieroIndustrial: BalancesFinancierosIndustrial[] = [];
  public cargandoCalculos = true;

  public importeFijo = 0;
  public importePortatil = 0;
  public importeIntangible = 0;
  public totalProforma = 0;
  public meses = 84;
  public VR = 6;
  public seguro = 0;
  public gestion = 0;
  public importeRiesgo = 0;
  public interesAnual = 6;
  public interesMensual = 0;
  public comAdmMensual = 10;

  constructor(
    public http: HttpService,
    private notifyService: NotificationService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public shared: SharedService,
    public dialog: MatDialog,
  ) {
  }

  public triggerPrint(): void {
  }

  guardarPPTO(origen: string) {
    this.PPTOGuardar.ID_PPTO_MANTENIMIENTO_ENLAZADO = 0;
    this.PPTOGuardar.NUMERACION = this.numPresupuesto;
    this.PPTOGuardar.ID_TIPO_CONTRATO = this.id_kit;
    this.PPTOGuardar.NOMBRE_APELLIDOS_CLIENTE = this.nombreCliente;
    this.PPTOGuardar.MAIL = this.correoCliente;
    this.PPTOGuardar.TELEFONO = this.telefono;
    this.PPTOGuardar.DIRECCION = this.direccion;

    this.PPTOGuardar.VIVIENDA_HABITUAL = +this.viviendaHabitual;

    this.PPTOGuardar.POBLACION = this.poblacion;
    this.PPTOGuardar.CONSUMO_ANUAL = +this.potenciaPVGISIndustrial;
    this.PPTOGuardar.ESTADO = EstadoPresupuesto.Pendiente;
    this.PPTOGuardar.ORIENTACION = this.orientacion;
    this.PPTOGuardar.PICO_DE_CUBIERTA = this.picoCubierta;
    this.PPTOGuardar.FECHA_REVISION_PPTO = new Date().toISOString();

    if (
      (localStorage.getItem("accion") == "editar") ||
      (localStorage.getItem("accion") == "PDF")
    ) {
      this.fechaPreFormatada = new Date();
      this.fechaPpto = this.fechaPreFormatada.getFullYear() + "/" +
        ("0" + (this.fechaPreFormatada.getMonth() + 1)).slice(-2) + "/" +
        this.fechaPreFormatada.getDate();
      this.PPTOGuardar.FECHA_PPTO = this.fechaPpto;
    } else {
      this.fechaPreFormatada = new Date();
      this.fechaPpto = this.fechaPreFormatada.getFullYear() + "/" +
        ("0" + (this.fechaPreFormatada.getMonth() + 1)).slice(-2) + "/" +
        this.fechaPreFormatada.getDate();
      this.PPTOGuardar.FECHA_PPTO = this.fechaPpto;
    }

    if (this.tarifaDe6Canales) {
      this.PPTOGuardar.TIPO_CONTRATO = "6.0";
    } else {
      this.PPTOGuardar.TIPO_CONTRATO = "3.0";
    }

    //this.PPTOGuardar.TIPO_CONTRATO = this.tipoContrato;
    this.PPTOGuardar.IMAGEN_GOOGLE_MAPS = this.imagenGoogleMaps;
    this.PPTOGuardar.TEXTO_ADDICIONAL = this.textoImporteAdicional;
    this.PPTOGuardar.IMPORTE_ADDICIONAL = +this.importeAdicional;
    this.PPTOGuardar.PRECIO_FINAL = +this.totalPresupuestoIndustrial;
    this.PPTOGuardar.TRABAJOS_A_REALIZAR = this.trabajosARealizar;

    this.PPTOGuardar.TIPO_PPTO = "I";
    this.PPTOGuardar.POTENCIA_PICO_I = +this.potenciaPVGISIndustrial;
    this.PPTOGuardar.INCLINACION_PVGIS_I = +this.inclinacionPVGISIndustrial;
    this.PPTOGuardar.AZIMUTH_PVGIS_I = +this.azimuthPVGISIndustrial;
    this.PPTOGuardar.PERDIDAS_PVGIS_I = +this.perdidasPVGISIndustrial;
    this.PPTOGuardar.COORDENADAS_LAT_PVGIS_I = this.latCoordIndustrial;
    this.PPTOGuardar.COORDENADAS_LON_PVGIS_I = this.lonCoordIndustrial;
    this.PPTOGuardar.INVERSOR_I =
      this.inversorSeleccionadoIndustrial.ID_ARTICULO;
    this.PPTOGuardar.NUMERO_INVERSORES_I = +this.numInversoresIndustrial;
    this.PPTOGuardar.PANEL_I = this.panelSeleccionadoIndustrial.ID_ARTICULO;
    this.PPTOGuardar.NUMERO_PANELES_I = +this.numPanelesIndustrial;
    this.PPTOGuardar.POTENCIA_BATERIA = "";
    this.PPTOGuardar.MOTIVO_DENEGACION = "";

    if (this.solarblockIndustrial) {
      this.PPTOGuardar.SOLARBLOCK_I = 1;
    } else {
      this.PPTOGuardar.SOLARBLOCK_I = 0;
    }

    if (origen != "impression") {
      this.spinner.show();
    }

    if (this.idCliente == "NUEVO") {
      var clienteGuardar = new CRM_Clientes();
      clienteGuardar.NOMBRE = this.nombreCliente.toUpperCase();
      clienteGuardar.CORREO = this.correoCliente;
      clienteGuardar.TELEFONO = this.telefono;
      clienteGuardar.DIRECCION = this.direccion.toUpperCase();
      clienteGuardar.MUNICIPIO = this.poblacion.toUpperCase();
      clienteGuardar.PROVINCIA = ""; //99
      clienteGuardar.PROCEDENCIA = ProcedenciaClientes.Nuevo_PPTO; //Nuevo PPTO
      clienteGuardar.ESTADO = EstadoClientes.Tiene_Borradores_PPTO;

      this.http.guardarCliente(clienteGuardar).then(
        (clienteGuardado) => {
          var s = new CRM_AccionesRealizadas();
          s.ID_CLIENTE = clienteGuardado["identifiers"][0]["ID_CLIENTE"];
          var date = new Date(); // Or the date you'd like converted.
          var isoDateTime = new Date(
            date.getTime() - (date.getTimezoneOffset() * 60000),
          ).toISOString().slice(0, 16);
          s.FECHA = isoDateTime;
          s.DESCRIPCION = "Nuevo cliente dado de alta";
          this.http.guardarAccionRealizada(s);

          console.log(this.PPTOGuardar)

          this.PPTOGuardar.ID_CLIENTE =
            +clienteGuardado["identifiers"][0]["ID_CLIENTE"];
          this.http.guardarPPTO(this.PPTOGuardar).then(
            (result) => {
              var z = new CRM_AccionesRealizadas();
              z.ID_CLIENTE = clienteGuardado["identifiers"][0]["ID_CLIENTE"];
              var date = new Date(); // Or the date you'd like converted.
              var isoDateTime = new Date(
                date.getTime() - (date.getTimezoneOffset() * 60000),
              ).toISOString().slice(0, 16);
              z.FECHA = isoDateTime;
              z.DESCRIPCION = "Borrador presupuesto creado: " +
                this.PPTOGuardar.NUMERACION;
              this.http.guardarAccionRealizada(z);

              var preciosKHWIndustrial = new PPTOS_PreciosKWHIndustrial();
              preciosKHWIndustrial.ID_PPTO =
                result["identifiers"][0]["ID_PPTO"];
              preciosKHWIndustrial.PRECIO_P1_I =
                this.consumosManualesIndustrial[0].PRECIO_KWH_P1;
              preciosKHWIndustrial.PRECIO_P2_I =
                this.consumosManualesIndustrial[0].PRECIO_KWH_P2;
              preciosKHWIndustrial.PRECIO_P3_I =
                this.consumosManualesIndustrial[0].PRECIO_KWH_P3;
              preciosKHWIndustrial.PRECIO_P4_I =
                this.consumosManualesIndustrial[0].PRECIO_KWH_P4;
              preciosKHWIndustrial.PRECIO_P5_I =
                this.consumosManualesIndustrial[0].PRECIO_KWH_P5;
              preciosKHWIndustrial.PRECIO_P6_I =
                this.consumosManualesIndustrial[0].PRECIO_KWH_P6;
              preciosKHWIndustrial.POTENCIA_P1_I =
                this.consumosManualesIndustrial[0].POTENCIA_P1;
              preciosKHWIndustrial.POTENCIA_P2_I =
                this.consumosManualesIndustrial[0].POTENCIA_P2;
              preciosKHWIndustrial.POTENCIA_P3_I =
                this.consumosManualesIndustrial[0].POTENCIA_P3;
              preciosKHWIndustrial.POTENCIA_P4_I =
                this.consumosManualesIndustrial[0].POTENCIA_P4;
              preciosKHWIndustrial.POTENCIA_P5_I =
                this.consumosManualesIndustrial[0].POTENCIA_P5;
              preciosKHWIndustrial.POTENCIA_P6_I =
                this.consumosManualesIndustrial[0].POTENCIA_P6;
              this.http.guardarPreciosKWHIndustrial(preciosKHWIndustrial).then(
                () => {
                  if (origen != "impression") {
                    var generacionConsumos =
                      new PPTOS_GeneracionConsumosIndustrial();
                    generacionConsumos.ID_PPTO = preciosKHWIndustrial.ID_PPTO;
                    generacionConsumos.CONSUMO_ANUAL =
                      this.consumoAnualIndustrial;
                    generacionConsumos.CONSUMO_MENSUAL_FACTURA_P1 =
                      this.consumoMensualFacturaP1;
                    generacionConsumos.CONSUMO_MENSUAL_FACTURA_P2 =
                      this.consumoMensualFacturaP2;
                    generacionConsumos.CONSUMO_MENSUAL_FACTURA_P3 =
                      this.consumoMensualFacturaP3;

                    this.http.guardarGeneracionConsumosIndustrial(
                      generacionConsumos,
                    ).then(
                      () => {
                        this.consumosManualesIndustrial.forEach((element) => {
                          var consumoAGuardar =
                            new PPTOS_ConsumosIntroduccionManualIndustrial();
                          consumoAGuardar.ID_PPTO =
                            preciosKHWIndustrial.ID_PPTO;

                          if (element.Mes == "Enero") {
                            consumoAGuardar.MES = 1;
                          }
                          if (element.Mes == "Febrero") {
                            consumoAGuardar.MES = 2;
                          }
                          if (element.Mes == "Marzo") {
                            consumoAGuardar.MES = 3;
                          }
                          if (element.Mes == "Abril") {
                            consumoAGuardar.MES = 4;
                          }
                          if (element.Mes == "Mayo") {
                            consumoAGuardar.MES = 5;
                          }
                          if (element.Mes == "Junio") {
                            consumoAGuardar.MES = 6;
                          }
                          if (element.Mes == "Julio") {
                            consumoAGuardar.MES = 7;
                          }
                          if (element.Mes == "Agosto") {
                            consumoAGuardar.MES = 8;
                          }
                          if (element.Mes == "Septiembre") {
                            consumoAGuardar.MES = 9;
                          }
                          if (element.Mes == "Octubre") {
                            consumoAGuardar.MES = 10;
                          }
                          if (element.Mes == "Noviembre") {
                            consumoAGuardar.MES = 11;
                          }
                          if (element.Mes == "Diciembre") {
                            consumoAGuardar.MES = 12;
                          }

                          consumoAGuardar.P1 = +element.P1;
                          consumoAGuardar.P2 = +element.P2;
                          consumoAGuardar.P3 = +element.P3;
                          consumoAGuardar.P4 = +element.P4;
                          consumoAGuardar.P5 = +element.P5;
                          consumoAGuardar.P6 = +element.P6;

                          this.http.guardarConsumosIntroduccionManualIndustrial(
                            consumoAGuardar,
                          ).then(
                            () => {
                            },
                          );
                        });

                        this.abrirDialogDefinicionAlarmaPpto(
                          result["identifiers"][0]["ID_PPTO"],
                        );
                      },
                    );
                  }
                },
                () => {
                  if (origen != "impression") {
                    this.notifyService.showMessage(
                      "Error efectuando operación",
                      "Error",
                      true,
                    );
                    this.spinner.hide();
                  }
                },
              );
            },
            () => {
              if (origen != "impression") {
                this.notifyService.showMessage(
                  "Error efectuando operación",
                  "Error",
                  true,
                );
                this.spinner.hide();
              }
            },
          );
        },
      );
    } else {
      this.PPTOGuardar.ID_CLIENTE = +this.idCliente;

      if (
        (localStorage.getItem("accion") == "editar") ||
        (localStorage.getItem("accion") == "PDF")
      ) {
        this.PPTOGuardar.ID_PPTO = +localStorage.getItem("id_ppto");
        this.http.actualizarPPTO(this.PPTOGuardar).then(
          () => {
            var z = new CRM_AccionesRealizadas();
            z.ID_CLIENTE = this.PPTOGuardar.ID_CLIENTE;
            var date = new Date(); // Or the date you'd like converted.
            var isoDateTime = new Date(
              date.getTime() - (date.getTimezoneOffset() * 60000),
            ).toISOString().slice(0, 16);
            z.FECHA = isoDateTime;
            z.DESCRIPCION = "Borrador presupuesto actualizado: " +
              this.PPTOGuardar.NUMERACION;
            this.http.guardarAccionRealizada(z);

            var preciosKHWIndustrial = new PPTOS_PreciosKWHIndustrial();
            preciosKHWIndustrial.ID_PPTO = this.PPTOGuardar.ID_PPTO;
            preciosKHWIndustrial.PRECIO_P1_I =
              this.consumosManualesIndustrial[0].PRECIO_KWH_P1;
            preciosKHWIndustrial.PRECIO_P2_I =
              this.consumosManualesIndustrial[0].PRECIO_KWH_P2;
            preciosKHWIndustrial.PRECIO_P3_I =
              this.consumosManualesIndustrial[0].PRECIO_KWH_P3;
            preciosKHWIndustrial.PRECIO_P4_I =
              this.consumosManualesIndustrial[0].PRECIO_KWH_P4;
            preciosKHWIndustrial.PRECIO_P5_I =
              this.consumosManualesIndustrial[0].PRECIO_KWH_P5;
            preciosKHWIndustrial.PRECIO_P6_I =
              this.consumosManualesIndustrial[0].PRECIO_KWH_P6;
            preciosKHWIndustrial.POTENCIA_P1_I =
              this.consumosManualesIndustrial[0].POTENCIA_P1;
            preciosKHWIndustrial.POTENCIA_P2_I =
              this.consumosManualesIndustrial[0].POTENCIA_P2;
            preciosKHWIndustrial.POTENCIA_P3_I =
              this.consumosManualesIndustrial[0].POTENCIA_P3;
            preciosKHWIndustrial.POTENCIA_P4_I =
              this.consumosManualesIndustrial[0].POTENCIA_P4;
            preciosKHWIndustrial.POTENCIA_P5_I =
              this.consumosManualesIndustrial[0].POTENCIA_P5;
            preciosKHWIndustrial.POTENCIA_P6_I =
              this.consumosManualesIndustrial[0].POTENCIA_P6;

            this.http.actualizarPreciosKWHIndustrial(preciosKHWIndustrial).then(
              () => {
                var generacionConsumos =
                  new PPTOS_GeneracionConsumosIndustrial();
                generacionConsumos.ID_PPTO = preciosKHWIndustrial.ID_PPTO;
                generacionConsumos.CONSUMO_ANUAL = this.consumoAnualIndustrial;
                generacionConsumos.CONSUMO_MENSUAL_FACTURA_P1 =
                  this.consumoMensualFacturaP1;
                generacionConsumos.CONSUMO_MENSUAL_FACTURA_P2 =
                  this.consumoMensualFacturaP2;
                generacionConsumos.CONSUMO_MENSUAL_FACTURA_P3 =
                  this.consumoMensualFacturaP3;

                this.http.actualizarGeneracionConsumosIndustrial(
                  generacionConsumos,
                ).then(
                  () => {
                    this.consumosManualesIndustrial.forEach((element) => {
                      var consumoKWH =
                        new PPTOS_ConsumosIntroduccionManualIndustrial();
                      consumoKWH.ID_PPTO = this.PPTOGuardar.ID_PPTO;

                      if (element.Mes == "Enero") {
                        consumoKWH.MES = 1;
                      }
                      if (element.Mes == "Febrero") {
                        consumoKWH.MES = 2;
                      }
                      if (element.Mes == "Marzo") {
                        consumoKWH.MES = 3;
                      }
                      if (element.Mes == "Abril") {
                        consumoKWH.MES = 4;
                      }
                      if (element.Mes == "Mayo") {
                        consumoKWH.MES = 5;
                      }
                      if (element.Mes == "Junio") {
                        consumoKWH.MES = 6;
                      }
                      if (element.Mes == "Julio") {
                        consumoKWH.MES = 7;
                      }
                      if (element.Mes == "Agosto") {
                        consumoKWH.MES = 8;
                      }
                      if (element.Mes == "Septiembre") {
                        consumoKWH.MES = 9;
                      }
                      if (element.Mes == "Octubre") {
                        consumoKWH.MES = 10;
                      }
                      if (element.Mes == "Noviembre") {
                        consumoKWH.MES = 11;
                      }
                      if (element.Mes == "Diciembre") {
                        consumoKWH.MES = 12;
                      }

                      consumoKWH.P1 = +element.P1;
                      consumoKWH.P2 = +element.P2;
                      consumoKWH.P3 = +element.P3;
                      consumoKWH.P4 = +element.P4;
                      consumoKWH.P5 = +element.P5;
                      consumoKWH.P6 = +element.P6;

                      this.http.actualizarConsumosIntroduccionManualIndustrial(
                        consumoKWH,
                      );
                    });
                  },
                );

                if (origen != "impression") {
                  this.notifyService.showMessage(
                    "Operación efectuada correctamente",
                    "Success",
                    false,
                  );
                  this.spinner.hide();
                  //this.router.navigate(['grid-presupuestos']);
                }
              },
              () => {
                if (origen != "impression") {
                  this.notifyService.showMessage(
                    "Error efectuando operación",
                    "Error",
                    true,
                  );
                  this.spinner.hide();
                }
              },
            );
          },
          () => {
            if (origen != "impression") {
              this.notifyService.showMessage(
                "Error efectuando operación",
                "Error",
                true,
              );
              this.spinner.hide();
            }
          },
        );
      } else {
        this.http.guardarPPTO(this.PPTOGuardar).then(
          (result) => {
            var z = new CRM_AccionesRealizadas();
            z.ID_CLIENTE = this.PPTOGuardar.ID_CLIENTE;
            var date = new Date(); // Or the date you'd like converted.
            var isoDateTime = new Date(
              date.getTime() - (date.getTimezoneOffset() * 60000),
            ).toISOString().slice(0, 16);
            z.FECHA = isoDateTime;
            z.DESCRIPCION = "Borrador presupuesto creado: " +
              this.PPTOGuardar.NUMERACION;
            this.http.guardarAccionRealizada(z);

            var preciosKHWIndustrial = new PPTOS_PreciosKWHIndustrial();
            preciosKHWIndustrial.ID_PPTO = result["identifiers"][0]["ID_PPTO"];
            preciosKHWIndustrial.PRECIO_P1_I =
              this.consumosManualesIndustrial[0].PRECIO_KWH_P1;
            preciosKHWIndustrial.PRECIO_P2_I =
              this.consumosManualesIndustrial[0].PRECIO_KWH_P2;
            preciosKHWIndustrial.PRECIO_P3_I =
              this.consumosManualesIndustrial[0].PRECIO_KWH_P3;
            preciosKHWIndustrial.PRECIO_P4_I =
              this.consumosManualesIndustrial[0].PRECIO_KWH_P4;
            preciosKHWIndustrial.PRECIO_P5_I =
              this.consumosManualesIndustrial[0].PRECIO_KWH_P5;
            preciosKHWIndustrial.PRECIO_P6_I =
              this.consumosManualesIndustrial[0].PRECIO_KWH_P6;
            preciosKHWIndustrial.POTENCIA_P1_I =
              this.consumosManualesIndustrial[0].POTENCIA_P1;
            preciosKHWIndustrial.POTENCIA_P2_I =
              this.consumosManualesIndustrial[0].POTENCIA_P2;
            preciosKHWIndustrial.POTENCIA_P3_I =
              this.consumosManualesIndustrial[0].POTENCIA_P3;
            preciosKHWIndustrial.POTENCIA_P4_I =
              this.consumosManualesIndustrial[0].POTENCIA_P4;
            preciosKHWIndustrial.POTENCIA_P5_I =
              this.consumosManualesIndustrial[0].POTENCIA_P5;
            preciosKHWIndustrial.POTENCIA_P6_I =
              this.consumosManualesIndustrial[0].POTENCIA_P6;

            this.http.guardarPreciosKWHIndustrial(preciosKHWIndustrial).then(
              () => {
                if (origen != "impression") {
                  var generacionConsumos =
                    new PPTOS_GeneracionConsumosIndustrial();
                  generacionConsumos.ID_PPTO = preciosKHWIndustrial.ID_PPTO;
                  generacionConsumos.CONSUMO_ANUAL =
                    this.consumoAnualIndustrial;
                  generacionConsumos.CONSUMO_MENSUAL_FACTURA_P1 =
                    this.consumoMensualFacturaP1;
                  generacionConsumos.CONSUMO_MENSUAL_FACTURA_P2 =
                    this.consumoMensualFacturaP2;
                  generacionConsumos.CONSUMO_MENSUAL_FACTURA_P3 =
                    this.consumoMensualFacturaP3;

                  this.http.guardarGeneracionConsumosIndustrial(
                    generacionConsumos,
                  ).then(
                    () => {
                      this.consumosManualesIndustrial.forEach((element) => {
                        var consumoAGuardar =
                          new PPTOS_ConsumosIntroduccionManualIndustrial();
                        consumoAGuardar.ID_PPTO = preciosKHWIndustrial.ID_PPTO;

                        if (element.Mes == "Enero") {
                          consumoAGuardar.MES = 1;
                        }
                        if (element.Mes == "Febrero") {
                          consumoAGuardar.MES = 2;
                        }
                        if (element.Mes == "Marzo") {
                          consumoAGuardar.MES = 3;
                        }
                        if (element.Mes == "Abril") {
                          consumoAGuardar.MES = 4;
                        }
                        if (element.Mes == "Mayo") {
                          consumoAGuardar.MES = 5;
                        }
                        if (element.Mes == "Junio") {
                          consumoAGuardar.MES = 6;
                        }
                        if (element.Mes == "Julio") {
                          consumoAGuardar.MES = 7;
                        }
                        if (element.Mes == "Agosto") {
                          consumoAGuardar.MES = 8;
                        }
                        if (element.Mes == "Septiembre") {
                          consumoAGuardar.MES = 9;
                        }
                        if (element.Mes == "Octubre") {
                          consumoAGuardar.MES = 10;
                        }
                        if (element.Mes == "Noviembre") {
                          consumoAGuardar.MES = 11;
                        }
                        if (element.Mes == "Diciembre") {
                          consumoAGuardar.MES = 12;
                        }

                        consumoAGuardar.P1 = +element.P1;
                        consumoAGuardar.P2 = +element.P2;
                        consumoAGuardar.P3 = +element.P3;
                        consumoAGuardar.P4 = +element.P4;
                        consumoAGuardar.P5 = +element.P5;
                        consumoAGuardar.P6 = +element.P6;

                        this.http.guardarConsumosIntroduccionManualIndustrial(
                          consumoAGuardar,
                        ).then(
                          () => {
                          },
                        );
                      });

                      this.abrirDialogDefinicionAlarmaPpto(
                        result["identifiers"][0]["ID_PPTO"],
                      );
                    },
                  );
                }
              },
              () => {
                if (origen != "impression") {
                  this.notifyService.showMessage(
                    "Error efectuando operación",
                    "Error",
                    true,
                  );
                  this.spinner.hide();
                }
              },
            );
          },
          () => {
            if (origen != "impression") {
              this.notifyService.showMessage(
                "Error efectuando operación",
                "Error",
                true,
              );
              this.spinner.hide();
            }
          },
        );
      }
    }
  }

  abrirDialogDefinicionAlarmaPpto(_id_ppto: number) {
    var tituloModal = "Definición alarma";

    const dialogRef = this.dialog.open(DefinicionAlarmaPresupuesto, {
      width: "50%",
      data: {
        tituloModal: tituloModal,
        id_ppto: _id_ppto,
        //id_cliente: _id_cliente,
        //accionesRealizadas: new Array<CRM_AccionesRealizadas>()
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        var dateObj = new Date();
        var alarma = new PPTOS_Alarmas();
        if (result.estadoFinalizacionPpto == "S") { //ALARMA SEGUIMIENTO
          alarma.ID_PPTO = _id_ppto;
          alarma.TIPO_ALARMA = TiposAlarmas.Seguimiento;
          alarma.VISUALIZADA = false;
          alarma.SIGUIENTE_RECORDATORIO =
            momenta(dateObj, "DD-MM-YYYY").businessAdd(3)._d;
        } else {
          alarma.ID_PPTO = _id_ppto;
          alarma.TIPO_ALARMA = TiposAlarmas.Pendiente;
          alarma.VISUALIZADA = false;
          alarma.SIGUIENTE_RECORDATORIO =
            momenta(dateObj, "DD-MM-YYYY").businessAdd(1)._d;
          alarma.MOTIVO_SEGUIMIENTO = result["motivoDenegacionPpto"];
        }

        alarma.SIGUIENTE_RECORDATORIO = moment(alarma.SIGUIENTE_RECORDATORIO)
          .add(1, "hour").format("YYYY-MM-DD");

        var alarmaObtenidaFormatada = moment(alarma.SIGUIENTE_RECORDATORIO)
          .format("DD-MM-YYYY");

        this.http.guardarAlarma(alarma).then(
          () => {
            this.notifyService.showMessage(
              "Operación efectuada correctamente",
              "Success",
              false,
            );
            this.notifyService.showMessage(
              "Alarma programada para el día: " + alarmaObtenidaFormatada,
              "Success",
              false,
            );
            this.spinner.hide();
            this.router.navigate(["grid-presupuestos"]);
          },
        );

        //this.router.navigate(['grid-presupuestos']);
      }
    });
  }

  isValidDate(dateObject: any) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }

  inicializaElementos() {
    this.accion = "";

    this.consumoAnualTotalFormatado = 0;

    this.elementosTrabajosARealiar = [];

    this.ocultarPDFGenerado = true;

    this.importeLegalizacionExcedenteElectrico = 640;
    this.id_kit = 0;
    this.arrFormatadoPVGIS = [];
    this.arrFormatadoSinDecimalesPVGIS = [];
    this.arrFormatadoValleSinDecimalesPVGIS = [];
    this.arrFormatadoLlanoSinDecimalesPVGIS = [];
    this.arrFormatadoPuntaSinDecimalesPVGIS = [];
    this.arrRepercusionesEconomicasPuntas = [];
    this.arrRepercusionesEconomicasLlanos = [];
    this.arrRepercusionesEconomicasValles = [];
    this.arrTotalRepercusionesEconomicas = [];

    this.totalPVGISFormatado = "";
    this.totalPVGIS = 0;
    this.totalVallePVGIS = 0;
    this.totalLlanoPVGIS = 0;
    this.totalPuntaPVGIS = 0;
    this.totalRepercusionesEconomicasPuntas = 0;
    this.totalRepercusionesEconomicasLlanos = 0;
    this.totalRepercusionesEconomicasValles = 0;
    this.totalRepercusionesEconomicas = 0;
    this.totalRepercusionesEconomicasFormatado;
    this.totalRepercusiones25a;
    this.cuotaFinanciada84Formatado;

    this.totalPorcentajeAhorroPunta = 0;
    this.totalPorcentajeAhorroLlano = 0;
    this.totalPorcentajeAhorroValle = 0;

    this.articulosKit = [];
    this.panelKit = new MNT_Articulos();
    this.marcaKit = new MNT_Marcas();
    this.rutaImagenPanelKit = "";
    this.rutaImagenInversorKit = "";
    this.fitxasTecnicasKits = "";

    this.PPTOGuardar = new PPTOS();
    this.accion = "";

    this.elementosTrabajosARealiar = [];

    this.valoracionEconomica = "";

    this.fechaPreFormatada;

    this.consumosEstimados = new VTR_ConsumoEstimado();

    this.datosFinanciacionSinBateria = Array<
      {
        global: boolean;
        plazo: number;
        importeMultiplicador1: string;
        importeMultiplicador2: string;
      }
    >();
    this.datosFinanciacionConBateria = Array<
      {
        global: boolean;
        plazo: number;
        importeMultiplicador1: string;
        importeMultiplicador2: string;
      }
    >();
    this.valoresMultiplicador1DatosFinanciadosSinBateria = [];
    this.valoresMultiplicador2DatosFinanciadosSinBateria = [];
    this.valoresMultiplicador1DatosFinanciadosConBateria = [];
    this.valoresMultiplicador2DatosFinanciadosConBateria = [];

    this.numeroPanelesSegunKit = 0;
    this.potenciaKit = 0;
    this.nombreCortoMarca = "";
    this.potenciaPanelKit = "";
    this.tipoPanelKit = "";
    this.potenciaInversor = "";
    this.modeloInversor = "";

    this.porcentajeSobreConsumoEstimado;
    this.consumosCargados = false;
  }

  formataFecha() {
    if (
      (localStorage.getItem("accion") == "editar") ||
      (localStorage.getItem("accion") == "PDF")
    ) {
      this.fechaPreFormatada = new Date(this.fechaPpto);
      this.fechaPpto = this.fechaPreFormatada.getDate() + "/" +
        ("0" + (this.fechaPreFormatada.getMonth() + 1)).slice(-2) + "/" +
        this.fechaPreFormatada.getFullYear();
    } else {
      this.fechaPreFormatada = new Date();
      this.fechaPpto = this.fechaPreFormatada.getDate() + "/" +
        ("0" + (this.fechaPreFormatada.getMonth() + 1)).slice(-2) + "/" +
        this.fechaPreFormatada.getFullYear();
    }
    //alert("FECHA PPTO (0) --> " + this.fechaPpto);

    //alert("FECHA PPTO (1) --> " + this.fechaPpto);
  }

  sleep(milliseconds: any) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }

  formataTipoContrato() {
    if (this.tarifaDe6Canales) {
      this.tipoContrato = "6.0 A";
    } else {
      this.tipoContrato = "3.0 A";
    }
  }

  formataPotenciaCanalesTarifa() {
    if (this.tarifaDe6Canales) {
      this.potenciaCanalesTarifaIndustrial = "P1: " +
        this.consumosManualesIndustrial[0].POTENCIA_P1 + " kW P2: " +
        this.consumosManualesIndustrial[0].POTENCIA_P2 + " kW P3: " +
        this.consumosManualesIndustrial[0].POTENCIA_P3 + " kW P4: " +
        this.consumosManualesIndustrial[0].POTENCIA_P4 + " kW P5: " +
        this.consumosManualesIndustrial[0].POTENCIA_P5 + " kW P6: " +
        this.consumosManualesIndustrial[0].POTENCIA_P6 + " kW";
    } else {
      this.potenciaCanalesTarifaIndustrial = "P1: " +
        this.consumosManualesIndustrial[0].POTENCIA_P1 + " kW P2: " +
        this.consumosManualesIndustrial[0].POTENCIA_P2 + " kW P3: " +
        this.consumosManualesIndustrial[0].POTENCIA_P3 + " kW";
    }
  }

  inicializaTextosYImagenesSegunPanelYInversores() {
    this.potenciaPanelSeleccionadoIndustrial = this.panelSeleccionadoIndustrial
      .POTENCIA.replace(" W", " Wp");
    this.potenciaInversoresSeleccionadosIndustrial = (Number(
      this.inversorSeleccionadoIndustrial.POTENCIA.replace(" kW", "").replace(
        " k",
        "",
      ),
    ) * Number(this.numInversoresIndustrial)).toString() + " kW";
    this.nombreFormatadoInversorSeleccionadoIndustrial = this
      .inversorSeleccionadoIndustrial.MODELO.replace(" SUN2000", "");

    if (
      this.doesFileExist(
        "../../../assets/images/" + this.panelSeleccionadoIndustrial.MODELO +
          "/F.jpg",
      )
    ) {
      this.fichaTecnica1Panel = true;
    }

    if (
      this.doesFileExist(
        "../../../assets/images/" + this.panelSeleccionadoIndustrial.MODELO +
          "/F-2.jpg",
      )
    ) {
      this.fichaTecnica2Panel = true;
    }

    if (
      this.doesFileExist(
        "../../../assets/images/" + this.inversorSeleccionadoIndustrial.MODELO +
          "/F.jpg",
      )
    ) {
      this.fichaTecnica1Inversor = true;
    }

    if (
      this.doesFileExist(
        "../../../assets/images/" + this.inversorSeleccionadoIndustrial.MODELO +
          "/F-2.jpg",
      )
    ) {
      this.fichaTecnica2Inversor = true;
    }

    this.fitxasTecnicasKits = "../../../assets/images/" +
      this.panelSeleccionadoIndustrial.MODELO + "/F.jpg";
  }

  formataTotalPresupuesto() {
    if (
      (isNaN(this.totalPresupuestoIndustrial)) ||
      (!isFinite(this.totalPresupuestoIndustrial))
    ) {
      this.totalPresupuestoIndustrial = 0;
    }

    this.totalPresupuestoIndustrialFormatado = this.shared
      .formataNumeroParaVisualizacion(
        this.totalPresupuestoIndustrial,
        2,
        ",",
        ".",
      );
    this.totalPresupuestoIndustrialFormatadoSinDecimales = this.shared
      .formataNumeroParaVisualizacion(
        this.totalPresupuestoIndustrial,
        0,
        "",
        ".",
      );
  }

  ngOnInit() {
    //alert(JSON.stringify(this.consumosManualesIndustrial[0]));
    this.formataTipoContrato();

    this.formataPotenciaCanalesTarifa();

    this.generaNombreDocumentoPDF();
    this.formataTotalPresupuesto();

    this.inicializaTextosYImagenesSegunPanelYInversores();

    this.inicializaElementos();
    //alert("2 -> " + this.trabajosARealizar);

    this.accion = localStorage.getItem("accion");

    this.formataFecha();
    this.formataConsumoAnual();

    //this.trabajosARealizar = this.trabajosARealizar.replace('<li','<li style="color: #ffd206;"')

    this.inicializaTrabajosARealizar();

    this.realizaCalculosYInicializaGraficos();

    if (this.accion == "PDF") {
      setTimeout(() => {
        this.print.nativeElement.click();
      }, 1900);
    }

    //}, 1000);
  }

  formataConsumoAnual() {
    this.consumoAnualTotalFormatado = this.shared
      .formataNumeroParaVisualizacion(
        this.potenciaPVGISIndustrial,
        0,
        ",",
        ".",
      );
  }

  realizaCalculosYInicializaGraficos() {
    this.calculaConsumosEstimados().then(
      () => {
        //arrFormatadoSinDecimalesPVGIS
        var totalDatosIntroducidos = [];
        var totalPorcentajeDatosIntroducidos = [];
        this.calculaDatosManualesIndustrial();
        this.consumosManualesIndustrial.forEach((element) => {
          totalDatosIntroducidos.push(element.TOTAL_CONSUMO);
          totalPorcentajeDatosIntroducidos.push(element.TOTAL_HORARIO_SOLAR);
        });
        //alert(JSON.stringify(this.arrFormatadoSinDecimalesPVGIS));
        //INDUSTRIAL
        this.chartOptionsConsumoTotalIndustrial = {
          series: [
            {
              name: "Consumo Total",
              data: totalDatosIntroducidos,
            },
            {
              name: "Producción",
              data: this.arrFormatadoSinDecimalesPVGIS,
            },
          ],
          chart: {
            id: "chartTotalIndustrial",
            toolbar: {
              show: false,
            },
            type: "area",
            height: 225,
            width: 500,
            zoom: {
              enabled: false,
            },
          },
          colors: ["#C6C6C6", "#FFE17E"],
          //colors: ["#008FFB", "#00E396"],
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: ["solid", "solid"],
            /*gradient: {
              opacityFrom: 0.6,
              opacityTo: 0.8
            }*/
          },
          legend: {
            show: false,
          },

          xaxis: {
            type: "category",
            categories: [
              "E",
              "F",
              "M",
              "A",
              "M",
              "J",
              "J",
              "A",
              "S",
              "O",
              "N",
              "D",
            ],
          },
        };

        var z = new ApexCharts(
          document.querySelector("#chartTotalIndustrial"),
          this.chartOptionsConsumoTotalIndustrial,
        );

        z.render();

        this.chartOptionsConsumoHSIndustrial = {
          series: [
            {
              name: "Consumo HS",
              data: totalPorcentajeDatosIntroducidos,
            },
            {
              name: "Producción",
              data: this.arrFormatadoSinDecimalesPVGIS,
            },
          ],
          chart: {
            id: "chartConsumoHSIndustrial",
            toolbar: {
              show: false,
            },
            type: "area",
            height: 225,
            width: 500,
            zoom: {
              enabled: false,
            },
          },
          colors: ["#C6C6C6", "#FFE17E"],
          //colors: ["#008FFB", "#00E396"],
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: ["solid", "gradient"],
            gradient: {
              opacityFrom: 0.0,
              opacityTo: 0.3,
            },
          },
          legend: {
            show: false,
          },

          xaxis: {
            type: "category",
            categories: [
              "E",
              "F",
              "M",
              "A",
              "M",
              "J",
              "J",
              "A",
              "S",
              "O",
              "N",
              "D",
            ],
          },
        };

        var w = new ApexCharts(
          document.querySelector("#chartConsumoHSIndustrial"),
          this.chartOptionsConsumoHSIndustrial,
        );
        w.render();

        //FINS AQUI INDUSTRIAL
        this.chartOptionsComparacionPunta = {
          series: [
            {
              name: "Producción energía sistema FV fijo",
              data: this.arrFormatadoPuntaSinDecimalesPVGIS,
            },
            {
              name: "Producción actual estimada",
              data: this.consumosEstimados.datosEstimadosPunta,
            },
          ],
          chart: {
            id: "chartPunta",
            toolbar: {
              show: false,
            },
            type: "area",
            height: 225,
            width: 500,
            zoom: {
              enabled: false,
            },
          },
          //colors: ["#008FFB", "#00E396", "#CED4DC"],
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: "gradient",
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0.8,
            },
          },
          legend: {
            show: false,
          },

          xaxis: {
            type: "category",
            categories: [
              "E",
              "F",
              "M",
              "A",
              "M",
              "J",
              "J",
              "A",
              "S",
              "O",
              "N",
              "D",
            ],
          },
        };

        // var a = new ApexCharts(
        //   document.querySelector("#chartPunta"),
        //   this.chartOptionsComparacionPunta,
        // );
        // a.render();

        this.chartOptionsComparacionLlano = {
          series: [
            {
              name: "Producción energía sistema FV fijo",
              data: this.arrFormatadoLlanoSinDecimalesPVGIS,
            },
            {
              name: "Producción actual estimada",
              data: this.consumosEstimados.datosEstimadosLlano,
            },
          ],
          chart: {
            id: "chartLlano",
            toolbar: {
              show: false,
            },
            type: "area",
            height: 225,
            zoom: {
              enabled: false,
            },
          },

          dataLabels: {
            enabled: false,
          },
          fill: {
            type: "gradient",
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0.8,
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            type: "category",
            categories: [
              "E",
              "F",
              "M",
              "A",
              "M",
              "J",
              "J",
              "A",
              "S",
              "O",
              "N",
              "D",
            ],
          },
        };

        // var b = new ApexCharts(
        //   document.querySelector("#chartLlano"),
        //   this.chartOptionsComparacionLlano,
        // );
        // b.render();

        this.chartOptionsComparacionValle = {
          series: [
            {
              name: "Producción energía sistema FV fijo",
              data: this.arrFormatadoValleSinDecimalesPVGIS,
            },
            {
              name: "Producción actual estimada",
              data: this.consumosEstimados.datosEstimadosValle,
            },
          ],
          chart: {
            id: "chartValle",
            toolbar: {
              show: false,
            },
            type: "area",
            height: 225,
            zoom: {
              enabled: false,
            },
          },
          //colors: ["#008FFB", "#00E396", "#CED4DC"],
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: "gradient",
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0.8,
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            type: "category",
            categories: [
              "E",
              "F",
              "M",
              "A",
              "M",
              "J",
              "J",
              "A",
              "S",
              "O",
              "N",
              "D",
            ],
          },
        };

        // var c = new ApexCharts(
        //   document.querySelector("#chartValle"),
        //   this.chartOptionsComparacionValle,
        // );
        // c.render();
      },
    );
  }

  inicializaTrabajosARealizar() {
    this.elementosTrabajosARealiar = [];
    this.trabajosARealizar.split("<li>").forEach((element) => {
      if (element != "<ul>") {
        this.elementosTrabajosARealiar.push(
          element.replace("</li>", "").replace("</ul>", ""),
        );
      }
    });
  }

  generaNombreDocumentoPDF() {
    this.nombreDocumentoPDF = this.numPresupuesto + " " +
      this.direccion.toUpperCase() + " " + this.nombreCliente.toUpperCase() +
      " " + this.poblacion.toUpperCase() + " " + this.potenciaPVGISIndustrial +
      " kW";
  }

  calculaDatosManualesIndustrial() {
    this.totalP1ConsumosManualesIndustrial = 0;
    this.totalP2ConsumosManualesIndustrial = 0;
    this.totalP3ConsumosManualesIndustrial = 0;
    this.totalP4ConsumosManualesIndustrial = 0;
    this.totalP5ConsumosManualesIndustrial = 0;
    this.totalP6ConsumosManualesIndustrial = 0;
    this.totalHS_P1ConsumosManualesIndustrial = 0;
    this.totalHS_P2ConsumosManualesIndustrial = 0;
    this.totalHS_P3ConsumosManualesIndustrial = 0;
    this.totalHS_P4ConsumosManualesIndustrial = 0;
    this.totalHS_P5ConsumosManualesIndustrial = 0;
    this.totalHS_P6ConsumosManualesIndustrial = 0;

    this.consumosManualesIndustrial.forEach((element) => {
      element.HS_P1 = Math.round(
        +element.P1 * (element.PORCENTAJE_USADO_P1) / 100,
      );
      element.HS_P2 = Math.round(
        +element.P2 * (element.PORCENTAJE_USADO_P2) / 100,
      );
      element.HS_P3 = Math.round(
        +element.P3 * (element.PORCENTAJE_USADO_P3) / 100,
      );
      element.HS_P4 = Math.round(
        +element.P4 * (element.PORCENTAJE_USADO_P4) / 100,
      );
      element.HS_P5 = Math.round(
        +element.P5 * (element.PORCENTAJE_USADO_P5) / 100,
      );
      element.HS_P6 = Math.round(
        +element.P6 * (element.PORCENTAJE_USADO_P6) / 100,
      );

      element.TOTAL_CONSUMO =
        +(Number(element.P1) + Number(element.P2) + Number(element.P3) +
          Number(element.P4) + Number(element.P5) + Number(element.P6));
      element.TOTAL_HORARIO_SOLAR =
        +(Number(element.HS_P1) + Number(element.HS_P2) +
          Number(element.HS_P3) + Number(element.HS_P4) +
          Number(element.HS_P5) + Number(element.HS_P6));

      this.totalP1ConsumosManualesIndustrial =
        this.totalP1ConsumosManualesIndustrial + Number(element.P1);
      this.totalP2ConsumosManualesIndustrial =
        this.totalP2ConsumosManualesIndustrial + Number(element.P2);
      this.totalP3ConsumosManualesIndustrial =
        this.totalP3ConsumosManualesIndustrial + Number(element.P3);
      this.totalP4ConsumosManualesIndustrial =
        this.totalP4ConsumosManualesIndustrial + Number(element.P4);
      this.totalP5ConsumosManualesIndustrial =
        this.totalP5ConsumosManualesIndustrial + Number(element.P5);
      this.totalP6ConsumosManualesIndustrial =
        this.totalP6ConsumosManualesIndustrial + Number(element.P6);

      this.totalHS_P1ConsumosManualesIndustrial =
        this.totalHS_P1ConsumosManualesIndustrial + Number(element.HS_P1);
      this.totalHS_P2ConsumosManualesIndustrial =
        this.totalHS_P2ConsumosManualesIndustrial + Number(element.HS_P2);
      this.totalHS_P3ConsumosManualesIndustrial =
        this.totalHS_P3ConsumosManualesIndustrial + Number(element.HS_P3);
      this.totalHS_P4ConsumosManualesIndustrial =
        this.totalHS_P4ConsumosManualesIndustrial + Number(element.HS_P4);
      this.totalHS_P5ConsumosManualesIndustrial =
        this.totalHS_P5ConsumosManualesIndustrial + Number(element.HS_P5);
      this.totalHS_P6ConsumosManualesIndustrial =
        this.totalHS_P6ConsumosManualesIndustrial + Number(element.HS_P6);
    });
  }

  doesFileExist(urlToFile: any) {
    var xhr = new XMLHttpRequest();
    xhr.open("HEAD", urlToFile, false);
    xhr.send();

    if (xhr.status == 404) {
      return false;
    } else {
      return true;
    }
  }

  previsualizacionPDF() {
    this.ocultarPDFGenerado = false;
  }

  ngAfterViewInit() {
    this.spinner.show();
    try {
      //this.geocodePVGIS();
    } catch (error) {
    }

    try {
      this.formataValoracionEconomica();
    } catch (error) {
    }

    try {
      //      this.calculaConsumosEstimados();
    } catch (error) {
    }

    this.consumosCargados = true;
  }

  formataValoracionEconomica() {
    this.valoracionEconomica =
      '<table><tr><td colspan="3"><p>El suministro e instalación de los materiales y servicios anteriormente detallados dan como resultado la siguiente valoración económica:</p><p style="text-align:center">TOTAL PRESUPUESTO ' +
      (+this.precioFinal + this.importeAdicional) +
      ' €+IVA* <br/></p><i style="text-align:center"> * IVA: 10% para vivienda habitual y 21% en el resto de casos.</i><hr /></td></tr><tr><td colspan="3"><table><tr><td> CUOTA FINANCIADA </td><td> XXX €</td></tr><tr><td> PORCENTAJE DE AUTOCONSUMO MÁXIMO </td><td>45 %</td></tr></table></td></tr><tr><td colspan="3"><h4>RECUERDE: AUMENTE SU AUTOSUFICIENCIA INSTALANDO UNA BATERÍA</h4></td></tr><tr><td colspan="3"><b> OPCIONES </b></td></tr><tr><td colspan="3"><b>- Realización de Proyecto para la solicitud de la reducción de IBI municipal. (SEGÚN MUNICIPIO)</b> </td></tr><tr><td colspan="3"><b>- Proyecto para la legalización de inyección a Red del excedente eléctrico de producción. 640.00€</b></td></tr><tr><td colspan="3"><b>- Suministro e instalación de Batería Luna 15 kW</b></td></tr><tr><td colspan="3"><br/><br/></td></tr><tr><td colspan="3"><h4>No se incluye</h4></td></tr><tr><td><p>- Licencia de Obra menor.                         </p><p>- Todo lo no especificado de forma clara y directa. </p></td><td></td>                    </tr><tr><td colspan="3"><h4>FORMA DE PAGO: </h4><br/><br/></td></tr><tr><td colspan="2"><p><b>50% a la firma del contrato.</b></p><p><b>30% Inicio de Obra.</b></p><p><b>20% Puesta en marcha. </b></p></td><td><p><b>Firma: </b></p><br /><div style="   width: 270px;height: 120px;border-left: 1px solid black;border-right: 1px solid black;border-top: 1px solid black;border-bottom: 1px solid black;"></div>        </td></tr></table>';
  }

  trackByIdx(index: number, _: any): any {
    return index;
  }

  async obtenerInforme() {
  }

  async geocodePVGIS() {
    var s = [];
    this.totalPVGIS = 0;
    this.totalPVGISFormatado = "";
    this.arrFormatadoSinDecimalesPVGIS = [];
    this.arrFormatadoValleSinDecimalesPVGIS = [];
    this.arrFormatadoPVGIS = [];
    this.arrFormatadoLlanoSinDecimalesPVGIS = [];
    this.arrFormatadoPuntaSinDecimalesPVGIS = [];
    this.totalVallePVGIS = 0;
    this.totalLlanoPVGIS = 0;
    this.totalPuntaPVGIS = 0;
    this.arrRepercusionesEconomicasPuntas = [];
    this.arrRepercusionesEconomicasLlanos = [];
    this.arrRepercusionesEconomicasValles = [];
    this.totalRepercusionesEconomicasPuntas = 0;
    this.totalRepercusionesEconomicasLlanos = 0;
    this.totalRepercusionesEconomicasValles = 0;
    this.arrTotalRepercusionesEconomicas = [];
    this.totalRepercusionesEconomicas = 0;
    this.totalRepercusionesEconomicasFormatado = "";
    this.totalRepercusiones25a = "";
    this.porcentajeSobreConsumoEstimado = "";

    try {
      //41.927, 2.251
      const result = await this.http.pvgis(
        this.azimuthPVGISIndustrial,
        this.latCoordIndustrial,
        this.lonCoordIndustrial,
        this.potenciaPVGISIndustrial,
        +this.inclinacionPVGISIndustrial,
        +this.perdidasPVGISIndustrial,
      );
      s = result["outputs"]["monthly"]["fixed"];

      let iteracion = 0;
      s.forEach((element) => {
        this.totalPVGIS = Math.round(this.totalPVGIS + element.E_m);
        this.arrFormatadoSinDecimalesPVGIS.push(Math.round(element.E_m));
        this.arrFormatadoValleSinDecimalesPVGIS.push(
          Math.round((Math.round(element.E_m / 30)) * 8),
        );
        this.arrFormatadoPVGIS.push(element.E_m);

        this.arrFormatadoLlanoSinDecimalesPVGIS.push(
          Math.round(
            (element.E_m - Math.round((Math.round(element.E_m / 30)) * 8)) *
              0.45,
          ),
        );

        this.arrFormatadoPuntaSinDecimalesPVGIS.push(
          Math.round(
            element.E_m -
              ((element.E_m - Math.round((Math.round(element.E_m / 30)) * 8)) *
                0.45) -
              ((Math.round(element.E_m / 30)) * 8),
          ),
        );

        iteracion++;
        //FIN ITERACIONES ARRAYS
        if (iteracion === (s.length)) {
          if ((isNaN(this.totalPVGIS)) || (!isFinite(this.totalPVGIS))) {
            this.totalPVGIS = 0;
          }

          this.totalPVGISFormatado = this.shared.formataNumeroParaVisualizacion(
            this.totalPVGIS,
            0,
            ",",
            ".",
          );

          this.arrFormatadoValleSinDecimalesPVGIS.forEach((element) => {
            this.arrRepercusionesEconomicasValles.push(
              Math.round((element * 0.12) * 100) / 100,
            );
          });

          this.totalVallePVGIS = Math.round((this.totalPVGIS / 30) * 8);
          this.arrFormatadoLlanoSinDecimalesPVGIS.forEach((element) => {
            this.totalLlanoPVGIS = this.totalLlanoPVGIS + Math.round(element);
            this.arrRepercusionesEconomicasLlanos.push(
              Math.round((element * 0.2) * 100) / 100,
            );
          });

          this.arrFormatadoPuntaSinDecimalesPVGIS.forEach((element) => {
            this.totalPuntaPVGIS = this.totalPuntaPVGIS + Math.round(element);
            this.arrRepercusionesEconomicasPuntas.push(
              Math.round((element * 0.28) * 100) / 100,
            );
          });

          this.arrRepercusionesEconomicasPuntas.forEach((element) => {
            this.totalRepercusionesEconomicasPuntas =
              this.totalRepercusionesEconomicasPuntas + +(Math.round(element));
          });

          this.arrRepercusionesEconomicasLlanos.forEach((element) => {
            this.totalRepercusionesEconomicasLlanos =
              this.totalRepercusionesEconomicasLlanos + +(Math.round(element));
          });

          this.arrRepercusionesEconomicasValles.forEach((element) => {
            this.totalRepercusionesEconomicasValles =
              this.totalRepercusionesEconomicasValles + +(Math.round(element));
          });

          for (var i = 0; i <= 11; i++) {
            this.arrTotalRepercusionesEconomicas.push(
              (this.arrRepercusionesEconomicasPuntas[i] +
                this.arrRepercusionesEconomicasLlanos[i] +
                this.arrRepercusionesEconomicasValles[i]).toFixed(2),
            );
          }

          this.arrTotalRepercusionesEconomicas.forEach((element) => {
            this.totalRepercusionesEconomicas =
              this.totalRepercusionesEconomicas + +(Math.round(element));
          });

          if (
            (isNaN(this.totalRepercusionesEconomicas)) ||
            (!isFinite(this.totalRepercusionesEconomicas))
          ) {
            this.totalRepercusionesEconomicas = 0;
          }

          this.totalRepercusionesEconomicasFormatado = this.shared
            .formataNumeroParaVisualizacion(
              this.totalRepercusionesEconomicas,
              0,
              "",
              ".",
            );
          this.totalRepercusiones25a = this.shared
            .formataNumeroParaVisualizacion(
              +Math.round(this.totalRepercusionesEconomicas * 25),
              0,
              "",
              ".",
            );
          //(this.totalPVGIS/30)*8 | number: '1.0-0'

          //this.mergeOption = { series: this.arrFormatadoPVGIS };
          /*eccharts */
          this.initOpts = {
            renderer: "svg",
            width: 1100,
            height: 600,
          };

          this.initOptsMinimizado = {
            renderer: "canvas",
            width: 100,
            height: 100,
          };

          this.options = {
            title: {
              id: "title",
              show: true,
              text: "Producción de energía mensual del sistema FV fijo",
            },
            color: ["#3398DB"],
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                data: [
                  "Ene",
                  "Feb",
                  "Mar",
                  "Abr",
                  "May",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dic",
                ],
                axisTick: {
                  alignWithLabel: true,
                },
              },
            ],
            yAxis: [{
              type: "value",
            }],
            yAxisLabel: "aaa",
            showYAxisLabel: true,
            series: [{
              name: "Counters",
              type: "bar",
              barWidth: "60%",
              data: this.arrFormatadoPVGIS,
              label: {
                normal: {
                  show: true,
                  position: "top",
                },
              },
            }],
          };

          var result = (this.totalPVGIS / +this.potenciaPVGISIndustrial) * 100;
          if ((isNaN(result)) || (!isFinite(result))) {
            result = 0;
          }

          this.porcentajeSobreConsumoEstimado = this.shared
            .formataNumeroParaVisualizacion(result, 1, ",", ".");

          //CÁLCULO AHORRO ANUAL
          this.calculoAhorroAnual();

          //totalpvgis

          this.spinner.hide();
        }
      });
    } catch (e) {
      //console.log(e)
    }
  }

  calculoBalanceFinanciero() {
    var tmpVan = 0;
    this.sumaAlgebraica = 0;
    this.balanceFinancieroIndustrial = [];
    var ingresoFinancieroIndustrial = [];
    this.totalAhorroAnualIndustrial25AFormatado = 0;
    this.TIR = 0;
    this.VAN = 0;

    this.anoRecuperacionInversionBalanceFinancieroIndustrial = 0;
    this.sumaAlgebraica = this.subidaFacturaLuzIndustrial -
      this.perdidaEficienciaPanel;

    for (var i = 0; i <= 24; i++) {
      if (i == 0) {
        this.balanceFinancieroIndustrial.push(
          {
            ANO: i,
            INGRESO: this.totalAhorroAnualIndustrial,
            BALANCE: Math.ceil(
              (-1 * this.totalPresupuestoIndustrial) +
                this.totalAhorroAnualIndustrial,
            ),
          },
        );

        ingresoFinancieroIndustrial.push(this.totalAhorroAnualIndustrial);
      } else {
        var calculoIngreso = 0;
        calculoIngreso = ((this.sumaAlgebraica / 100) *
          this.balanceFinancieroIndustrial[i - 1].INGRESO) +
          this.balanceFinancieroIndustrial[i - 1].INGRESO;

        ingresoFinancieroIndustrial.push(calculoIngreso);
        this.balanceFinancieroIndustrial.push(
          {
            ANO: i,
            INGRESO: calculoIngreso,
            BALANCE: Math.ceil(
              this.balanceFinancieroIndustrial[i - 1].BALANCE + calculoIngreso,
            ),
          },
        );

        if (i == 24) {
          this.calculoRenting();

          this.totalAhorroAnualIndustrial25AFormatado = this.shared
            .formataNumeroParaVisualizacion(
              this.balanceFinancieroIndustrial[24].BALANCE,
              2,
              ",",
              ".",
            );

          var valoresGraficaBalance = [];
          this.balanceFinancieroIndustrial.forEach((element) => {
            valoresGraficaBalance.push(element.BALANCE);
          });

          this.optionsAmortizacionIndustrial = {
            title: {
              id: "title",
              show: false,
              text: "",
            },
            color: ["#FFE17E"],
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                data: [
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "10",
                  "11",
                  "12",
                  "13",
                  "14",
                  "15",
                  "16",
                  "17",
                  "18",
                  "19",
                  "20",
                  "21",
                  "22",
                  "23",
                  "24",
                ],
                axisTick: {
                  alignWithLabel: true,
                },
              },
            ],
            yAxis: [{
              type: "value",
            }],
            yAxisLabel: "aaa",
            showYAxisLabel: true,
            series: [{
              name: "Counters",
              type: "bar",
              barWidth: "60%",
              data: valoresGraficaBalance,
              label: {
                normal: {
                  show: false,
                  position: "top",
                },
              },
            }],
          };
          tmpVan = Math.ceil(
            this.getNPV(
              0,
              -this.totalPresupuestoIndustrial,
              ingresoFinancieroIndustrial,
            ),
          );
          if ((isNaN(tmpVan)) || (!isFinite(tmpVan))) {
            tmpVan = 0;
          }

          this.VAN = this.shared.formataNumeroParaVisualizacion(
            tmpVan,
            0,
            "",
            ".",
          );
          ingresoFinancieroIndustrial.unshift(
            -1 * this.totalPresupuestoIndustrial,
          );
          this.TIR = parseInt(
            this.IRRCalc(ingresoFinancieroIndustrial).toString(),
          );
          var iterador = 1;
          valoresGraficaBalance.forEach((element) => {
            if ((Math.sign(element) == 1) || (Math.sign(element) == 0)) {
              this.anoRecuperacionInversionBalanceFinancieroIndustrial =
                iterador;
            } else {
              iterador++;
            }
          });

          this.cargandoCalculos = false;
        }
      }
    }
  }

  calculoRenting() {
    this.importeFijo = +this.totalPresupuestoIndustrial;
    this.importePortatil = 0;
    this.importeIntangible = 0;
    this.totalProforma = this.importeFijo + this.importePortatil +
      this.importeIntangible;

    //IMPORTE RIESGO
    if (this.totalProforma < 50000) {
      this.gestion = this.totalProforma * (7.50 / 100);
    } else if (this.totalProforma < 100000) {
      this.gestion = this.totalProforma * (5 / 100);
    } else if (this.totalProforma >= 100000) {
      this.gestion = this.totalProforma * (3.75 / 100);
    }

    this.importeRiesgo = this.totalProforma + this.seguro + this.gestion;

    //INTERES MENSUAL
    this.interesMensual = (this.interesAnual / 12) / 100;
    //alert("INTERES MENSUAL " + interesMensual);

    this.totalCalculoCuotaRentingIndustrial =
      ((this.importeRiesgo * Math.pow(1 + this.interesMensual, this.meses)) -
          this.VR) *
        (this.interesMensual * Math.pow(1 + this.interesMensual, 84 - 1)) /
        ((Math.pow(1 + this.interesMensual, 2 * this.meses)) -
          (Math.pow(1 + this.interesMensual, this.meses))) + this.comAdmMensual;

    if (
      (isNaN(this.totalCalculoCuotaRentingIndustrial)) ||
      (!isFinite(this.totalCalculoCuotaRentingIndustrial))
    ) {
      this.totalCalculoCuotaRentingIndustrial = 0;
    }

    this.totalCalculoCuotaRentingIndustrial = this.shared
      .formataNumeroParaVisualizacion(
        this.totalCalculoCuotaRentingIndustrial,
        2,
        ",",
        ".",
      );

    //(IMPORTE RIESGO * POTENCIA(1+Interes mensual+ComAdmMensual+numMeses)-valorRedisual) * (InteresMensual* POTENCIA(1+InteresMensual+ComAdmMensual+(numMeses-1)))/((POTENCIA((1+InteresMensual)*(numMeses))))
  }

  getNPV(rate: any, initialCost: any, cashFlows: any) {
    var npv = initialCost;

    for (var i = 0; i < cashFlows.length; i++) {
      npv += cashFlows[i] / Math.pow(rate / 100 + 1, i + 1);
    }

    return npv;
  }

  IRRCalc(CArray: any) {
    var min = 0.0;
    var max = 1.0;
    var c = 0;

    do {
      var guest = (min + max) / 2;
      var NPV = 0;
      for (var j = 0; j < CArray.length; j++) {
        NPV += CArray[j] / Math.pow(1 + guest, j);
      }
      if (NPV > 0) {
        min = guest;
        c++;
      } else {
        max = guest;
        c++;
      }
      if (c >= 15) return guest * 100;
    } while (Math.abs(NPV) > 0.000001);

    return guest * 100;

    //return this.irr(CArray);
  }

  calculoAhorroAnual() {
    var coeficientesP1 = [];
    var coeficientesP2 = [];
    var coeficientesP3 = [];
    var coeficientesP4 = [];
    var coeficientesP5 = [];
    var coeficientesP6 = [];

    var arrAhorroTmpP1Industrial = [];
    var arrAhorroTmpP2Industrial = [];
    var arrAhorroTmpP3Industrial = [];
    var arrAhorroTmpP4Industrial = [];
    var arrAhorroTmpP5Industrial = [];
    var arrAhorroTmpP6Industrial = [];

    var arrAhorroP1Industrial = [];
    var arrAhorroP2Industrial = [];
    var arrAhorroP3Industrial = [];
    var arrAhorroP4Industrial = [];
    var arrAhorroP5Industrial = [];
    var arrAhorroP6Industrial = [];

    this.totalAhorroAnualIndustrial = 0;
    this.totalAhorroAnualIndustrialFormatado = 0;

    //this.arrFormatadoSinDecimalesPVGIS = [10409,15160,23779,28165,34288,35810,37347,32993,25359,18850,11487,9058];

    if (!this.tarifaDe6Canales) {
      coeficientesP1 = [0, 0, 0, 0, 96.19, 97.94, 98.40, 95.97, 97.81, 0, 0, 0];
      coeficientesP2 = [
        100,
        100,
        100,
        100,
        3.80,
        2.05,
        1.59,
        4.02,
        2.18,
        100,
        100,
        100,
      ];
      coeficientesP3 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      //alert(this.consumosManualesIndustrial[0].PRECIO_KWH_P1);
      for (var i = 0; i <= this.arrFormatadoSinDecimalesPVGIS.length - 1; i++) {
        arrAhorroTmpP1Industrial.push(
          Math.ceil(
            this.arrFormatadoSinDecimalesPVGIS[i] * (coeficientesP1[i] / 100),
          ),
        );
        arrAhorroTmpP2Industrial.push(
          Math.ceil(
            this.arrFormatadoSinDecimalesPVGIS[i] * (coeficientesP2[i] / 100),
          ),
        );
        arrAhorroTmpP3Industrial.push(
          Math.ceil(
            this.arrFormatadoSinDecimalesPVGIS[i] * (coeficientesP3[i] / 100),
          ),
        );

        arrAhorroP1Industrial.push(
          arrAhorroTmpP1Industrial[i] *
            Number(this.consumosManualesIndustrial[0].PRECIO_KWH_P1),
        );

        arrAhorroP2Industrial.push(
          arrAhorroTmpP2Industrial[i] *
            Number(this.consumosManualesIndustrial[0].PRECIO_KWH_P2),
        );

        arrAhorroP3Industrial.push(
          arrAhorroTmpP3Industrial[i] *
            Number(this.consumosManualesIndustrial[0].PRECIO_KWH_P3),
        );

        this.totalAhorroAnualIndustrial = this.totalAhorroAnualIndustrial +
          (arrAhorroP1Industrial[i] + arrAhorroP2Industrial[i] +
            arrAhorroP3Industrial[i]);

        if (i == this.arrFormatadoSinDecimalesPVGIS.length - 1) {
          if (
            (isNaN(this.totalAhorroAnualIndustrial)) ||
            (!isFinite(this.totalAhorroAnualIndustrial))
          ) {
            this.totalAhorroAnualIndustrial = 0;
          }

          this.totalAhorroAnualIndustrialFormatado = this.shared
            .formataNumeroParaVisualizacion(
              this.totalAhorroAnualIndustrial,
              2,
              ",",
              ".",
            );

          //this.totalAhorroAnualIndustrial25A = Number(this.totalAhorroAnualIndustrial) * 25;
          //this.totalAhorroAnualIndustrial25AFormatado = this.shared.formataNumeroParaVisualizacion(this.totalAhorroAnualIndustrial25A,2,',',".");
          this.calculoBalanceFinanciero();
        }
      }
    } else {
      coeficientesP1 = [44.57, 41.66, 0, 0, 0, 87.69, 88.26, 0, 0, 0, 0, 46.78];
      coeficientesP2 = [55.43, 58.34, 0, 0, 0, 12.31, 11.74, 0, 0, 0, 0, 53.22];
      coeficientesP3 = [0, 0, 19.25, 0, 0, 0, 0, 0, 56.31, 0, 10.81, 0];
      coeficientesP4 = [0, 0, 80.75, 0, 0, 0, 0, 0, 43.69, 0, 89.19, 0];
      coeficientesP5 = [0, 0, 0, 100, 100, 0, 0, 0, 0, 100, 0, 0];
      coeficientesP6 = [0, 0, 0, 0, 0, 0, 0, 100, 0, 0, 0, 0];

      //alert(this.consumosManualesIndustrial[0].PRECIO_KWH_P1);
      for (var i = 0; i <= this.arrFormatadoSinDecimalesPVGIS.length - 1; i++) {
        arrAhorroTmpP1Industrial.push(
          Math.ceil(
            this.arrFormatadoSinDecimalesPVGIS[i] * (coeficientesP1[i] / 100),
          ),
        );
        arrAhorroTmpP2Industrial.push(
          Math.ceil(
            this.arrFormatadoSinDecimalesPVGIS[i] * (coeficientesP2[i] / 100),
          ),
        );
        arrAhorroTmpP3Industrial.push(
          Math.ceil(
            this.arrFormatadoSinDecimalesPVGIS[i] * (coeficientesP3[i] / 100),
          ),
        );
        arrAhorroTmpP4Industrial.push(
          Math.ceil(
            this.arrFormatadoSinDecimalesPVGIS[i] * (coeficientesP4[i] / 100),
          ),
        );
        arrAhorroTmpP5Industrial.push(
          Math.ceil(
            this.arrFormatadoSinDecimalesPVGIS[i] * (coeficientesP5[i] / 100),
          ),
        );
        arrAhorroTmpP6Industrial.push(
          Math.ceil(
            this.arrFormatadoSinDecimalesPVGIS[i] * (coeficientesP6[i] / 100),
          ),
        );

        arrAhorroP1Industrial.push(
          arrAhorroTmpP1Industrial[i] *
            Number(this.consumosManualesIndustrial[0].PRECIO_KWH_P1),
        );

        arrAhorroP2Industrial.push(
          arrAhorroTmpP2Industrial[i] *
            Number(this.consumosManualesIndustrial[0].PRECIO_KWH_P2),
        );

        arrAhorroP3Industrial.push(
          arrAhorroTmpP3Industrial[i] *
            Number(this.consumosManualesIndustrial[0].PRECIO_KWH_P3),
        );

        arrAhorroP4Industrial.push(
          arrAhorroTmpP4Industrial[i] *
            Number(this.consumosManualesIndustrial[0].PRECIO_KWH_P4),
        );

        arrAhorroP5Industrial.push(
          arrAhorroTmpP5Industrial[i] *
            Number(this.consumosManualesIndustrial[0].PRECIO_KWH_P5),
        );

        arrAhorroP6Industrial.push(
          arrAhorroTmpP6Industrial[i] *
            Number(this.consumosManualesIndustrial[0].PRECIO_KWH_P6),
        );

        this.totalAhorroAnualIndustrial = this.totalAhorroAnualIndustrial +
          (arrAhorroP1Industrial[i] + arrAhorroP2Industrial[i] +
            arrAhorroP3Industrial[i] + arrAhorroP4Industrial[i] +
            arrAhorroP5Industrial[i] + arrAhorroP6Industrial[i]);

        if (i == this.arrFormatadoSinDecimalesPVGIS.length - 1) {
          if (
            (isNaN(this.totalAhorroAnualIndustrial)) ||
            (!isFinite(this.totalAhorroAnualIndustrial))
          ) {
            this.totalAhorroAnualIndustrial = 0;
          }

          this.totalAhorroAnualIndustrialFormatado = this.shared
            .formataNumeroParaVisualizacion(
              this.totalAhorroAnualIndustrial,
              2,
              ",",
              ".",
            );

          this.calculoBalanceFinanciero();
        }
      }
    }
  }

  async calculaConsumosEstimados() {
    this.consumosEstimados.consumoAnualTotal = 0;
    this.totalPorcentajeAhorroPunta = 0;
    this.totalPorcentajeAhorroLlano = 0;
    this.totalPorcentajeAhorroValle = 0;

    await this.geocodePVGIS().then(
      () => {
        this.consumosEstimados.consumoAnualTotal = +this
          .potenciaPVGISIndustrial;

        for (var i = 0; i <= 11; i++) {
          if ((i >= 4) && (i <= 7)) {
            //=(potencia-(potencia/14)*8)/4
            var resultado = Math.round(
              (+this.potenciaPVGISIndustrial -
                (+this.potenciaPVGISIndustrial / 14) * 8) / 4,
            );
            this.consumosEstimados.consumos[i] = resultado;
          } else {
            this.consumosEstimados.consumos[i] = Math.round(
              +this.potenciaPVGISIndustrial / 14,
            );
          }
        }

        this.consumosEstimados.datosEstimadosPunta = [];
        this.consumosEstimados.datosEstimadosLlano = [];
        this.consumosEstimados.datosEstimadosValle = [];

        this.consumosEstimados.consumos.forEach((element) => {
          this.consumosEstimados.datosEstimadosPunta.push(
            Math.round(
              element * 0.4,
            ),
          );

          this.consumosEstimados.datosEstimadosLlano.push(
            Math.round(
              element * 0.45,
            ),
          );

          this.consumosEstimados.datosEstimadosValle.push(
            Math.round(
              element * 0.15,
            ),
          );
        });

        this.consumosEstimados.consumoAnualEstimadoPunta = 0;
        this.consumosEstimados.consumoAnualEstimadoLlano = 0;
        this.consumosEstimados.consumoAnualEstimadoValle = 0;

        for (var i = 0; i <= 11; i++) {
          this.consumosEstimados.consumoAnualEstimadoPunta =
            this.consumosEstimados.consumoAnualEstimadoPunta +
            this.consumosEstimados.datosEstimadosPunta[i];
          this.consumosEstimados.consumoAnualEstimadoLlano =
            this.consumosEstimados.consumoAnualEstimadoLlano +
            this.consumosEstimados.datosEstimadosLlano[i];
          this.consumosEstimados.consumoAnualEstimadoValle =
            this.consumosEstimados.consumoAnualEstimadoValle +
            this.consumosEstimados.datosEstimadosValle[i];
        }

        var totalPVGISPunta = eval(
          this.arrFormatadoPuntaSinDecimalesPVGIS.join("+"),
        );
        var totalDatosPunta = eval(
          this.consumosEstimados.datosEstimadosPunta.join("+"),
        );

        this.totalPorcentajeAhorroPunta =
          +((totalPVGISPunta / totalDatosPunta) * 100);

        if (
          (isNaN(this.totalPorcentajeAhorroPunta)) ||
          (!isFinite(this.totalPorcentajeAhorroPunta))
        ) {
          this.totalPorcentajeAhorroPunta = 0;
        }

        this.totalPorcentajeAhorroPunta = this.shared
          .formataNumeroParaVisualizacion(
            this.totalPorcentajeAhorroPunta,
            2,
            ",",
            ".",
          );

        var totalPVGISLlano = eval(
          this.arrFormatadoLlanoSinDecimalesPVGIS.join("+"),
        );
        var totalDatosLlano = eval(
          this.consumosEstimados.datosEstimadosLlano.join("+"),
        );

        this.totalPorcentajeAhorroLlano =
          +((totalPVGISLlano / totalDatosLlano) * 100);

        if (
          (isNaN(this.totalPorcentajeAhorroLlano)) ||
          (!isFinite(this.totalPorcentajeAhorroLlano))
        ) {
          this.totalPorcentajeAhorroLlano = 0;
        }

        this.totalPorcentajeAhorroLlano = this.shared
          .formataNumeroParaVisualizacion(
            this.totalPorcentajeAhorroLlano,
            2,
            ",",
            ".",
          );

        var totalPVGISValle = eval(
          this.arrFormatadoValleSinDecimalesPVGIS.join("+"),
        );
        var totalDatosValle = eval(
          this.consumosEstimados.datosEstimadosValle.join("+"),
        );

        this.totalPorcentajeAhorroValle =
          +((totalPVGISValle / totalDatosValle) * 100);

        if (
          (isNaN(this.totalPorcentajeAhorroValle)) ||
          (!isFinite(this.totalPorcentajeAhorroValle))
        ) {
          this.totalPorcentajeAhorroValle = 0;
        }

        this.totalPorcentajeAhorroValle = this.shared
          .formataNumeroParaVisualizacion(
            this.totalPorcentajeAhorroValle,
            2,
            ",",
            ".",
          );
      },
    ).catch((e) => {
      console.log(e);
    });
  }

  wait(ms: any) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
}
