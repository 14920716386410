<div class="row" style="padding-bottom: 10px">
  <div class="col-12 col-sm-3 align-self-center">
    <button mat-raised-button color="primary" (click)="nuevoPresupuesto()">
      <i class="fas fa-plus"></i>
      <span> Nuevo presupuesto</span>
    </button>
  </div>
  <div class="col-12 offset-sm-6 col-sm-3 text-sm-right">
    <!-- 
      <mat-form-field>
          <input matInput [(ngModel)]="filtradoGrd" (keyup)="filtrar($event.target.value)" placeholder="Buscar">
      </mat-form-field>
    -->
  </div>
</div>

<div class="col-sm-12">
  <angular-slickgrid
    *ngIf="pageReady"
    gridId="grid1"
    [columnDefinitions]="columnDefinitions1"
    [gridOptions]="gridOptions1"
    [dataset]="dataset1"
    gridWidth="100%"
  >
  </angular-slickgrid>
</div>

<!-- <a [href]="emailstring" target="_top">aaaaaaaaaaaaaaaa</a> -->

<!-- <div *ngIf="this.grupos.length == 0" class="text-center centroPantalla">
  <h5 style="color: #808080; font-style: italic;">No hay grupos de dominio creados para esta sede.</h5>
</div> -->
