import { Component, OnInit, ViewChild } from "@angular/core";

import { NgxSpinnerService } from "ngx-spinner";
import {
  Router,
} from "@angular/router";
import { HttpService } from "src/app/services/http/http.service";

import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NotificationService } from "src/app/services/notification/notification.service";
//import { INV_CreacionEdicionCliente } from './INV_creacion-edicion-cliente.component';

import {
  AngularGridInstance,
  Column,
  ExtensionName,
  FieldType,
  Formatter,
  GridOption,
} from "angular-slickgrid";

import { CreacionEdicionFamilia } from "./creacion-edicion-familia.component";
import { MNT_Familias } from "@shared/MNT.familias.entity";

@Component({
  selector: "grid-familias",
  templateUrl: "./grid-familias.component.html",
  styleUrls: ["./grid-familias.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class GridFamiliasComponent implements OnInit {
  pageReady = false;
  tituloModal = "";
  filtradoGrd = "";

  displayedColumns: string[] = ["NOMBRE", "editarGrupo"];
  angularGrid: AngularGridInstance;

  columnDefinitions1: Column[];
  columnDefinitionsHiddenDefault: Column[];
  dataset1: any[] = [];
  gridOptions1: GridOption;

  rowData = [];
  filtrado = new Array<MNT_Familias>();
  public familias: Array<MNT_Familias>;

  source: Array<any> = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public router: Router,
    public http: HttpService,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    public dialog: MatDialog) { }

  async ngOnInit() {

    const actionFormatter: Formatter = (
    ) => {
      return `<div class="fake-hyperlink">Acciones <i class="fa fa-caret-down"></i></div>`;
    };



    this.columnDefinitions1 = [
      {
        id: "id",
        field: "id",
        name: "ID",
        sortable: true,
        filterable: true,
        //maxWidth: 75,
      },
      {
        id: "nombre",
        name: "Nombre",
        field: "NOMBRE",
        //width: 150,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "acciones",
        name: "Acciones",
        field: "acciones",
        columnGroup: "Acciones",
        excludeFromExport: true,
        formatter: actionFormatter,
        cellMenu: {
          hideCloseButton: false,
          //width: 200,
          menuUsabilityOverride: () => {
            return true;
          },
          commandItems: [
            { command: "editar", title: "Editar", positionOrder: 60 },
            { divider: true, command: "", positionOrder: 65 },
            {
              command: "eliminar",
              title: "Eliminar",
              cssClass: "red",
              positionOrder: 66,
            },
          ],
        },
      },
    ];

    this.gridOptions1 = {
      columnPicker: {
        hideForceFitButton: true,
        hideSyncResizeButton: true,
        onColumnsChanged: (e, args) => {
          console.log(
            "Column selection changed from Column Picker, visible columns: ",
            args.columns
          );
        },
      },
      autoEdit: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 28,
      //enableAutoResize: true,
      forceFitColumns: true,
      enableGridMenu: true,
      autoHeight: true,
      // enable the filtering but hide the user filter row since we use our own single filter
      enableFiltering: true,
      showHeaderRow: true, // hide the filter row (header row)

      alwaysShowVerticalScroll: false,
      enableColumnPicker: true,
      enableRowSelection: true,
      enablePagination: true,
      pagination: {
        pageSizes: [15, 30, 50, 100],
        pageSize: 15,
      },
      enableCellMenu: true,
      /*
          autoResize: {
            containerId: 'container',
            sidePadding: 5
          },
          */
      rowSelectionOptions: {
        // True (Single Selection), False (Multiple Selections)
        selectActiveRow: false,
      },
      enableAutoResize: true,
      enableCellNavigation: true,
      enableCheckboxSelector: true,
      multiSelect: false,
      enableSorting: true,
      excelExportOptions: {
        exportWithFormatter: true,
        customColumnWidth: 15,
        columnHeaderStyle: { font: { bold: true, italic: true } },
      },

      enableContextMenu: true,

      cellMenu: {
        onCommand: (e, args) => this.executeCommand(e, args),
        onOptionSelected: (e, args) => {
          const dataContext = args && args.dataContext;
          if (dataContext && dataContext.hasOwnProperty("completed")) {
            dataContext.completed = args.item.option;
            this.angularGrid.gridService.updateItem(dataContext);
          }
        },
        onBeforeMenuClose: (e, args) =>
          console.log("Cell Menu is closing", args),
      },
    };

    await this.inicializaListadoFamilias();
    this.pageReady = true;
    this.spinner.hide();
  }

  onSelectedRowsChanged(args) {
    if (Array.isArray(args.rows)) {
      args.rows.map((idx) => {
         
      });
    }
  }

  executeCommand(e, args) {
    const command = args.command;
    const dataContext = args.dataContext;

     
    switch (command) {
      case "editar":
        this.editarFamilia(dataContext);
        break;
      case "eliminar":
        this.eliminarFamilia(dataContext.id);
        break;
    }
  }

  editarFamilia(_articulo) {
    this.abrirDialog(true, _articulo);
  }

  abrirDialog(_edicion = false, _familia: MNT_Familias) {
    var _fam = new MNT_Familias(0, "");

    if (_edicion) {
      this.tituloModal = "Editar família";
      _fam = _familia;
    } else {
      this.tituloModal = "Nueva família";
    }

    const dialogRef = this.dialog.open(CreacionEdicionFamilia, {
      width: "75%",
      data: {
        tituloModal: this.tituloModal,
        edicion: _edicion,
        familia: _fam,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
       


      if (result) {
        if (result.edicion) {
          //UPDATE
           
           
          this.spinner.show();

          this.http.actualizarFamilia(result.familia).then((resultat) => {
            console.log(
              "Família actualizada correctamente" + JSON.stringify(resultat)
            );
            this.notifyService.showMessage(
              "Família actualizada correctamente",
              "Success",
              false
            );
            this.inicializaListadoFamilias();
            this.spinner.hide();
          });
        } //CREATE
        else {
           
          this.spinner.show();

          this.http.guardarFamilia(result.familia).then((resultat) => {
            console.log(
              "Família creada correctamente" + JSON.stringify(resultat)
            );
            this.notifyService.showMessage(
              "Família creada correctamente",
              "Success",
              false
            );
            this.inicializaListadoFamilias();
            this.spinner.hide();
          });
        }
      }
    });
  }

  eliminarFamilia(_id_familia) {
    if (confirm("Estás seguro? ")) {
      this.spinner.show();
      this.http.eliminarFamilia(_id_familia).then(
        () => {
          this.spinner.hide();
          this.notifyService.showMessage(
            "Operación efectuada correctamente",
            "Success",
            false
          );
          this.inicializaListadoFamilias();
        },
        () => {
          this.spinner.hide();
          this.notifyService.showMessage(
            "Error efectuando operación",
            "Error",
            true
          );
          this.spinner.hide();
        }
      );
    }
  }

  angularGridReady(angularGrid: AngularGridInstance) {
    this.angularGrid = angularGrid;
  }

  get cellMenuInstance(): any {
    return (
      (this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.cellMenu
        )) ||
      {}
    );
  }

  get contextMenuInstance(): any {
    return (
      (this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.contextMenu
        )) ||
      {}
    );
  }

  async inicializaListadoFamilias() {
    this.http.obtenerTodasLasFamilias().then(
      (result: Array<MNT_Familias>) => {
        this.familias = result;
        this.spinner.hide();
        this.filtrar(this.filtradoGrd);
      },
      () => {
         
        this.spinner.hide();
        this.filtrar(this.filtradoGrd);
      }
    );
  }

  nuevaFamilia() {
    this.abrirDialog(false, new MNT_Familias());
  }

  filtrar(valor) {
    this.filtrado = this.familias.filter((art) =>
      art.NOMBRE.toString()
        .toLowerCase()
        .includes(valor.toString().toLowerCase())
    );

    this.rowData = this.filtrado;
    this.source = this.filtrado;

    //slickgrid
    // this.slick.beginUpdate();
    var datosFormatados = [];
    return new Promise((resolve) => {
      for (var i = 0; i < this.rowData.length; i++) {

        //nombre_usuario_asignado
        datosFormatados[i] = {
          id: this.rowData[i].ID_FAMILIA,
          NOMBRE: this.rowData[i].NOMBRE
        };

        if (i == this.rowData.length - 1) resolve(this);
      }
    }).then(() => {
      this.dataset1 = datosFormatados;
    });
  }

  float2int(value) {
    return value | 0;
  }
}
