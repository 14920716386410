<h1 mat-dialog-title style="text-align: center;">{{ data.tituloModal }}</h1>
<hr />
<div mat-dialog-content style="margin:0px;" class="container">

    <div class="row">

        <div class="col-md-4">
            <mat-form-field class="example-full-width">
                <input matInput required placeholder="Modelo" style="width:100%" [(ngModel)]="this.data.articulo.MODELO" (ngModelChange)="evaluaSiEsPermitidoGuardar()" name="MODELO">
            </mat-form-field>
        </div>

        <div class="col-md-4">
            
            <mat-form-field>          
                <mat-label>Familia</mat-label>      
                <mat-select style="width:280px" placeholder="Familia" #singleSelect matInput [(ngModel)]="this.data.articulo.ID_FAMILIA" name="ID_FAMILIA"  (selectionChange)="evaluaSiEsPermitidoGuardar()">
                  <mat-option>
                      <ngx-mat-select-search noEntriesFoundLabel="Error obteniendo familias"></ngx-mat-select-search>
                  </mat-option>      
                
                  <mat-option *ngFor="let familia of familias" [value]="familia.ID_FAMILIA">
                    {{familia.NOMBRE}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
            
        </div>
        <div class="col-md-4">
            <mat-form-field>          
                <mat-label>Marca</mat-label>      
                <mat-select style="width:280px" placeholder="Marca" #singleSelect matInput [(ngModel)]="this.data.articulo.ID_MARCA" name="ID_MARCA"  (selectionChange)="evaluaSiEsPermitidoGuardar()">
                  <mat-option>
                      <ngx-mat-select-search noEntriesFoundLabel="Error obteniendo familias"></ngx-mat-select-search>
                  </mat-option>      
                
                  <mat-option *ngFor="let marca of marcas" [value]="marca.ID_MARCA">
                    {{marca.NOMBRE}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

        </div>


    </div>


    <div class="row">

        <div class="col-md-4">
            <mat-form-field class="example-full-width">
                <input matInput required placeholder="Potencia" style="width:100%" [(ngModel)]="this.data.articulo.POTENCIA" (ngModelChange)="evaluaSiEsPermitidoGuardar()" name="POTENCIA">
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <mat-form-field class="example-full-width">
                <input matInput placeholder="Característica 1" style="width:100%" [(ngModel)]="this.data.articulo.CARAC_1" name="CARAC_1">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field class="example-full-width">
                <input matInput placeholder="Característica 2" style="width:100%" [(ngModel)]="this.data.articulo.CARAC_2" name="CARAC_2">
            </mat-form-field>
        </div>

    </div>
    <div class="row">
        <div class="col-md-12">
            
            <table style="width:100%">
                <tr>
                    <td *ngIf="this.imagenAsignada">
                        <h3>Imagen</h3>            
                        <img src="{{this.rutaImagen}}" style="max-width: 200px;" />                        
                    </td>
                    <td>           
                        <h3>{{this.data.textoOperacionesImagen}}</h3>   
                        <input placeholder="Asignar/Cambiar imagen" type="file" id="FUImage" name="FUImage" accept=".png, .jpg, .jpeg" 
                        required (change)="handleFileInput($event.target.files)">       
                    </td>

                </tr>
            </table>
        </div>

    </div>
</div>
<div mat-dialog-actions>
    <div class="row col-12">
        <div class="col-3">
            <button mat-raised-button color="warn" (click)="cancelaDialogo()">Cancelar</button>
        </div>
        <div class="col-6"></div>
        <div class="col-3">
            <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial [disabled]="guardarDeshabilitado">Aceptar</button>
        </div>
    </div>


</div>
