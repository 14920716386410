import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { MNT_Familias } from '@shared/MNT.familias.entity';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { MNT_Marcas } from '@shared/MNT.marcas.entity';




@Component({
    selector: 'creacion-edicion-articulo',
    templateUrl: 'creacion-edicion-articulo.html',
    styleUrls: ['./creacion-edicion-articulo.css'],
  })
  export class CreacionEdicionArticulo {
        
    guardarDeshabilitado=true;    
    imagenAsignada=false;
    familias = new Array<MNT_Familias>();
    marcas = new Array<MNT_Marcas>();
    rutaImagen;
        
    constructor(
      public dialogRef: MatDialogRef<CreacionEdicionArticulo>,
      @Inject(MAT_DIALOG_DATA) public data,
      public http:HttpService,
      public dialog: MatDialog,
      private spinner: NgxSpinnerService,
      private notifyService : NotificationService,
      ) {}

    cancelaDialogo(): void {
      this.dialogRef.close();
    }

     dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      var byteString = atob(dataURI.split(',')[1]);
  
      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  
      // write the bytes of the string to an ArrayBuffer
      var arrayBuffer = new ArrayBuffer(byteString.length);
      var _ia = new Uint8Array(arrayBuffer);
      for (var i = 0; i < byteString.length; i++) {
          _ia[i] = byteString.charCodeAt(i);
      }
  
      var dataView = new DataView(arrayBuffer);
      var blob = new Blob([dataView], { type: mimeString });
      return blob;
  }

    ngOnInit()
    {
            
      
        this.spinner.show();
        this.obtenerFamilias();
        this.obtenerMarcas();
        this.evaluaSiEsPermitidoGuardar();

        //this.evaluaImagenArticulo();

        if (this.data.edicion)
        {
          
            if (this.data.articulo.IMAGEN.toString().length > 0)
            {
                this.rutaImagen = this.data.articulo.IMAGEN;
                this.imagenAsignada = true;
            }
            

        }

        this.spinner.hide();

    }

    /*
    evaluaImagenArticulo()
    {
        this.rutaImagen = "../../../assets/images/" + this.data.articulo.MODELO + "/I.jpg";
    }
    */

    obtenerFamilias()
    {
      this.http.obtenerTodasLasFamilias().then(
        (result:Array<MNT_Familias>) => {
           
          this.familias = result;
        }
      )
    }
    
    handleFileInput(files: FileList) {
      /*
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        alert("FILE -> " + JSON.stringify(file));
        this.http.upload(file).then(
          (result) => {
            alert(JSON.stringify(result));
          }
        )
        
      }
      */

     
      let me = this;
      let file = files[0];            
      
      
      const formData: FormData = new FormData();
      formData.append('image', file, file.name);                 
      let reader = new FileReader();
      
      reader.readAsDataURL(file);
      reader.onload = function () {
        
        me.rutaImagen = reader.result; 
        me.data.articulo.IMAGEN = reader.result;
        me.imagenAsignada = true;
      
      };
      reader.onerror = function (error) {
         
      };
      
   }


   handleFileInputPDF(files: FileList) {
    let me = this;
    let file = files[0];

    let reader = new FileReader();

    const formDataPDF: FormData = new FormData();
    formDataPDF.append('File', file, file.name);
    formDataPDF.append('StoreFile', "false");


    

    this.http.PDFToJPEG(formDataPDF).then(
      (result) => {
        alert(JSON.stringify(result));
      }
    )
    /*
    reader.readAsDataURL(file);
    reader.onload = function () {
      
      me.rutaImagen = reader.result; 
      var fs = require('fs');


      var base64Data = me.rutaImagen.replace(/^data:image\/png;base64,/, "");
    //  var fs = require('file-system');

      fs.writeFile("out.png", base64Data, 'base64', function(err) {
       
      
});
    
    };
    reader.onerror = function (error) {
       
    };
    */
 }
  


    obtenerMarcas()
    {
      this.http.obtenerTodasMarcas().then(
        (result:Array<MNT_Marcas>) => {
           
          this.marcas = result;
        }
      )
    }

    evaluaSiEsPermitidoGuardar()
    {

      if ((this.data.articulo.MODELO.toString().trim().length > 0) 
      && (this.data.articulo.ID_FAMILIA > 0)
      && (this.data.articulo.ID_MARCA > 0)
      && (this.data.articulo.POTENCIA.toString().trim().length > 0)
      )
          this.guardarDeshabilitado = false;
      else
          this.guardarDeshabilitado = true;


  }

  }
