<div id="previsualizacion" [hidden]="!this.ocultarPDFGenerado">
  <table style="vertical-align: middle">
    <tr style="vertical-align: middle">
      <td>
        <button
          printTitle="{{ this.nombreDocumentoPDF }}"
          mat-fab
          color="primary"
          printSectionId="print-section"
          [useExistingCss]="true"
          mat-raised-button
          color="primary"
          ngxPrint
          style="z-index: 10000; top: 0"
        >
          <mat-icon>print</mat-icon>
        </button>
      </td>
    </tr>
    <tr style="vertical-align: middle">
      <td>
        <button
          mat-fab
          style="
            z-index: 10000;
            top: 10%;
            background-color: cadetblue;
            color: white;
            display: none;
          "
          (click)="this.ngOnInit(); this.ngAfterViewInit()"
        >
          <mat-icon>refresh</mat-icon>
        </button>
      </td>
    </tr>
    <tr style="vertical-align: middle">
      <td>
        <button
          mat-raised-button
          color="warn"
          (click)="this.guardarPPTO('none')"
          style="z-index: 9999"
        >
          Guardar
        </button>
      </td>
    </tr>
  </table>

  <h3>Previsualización datos generados</h3>
  <table>
    <tr>
      <td colspan="3">
        <p style="text-align: center">
          <b> Trabajos a realizar </b>
        </p>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <!-- <ckeditor data="this.model.editorData" [editor]="Editor"></ckeditor>-->
        <ckeditor [(ngModel)]="this.trabajosARealizar"></ckeditor>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <br />
        <hr />
        <p style="text-align: center">
          <b> Consumo estimado de la vivienda: </b>
        </p>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <table>
          <tr>
            <td>
              <b>Mes</b>
            </td>
            <td>
              <b>HS P1</b>
            </td>
            <td>
              <b>HS P2</b>
            </td>
            <td>
              <b>HS P3</b>
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <b>HS P4</b>
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <b>HS P5</b>
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <b>HS P6</b>
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ this.consumosManualesIndustrial[0].Mes }}</p>
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[0].HS_P1" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[0].HS_P2" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[0].HS_P3" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[0].HS_P4" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[0].HS_P5" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[0].HS_P6" />
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ this.consumosManualesIndustrial[1].Mes }}</p>
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[1].HS_P1" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[1].HS_P2" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[1].HS_P3" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[1].HS_P4" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[1].HS_P5" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[1].HS_P6" />
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ this.consumosManualesIndustrial[2].Mes }}</p>
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[2].HS_P1" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[2].HS_P2" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[2].HS_P3" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[2].HS_P4" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[2].HS_P5" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[2].HS_P6" />
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ this.consumosManualesIndustrial[3].Mes }}</p>
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[3].HS_P1" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[3].HS_P2" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[3].HS_P3" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[3].HS_P4" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[3].HS_P5" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[3].HS_P6" />
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ this.consumosManualesIndustrial[4].Mes }}</p>
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[4].HS_P1" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[4].HS_P2" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[4].HS_P3" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[4].HS_P4" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[4].HS_P5" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[4].HS_P6" />
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ this.consumosManualesIndustrial[5].Mes }}</p>
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[5].HS_P1" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[5].HS_P2" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[5].HS_P3" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[5].HS_P4" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[5].HS_P5" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[5].HS_P6" />
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ this.consumosManualesIndustrial[6].Mes }}</p>
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[6].HS_P1" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[6].HS_P2" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[6].HS_P3" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[6].HS_P4" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[6].HS_P5" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[6].HS_P6" />
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ this.consumosManualesIndustrial[7].Mes }}</p>
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[7].HS_P1" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[7].HS_P2" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[7].HS_P3" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[7].HS_P4" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[7].HS_P5" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[7].HS_P6" />
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ this.consumosManualesIndustrial[8].Mes }}</p>
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[8].HS_P1" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[8].HS_P2" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[8].HS_P3" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[8].HS_P4" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[8].HS_P5" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[8].HS_P6" />
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ this.consumosManualesIndustrial[9].Mes }}</p>
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[9].HS_P1" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[9].HS_P2" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[9].HS_P3" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[9].HS_P4" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[9].HS_P5" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[9].HS_P6" />
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ this.consumosManualesIndustrial[10].Mes }}</p>
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[10].HS_P1" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[10].HS_P2" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[10].HS_P3" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[10].HS_P4" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[10].HS_P5" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[10].HS_P6" />
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ this.consumosManualesIndustrial[11].Mes }}</p>
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[11].HS_P1" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[11].HS_P2" />
            </td>
            <td>
              <input [(ngModel)]="this.consumosManualesIndustrial[11].HS_P3" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[11].HS_P4" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[11].HS_P5" />
            </td>
            <td *ngIf="this.tarifaDe6Canales">
              <input [(ngModel)]="this.consumosManualesIndustrial[11].HS_P6" />
            </td>
          </tr>
        </table>

        <br />
        <hr />
      </td>
    </tr>

    <tr>
      <td colspan="3">
        <p style="text-align: center"><b> Datos generados por PVGIS </b></p>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <table>
          <thead>
            <tr>
              <td><b>Enero</b></td>
              <td><b>Febrero</b></td>
              <td><b>Marzo</b></td>
              <td><b>Abril</b></td>
              <td><b>Mayo</b></td>
              <td><b>Junio</b></td>
              <td><b>Julio</b></td>
              <td><b>Agosto</b></td>
              <td><b>Sep.</b></td>
              <td><b>Octubre</b></td>
              <td><b>Nov.</b></td>
              <td><b>Dic.</b></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[0]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[1]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[2]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[3]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[4]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[5]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[6]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[7]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[8]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[9]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[10]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[11]"
                  style="width: 60px"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>

    <tr>
      <td colspan="3">
        <br />
        <hr />
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <p style="text-align: center"><b> Calculo Cuota EQUIPRENTING </b></p>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <table>
          <thead>
            <tr>
              <td>Importe fijo</td>
              <td>Importe portátil</td>
              <td>Importe Intangible</td>
              <td>Total proforma</td>
              <td>Meses</td>
              <td>V.R.</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  [(ngModel)]="this.totalPresupuestoIndustrial"
                  (change)="calculoRenting()"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.importePortatil"
                  (change)="calculoRenting()"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.importeIntangible"
                  (change)="calculoRenting()"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.totalProforma"
                  (change)="calculoRenting()"
                />
              </td>
              <td>
                <input [(ngModel)]="this.meses" (change)="calculoRenting()" />
              </td>
              <td>
                <input [(ngModel)]="this.VR" (change)="calculoRenting()" />
              </td>
            </tr>
            <tr>
              <td colspan="6"></td>
            </tr>
            <tr>
              <td>Seguro</td>
              <td>Gestión</td>
              <td>Importe Riesgo</td>
              <td>Interés anual</td>
              <td>Interés mensual</td>
              <td>Cod.adm.Mensual</td>
            </tr>
            <tr>
              <td>
                <input [(ngModel)]="this.seguro" (change)="calculoRenting()" />
              </td>
              <td>
                <input [(ngModel)]="this.gestion" (change)="calculoRenting()" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.importeRiesgo"
                  (change)="calculoRenting()"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.interesAnual"
                  (change)="calculoRenting()"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.interesMensual"
                  (change)="calculoRenting()"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.comAdmMensual"
                  (change)="calculoRenting()"
                />
              </td>
            </tr>
            <tr>
              <td colspan="6"></td>
            </tr>
            <tr>
              <td>Valor Residual</td>
              <td>Ahorro</td>
              <td><b>CUOTA</b></td>
            </tr>
            <tr>
              <td>
                <input [(ngModel)]="this.VR" (change)="calculoRenting()" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.balanceFinancieroIndustrial[0].INGRESO"
                  *ngIf="!this.cargandoCalculos"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.totalCalculoCuotaRentingIndustrial"
                  (change)="calculoRenting()"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <br />
        <hr />
        <p style="text-align: center"><b> Balance financierio </b></p>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <table>
          <thead>
            <tr>
              <td><b>Total presupuesto</b></td>
              <td><b>Subida factura luz</b></td>
              <td><b>Pérdida eficiencia panel</b></td>
              <td><b>Suma algebraica</b></td>
              <td><b>VAN</b></td>
              <td><b>TIR</b></td>
              <td><b>Retorno inversión (años)</b></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input [(ngModel)]="this.totalPresupuestoIndustrial" />
              </td>
              <td>
                <input [(ngModel)]="this.subidaFacturaLuzIndustrial" />
              </td>
              <td>
                <input [(ngModel)]="this.perdidaEficienciaPanel" />
              </td>
              <td>
                <input [(ngModel)]="this.sumaAlgebraica" />
              </td>
              <td>
                <input [(ngModel)]="this.VAN" />
              </td>
              <td>
                <input [(ngModel)]="this.TIR" />
              </td>
              <td>
                <input
                  [(ngModel)]="
                    this.anoRecuperacionInversionBalanceFinancieroIndustrial
                  "
                />
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <!-- 
        <tr>
            <td colspan="3">
                <br/>
                <mat-form-field class="example-full-width">
                    <mat-label>Ahorro</mat-label>
                    <input matInput placeholder="€/kWh" [(ngModel)]="this.balanceFinancieroIndustrial[0].INGRESO">
                  </mat-form-field>
            </td>           
        </tr>
        <tr>
            <td></td>
            <td>
                <table>
                    <tr>
                        <td></td>
                        <td><b>INGRESOS</b></td>
                        <td><b>BALANCE</b></td>
                    </tr>              
                    <tr *ngFor="let item of balanceFinancieroIndustrial;let indice=index">
                        <td>Año {{indice+1}} </td>
                        <td> <input [(ngModel)]="item.INGRESO" /></td>
                        <td><input [(ngModel)]="item.BALANCE" /> </td>
                    
                    </tr>
                </table>
            </td>
            <td></td>
        </tr>        
        -->
  </table>

  <div *ngIf="this.accion == 'PDF'">
    <button
      #print
      printTitle="report"
      printSectionId="print-section"
      ngxPrint
      hidden="true"
      [useExistingCss]="true"
    ></button>
  </div>

  <!-- 
    <div (click)="test();">

    </div>
    -->
</div>

<div id="print-section" [hidden]="this.ocultarPDFGenerado">
  <div *ngIf="this.tipoPPTO == 'I'">
    <table>
      <tr>
        <td colspan="3">
          <img
            src="../../../assets/images/PPTO_2/portada_industrial.png"
            style="z-index: 999; position: relative; width: 100%; top: 0px"
          />
        </td>
      </tr>
      <tr>
        <td style="text-align: left"></td>
        <td></td>
        <td>
          <div
            style="
              top: 1870px;
              left: 1100px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h2 style="color: white">
              <b> PRESUPUESTO: Nº {{ this.numPresupuesto }} </b>
            </h2>
            <h3 style="color: white">
              EQUIPO {{ this.potenciaPVGISIndustrial }} KW TRIFÁSICO <br />
              {{ this.fechaPpto }} <br />
              {{ this.direccion }}, {{ this.poblacion }} <br />
              {{ this.nombreCliente | uppercase }}
            </h3>
          </div>
        </td>
      </tr>
    </table>

    <div class="page-header" style="text-align: left">
      <img
        src="../../../assets/images/membrete_tecnogeca.jpg"
        class="membrete"
        style="
          width: 900px;
          height: 125px;
          margin-left: 100px;
          margin-top: 100px;
          margin-bottom: 100px;
          position: relative;
          z-index: -9999;
        "
      />
    </div>

    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/index_industrial.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/presentacion_domestico.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/resumen_industrial.png"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              style="
                top: 8005px;
                left: 485px;
                position: absolute;
                z-index: 99999;
              "
            >
              <!-- top:7888px;left:598px -->
              <h1
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 51px;
                "
              >
                <b>{{ this.numPanelesIndustrial }}</b>
              </h1>
            </div>
            <div
              style="
                top: 7997px;
                left: 828px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 57px;
                "
              >
                <b>{{ this.potenciaPVGISIndustrial }} KW</b>
              </h1>
            </div>
            <div
              style="
                top: 8005px;
                left: 1094px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 51px;
                "
              >
                <b>TRINA SOLAR</b>
              </h1>
            </div>
            <div
              style="
                top: 8338px;
                left: 500px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #ffe17e;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 69px;
                "
              >
                <b
                  >{{
                    this.totalPresupuestoIndustrialFormatadoSinDecimales
                  }}
                  €</b
                >
              </h1>
              <!-- total presupuesto-->
            </div>
            <div
              style="
                top: 8404px;
                left: 560px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #ffe17e;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 48px;
                "
              >
                <b>+ IVA</b>
              </h1>
            </div>
            <div
              style="
                top: 8328px;
                left: 1065px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #ffe17e;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 69px;
                "
              >
                <b>
                  {{ this.totalCalculoCuotaRentingIndustrial }}
                </b>
              </h1>
              <!-- cuota financiada-->
            </div>
            <div
              style="
                top: 8399px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #ffe17e;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 48px;
                "
              >
                <b>€/mes</b>
              </h1>
            </div>
            <div
              style="
                top: 8662px;
                left: 435px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 47px;
                "
              >
                <b>{{ this.totalAhorroAnualIndustrialFormatado }} €</b>
              </h1>
              <!-- ahorro anual -->
            </div>
            <div
              style="
                top: 8662px;
                left: 1098px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 47px;
                "
              >
                <b>{{ this.totalAhorroAnualIndustrial25AFormatado }} €</b>
              </h1>
              <!-- ahorro 25 años -->
            </div>
            <div
              style="
                top: 9000px;
                left: 615px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #ffffff;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 63px;
                "
              >
                <b>{{ this.TIR }} %</b>
              </h1>
              <!-- TIR -->
            </div>
            <div
              style="
                top: 9000px;
                left: 955px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #ffffff;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 63px;
                "
              >
                <b>{{ this.VAN }} €</b>
              </h1>
              <!-- VAN -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/decision_industrial.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page" *ngIf="!this.tarifaDe6Canales">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/ubicacion_industrial.png"
            />
          </td>
        </tr>
        <tr class="slight-down">
          <div
            style="
              top: 12612px;
              left: 850px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h3>{{ this.direccion }}</h3>
          </div>
          <div
            style="
              top: 12656px;
              left: 850px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h3>{{ this.poblacion }}</h3>
          </div>
          <div
            style="
              top: 12706px;
              left: 850px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h3>{{ this.tipoContrato }}</h3>
          </div>
          <div
            style="
              top: 12753px;
              left: 850px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h3>{{ this.potenciaCanalesTarifaIndustrial }}</h3>
          </div>
          <div
            class="card-container"
            style="
              top: 13758px;
              left: 606px;
              position: absolute;
              z-index: 99999;
              margin-right: 15px;
            "
            *ngIf="!this.tarifaDe6Canales"
          >
            <img
              src="{{ this.imagenGoogleMaps }}"
              alt="Selected Image"
              height="300"
              width="584"
            />
          </div>
          <div
            style="
              top: 13585px;
              left: 193px;
              position: absolute;
              z-index: 99999;
              margin-right: 20px;
            "
            *ngIf="!this.solarblockIndustrial"
          >
            <h3
              style="
                color: #38424b;
                font-family: 'Helvetica Neue LT Std';
                font-weight: 'bold';
                font-size: 31px;
              "
            >
              Buscando la mejor orientación, los paneles se instalarán en la
              cubierta marcada en la fotografía, de manera que sigan el agua
              natural del tejado de forma coplanar a él, sienda ésta la solución
              más estética y funcional posible.
            </h3>
          </div>
          <div
            style="
              top: 13585px;
              left: 193px;
              position: absolute;
              z-index: 99999;
              margin-right: 20px;
            "
            *ngIf="this.solarblockIndustrial"
          >
            <h3
              style="
                color: #38424b;
                font-family: 'Helvetica Neue LT Std';
                font-weight: 'bold';
                font-size: 31px;
              "
            >
              Buscando la mejor orientación, los paneles se instalan según
              marcado en la fotografía, con estructuras autoportantes de
              hormigón pretensado modelo patentado Solarbloc.
            </h3>
          </div>
          <div
            style="
              top: 12930px;
              left: 790px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[0].P1 }}</h4>
          </div>
          <div
            style="
              top: 12930px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[0].P2 }}</h4>
          </div>
          <div
            style="
              top: 12930px;
              left: 1200px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[0].P3 }}</h4>
          </div>
          <div
            style="
              top: 12978px;
              left: 790px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[1].P1 }}</h4>
          </div>
          <div
            style="
              top: 12978px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[1].P2 }}</h4>
          </div>
          <div
            style="
              top: 12978px;
              left: 1200px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[1].P3 }}</h4>
          </div>
          <div
            style="
              top: 13018px;
              left: 790px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[2].P1 }}</h4>
          </div>
          <div
            style="
              top: 13018px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[2].P2 }}</h4>
          </div>
          <div
            style="
              top: 13018px;
              left: 1200px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[2].P3 }}</h4>
          </div>
          <div
            style="
              top: 13058px;
              left: 790px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[3].P1 }}</h4>
          </div>
          <div
            style="
              top: 13058px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[3].P2 }}</h4>
          </div>
          <div
            style="
              top: 13058px;
              left: 1200px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[3].P3 }}</h4>
          </div>
          <div
            style="
              top: 13098px;
              left: 790px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[4].P1 }}</h4>
          </div>
          <div
            style="
              top: 13098px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[4].P2 }}</h4>
          </div>
          <div
            style="
              top: 13098px;
              left: 1200px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[4].P3 }}</h4>
          </div>
          <div
            style="
              top: 13138px;
              left: 790px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[5].P1 }}</h4>
          </div>
          <div
            style="
              top: 13138px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[5].P2 }}</h4>
          </div>
          <div
            style="
              top: 13138px;
              left: 1200px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[5].P3 }}</h4>
          </div>
          <div
            style="
              top: 13178px;
              left: 790px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[6].P1 }}</h4>
          </div>
          <div
            style="
              top: 13178px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[6].P2 }}</h4>
          </div>
          <div
            style="
              top: 13178px;
              left: 1200px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[6].P3 }}</h4>
          </div>
          <div
            style="
              top: 13223px;
              left: 790px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[7].P1 }}</h4>
          </div>
          <div
            style="
              top: 13223px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[7].P2 }}</h4>
          </div>
          <div
            style="
              top: 13223px;
              left: 1200px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[7].P3 }}</h4>
          </div>
          <div
            style="
              top: 13263px;
              left: 790px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[8].P1 }}</h4>
          </div>
          <div
            style="
              top: 13263px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[8].P2 }}</h4>
          </div>
          <div
            style="
              top: 13263px;
              left: 1200px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[8].P3 }}</h4>
          </div>
          <div
            style="
              top: 13303px;
              left: 790px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[9].P1 }}</h4>
          </div>
          <div
            style="
              top: 13303px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[9].P2 }}</h4>
          </div>
          <div
            style="
              top: 13303px;
              left: 1200px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[9].P3 }}</h4>
          </div>
          <div
            style="
              top: 13343px;
              left: 790px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[10].P1 }}</h4>
          </div>
          <div
            style="
              top: 13343px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[10].P2 }}</h4>
          </div>
          <div
            style="
              top: 13343px;
              left: 1200px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[10].P3 }}</h4>
          </div>
          <div
            style="
              top: 13383px;
              left: 790px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[11].P1 }}</h4>
          </div>
          <div
            style="
              top: 13383px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[11].P2 }}</h4>
          </div>
          <div
            style="
              top: 13383px;
              left: 1200px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[11].P3 }}</h4>
          </div>
          <!-- totales -->
          <div
            style="
              top: 13431px;
              left: 780px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>
              <b> {{ this.totalP1ConsumosManualesIndustrial }} </b>
            </h4>
          </div>
          <div
            style="
              top: 13431px;
              left: 985px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>
              <b> {{ this.totalP2ConsumosManualesIndustrial }} </b>
            </h4>
          </div>
          <div
            style="
              top: 13431px;
              left: 1190px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>
              <b> {{ this.totalP3ConsumosManualesIndustrial }} </b>
            </h4>
          </div>
        </tr>
      </tbody>
    </table>
    <table class="page" *ngIf="this.tarifaDe6Canales">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/ubicacion_industrial_6.png"
            />
          </td>
        </tr>
        <tr>
          <div
            style="
              top: 12621px;
              left: 690px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h3>{{ this.direccion }}</h3>
          </div>
          <div
            style="
              top: 12667px;
              left: 690px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h3>{{ this.poblacion }}</h3>
          </div>
          <div
            style="
              top: 12715px;
              left: 690px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h3>{{ this.tipoContrato }}</h3>
          </div>
          <div
            style="
              top: 12760px;
              left: 690px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h3>{{ this.potenciaCanalesTarifaIndustrial }}</h3>
          </div>
          <div
            class="card-container"
            style="
              top: 13761px;
              left: 606px;
              position: absolute;
              z-index: 99999;
            "
            *ngIf="this.tarifaDe6Canales"
          >
            <img
              src="{{ this.imagenGoogleMaps }}"
              alt="Selected Image"
              height="300"
              width="584"
            />
          </div>
          <div
            style="
              top: 13590px;
              left: 193px;
              position: absolute;
              z-index: 99999;
              margin-right: 20px;
            "
            *ngIf="!this.solarblockIndustrial"
          >
            <h3
              style="
                color: #38424b;
                font-family: 'Helvetica Neue LT Std';
                font-weight: 'bold';
                font-size: 31px;
              "
            >
              Buscando la mejor orientación, los paneles se instalarán en la
              cubierta marcada en la fotografía, de manera que sigan el agua
              natural del tejado de forma coplanar a él, sienda ésta la solución
              más estética y funcional posible.
            </h3>
          </div>
          <div
            style="
              top: 13590px;
              left: 193px;
              position: absolute;
              z-index: 99999;
              margin-right: 20px;
            "
            *ngIf="this.solarblockIndustrial"
          >
            <h3
              style="
                color: #38424b;
                font-family: 'Helvetica Neue LT Std';
                font-weight: 'bold';
                font-size: 31px;
              "
            >
              Buscando la mejor orientación, los paneles se instalan según
              marcado en la fotografía, con estructuras autoportantes de
              hormigón pretensado modelo patentado Solarbloc.
            </h3>
          </div>
          <div
            style="
              top: 12930px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[0].P1 }}</h4>
          </div>
          <div
            style="
              top: 12930px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[0].P2 }}</h4>
          </div>
          <div
            style="
              top: 12930px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[0].P3 }}</h4>
          </div>
          <div
            style="
              top: 12930px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[0].P4 }}</h4>
          </div>
          <div
            style="
              top: 12930px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[0].P5 }}</h4>
          </div>
          <div
            style="
              top: 12930px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[0].P6 }}</h4>
          </div>
          <div
            style="
              top: 12970px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[1].P1 }}</h4>
          </div>
          <div
            style="
              top: 12970px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[1].P2 }}</h4>
          </div>
          <div
            style="
              top: 12970px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[1].P3 }}</h4>
          </div>
          <div
            style="
              top: 12970px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[1].P4 }}</h4>
          </div>
          <div
            style="
              top: 12970px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[1].P5 }}</h4>
          </div>
          <div
            style="
              top: 12970px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[1].P6 }}</h4>
          </div>

          <div
            style="
              top: 13010px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[2].P1 }}</h4>
          </div>
          <div
            style="
              top: 13010px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[2].P2 }}</h4>
          </div>
          <div
            style="
              top: 13010px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[2].P3 }}</h4>
          </div>
          <div
            style="
              top: 13010px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[2].P4 }}</h4>
          </div>
          <div
            style="
              top: 13010px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[2].P5 }}</h4>
          </div>
          <div
            style="
              top: 13010px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[2].P6 }}</h4>
          </div>

          <div
            style="
              top: 13050px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[3].P1 }}</h4>
          </div>
          <div
            style="
              top: 13050px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[3].P2 }}</h4>
          </div>
          <div
            style="
              top: 13050px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[3].P3 }}</h4>
          </div>
          <div
            style="
              top: 13050px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[3].P4 }}</h4>
          </div>
          <div
            style="
              top: 13050px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[3].P5 }}</h4>
          </div>
          <div
            style="
              top: 13050px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[3].P6 }}</h4>
          </div>

          <div
            style="
              top: 13090px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[4].P1 }}</h4>
          </div>
          <div
            style="
              top: 13090px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[4].P2 }}</h4>
          </div>
          <div
            style="
              top: 13090px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[4].P3 }}</h4>
          </div>
          <div
            style="
              top: 13090px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[4].P4 }}</h4>
          </div>
          <div
            style="
              top: 13090px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[4].P5 }}</h4>
          </div>
          <div
            style="
              top: 13090px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[4].P6 }}</h4>
          </div>

          <div
            style="
              top: 13130px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[5].P1 }}</h4>
          </div>
          <div
            style="
              top: 13130px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[5].P2 }}</h4>
          </div>
          <div
            style="
              top: 13130px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[5].P3 }}</h4>
          </div>
          <div
            style="
              top: 13130px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[5].P4 }}</h4>
          </div>
          <div
            style="
              top: 13130px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[5].P5 }}</h4>
          </div>
          <div
            style="
              top: 13130px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[5].P6 }}</h4>
          </div>

          <div
            style="
              top: 13170px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[6].P1 }}</h4>
          </div>
          <div
            style="
              top: 13170px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[6].P2 }}</h4>
          </div>
          <div
            style="
              top: 13170px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[6].P3 }}</h4>
          </div>
          <div
            style="
              top: 13170px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[6].P4 }}</h4>
          </div>
          <div
            style="
              top: 13170px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[6].P5 }}</h4>
          </div>
          <div
            style="
              top: 13170px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[6].P6 }}</h4>
          </div>

          <div
            style="
              top: 13220px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[7].P1 }}</h4>
          </div>
          <div
            style="
              top: 13220px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[7].P2 }}</h4>
          </div>
          <div
            style="
              top: 13220px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[7].P3 }}</h4>
          </div>
          <div
            style="
              top: 13220px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[7].P4 }}</h4>
          </div>
          <div
            style="
              top: 13220px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[7].P5 }}</h4>
          </div>
          <div
            style="
              top: 13220px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[7].P6 }}</h4>
          </div>

          <div
            style="
              top: 13261px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[8].P1 }}</h4>
          </div>
          <div
            style="
              top: 13261px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[8].P2 }}</h4>
          </div>
          <div
            style="
              top: 13261px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[8].P3 }}</h4>
          </div>
          <div
            style="
              top: 13261px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[8].P4 }}</h4>
          </div>
          <div
            style="
              top: 13261px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[8].P5 }}</h4>
          </div>
          <div
            style="
              top: 13261px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[8].P6 }}</h4>
          </div>

          <div
            style="
              top: 13302px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[9].P1 }}</h4>
          </div>
          <div
            style="
              top: 13302px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[9].P2 }}</h4>
          </div>
          <div
            style="
              top: 13302px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[9].P3 }}</h4>
          </div>
          <div
            style="
              top: 13302px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[9].P4 }}</h4>
          </div>
          <div
            style="
              top: 13302px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[9].P5 }}</h4>
          </div>
          <div
            style="
              top: 13302px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[9].P6 }}</h4>
          </div>

          <div
            style="
              top: 13333px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[10].P1 }}</h4>
          </div>
          <div
            style="
              top: 13333px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[10].P2 }}</h4>
          </div>
          <div
            style="
              top: 13333px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[10].P3 }}</h4>
          </div>
          <div
            style="
              top: 13333px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[10].P4 }}</h4>
          </div>
          <div
            style="
              top: 13333px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[10].P5 }}</h4>
          </div>
          <div
            style="
              top: 13333px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[10].P6 }}</h4>
          </div>

          <div
            style="
              top: 13374px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[11].P1 }}</h4>
          </div>
          <div
            style="
              top: 13374px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[11].P2 }}</h4>
          </div>
          <div
            style="
              top: 13374px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[11].P3 }}</h4>
          </div>
          <div
            style="
              top: 13374px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[11].P4 }}</h4>
          </div>
          <div
            style="
              top: 13374px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[11].P5 }}</h4>
          </div>
          <div
            style="
              top: 13374px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>{{ this.consumosManualesIndustrial[11].P6 }}</h4>
          </div>
          <!-- totales -->
          <div
            style="
              top: 13423px;
              left: 550px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>
              <b> {{ this.totalP1ConsumosManualesIndustrial }} </b>
            </h4>
          </div>
          <div
            style="
              top: 13423px;
              left: 720px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>
              <b> {{ this.totalP2ConsumosManualesIndustrial }} </b>
            </h4>
          </div>
          <div
            style="
              top: 13423px;
              left: 890px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>
              <b> {{ this.totalP3ConsumosManualesIndustrial }} </b>
            </h4>
          </div>
          <div
            style="
              top: 13423px;
              left: 1060px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>
              <b> {{ this.totalP4ConsumosManualesIndustrial }} </b>
            </h4>
          </div>
          <div
            style="
              top: 13423px;
              left: 1230px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>
              <b> {{ this.totalP5ConsumosManualesIndustrial }} </b>
            </h4>
          </div>
          <div
            style="
              top: 13423px;
              left: 1400px;
              position: absolute;
              z-index: 99999;
            "
          >
            <h4>
              <b> {{ this.totalP6ConsumosManualesIndustrial }} </b>
            </h4>
          </div>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/calculo_industrial.png"
            />
          </td>
        </tr>
        <tr>
          <div
            style="
              top: 15599px;
              left: 995px;
              position: absolute;
              z-index: 99999;
            "
          >
            <!-- 40 -->
            <h3>
              <b>{{ this.totalPVGISFormatado }} kWh </b>
            </h3>
          </div>
          <div
            style="
              top: 15810px;
              left: 300px;
              position: absolute;
              z-index: 99999;
            "
          >
            <!-- 40 -->
            <echarts [initOpts]="initOpts" [options]="options"></echarts>
          </div>
        </tr>
      </tbody>
    </table>
    <table class="page" *ngIf="!this.tarifaDe6Canales">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/calculo_industrial_2.png"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              style="
                top: 17465px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].P1 }}</h4>
            </div>
            <div
              style="
                top: 17465px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17465px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].P2 }}</h4>
            </div>
            <div
              style="
                top: 17465px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17465px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].P3 }}</h4>
            </div>
            <div
              style="
                top: 17465px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].HS_P3 }}</h4>
            </div>

            <div
              style="
                top: 17505px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].P1 }}</h4>
            </div>
            <div
              style="
                top: 17505px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17505px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].P2 }}</h4>
            </div>
            <div
              style="
                top: 17505px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17505px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].P3 }}</h4>
            </div>
            <div
              style="
                top: 17505px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].HS_P3 }}</h4>
            </div>

            <div
              style="
                top: 17545px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].P1 }}</h4>
            </div>
            <div
              style="
                top: 17545px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17545px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].P2 }}</h4>
            </div>
            <div
              style="
                top: 17545px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17545px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].P3 }}</h4>
            </div>
            <div
              style="
                top: 17545px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].HS_P3 }}</h4>
            </div>

            <div
              style="
                top: 17585px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].P1 }}</h4>
            </div>
            <div
              style="
                top: 17585px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17585px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].P2 }}</h4>
            </div>
            <div
              style="
                top: 17585px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17585px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].P3 }}</h4>
            </div>
            <div
              style="
                top: 17585px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].HS_P3 }}</h4>
            </div>

            <div
              style="
                top: 17626px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].P1 }}</h4>
            </div>
            <div
              style="
                top: 17626px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17626px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].P2 }}</h4>
            </div>
            <div
              style="
                top: 17626px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17626px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].P3 }}</h4>
            </div>
            <div
              style="
                top: 17626px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].HS_P3 }}</h4>
            </div>

            <div
              style="
                top: 17666px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].P1 }}</h4>
            </div>
            <div
              style="
                top: 17666px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17666px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].P2 }}</h4>
            </div>
            <div
              style="
                top: 17666px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17666px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].P3 }}</h4>
            </div>
            <div
              style="
                top: 17666px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].HS_P3 }}</h4>
            </div>

            <div
              style="
                top: 17706px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].P1 }}</h4>
            </div>
            <div
              style="
                top: 17706px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17706px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].P2 }}</h4>
            </div>
            <div
              style="
                top: 17706px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17706px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].P3 }}</h4>
            </div>
            <div
              style="
                top: 17706px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].HS_P3 }}</h4>
            </div>

            <div
              style="
                top: 17747px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].P1 }}</h4>
            </div>
            <div
              style="
                top: 17747px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17747px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].P2 }}</h4>
            </div>
            <div
              style="
                top: 17747px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17747px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].P3 }}</h4>
            </div>
            <div
              style="
                top: 17747px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].HS_P3 }}</h4>
            </div>

            <div
              style="
                top: 17787px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].P1 }}</h4>
            </div>
            <div
              style="
                top: 17787px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17787px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].P2 }}</h4>
            </div>
            <div
              style="
                top: 17787px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17787px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].P3 }}</h4>
            </div>
            <div
              style="
                top: 17787px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].HS_P3 }}</h4>
            </div>

            <div
              style="
                top: 17828px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].P1 }}</h4>
            </div>
            <div
              style="
                top: 17828px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17828px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].P2 }}</h4>
            </div>
            <div
              style="
                top: 17828px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17828px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].P3 }}</h4>
            </div>
            <div
              style="
                top: 17828px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].HS_P3 }}</h4>
            </div>

            <div
              style="
                top: 17868px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].P1 }}</h4>
            </div>
            <div
              style="
                top: 17868px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17868px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].P2 }}</h4>
            </div>
            <div
              style="
                top: 17868px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17868px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].P3 }}</h4>
            </div>
            <div
              style="
                top: 17868px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].HS_P3 }}</h4>
            </div>

            <div
              style="
                top: 17908px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].P1 }}</h4>
            </div>
            <div
              style="
                top: 17908px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17908px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].P2 }}</h4>
            </div>
            <div
              style="
                top: 17908px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17908px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].P3 }}</h4>
            </div>
            <div
              style="
                top: 17908px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].HS_P3 }}</h4>
            </div>
            <!-- totales -->
            <div
              style="
                top: 17960px;
                left: 510px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalP1ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17960px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalHS_P1ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17960px;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalP2ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17960px;
                left: 1090px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalHS_P2ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17960px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalP3ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17960px;
                left: 1470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalHS_P3ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <!-- graficas -->
            <div
              style="
                top: 18410px;
                left: 390px;
                position: absolute;
                z-index: 99999;
              "
            >
              <div id="chartTotalIndustrial">
                <!-- <apx-chart
                  [series]="chartOptionsConsumoTotalIndustrial.series"
                  [chart]="chartOptionsConsumoTotalIndustrial.chart"
                  [xaxis]="chartOptionsConsumoTotalIndustrial.xaxis"
                  [dataLabels]="chartOptionsConsumoTotalIndustrial.dataLabels"
                  [yaxis]="chartOptionsConsumoTotalIndustrial.yaxis"
                  [colors]="chartOptionsConsumoTotalIndustrial.colors"
                  [legend]="chartOptionsConsumoTotalIndustrial.legend"
                  [fill]="chartOptionsConsumoTotalIndustrial.fill"
                ></apx-chart> -->
              </div>
            </div>
            <div
              style="
                top: 18410px;
                left: 910px;
                position: absolute;
                z-index: 99999;
              "
            >
              <div id="chartConsumoHSIndustrial">
                <!-- <apx-chart
                  [series]="chartOptionsConsumoTotalIndustrial.series"
                  [chart]="chartOptionsConsumoTotalIndustrial.chart"
                  [xaxis]="chartOptionsConsumoTotalIndustrial.xaxis"
                  [dataLabels]="chartOptionsConsumoTotalIndustrial.dataLabels"
                  [yaxis]="chartOptionsConsumoTotalIndustrial.yaxis"
                  [colors]="chartOptionsConsumoTotalIndustrial.colors"
                  [legend]="chartOptionsConsumoTotalIndustrial.legend"
                  [fill]="chartOptionsConsumoTotalIndustrial.fill"
                ></apx-chart> -->
              </div>
            </div>
            <!-- produccion versus cosumo -->
            <div
              style="
                top: 18275px;
                left: 220px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[0] }}</h4>
            </div>
            <div
              style="
                top: 18275px;
                left: 325px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[1] }}</h4>
            </div>
            <div
              style="
                top: 18275px;
                left: 435px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[2] }}</h4>
            </div>
            <div
              style="
                top: 18275px;
                left: 542px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[3] }}</h4>
            </div>
            <div
              style="
                top: 18275px;
                left: 647px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[4] }}</h4>
            </div>
            <div
              style="
                top: 18275px;
                left: 755px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[5] }}</h4>
            </div>
            <div
              style="
                top: 18275px;
                left: 865px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[6] }}</h4>
            </div>
            <div
              style="
                top: 18275px;
                left: 975px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[7] }}</h4>
            </div>
            <div
              style="
                top: 18275px;
                left: 1080px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[8] }}</h4>
            </div>
            <div
              style="
                top: 18275px;
                left: 1185px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[9] }}</h4>
            </div>
            <div
              style="
                top: 18275px;
                left: 1292px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[10] }}</h4>
            </div>
            <div
              style="
                top: 18275px;
                left: 1402px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[11] }}</h4>
            </div>
            <!-- total -->
            <div
              style="
                top: 18275px;
                left: 1507px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.totalPVGIS }}</h4>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page" *ngIf="this.tarifaDe6Canales">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 2px"
              src="../../../assets/images/PPTO_2/calculo_industrial_2_6tramos.png"
            />
          </td>
        </tr>

        <tr>
          <td colspan="3">
            <div
              style="
                top: 17460px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].P1 }}</h4>
            </div>
            <div
              style="
                top: 17460px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17460px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].P2 }}</h4>
            </div>
            <div
              style="
                top: 17460px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17460px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].P3 }}</h4>
            </div>
            <div
              style="
                top: 17460px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].HS_P3 }}</h4>
            </div>
            <div
              style="
                top: 17460px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].P4 }}</h4>
            </div>
            <div
              style="
                top: 17460px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].HS_P4 }}</h4>
            </div>
            <div
              style="
                top: 17460px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].P5 }}</h4>
            </div>
            <div
              style="
                top: 17460px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].HS_P5 }}</h4>
            </div>
            <div
              style="
                top: 17460px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].P6 }}</h4>
            </div>
            <div
              style="
                top: 17460px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[0].HS_P6 }}</h4>
            </div>

            <div
              style="
                top: 17502px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].P1 }}</h4>
            </div>
            <div
              style="
                top: 17502px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17502px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].P2 }}</h4>
            </div>
            <div
              style="
                top: 17502px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17502px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].P3 }}</h4>
            </div>
            <div
              style="
                top: 17502px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].HS_P3 }}</h4>
            </div>
            <div
              style="
                top: 17502px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].P4 }}</h4>
            </div>
            <div
              style="
                top: 17502px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].HS_P4 }}</h4>
            </div>
            <div
              style="
                top: 17502px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].P5 }}</h4>
            </div>
            <div
              style="
                top: 17502px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].HS_P5 }}</h4>
            </div>
            <div
              style="
                top: 17502px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].P6 }}</h4>
            </div>
            <div
              style="
                top: 17502px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[1].HS_P6 }}</h4>
            </div>

            <div
              style="
                top: 17544px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].P1 }}</h4>
            </div>
            <div
              style="
                top: 17544px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17544px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].P2 }}</h4>
            </div>
            <div
              style="
                top: 17544px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17544px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].P3 }}</h4>
            </div>
            <div
              style="
                top: 17544px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].HS_P3 }}</h4>
            </div>
            <div
              style="
                top: 17544px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].P4 }}</h4>
            </div>
            <div
              style="
                top: 17544px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].HS_P4 }}</h4>
            </div>
            <div
              style="
                top: 17544px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].P5 }}</h4>
            </div>
            <div
              style="
                top: 17544px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].HS_P5 }}</h4>
            </div>
            <div
              style="
                top: 17544px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].P6 }}</h4>
            </div>
            <div
              style="
                top: 17544px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[2].HS_P6 }}</h4>
            </div>

            <div
              style="
                top: 17586px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].P1 }}</h4>
            </div>
            <div
              style="
                top: 17586px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17586px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].P2 }}</h4>
            </div>
            <div
              style="
                top: 17586px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17586px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].P3 }}</h4>
            </div>
            <div
              style="
                top: 17586px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].HS_P3 }}</h4>
            </div>
            <div
              style="
                top: 17586px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].P4 }}</h4>
            </div>
            <div
              style="
                top: 17586px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].HS_P4 }}</h4>
            </div>
            <div
              style="
                top: 17586px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].P5 }}</h4>
            </div>
            <div
              style="
                top: 17586px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].HS_P5 }}</h4>
            </div>
            <div
              style="
                top: 17586px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].P6 }}</h4>
            </div>
            <div
              style="
                top: 17586px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[3].HS_P6 }}</h4>
            </div>

            <div
              style="
                top: 17628px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].P1 }}</h4>
            </div>
            <div
              style="
                top: 17628px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17628px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].P2 }}</h4>
            </div>
            <div
              style="
                top: 17628px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17628px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].P3 }}</h4>
            </div>
            <div
              style="
                top: 17628px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].HS_P3 }}</h4>
            </div>
            <div
              style="
                top: 17628px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].P4 }}</h4>
            </div>
            <div
              style="
                top: 17628px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].HS_P4 }}</h4>
            </div>
            <div
              style="
                top: 17628px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].P5 }}</h4>
            </div>
            <div
              style="
                top: 17628px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].HS_P5 }}</h4>
            </div>
            <div
              style="
                top: 17628px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].P6 }}</h4>
            </div>
            <div
              style="
                top: 17628px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[4].HS_P6 }}</h4>
            </div>

            <div
              style="
                top: 17670px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].P1 }}</h4>
            </div>
            <div
              style="
                top: 17670px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17670px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].P2 }}</h4>
            </div>
            <div
              style="
                top: 17670px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17670px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].P3 }}</h4>
            </div>
            <div
              style="
                top: 17670px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].HS_P3 }}</h4>
            </div>
            <div
              style="
                top: 17670px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].P4 }}</h4>
            </div>
            <div
              style="
                top: 17670px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].HS_P4 }}</h4>
            </div>
            <div
              style="
                top: 17670px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].P5 }}</h4>
            </div>
            <div
              style="
                top: 17670px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].HS_P5 }}</h4>
            </div>
            <div
              style="
                top: 17670px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].P6 }}</h4>
            </div>
            <div
              style="
                top: 17670px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[5].HS_P6 }}</h4>
            </div>

            <div
              style="
                top: 17712px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].P1 }}</h4>
            </div>
            <div
              style="
                top: 17712px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17712px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].P2 }}</h4>
            </div>
            <div
              style="
                top: 17712px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17712px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].P3 }}</h4>
            </div>
            <div
              style="
                top: 17712px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].HS_P3 }}</h4>
            </div>
            <div
              style="
                top: 17712px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].P4 }}</h4>
            </div>
            <div
              style="
                top: 17712px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].HS_P4 }}</h4>
            </div>
            <div
              style="
                top: 17712px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].P5 }}</h4>
            </div>
            <div
              style="
                top: 17712px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].HS_P5 }}</h4>
            </div>
            <div
              style="
                top: 17712px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].P6 }}</h4>
            </div>
            <div
              style="
                top: 17712px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[6].HS_P6 }}</h4>
            </div>

            <div
              style="
                top: 17754px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].P1 }}</h4>
            </div>
            <div
              style="
                top: 17754px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17754px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].P2 }}</h4>
            </div>
            <div
              style="
                top: 17754px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17754px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].P3 }}</h4>
            </div>
            <div
              style="
                top: 17754px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].HS_P3 }}</h4>
            </div>
            <div
              style="
                top: 17754px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].P4 }}</h4>
            </div>
            <div
              style="
                top: 17754px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].HS_P4 }}</h4>
            </div>
            <div
              style="
                top: 17754px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].P5 }}</h4>
            </div>
            <div
              style="
                top: 17754px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].HS_P5 }}</h4>
            </div>
            <div
              style="
                top: 17754px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].P6 }}</h4>
            </div>
            <div
              style="
                top: 17754px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[7].HS_P6 }}</h4>
            </div>

            <div
              style="
                top: 17795px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].P1 }}</h4>
            </div>
            <div
              style="
                top: 17795px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17795px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].P2 }}</h4>
            </div>
            <div
              style="
                top: 17795px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17795px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].P3 }}</h4>
            </div>
            <div
              style="
                top: 17795px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].HS_P3 }}</h4>
            </div>
            <div
              style="
                top: 17795px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].P4 }}</h4>
            </div>
            <div
              style="
                top: 17795px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].HS_P4 }}</h4>
            </div>
            <div
              style="
                top: 17795px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].P5 }}</h4>
            </div>
            <div
              style="
                top: 17795px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].HS_P5 }}</h4>
            </div>
            <div
              style="
                top: 17795px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].P6 }}</h4>
            </div>
            <div
              style="
                top: 17795px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[8].HS_P6 }}</h4>
            </div>

            <div
              style="
                top: 17836px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].P1 }}</h4>
            </div>
            <div
              style="
                top: 17836px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17836px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].P2 }}</h4>
            </div>
            <div
              style="
                top: 17836px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17836px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].P3 }}</h4>
            </div>
            <div
              style="
                top: 17836px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].HS_P3 }}</h4>
            </div>
            <div
              style="
                top: 17836px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].P4 }}</h4>
            </div>
            <div
              style="
                top: 17836px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].HS_P4 }}</h4>
            </div>
            <div
              style="
                top: 17836px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].P5 }}</h4>
            </div>
            <div
              style="
                top: 17836px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].HS_P5 }}</h4>
            </div>
            <div
              style="
                top: 17836px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].P6 }}</h4>
            </div>
            <div
              style="
                top: 17836px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[9].HS_P6 }}</h4>
            </div>

            <div
              style="
                top: 17876px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].P1 }}</h4>
            </div>
            <div
              style="
                top: 17876px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17876px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].P2 }}</h4>
            </div>
            <div
              style="
                top: 17876px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17876px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].P3 }}</h4>
            </div>
            <div
              style="
                top: 17876px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].HS_P3 }}</h4>
            </div>
            <div
              style="
                top: 17876px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].P4 }}</h4>
            </div>
            <div
              style="
                top: 17876px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].HS_P4 }}</h4>
            </div>
            <div
              style="
                top: 17876px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].P5 }}</h4>
            </div>
            <div
              style="
                top: 17876px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].HS_P5 }}</h4>
            </div>
            <div
              style="
                top: 17876px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].P6 }}</h4>
            </div>
            <div
              style="
                top: 17876px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[10].HS_P6 }}</h4>
            </div>

            <div
              style="
                top: 17917px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].P1 }}</h4>
            </div>
            <div
              style="
                top: 17917px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].HS_P1 }}</h4>
            </div>
            <div
              style="
                top: 17917px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].P2 }}</h4>
            </div>
            <div
              style="
                top: 17917px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].HS_P2 }}</h4>
            </div>
            <div
              style="
                top: 17917px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].P3 }}</h4>
            </div>
            <div
              style="
                top: 17917px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].HS_P3 }}</h4>
            </div>
            <div
              style="
                top: 17917px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].P4 }}</h4>
            </div>
            <div
              style="
                top: 17917px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].HS_P4 }}</h4>
            </div>
            <div
              style="
                top: 17917px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].P5 }}</h4>
            </div>
            <div
              style="
                top: 17917px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].HS_P5 }}</h4>
            </div>
            <div
              style="
                top: 17917px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].P6 }}</h4>
            </div>
            <div
              style="
                top: 17917px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosManualesIndustrial[11].HS_P6 }}</h4>
            </div>
            <!-- totales -->
            <div
              style="
                top: 17978px;
                left: 330px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalP1ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17978px;
                left: 437px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalHS_P1ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17978px;
                left: 543px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalP2ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17978px;
                left: 649px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalHS_P2ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17978px;
                left: 757px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalP3ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17978px;
                left: 863px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalHS_P3ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17978px;
                left: 965px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalP4ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17978px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalHS_P4ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17978px;
                left: 1183px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalP5ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17978px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalHS_P5ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17978px;
                left: 1395px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalP6ConsumosManualesIndustrial }}</b>
              </h4>
            </div>
            <div
              style="
                top: 17978px;
                left: 1505px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b>{{ this.totalHS_P6ConsumosManualesIndustrial }}</b>
              </h4>
            </div>

            <div
              style="
                top: 18215px;
                left: 385px;
                position: absolute;
                z-index: 99999;
              "
            >
              <div id="chartTotalIndustrial">
                <apx-chart
                  [series]="chartOptionsConsumoTotalIndustrial.series"
                  [chart]="chartOptionsConsumoTotalIndustrial.chart"
                  [xaxis]="chartOptionsConsumoTotalIndustrial.xaxis"
                  [dataLabels]="chartOptionsConsumoTotalIndustrial.dataLabels"
                  [yaxis]="chartOptionsConsumoTotalIndustrial.yaxis"
                  [colors]="chartOptionsConsumoTotalIndustrial.colors"
                  [legend]="chartOptionsConsumoTotalIndustrial.legend"
                  [fill]="chartOptionsConsumoTotalIndustrial.fill"
                ></apx-chart>
              </div>
            </div>

            <div
              style="
                top: 18215px;
                left: 875px;
                position: absolute;
                z-index: 99999;
              "
            >
              <div id="chartConsumoHSIndustrial">
                <apx-chart
                  [series]="chartOptionsConsumoTotalIndustrial.series"
                  [chart]="chartOptionsConsumoTotalIndustrial.chart"
                  [xaxis]="chartOptionsConsumoTotalIndustrial.xaxis"
                  [dataLabels]="chartOptionsConsumoTotalIndustrial.dataLabels"
                  [yaxis]="chartOptionsConsumoTotalIndustrial.yaxis"
                  [colors]="chartOptionsConsumoTotalIndustrial.colors"
                  [legend]="chartOptionsConsumoTotalIndustrial.legend"
                  [fill]="chartOptionsConsumoTotalIndustrial.fill"
                ></apx-chart>
              </div>
            </div>

            <!-- produccion versus cosumo -->
            <div
              style="
                top: 18302px;
                left: 210px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[0] }}</h4>
            </div>
            <div
              style="
                top: 18302px;
                left: 315px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[1] }}</h4>
            </div>
            <div
              style="
                top: 18302px;
                left: 415px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[2] }}</h4>
            </div>
            <div
              style="
                top: 18302px;
                left: 522px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[3] }}</h4>
            </div>
            <div
              style="
                top: 18302px;
                left: 625px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[4] }}</h4>
            </div>
            <div
              style="
                top: 18302px;
                left: 730px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[5] }}</h4>
            </div>
            <div
              style="
                top: 18302px;
                left: 830px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[6] }}</h4>
            </div>
            <div
              style="
                top: 18302px;
                left: 930px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[7] }}</h4>
            </div>
            <div
              style="
                top: 18302px;
                left: 1035px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[8] }}</h4>
            </div>
            <div
              style="
                top: 18302px;
                left: 1140px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[9] }}</h4>
            </div>
            <div
              style="
                top: 18302px;
                left: 1248px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[10] }}</h4>
            </div>
            <div
              style="
                top: 18302px;
                left: 1349px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[11] }}</h4>
            </div>
            <!--  total -->
            <div
              style="
                top: 18302px;
                left: 1450px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.totalPVGIS }}</h4>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <img
              *ngIf="!this.conExcedentes"
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/instalacion_industrial.png"
            />
            <img
              *ngIf="this.conExcedentes"
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/instalacion_industrial_con.png"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <!-- parragrafo -->
            <div
              style="
                top: 19648px;
                left: 880px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3 style="color: #38424b; font-family: 'Helvetica Neue LT Std'">
                <b>{{ this.potenciaPVGISIndustrial }} kWp</b>
              </h3>
            </div>
            <!-- titulo instalacion -->
            <div
              style="
                top: 19905px;
                left: 955px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 27px;
                "
              >
                <b>{{ this.potenciaPVGISIndustrial }} kW</b>
              </h2>
            </div>
            <!-- tabla -->
            <div
              style="
                top: 19953px;
                left: 640px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.numPanelesIndustrial }}</h3>
            </div>
            <div
              style="
                top: 19953px;
                left: 1270px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>TRINA SOLAR</h3>
            </div>
            <div
              style="
                top: 19997px;
                left: 630px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.potenciaPanelSeleccionadoIndustrial }}</h3>
            </div>
            <div
              style="
                top: 19997px;
                left: 1200px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>MONOCRISTALINO-PERC</h3>
            </div>
            <div
              style="
                top: 20043px;
                left: 630px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ potenciaInversoresSeleccionadosIndustrial }}</h3>
            </div>
            <div
              style="
                top: 20043px;
                left: 1200px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>
                ({{ numInversoresIndustrial }}UD.)
                {{ nombreFormatadoInversorSeleccionadoIndustrial }}
              </h3>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/materiales_industrial.png"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              style="
                top: 22295px;
                left: 190px;
                position: absolute;
                z-index: 99999;
                margin-right: 700px;
              "
            >
              <h3
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 31px;
                "
              >
                Módulo solar <b>Monocristalino con tecnología PERC </b>de marca
                <b>{{ this.panelSeleccionadoIndustrial.MODELO }}</b> con una
                potencia de
                {{
                  this.potenciaPanelSeleccionadoIndustrial
                }}.<br />Reconocimiento <b>Tier 1,</b> que solo se concede a los
                fabricantes pioneros a nivel mundial, especializados en paneles
                de alta calidad. Seleccionamos este módulo dado su robustez,
                garantías y servicio postventa.
              </h3>

              <div
                style="
                  top: -140px;
                  left: 940px;
                  position: absolute;
                  z-index: 99999;
                "
              >
                <img
                  height="500"
                  width="550"
                  src="{{ this.panelSeleccionadoIndustrial.IMAGEN }}"
                />
              </div>
            </div>

            <div
              style="
                top: 22895px;
                left: 190px;
                position: absolute;
                z-index: 99999;
                margin-right: 700px;
              "
            >
              <h3
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 31px;
                "
              >
                Inversores con <b>6 seguidores MPPT independientes,</b> lo que
                permite maximizar toda la instalación. Rendimiento del 99%.
                <b>Protecciones eléctricas integradas,</b> lo que hace que la
                aparición de incidencias de instalación sea mínima. Servicio
                técnico especializado con sede en Cataluña.
              </h3>

              <div
                style="
                  top: -140px;
                  left: 940px;
                  position: absolute;
                  z-index: 99999;
                "
              >
                <img
                  height="400"
                  width="450"
                  src="{{ this.inversorSeleccionadoIndustrial.IMAGEN }}"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/trabajos_industrial.png"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              style="
                top: 24410px;
                left: 150px;
                border-right: 240px;
                position: absolute;
                z-index: 99999;
                margin-right: 120px;
              "
            >
              <div *ngFor="let item of elementosTrabajosARealiar">
                <h3
                  style="color: #38424b; font-family: 'Helvetica Neue LT Std'"
                  [innerHTML]="item"
                ></h3>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/valoracion_industrial.png"
            />
            <div
              *ngIf="this.inspeccionPrevia"
              style="top: 27200; left: 800; position: absolute; z-index: 99999"
            >
              <h1 style="color: #38424b; font-size: 24px; margin-right: 2cm">
                <ul class="custom-bullets">
                  <li>
                    Para el cumplimiento del Reglamento de Baja Tensión: Son
                    objeto de inspección inicial las instalaciones eléctricas
                    con potencia instalada superior a 25 kW , que las costas
                    serán por parte del cliente.
                  </li>
                  <br />
                  <li>
                    Es necesario que la TMF de consumo por donde evacua la
                    energía este adecuada a la reglamentación vigente si la
                    potencia contratada es superior a 50 kW.
                  </li>
                </ul>
              </h1>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              style="
                top: 26928px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                "
              >
                <b
                  >{{ this.totalPresupuestoIndustrialFormatadoSinDecimales }} €
                  + IVA</b
                >
              </h1>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/amortizacion_industrial.png"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              style="
                top: 29365px;
                left: 305px;
                position: absolute;
                z-index: 999;
              "
            >
              <echarts
                [initOpts]="initOpts"
                [options]="optionsAmortizacionIndustrial"
              ></echarts>
            </div>
            <!-- {{ this.tipoContrato }} -->
            <div
              style="
                top: 29170px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                "
              >
                {{ this.tipoContrato }}
              </h2>
            </div>
            <div
              style="
                top: 29955px;
                left: 790px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                "
              >
                meses
              </h3>
            </div>
            <div
              style="
                top: 30155px;
                left: 1015px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2 style="color: #38424b; font-family: 'Helvetica Neue LT Std'">
                {{ this.totalPresupuestoIndustrialFormatado }} €
              </h2>
            </div>
            <div
              style="
                top: 30200px;
                left: 1015px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2 style="color: #38424b; font-family: 'Helvetica Neue LT Std'">
                {{ this.anoRecuperacionInversionBalanceFinancieroIndustrial }}
                AÑOS
              </h2>
            </div>
            <div
              style="
                top: 30366px;
                left: 860px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 39px;
                "
              >
                <b>{{ this.TIR }} %</b>
              </h2>
            </div>
            <div
              style="
                top: 30551px;
                left: 830px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 39px;
                "
              >
                <b>{{ this.VAN }} €</b>
              </h2>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/financiacion_industrial.png"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              style="
                top: 31835px;
                left: 1165px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                "
              >
                {{ this.totalPresupuestoIndustrialFormatadoSinDecimales }} €
              </h2>
            </div>
            <div
              style="
                top: 31880px;
                left: 1210px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                "
              >
                0 €
              </h2>
            </div>
            <div
              style="
                top: 31925px;
                left: 1165px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                "
              >
                <b>
                  {{ this.totalPresupuestoIndustrialFormatadoSinDecimales }} €
                </b>
              </h2>
            </div>
            <div
              style="
                top: 31972px;
                left: 1210px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                "
              >
                84
              </h2>
            </div>
            <div
              style="
                top: 32020px;
                left: 1165px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                "
              >
                <b>{{ this.totalCalculoCuotaRentingIndustrial }} €</b>
              </h2>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/servicios_industrial.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/clientes_satisfechos_industrial.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/documentos_adicionales_domestico.png"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <!-- <div [innerHTML]="this.fitxasTecnicasKits"></div>  -->
    <table class="page" *ngIf="this.fichaTecnica1Panel">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br />
            <img
              width="1700px;"
              height="2177px;"
              style="z-index: -999"
              src="{{
                'assets/images/' +
                  this.panelSeleccionadoIndustrial.MODELO +
                  '/F.jpg'
              }}"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page" *ngIf="this.fichaTecnica2Panel">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br />
            <img
              width="1700px;"
              height="2177px;"
              style="z-index: -999"
              src="{{
                'assets/images/' +
                  this.panelSeleccionadoIndustrial.MODELO +
                  '/F-2.jpg'
              }}"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page" *ngIf="this.fichaTecnica1Inversor">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br />
            <img
              width="1700px;"
              height="2177px;"
              style="z-index: -999"
              src="{{
                'assets/images/' +
                  this.inversorSeleccionadoIndustrial.MODELO +
                  '/F.jpg'
              }}"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page" *ngIf="this.fichaTecnica2Inversor">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br />
            <img
              width="1700px;"
              height="2177px;"
              style="z-index: -999"
              src="{{
                'assets/images/' +
                  this.inversorSeleccionadoIndustrial.MODELO +
                  '/F-2.jpg'
              }}"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="this.solarblockIndustrial">
      <table class="page">
        <tbody>
          <tr>
            <td colspan="3">
              <br /><br /><br /><br /><img
                width="1700px;"
                height="2177px;"
                style="z-index: -999"
                src="../../../assets/images/SOLARBLOCK/F.jpg"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <img
              width="1700px;"
              height="2377px;"
              src="../../../assets/images/PPTO_2/cierre_domestico.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
