import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http/http.service';
import { Alertas } from '../alertas/alertas.component';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  username = "";
  numeroAlarmas = "";

  constructor(
    public dialog: MatDialog,
    public http:HttpService,
    public shared:SharedService
  ) {
     this.inicializaNumeroAlarmas();
  }

  ngOnInit() {
    
    this.username = localStorage.getItem('loggedUsername');
    //this.http.obtenerNumeroAlarmasDiarias(new Date())//estic aqui
  }

  inicializaNumeroAlarmas()
  {
    
      this.http.obtenerNumeroAlarmasDiarias(Date.now()).then(
        (result) => {
          this.shared.numeroAlarmas = result[0]["TOTAL"];
          
        }
      )
  }

  abrirDialog() { 
    
      
        const dialogRef = this.dialog.open(Alertas, {
          width: "85%",
          /*height:"45%",*/
          data: {
            tituloModal: "Alertas",            
          },
        });
    
        dialogRef.afterClosed().subscribe((result) => {
           
          this.inicializaNumeroAlarmas();
      
        });
      
    
  }
}
