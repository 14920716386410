<h1 mat-dialog-title style="text-align: center;">{{ data.tituloModal }}</h1>
<hr />
<div mat-dialog-content style="margin:0px;" class="container">

    <div class="row">

        <div class="col-md-3">
            <mat-form-field class="example-full-width">
                <input matInput required placeholder="Nombre" [(ngModel)]="this.data.kit.NOMBRE" (ngModelChange)="evaluaSiEsPermitidoGuardar()" name="NOMBRE">
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field>          
                <mat-label>Potencia (kW)</mat-label>      
                <input matInput required placeholder="Potencia" [(ngModel)]="this.data.kit.POTENCIA" (ngModelChange)="evaluaSiEsPermitidoGuardar()" name="POTENCIA">
            </mat-form-field>
        </div>
        <div class="col-md-3">            
            <mat-form-field>          
                <mat-label>Tipo</mat-label>                     
                <mat-select placeholder="Tipo" #singleSelect matInput [(ngModel)]="this.data.kit.TIPO" name="TIPO"  (selectionChange)="evaluaSiEsPermitidoGuardar()">                
                    <mat-option>
                        <ngx-mat-select-search noEntriesFoundLabel="Error obteniendo Tipos contrato"></ngx-mat-select-search>
                    </mat-option>      
                    <mat-option *ngFor="let tipo of tipoKit" [value]="tipo.id">
                        {{tipo.valor}}
                      </mat-option>
                  
                </mat-select>
            </mat-form-field>            
        </div>
        <div class="col-md-3">            
            <!-- 
            <mat-form-field>          
                <mat-label>Hábito consumo</mat-label>      
                <mat-select placeholder="Hábito consumo" #singleSelect matInput [(ngModel)]="this.data.kit.HABITO_CONSUMO" name="HABITO_CONSUMO"  (selectionChange)="evaluaSiEsPermitidoGuardar()">                
                    <mat-option>
                        <ngx-mat-select-search noEntriesFoundLabel="Error obteniendo hábitos consumo"></ngx-mat-select-search>
                    </mat-option>      
                    <mat-option *ngFor="let habito of habitosConsumo" [value]="habito.id">
                        {{habito.valor}}
                      </mat-option>                  
                </mat-select>            
            </mat-form-field>            
            -->
            <mat-form-field>          
                <mat-label>Precio de venta</mat-label>      
                <input matInput required placeholder="Precio de venta" [(ngModel)]="this.data.kit.PRECIO_VENTA" (ngModelChange)="evaluaSiEsPermitidoGuardar()" name="PRECIO_VENTA">
            </mat-form-field>     
        </div>
        
        


    </div>


    <br />
    <div class="row">
        
            <p style="text-align: center;"><b>Artículos disponibles</b></p>
            <br/><hr />
            <div class="col-md-12">
                
                <mat-select multiple required="true" placeholder="Artículos" matInput name="Artículos" multiple style="width:100%"  [(ngModel)]="selected" #selectionModel="ngModel">                
                    <mat-option>
                        <ngx-mat-select-search [formControl]="articulosFilterCtrl" noEntriesFoundLabel="No se encontró un artículo que coincida"></ngx-mat-select-search>
                    </mat-option>     
                    <mat-option *ngFor="let articulo of articulosFiltrados" [value]="articulo"  (onSelectionChange)="cambioArticulosAsignados($event)">
                        {{articulo.MODELO}}
                    </mat-option>                  
                </mat-select>           
                
            </div>
        
        
           
        
    </div>
    <div class="row">
        <table style="text-align:center;width:100%">
            <tr>
                <td colspan="2">
                    <hr/>
                </td>
            </tr>
            <tr>
                <td><b>Artículo seleccionado</b></td>
                <td style="text-align:left;"><b>Cantidad</b></td>
            </tr>
            <tr *ngFor="let item of articulosAsignadosAKit">
                <td>{{item.nombre}}</td>
                <td>
                    <input class="form-control col-md-6" [(ngModel)]="item.cantidad" />
                </td>
            </tr>
        </table>
    </div>
   


</div>
<div mat-dialog-actions>
    <div class="row col-12">
        <div class="col-3">
            <button mat-raised-button color="warn" (click)="cancelaDialogo()">Cancelar</button>
        </div>
        <div class="col-6"></div>
        <div class="col-3">
            <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial [disabled]="guardarDeshabilitado">Aceptar</button>
        </div>
    </div>


</div>