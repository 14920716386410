import { Column, Entity, PrimaryColumn, PrimaryGeneratedColumn } from "typeorm";

@Entity("mnt_tipos_contrato")
export class MNT_TiposContrato {
  @PrimaryGeneratedColumn()
  ID_TIPO_CONTRATO: number;
  @PrimaryColumn()
  TIPO: string;
  @Column()
  NOMBRE: string;
  @Column()
  DESCRIPCION: string;
  @Column({ type: "decimal" })
  POTENCIA: number;
  @Column()
  HABITO_CONSUMO: string;
  @Column()
  ROL: number;
  @Column()
  TRAMOS_TARIFA_I: number;
  @Column({ type: "decimal" })
  PRECIO_VENTA: number;

  constructor(
    _id_tipo_contrato = 0,
    _tipo = "",
    _nombre = "",
    _descripcion = "",
    _potencia = 0,
    _habito_consumo = "",
    _rol = 0,
    _tramos_tarifa_i = 0,
    _precio_venta = 0,
  ) {
    this.ID_TIPO_CONTRATO = +_id_tipo_contrato;
    this.TIPO = _tipo;
    this.NOMBRE = _nombre;
    this.DESCRIPCION = _descripcion;
    this.POTENCIA = +_potencia;
    this.HABITO_CONSUMO = _habito_consumo;
    this.ROL = +_rol;
    this.TRAMOS_TARIFA_I = +_tramos_tarifa_i;
    this.PRECIO_VENTA = +_precio_venta;
  }
}
