<h1 mat-dialog-title style="text-align: center;">{{ data.tituloModal }}</h1>
<hr />
<div mat-dialog-content style="margin:0px;" class="container">

    <div class="row">

        <div class="col-md-12">
            <mat-form-field class="example-full-width">
                <input matInput required placeholder="Nombre" style="width:100%" [(ngModel)]="this.data.marca.NOMBRE" (ngModelChange)="evaluaSiEsPermitidoGuardar()" name="NOMBRE">
            </mat-form-field>
        </div>
        
    </div>
 

</div>
<div mat-dialog-actions>
    <div class="row col-12">
        <div class="col-3">
            <button mat-raised-button color="warn" (click)="cancelaDialogo()">Cancelar</button>
        </div>
        <div class="col-6"></div>
        <div class="col-3">
            <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial [disabled]="guardarDeshabilitado">Aceptar</button>
        </div>
    </div>


</div>