import { Component, OnInit, ViewChild } from "@angular/core";

import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { HttpService } from "src/app/services/http/http.service";

import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NotificationService } from "src/app/services/notification/notification.service";

import {
  AngularGridInstance,
  Column,
  ExtensionName,
  FieldType,
  Filters,
  Formatter,
  Formatters,
  GridOption,
} from "angular-slickgrid";

import { DatePipe } from "@angular/common";
import { EstadoPresupuesto, PPTOS } from "@shared/pptos.entity";
import { MNT_TiposContrato } from "@shared/MNT.tipos_contrato.entity";
import { AfterContentChecked, ChangeDetectorRef } from "@angular/core";
import { FinalizacionPresupuesto } from "./finalizacion-ppto.component";
import { CRM_AccionesRealizadas } from "@shared/CRM.acciones_realizadas.entity";
import { SharedService } from "src/app/services/shared/shared.service";
import { PPTOS_Alarmas, TiposAlarmas } from "@shared/PPTOS.alarmas.entity";
import * as moment from "moment";
import { DefinicionAlarmaPresupuesto } from "./definicion-alarma-ppto.component";

const momenta = require("moment-business-days");

@Component({
  selector: "grid-presupuestos",
  templateUrl: "./grid-presupuestos.component.html",
  styleUrls: ["./grid-presupuestos.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" }),
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"),
      ),
    ]),
  ],
})
export class GridPresupuestosComponent implements OnInit, AfterContentChecked {
  pageReady = false;
  tituloModal = "";
  filtradoGrd = "";

  displayedColumns: string[] = ["NOMBRE", "editarGrupo"];
  angularGrid: AngularGridInstance;

  columnDefinitions1: Column[];
  columnDefinitionsHiddenDefault: Column[];
  dataset1: any[] = [];
  gridOptions1: GridOption;

  rowData = [];
  filtrado = new Array<PPTOS>();
  public pptos: Array<PPTOS>;
  source: Array<any> = [];

  public emailstring =
    "mailto:xyz@example.com?Subject=Hello&attachment=http://localhost:4200/assets/images/logo.png&body=links:  %0D http://link1.com  %0D http://link1.com";

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  constructor(
    public router: Router,
    public http: HttpService,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    public shared: SharedService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  async ngOnInit() {
    const actionFormatter: Formatter = () => {
      return `<div class="fake-hyperlink">Acciones <i class="fa fa-caret-down"></i></div>`;
    };

    const precioFormatter: Formatter = (_row, _cell, value) => {
      return value + " €";
    };

    const estadoFormatter: Formatter = (
      _row,
      _cell,
      value,
      _columnDef,
      dataContext,
    ) => {
      if (value.toString().trim().length <= 0) {
        return "";
      } else {
        if (value == EstadoPresupuesto.Pendiente) {
          return "Pendiente revisión";
        } else if (value == EstadoPresupuesto.Aceptado) {
          return "Aceptado";
        } else if (value == EstadoPresupuesto.Denegado) {
          //this.http.obtenerMotivoDenegacionSegunPpto()
          return "Denegado (" + dataContext["motivo_denegacion"] + ")";
        } else {
          return "No definido";
        }
      }
    };

    this.columnDefinitions1 = [
      {
        id: "numeracion",
        name: "Numeracion",
        field: "numeracion",
        filterable: true,
        sortable: true,
        columnGroup: "Datos PPTO",
        maxWidth: 125,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      /*{
        id: 'consumo_anual',
        name: 'Consumo anual',
        field: 'consumo_anual',
        width: 100,
        columnGroup: 'Datos PPTO',
        sortable: true,
        filterable:true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true
        }
      },   */
      {
        id: "precio_final",
        name: "Precio final",
        field: "precio_final",
        maxWidth: 150,
        columnGroup: "Datos PPTO",
        sortable: true,
        filterable: true,
        formatter: precioFormatter,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "potencia",
        name: "Potencia",
        field: "potencia",
        maxWidth: 100,
        columnGroup: "Datos PPTO",
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "estado",
        nameKey: "estado",
        field: "estado",
        name: "Estado",
        columnGroup: "Datos PPTO",
        formatter: estadoFormatter,
        sortable: true,
        filterable: true,
        maxWidth: 210,
        filter: {
          collection: [
            { value: "", label: "" },
            { value: EstadoPresupuesto.Pendiente, label: "Pendiente revisión" },
            { value: EstadoPresupuesto.Aceptado, labelKey: "Aceptado" },
            { value: EstadoPresupuesto.Denegado, labelKey: "Denegado" },
          ],
          model: Filters.singleSelect,
          filterOptions: {
            autoDropWidth: true,
          },
          searchTerms: [EstadoPresupuesto.Pendiente],
        },
      },
      {
        id: "fecha_ppto",
        name: "Fecha PPTO",
        field: "fecha_ppto",
        sortable: true,
        columnGroup: "Datos PPTO",
        maxWidth: 140,
        formatter: Formatters.dateIso,
        outputType: FieldType.dateIso,
        type: FieldType.date,
        filterable: true,
        filter: { model: Filters.compoundDate },
      },
      {
        id: "nombre_apellidos_cliente",
        name: "Cliente",
        field: "nombre_apellidos_cliente",
        sortable: true,
        filterable: true,
        columnGroup: "Datos personales",
        maxWidth: 250,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "mail",
        name: "Correo",
        field: "mail",
        sortable: true,
        filterable: true,
        columnGroup: "Datos personales",
        maxWidth: 350,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "direccion",
        name: "Dirección",
        field: "direccion",
        maxWidth: 380,
        columnGroup: "Datos personales",
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "acciones",
        name: "Acciones",
        field: "acciones",
        columnGroup: "Acciones",
        maxWidth: 120,
        excludeFromExport: true,
        formatter: actionFormatter,
        cellMenu: {
          hideCloseButton: false,
          width: 200,
          menuUsabilityOverride: () => {
            return true;
          },
          commandItems: [
            { command: "editar", title: "Editar", positionOrder: 60 },
            /*{ command: 'pdf', title: 'Ver PDF', positionOrder: 61 },*/
            {
              command: "finalizar",
              title: "Finalizar",
              cssClass: "green",
              positionOrder: 63,
              itemVisibilityOverride: function (args) {
                if (args.dataContext.estado == "P") {
                  return true;
                } else {
                  return false;
                }
              },
            },
            {
              command: "pendiente",
              title: "Cambiar a pendiente",
              cssClass: "green",
              positionOrder: 63,
              itemVisibilityOverride: function (args) {
                if (args.dataContext.estado == "D") {
                  return true;
                } else {
                  return false;
                }
              },
            },
            { divider: true, command: "", positionOrder: 65 },
            {
              command: "eliminar",
              title: "Eliminar PPTO",
              cssClass: "red",
              positionOrder: 66,
            },
          ],
        },
      },
    ];

    this.gridOptions1 = {
      columnPicker: {
        hideForceFitButton: true,
        hideSyncResizeButton: true,
        onColumnsChanged: () => {
        },
      },
      presets: {
        sorters: [
          { columnId: "id_ppto", direction: "DESC" },
        ],
      },
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 28,
      //enableAutoResize: true,
      forceFitColumns: true,
      enableGridMenu: true,
      autoHeight: true,
      // enable the filtering but hide the user filter row since we use our own single filter
      enableFiltering: true,
      showHeaderRow: true, // hide the filter row (header row)

      alwaysShowVerticalScroll: false,
      enableColumnPicker: true,
      enableRowSelection: true,
      enablePagination: true,
      pagination: {
        pageSizes: [15, 30, 50, 100],
        pageSize: 15,
      },
      enableCellMenu: true,
      enableCellNavigation: true,
      enableSorting: true,
      excelExportOptions: {
        exportWithFormatter: true,
        customColumnWidth: 15,
        columnHeaderStyle: { font: { bold: true, italic: true } },
      },

      enableContextMenu: true,

      cellMenu: {
        onCommand: (e, args) => this.executeCommand(e, args),
        onOptionSelected: (_e, args) => {
          const dataContext = args && args.dataContext;
          if (dataContext && dataContext.hasOwnProperty("completed")) {
            dataContext.completed = args.item.option;
            this.angularGrid.gridService.updateItem(dataContext);
          }
        },
        onBeforeMenuClose:
          ((_e, args) => console.log("Cell Menu is closing", args)),
      },
    };

    await this.inicializaListadoPresupuestos();
    this.pageReady = true;
  }

  executeCommand(_e: any, args: any) {
    const command = args.command;
    const dataContext = args.dataContext;

    /*


    var equipo = new INV_Equipos(dataContext.id_equipo,dataContext.id_sede,
      dataContext.nombre,dataContext.dominio,dataContext.id_usuario_asignado,dataContext.cpu_generacion,dataContext.cpu_caracteristicas,
      dataContext.ram,dataContext.hd_tipo,dataContext.hd_capacidad,dataContext.so,dataContext.tipo,dataContext.n_serie,dataContext.fabricante,dataContext.modelo,
      dataContext.fecha_alta,dataContext.ip_fija,dataContext.notas_privadas);




    */

    switch (command) {
      case "editar":
        this.editarPPTO(dataContext.id);
        break;
      case "pdf":
        this.verPDF(dataContext.id);
        break;
      case "finalizar":
        this.abrirDialogFinalizacionPpto(dataContext.id);
        break;
      case "pendiente":
        this.marcarPresupuestoDenegadoComoPendiente(dataContext.id);
        break;
      case "eliminar":
        this.eliminarPpto(dataContext.id);
        break;
    }
  }

  verPDF(_id_ppto: any) {
    localStorage.setItem("id_ppto", _id_ppto);
    localStorage.setItem("accion", "PDF");
    this.router.navigate(["resumen-ppto"]);
  }

  editarPPTO(_id_ppto: any) {
    localStorage.setItem("id_ppto", _id_ppto);
    localStorage.setItem("accion", "editar");
    this.router.navigate(["creacion-presupuesto"]);
  }

  finalizarPpto(_id_ppto: any, estado: any, motivoDenegacionPpto = "") {
    if (confirm("Estás seguro? ")) {
      this.finalizarPptoConfirmado(_id_ppto, estado, motivoDenegacionPpto);
      this.http.eliminarAlarma(_id_ppto).then(
        () => {
          this.inicializaNumeroAlarmas();
        },
      );
    } else {
      this.spinner.hide();
    }
  }

  inicializaNumeroAlarmas() {
    this.http.obtenerNumeroAlarmasDiarias(Date.now()).then(
      (result) => {
        this.shared.numeroAlarmas = result[0]["TOTAL"];
      },
    );
  }

  finalizaBorradoresConMismaNumeracion(_idPpto: any, _numeracion: string) {
    var numeracionFormatada = _numeracion.substring(0, 5);
    this.http.obtenerPresupuestosConMismaNumeracion(numeracionFormatada).then(
      (result: PPTOS[]) => {
        result.forEach((element) => {
          if (Number(_idPpto) != Number(element.ID_PPTO)) {
            if (element.ESTADO != EstadoPresupuesto.Denegado) {
              this.http.modificarEstadoPpto(
                element.ID_PPTO,
                EstadoPresupuesto.Denegado,
                "Aceptado otro presupuesto: " + _numeracion,
              ).then(
                () => {
                  this.http.eliminarAlarma(element.ID_PPTO).then(
                    () => {
                      this.inicializaListadoPresupuestos();

                      this.http.obtenerPresupuesto(element.ID_PPTO).then(
                        (pptoObtenido) => {
                          var s = new CRM_AccionesRealizadas();
                          s.ID_CLIENTE = pptoObtenido["ID_CLIENTE"];
                          var date = new Date(); // Or the date you'd like converted.
                          var isoDateTime = new Date(
                            date.getTime() - (date.getTimezoneOffset() * 60000),
                          ).toISOString().slice(0, 16);
                          s.FECHA = isoDateTime;
                          s.DESCRIPCION = "Presupuesto " +
                            pptoObtenido["NUMERACION"] + " denegado (MOTIVO: " +
                            "Aceptado otro presupuesto: " + _numeracion + ")";

                          this.http.guardarAccionRealizada(s);
                        },
                      );
                    },
                  );
                },
              );
            }
          }
        });
      },
    );
  }

  finalizarPptoConfirmado(
    _id_ppto: any,
    estado: any,
    motivoDenegacionPpto: any,
  ) {
    this.http.modificarEstadoPpto(_id_ppto, estado, motivoDenegacionPpto).then(
      () => {
        this.http.obtenerPresupuesto(_id_ppto).then(
          (pptoObtenido) => {
            var s = new CRM_AccionesRealizadas();
            s.ID_CLIENTE = pptoObtenido["ID_CLIENTE"];
            var date = new Date(); // Or the date you'd like converted.
            var isoDateTime = new Date(
              date.getTime() - (date.getTimezoneOffset() * 60000),
            ).toISOString().slice(0, 16);
            s.FECHA = isoDateTime;
            if (estado == EstadoPresupuesto.Aceptado) {
              s.DESCRIPCION = "Presupuesto " + pptoObtenido["NUMERACION"] +
                " aceptado.";
            } else if (estado == EstadoPresupuesto.Denegado) {
              s.DESCRIPCION = "Presupuesto " + pptoObtenido["NUMERACION"] +
                " denegado (MOTIVO: " + motivoDenegacionPpto + ")";
            }

            this.http.guardarAccionRealizada(s).then(
              () => {
                if (estado == EstadoPresupuesto.Aceptado) {
                  this.finalizaBorradoresConMismaNumeracion(
                    _id_ppto,
                    pptoObtenido["NUMERACION"],
                  );
                }

                this.spinner.hide();
                this.notifyService.showMessage(
                  "Operación efectuada correctamente",
                  "Success",
                  false,
                );
                this.inicializaListadoPresupuestos();
              },
            );
          },
        );
      },
      () => {
        this.spinner.hide();
        this.notifyService.showMessage(
          "Error efectuando operación",
          "Error",
          true,
        );
        this.spinner.hide();
      },
    );
  }

  abrirDialogFinalizacionPpto(_id_ppto: number) {
    this.tituloModal = "Finalización presupuesto";

    const dialogRef = this.dialog.open(FinalizacionPresupuesto, {
      width: "50%",
      data: {
        tituloModal: this.tituloModal,
        motivoDenegacionPpto: "",
        estadoFinalizacionPpto: EstadoPresupuesto.Aceptado,
        id_ppto: _id_ppto,
        //id_cliente: _id_cliente,
        //accionesRealizadas: new Array<CRM_AccionesRealizadas>()
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinner.show();
        this.finalizarPpto(
          result.id_ppto,
          result.estadoFinalizacionPpto,
          result.motivoDenegacionPpto,
        );

        /*
        this.http.eliminarAccionesRealizadasSegunCliente(_id_cliente).then(
          () => {
            //alert(JSON.stringify(result));
            result.accionesRealizadas.forEach(element => {

              this.http.guardarAccionRealizada(element).then(
                () => {

                }
              )
            });

            this.notifyService.showMessage(
              "Operación efectuada correctamente",
              "Success",
              false
            );
          }
        )
        */
      }
    });
  }

  marcarPresupuestoDenegadoComoPendiente(_id_ppto: any) {
    if (confirm("Estás seguro? ")) {
      this.http.modificarEstadoPpto(_id_ppto, EstadoPresupuesto.Pendiente, "")
        .then(
          () => {
            this.http.obtenerPresupuesto(_id_ppto).then(
              (pptoObtenido) => {
                var s = new CRM_AccionesRealizadas();
                s.ID_CLIENTE = pptoObtenido["ID_CLIENTE"];
                var date = new Date(); // Or the date you'd like converted.
                var isoDateTime = new Date(
                  date.getTime() - (date.getTimezoneOffset() * 60000),
                ).toISOString().slice(0, 16);
                s.FECHA = isoDateTime;
                s.DESCRIPCION = "Presupuesto " + pptoObtenido["NUMERACION"] +
                  " que estaba denegado se ha cambiado a pendiente";

                this.http.guardarAccionRealizada(s);
              },
            );

            this.abrirDialogDefinicionAlarmaPpto(_id_ppto);
          },
        );
    }
  }

  abrirDialogDefinicionAlarmaPpto(_id_ppto: number) {
    var tituloModal = "Definición alarma";

    const dialogRef = this.dialog.open(DefinicionAlarmaPresupuesto, {
      width: "50%",
      data: {
        tituloModal: tituloModal,
        id_ppto: _id_ppto,
        //id_cliente: _id_cliente,
        //accionesRealizadas: new Array<CRM_AccionesRealizadas>()
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        var dateObj = new Date();
        var alarma = new PPTOS_Alarmas();
        if (result.estadoFinalizacionPpto == "S") { //ALARMA SEGUIMIENTO
          alarma.ID_PPTO = _id_ppto;
          alarma.TIPO_ALARMA = TiposAlarmas.Seguimiento;
          alarma.VISUALIZADA = false;
          alarma.SIGUIENTE_RECORDATORIO =
            momenta(dateObj, "DD-MM-YYYY").businessAdd(3)._d;
        } else {
          alarma.ID_PPTO = _id_ppto;
          alarma.TIPO_ALARMA = TiposAlarmas.Pendiente;
          alarma.VISUALIZADA = false;
          alarma.SIGUIENTE_RECORDATORIO =
            momenta(dateObj, "DD-MM-YYYY").businessAdd(1)._d;
          alarma.MOTIVO_SEGUIMIENTO = result["motivoDenegacionPpto"];
        }

        alarma.SIGUIENTE_RECORDATORIO = moment(alarma.SIGUIENTE_RECORDATORIO)
          .add(1, "hour").format("YYYY-MM-DD");

        var alarmaObtenidaFormatada = moment(alarma.SIGUIENTE_RECORDATORIO)
          .format("DD-MM-YYYY");

        this.http.guardarAlarma(alarma).then(
          () => {
            this.inicializaListadoPresupuestos();
            this.notifyService.showMessage(
              "Operación efectuada correctamente",
              "Success",
              false,
            );
            this.notifyService.showMessage(
              "Alarma programada para el día: " + alarmaObtenidaFormatada,
              "Success",
              false,
            );
          },
        );

        //this.router.navigate(['grid-presupuestos']);
      }
    });
  }

  eliminarPpto(_id_ppto: any) {
    if (confirm("Estás seguro? ")) {
      this.spinner.show();
      this.http.eliminarPpto(_id_ppto).then(
        () => {
          this.http.eliminarPreciosKWHIndustrial(_id_ppto).then(
            () => {
              this.http.eliminarConsumosIntroduccionManualIndustrial(_id_ppto)
                .then(
                  () => {
                    this.http.eliminarGeneracionConsumosIndustrial(_id_ppto)
                      .then(
                        () => {
                          this.http.eliminarAlarma(_id_ppto).then(
                            () => {
                              this.inicializaNumeroAlarmas();
                              this.spinner.hide();
                              this.notifyService.showMessage(
                                "Operación efectuada correctamente",
                                "Success",
                                false,
                              );
                              this.inicializaListadoPresupuestos();
                            },
                          );
                        },
                      );
                  },
                );
            },
          );
        },
        () => {
          this.spinner.hide();
          this.notifyService.showMessage(
            "Error efectuando operación",
            "Error",
            true,
          );
          this.spinner.hide();
        },
      );
    } else {
      this.spinner.hide();
    }
  }

  angularGridReady(angularGrid: AngularGridInstance) {
    this.angularGrid = angularGrid;
  }

  get cellMenuInstance(): any {
    return this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.cellMenu,
        ) || {};
  }

  get contextMenuInstance(): any {
    return this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.contextMenu,
        ) || {};
  }

  async inicializaListadoPresupuestos() {
    this.spinner.show();
    this.http.obtenerTodosPresupuestos()
      .then(
        (result: Array<PPTOS>) => {
          this.pptos = result;

          this.spinner.hide();
          this.filtrar();
          this.pageReady = true;
        },
        () => {
          this.spinner.hide();
          this.filtrar();
        },
      );
  }

  nuevoPresupuesto() {
    localStorage.setItem("id_ppto", "");
    localStorage.setItem("accion", "");
    this.router.navigate(["creacion-presupuesto"]);
  }

  filtrar() {
    this.filtrado = this.pptos;

    this.rowData = this.filtrado;
    this.source = this.filtrado;

    var datosFormatados = [];
    var iteracion = 0;

    this.http.obtenerTodosTiposContrato().then(
      (result: Array<MNT_TiposContrato>) => {
        this.rowData.forEach((element) => {
          iteracion++;
          new Promise((resolve) => {
            var potencia = "";
            result.forEach((tipoContrato) => {
              if (element.TIPO_PPTO == "D") {
                if (tipoContrato.ID_TIPO_CONTRATO == element.ID_TIPO_CONTRATO) {
                  potencia = tipoContrato.POTENCIA.toString() + " kW";
                }
              } else {
                //
                potencia = element.POTENCIA_PICO_I + " kW";
              }
            });

            var fechaFormatada = this.datePipe.transform(
              element.FECHA_PPTO,
              "yyyy-MM-dd",
            );

            datosFormatados.push({
              id: element.ID_PPTO,
              numeracion: element.NUMERACION,
              potencia: potencia,
              nombre_apellidos_cliente: element.NOMBRE_APELLIDOS_CLIENTE,
              mail: element.MAIL,
              direccion: element.DIRECCION + " " + element.POBLACION,
              consumo_anual: element.CONSUMO_ANUAL,
              fecha_ppto: fechaFormatada,
              precio_final: element.PRECIO_FINAL,
              estado: element.ESTADO,
              motivo_denegacion: element.MOTIVO_DENEGACION,
            });

            if (iteracion == (this.rowData.length)) {
              resolve(this);
            }
          }).then(
            () => {
              this.dataset1 = datosFormatados;
            },
          );
        });
      },
    );
  }

  float2int(value: any) {
    return value | 0;
  }
}
