import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { MNT_Familias } from '@shared/MNT.familias.entity';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
    selector: 'creacion-edicion-marca',
    templateUrl: 'creacion-edicion-marca.html',
    styleUrls: ['./creacion-edicion-marca.css'],
  })
  export class CreacionEdicionMarca {
        
    guardarDeshabilitado=true;    

    familias = new Array<MNT_Familias>();
    
    rutaImagen:string = "";
        
    constructor(
      public dialogRef: MatDialogRef<CreacionEdicionMarca>,
      @Inject(MAT_DIALOG_DATA) public data,
      public http:HttpService,
      public dialog: MatDialog,
      private spinner: NgxSpinnerService,
      private notifyService : NotificationService,
      ) {}

    cancelaDialogo(): void {
      this.dialogRef.close();
    }



    ngOnInit()
    {
            
        this.spinner.show();
        this.evaluaSiEsPermitidoGuardar();


        this.spinner.hide();

    }

  

    evaluaSiEsPermitidoGuardar()
    {

      if (this.data.marca.NOMBRE.toString().trim().length > 0)       
          this.guardarDeshabilitado = false;
      else
          this.guardarDeshabilitado = true;


  }

  }
