import { Component, OnInit, ViewChild } from "@angular/core";

import { NgxSpinnerService } from "ngx-spinner";
import {
  Router,
} from "@angular/router";
import { HttpService } from "src/app/services/http/http.service";

import { MatDialog, } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NotificationService } from "src/app/services/notification/notification.service";
//import { INV_CreacionEdicionCliente } from './INV_creacion-edicion-cliente.component';

import {
  AngularGridInstance,
  Column,
  ExtensionName,
  FieldType,
  Filters,
  Formatter,
  GridOption,
} from "angular-slickgrid";

//import { Contacto } from '@shared/contacto.entity';


import { CreacionEdicionCliente } from "./creacion-edicion-cliente.component";
import { CRM_Clientes,EstadoClientes,ProcedenciaClientes } from "@shared/CRM.clientes.entity";
import { AccionesRealizadas } from "./acciones-realizadas.component";
import { CRM_AccionesRealizadas } from "@shared/CRM.acciones_realizadas.entity";

@Component({
  selector: "grid-clientes",
  templateUrl: "./grid-clientes.component.html",
  styleUrls: ["./grid-clientes.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class GridClientesComponent implements OnInit {
  pageReady = false;
  tituloModal = "";
  filtradoGrd = "";

  displayedColumns: string[] = ["NOMBRE", "editarGrupo"];
  angularGrid: AngularGridInstance;

  columnDefinitions1: Column[];
  columnDefinitionsHiddenDefault: Column[];
  dataset1: any[] = [];
  gridOptions1: GridOption;

  rowData = [];
  filtrado = new Array<CRM_Clientes>();
  public clientes: Array<CRM_Clientes>;
  
  source: Array<any> = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public router: Router,
    public http: HttpService,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    public dialog: MatDialog  ) {}

  async ngOnInit() {
    
    const actionFormatter: Formatter = (
          ) => {
      return `<div class="fake-hyperlink">Acciones <i class="fa fa-caret-down"></i></div>`;
    };

    const procedenciaFormatter: Formatter = (row, cell, value) => {    

      if (value == ProcedenciaClientes.Captacion_Propia)
        return "Captación propia";
      else if (value == ProcedenciaClientes.Contacto_Web)
        return "Contacto web";
      else if (value == ProcedenciaClientes.Flyer)
        return "Flyer";
      else if (value == ProcedenciaClientes.Llamada_Oficinas)
        return "Llamada oficinas";
      else if (value == ProcedenciaClientes.Nuevo_PPTO)
        return "Nuevo PPTO";
      else if (value == ProcedenciaClientes.Promotor)
        return "Promotor";

      
    };

    const estadoFormatter: Formatter = (row, cell, value) => {    

      if (value == EstadoClientes.Vendido)
        return "Vendido";
      else if (value == EstadoClientes.Presupuesto_Entregado)
        return "Presupuesto entregado";
      else if (value == EstadoClientes.Seguimiento)
        return "Seguimiento";
      else if (value == EstadoClientes.Interesado)
        return "Interesado";
      else if (value == EstadoClientes.No_Interesado)
        return "No interesado";
      else if (value == EstadoClientes.Pendiente_Documentacion)
        return "Pendiente documentación";
      else if (value == EstadoClientes.Pendiente_Contacto)
        return "Pendiente contacto";      
      else if (value == EstadoClientes.Desestimado)
        return "Desestimado";
      else if (value == EstadoClientes.Tiene_Borradores_PPTO)
        return "Borrador presupuesto asignado";

      
    };
  

    this.columnDefinitions1 = [
      {
        id: "id",
        field: "id",
        name: "ID",        
        sortable: true,
        filterable: true,
        maxWidth: 75,
      },
      {
        id: "nombre",
        name: "Nombre",
        field: "NOMBRE",
        //width: 150,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },  
      {
        id: "correo",
        name: "Correo",
        field: "CORREO",
        //width: 150,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },      
      {
        id: "telefono",
        name: "Telefono",
        field: "TELEFONO",
        //width: 150,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "municipio",
        name: "Municipio",
        field: "MUNICIPIO",
        //width: 150,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },  
      
      /*
  if (value == ProcedenciaClientes.Captacion_Propia)
        return "Captación propia";
      else if (value == ProcedenciaClientes.Contacto_Web)
        return "Contacto web";
      else if (value == ProcedenciaClientes.Flyer)
        return "Flyer";
      else if (value == ProcedenciaClientes.Llamada_Oficinas)
        return "Llamada oficinas";
      else if (value == ProcedenciaClientes.Nuevo_PPTO)
        return "Nuevo PPTO";
      else if (value == ProcedenciaClientes.Promotor)
        return "Promotor";
      */
      { 
        id: 'procedencia',
        name: 'Procedencia',
        field: 'PROCEDENCIA',
        maxWidth: 150,        
        filterable:true,
        formatter: procedenciaFormatter,   
        type: FieldType.string,
        sortable: true,
        filter: {
          collection: [
            { value: '', label: ''},
            { value: 'C', label: 'Contacto web', },
            { value: 'P', labelKey: 'Captacion propia' },
            { value: 'L', labelKey: 'Llamada Oficinas' },
            { value: 'F', labelKey: 'Flyer' },
            { value: 'O', labelKey: 'Promotor' },
            { value: 'N', labelKey: 'Nuevo PPTO' }],
          model: Filters.singleSelect,
          filterOptions: {
            autoDropWidth: true
          },          
        }
      },      
      { 
        /*
 if (value == EstadoClientes.Vendido)
        return "Vendido";
      else if (value == EstadoClientes.Presupuesto_Entregado)
        return "Presupuesto entregado";
      else if (value == EstadoClientes.Seguimiento)
        return "Seguimiento";
      else if (value == EstadoClientes.Interesado)
        return "Interesado";
      else if (value == EstadoClientes.No_Interesado)
        return "No interesado";
      else if (value == EstadoClientes.Pendiente_Documentacion)
        return "Pendiente documentación";
      else if (value == EstadoClientes.Pendiente_Contacto)
        return "Pendiente contacto";      
      else if (value == EstadoClientes.Desestimado)
        return "Desestimado";
      else if (value == EstadoClientes.Tiene_Borradores_PPTO)
        return "Borrador presupuesto asignado";
        */
        id: 'estado',
        name: 'Estado',
        field: 'ESTADO',
        maxWidth: 150,        
        filterable:true,
        formatter: estadoFormatter,   
        type: FieldType.string,
        sortable: true,
        filter: {
          collection: [
            { value: '', label: ''},
            { value: 'V', label: 'Vendido', },
            { value: 'E', labelKey: 'Presupuesto entregado' },
            { value: 'S', labelKey: 'Seguimiento' },
            { value: 'I', labelKey: 'Interesado' },
            { value: 'N', labelKey: 'No interesado' },
            { value: 'P', labelKey: 'Pendiente documentacion' },
            { value: 'C', labelKey: 'Pendiente contacto' },
            { value: 'D', labelKey: 'Desestimado' },
            { value: 'B', labelKey: 'Borrador presupuesto generado' }
          ],
          model: Filters.singleSelect,
          filterOptions: {
            autoDropWidth: true
          },          
        }
      },         
      /*
      {
        id: 'pptos',
        name: 'Pptos',
        field: 'PPTOS',
        maxWidth: 150,        
        filterable:true,
        //formatter: estadoFormatter,   
        type: FieldType.string,
        sortable: true,      
      },           
      */
      {
        id: "acciones",
        name: "Acciones",
        field: "acciones",
        columnGroup: "Acciones",
        excludeFromExport: true,
        formatter: actionFormatter,
        cellMenu: {
          hideCloseButton: false,
          width: 200,
          menuUsabilityOverride: () => {
            return true;
          },
          commandItems: [
            { command: "editar", title: "Editar", positionOrder: 1 },                      
            { command: "accionesRealizadas", title: "Acciones realizadas", positionOrder: 3 },
            { divider: true, command: "", positionOrder: 63 },
            {
              command: "eliminar",
              title: "Eliminar",
              cssClass: "red",
              positionOrder: 64,
            },
            
          ],
        },
      },
    ];

    this.gridOptions1 = {
      columnPicker: {
        hideForceFitButton: true,
        hideSyncResizeButton: true,
        onColumnsChanged: (e, args) => {
          console.log(
            "Column selection changed from Column Picker, visible columns: ",
            args.columns
          );
        },
      },
      autoEdit: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 28,
      //enableAutoResize: true,
      forceFitColumns: true,
      enableGridMenu: true,
      autoHeight: true,
      // enable the filtering but hide the user filter row since we use our own single filter
      enableFiltering: true,
      showHeaderRow: true, // hide the filter row (header row)

      alwaysShowVerticalScroll: false,
      enableColumnPicker: true,
      enableRowSelection: true,
      enablePagination: true,
      pagination: {
        pageSizes: [15, 30, 50, 100],
        pageSize: 15,
      },
      enableCellMenu: true,
      /*
          autoResize: {
            containerId: 'container',
            sidePadding: 5
          },
          */
      rowSelectionOptions: {
        // True (Single Selection), False (Multiple Selections)
        selectActiveRow: false,
      },
      enableAutoResize: true,
      enableCellNavigation: true,
      enableCheckboxSelector: true,
      multiSelect: false,
      enableSorting: true,
      excelExportOptions: {
        exportWithFormatter: true,
        customColumnWidth: 15,
        columnHeaderStyle: { font: { bold: true, italic: true } },
      },

      enableContextMenu: true,

      cellMenu: {
        onCommand: (e, args) => this.executeCommand(e, args),
        onOptionSelected: (e, args) => {
          const dataContext = args && args.dataContext;
          if (dataContext && dataContext.hasOwnProperty("completed")) {
            dataContext.completed = args.item.option;
            this.angularGrid.gridService.updateItem(dataContext);
          }
        },
        onBeforeMenuClose: (e, args) =>
          console.log("Cell Menu is closing", args),
      },
    };

    await this.inicializaListadoClientes();    
    this.pageReady = true;
  }

  onSelectedRowsChanged(args) {
    if (Array.isArray(args.rows)) {
      args.rows.map((idx) => {
         
      });
    }
  }

  executeCommand(e, args) {
    const command = args.command;
    const dataContext = args.dataContext;

         
    switch (command) {
      case "editar":
        this.editarCliente(dataContext);
        break;
      case "accionesRealizadas":
          this.abrirDialogAccionesRealizadas(dataContext.id);
          break;
      case "eliminar":
        this.eliminarCliente(dataContext.id);
        break;
    }    
  }

  editarCliente(_marca) {
    this.abrirDialog(true, _marca);
  }

  abrirDialog(_edicion = false, _cliente: CRM_Clientes) {
    
    var _mar = new CRM_Clientes(0,0,"","","","","","","",0,"");

    if (_edicion) {
      this.tituloModal = "Editar cliente";
      _mar = _cliente;
    } else {
      this.tituloModal = "Nuevo cliente";
    }

    const dialogRef = this.dialog.open(CreacionEdicionCliente, {
      width: "75%",
      data: {
        tituloModal: this.tituloModal,
        edicion: _edicion,
        cliente: _mar,
      },
    });

    
    dialogRef.afterClosed().subscribe((result) => {
       
      
      
      if (result) {
        if (result.edicion) {
          //UPDATE
          
           
           
          this.spinner.show();

          this.http.actualizarCliente(result.cliente).then((resultat) => {
            console.log(
              "Cliente actualizado correctamente" + JSON.stringify(resultat)
            );
            this.notifyService.showMessage(
              "Cliente actualizado correctamente",
              "Success",
              false
            );
            this.inicializaListadoClientes();
            this.spinner.hide();
          });
          
        } //CREATE
        else {
           
          this.spinner.show();

          
          this.http.guardarCliente(result.cliente).then((resultat) => {
            console.log(
              "Cliente creado correctamente" + JSON.stringify(resultat)
            );
            this.notifyService.showMessage(
              "Cliente creado correctamente",
              "Success",
              false
            );
            this.inicializaListadoClientes();
            this.spinner.hide();
          });
          
        }
      } 
    });
    
    
  }

  abrirDialogAccionesRealizadas(_id_cliente: number) {
    
    
    this.tituloModal = "Acciones realizadas";

    const dialogRef = this.dialog.open(AccionesRealizadas, {
      width: "75%",
      data: {
        tituloModal: this.tituloModal,
        id_cliente: _id_cliente,
        accionesRealizadas: new Array<CRM_AccionesRealizadas>()
      },
    });

    
    dialogRef.afterClosed().subscribe((result) => {
       
      
      
      if (result) {

        this.http.eliminarAccionesRealizadasSegunCliente(_id_cliente).then(
          () => {
            //alert(JSON.stringify(result));
            result.accionesRealizadas.forEach(element => {
              
              this.http.guardarAccionRealizada(element).then(
                () => {

                }
              )
            });

            this.notifyService.showMessage(
              "Operación efectuada correctamente",
              "Success",
              false
            );
          }
        )
  
      } 
    });
    
    
  }

  eliminarCliente(_id_marca) {
    if (confirm("Estás seguro? ")) {
      this.spinner.show();
      this.http.eliminarCliente(_id_marca).then(
        () => {
          this.http.eliminarAccionesRealizadasSegunCliente(_id_marca).then(
            () => {
              this.spinner.hide();
              this.notifyService.showMessage(
                "Operación efectuada correctamente",
                "Success",
                false
              );
              this.inicializaListadoClientes();
            }
          )         
        },
        () => {
          this.spinner.hide();
          this.notifyService.showMessage(
            "Error efectuando operación",
            "Error",
            true
          );
          this.spinner.hide();
        }
      );
    }
  }

  angularGridReady(angularGrid: AngularGridInstance) {
    this.angularGrid = angularGrid;
  }

  get cellMenuInstance(): any {
    return (
      (this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.cellMenu
        )) ||
      {}
    );
  }

  get contextMenuInstance(): any {
    return (
      (this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.contextMenu
        )) ||
      {}
    );
  }

  async inicializaListadoClientes() {
    this.http.obtenerTodosLosClientes().then(
      (result: Array<CRM_Clientes>) => {
        this.clientes = result;
        //this.pptos = result;

        this.spinner.hide();
        this.filtrar(this.filtradoGrd);
      },
      () => {
         
        this.spinner.hide();
        this.filtrar(this.filtradoGrd);
      }
    );
  }

  nuevoCliente() {
    this.abrirDialog(false, new CRM_Clientes());
  }

  filtrar(valor) {
    this.filtrado = this.clientes.filter((art) =>
      art.NOMBRE.toString()
        .toLowerCase()
        .includes(valor.toString().toLowerCase())
    );

    this.rowData = this.filtrado;
    this.source = this.filtrado;

    //slickgrid
    // this.slick.beginUpdate();
    var datosFormatados = [];
    return new Promise((resolve) => {
      for (var i = 0; i < this.rowData.length; i++) {
        
        /* TODO
        this.http.getPPTOSegunCliente(this.rowData[i].ID_CLIENTE).then(
          (clientes:PPTOS[]) => {
            if (clientes.length > 1)
                alert(JSON.stringify(clientes));
          }
        );
        */
        //nombre_usuario_asignado
        datosFormatados[i] = {
          id: this.rowData[i].ID_CLIENTE,
          NOMBRE: this.rowData[i].NOMBRE,
          CORREO: this.rowData[i].CORREO,
          TELEFONO: this.rowData[i].TELEFONO,
          DIRECCION: this.rowData[i].DIRECCION,
          MUNICIPIO: this.rowData[i].MUNICIPIO,
          PROCEDENCIA: this.rowData[i].PROCEDENCIA,
          ESTADO: this.rowData[i].ESTADO,
          PROVINCIA: this.rowData[i].PROVINCIA,
          PPTOS: '999'
        };

        if (i == this.rowData.length - 1) resolve(this);
      }
    }).then(() => {
      this.dataset1 = datosFormatados;
    });
  }

  float2int(value) {
    return value | 0;
  }
}
