
 import { PrimaryGeneratedColumn, Column, Entity, PrimaryColumn } from 'typeorm';

 @Entity('mnt_articulos')
export class MNT_Articulos
{

    
    @PrimaryGeneratedColumn() ID_ARTICULO:number; 
    @PrimaryColumn() ID_FAMILIA:number; 
    @PrimaryColumn() ID_MARCA:number; 
    @Column() MODELO:string; 
    @Column() POTENCIA:string;
    @Column() CARAC_1:string; 
    @Column() CARAC_2:string; 
    @Column() IMAGEN:string;

    
    constructor(
        _id_articulo:number=0, 
        _id_familia:number=0,
        _id_marca:number=0, 
        _modelo:string="",
        _potencia:string="", 
        _carac_1:string="",
        _carac_2:string="",
        _imagen:string=""        
    )
    {

        this.ID_ARTICULO = +_id_articulo; 
        this.ID_FAMILIA = +_id_familia; 
        this.ID_MARCA = +_id_marca; 
        this.MODELO = _modelo; 
        this.POTENCIA = _potencia; 
        this.CARAC_1 = _carac_1; 
        this.CARAC_2 = _carac_2;        
        this.IMAGEN = _imagen;
    }

    
    
}