import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { PPTOS_Alarmas, TiposAlarmas } from '@shared/PPTOS.alarmas.entity';
import { Router } from '@angular/router';
import { interficieAlarmas, SharedService } from 'src/app/services/shared/shared.service';
import { EstadoPresupuesto, PPTOS } from '@shared/pptos.entity';
import { AccionesRealizadas } from 'src/app/pages/Clientes/acciones-realizadas.component';
import { CRM_AccionesRealizadas } from '@shared/CRM.acciones_realizadas.entity';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DefinicionAlarmaPresupuesto } from 'src/app/pages/Presupuestos/definicion-alarma-ppto.component';

import * as moment from 'moment';
import { FinalizacionPresupuesto } from 'src/app/pages/Presupuestos/finalizacion-ppto.component';
//import * from 'moment-business-days';
//import * as momenta from 'moment-business-days';
const momenta = require('moment-business-days');

@Component({
  selector: 'edicion-alerta',
  templateUrl: 'edicion-alerta.html',
  styleUrls: ['./edicion-alerta.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EdicionAlerta {


  guardarDeshabilitado = true;
  imagenAsignada = false;
  //alarma:interficieAlarmas = new interficieAlarmas();
  rutaImagen;
  hayAlarmas = false;

  constructor(
    public dialogRef: MatDialogRef<EdicionAlerta>,
    @Inject(MAT_DIALOG_DATA) public data,
    public http: HttpService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    public router: Router,
    public shared: SharedService
  ) {


    //alert(JSON.stringify(this.data.alarma.alarma));
    this.obtieneAlarma(this.data.id_ppto);



  }

  evaluaCambio(event) {
    this.data.alarma.SIGUIENTE_RECORDATORIO = moment(event).format('YYYY-MM-DD');
    //alert(JSON.stringify(event));
  }

  obtieneAlarma(_id_alarma) {
    this.http.obtenerAlarmaById(_id_alarma).then(
      (result: PPTOS_Alarmas) => {

        this.http.obtenerPresupuesto(_id_alarma).then(
          (pptoObtenido: PPTOS) => {


            var s: interficieAlarmas = new interficieAlarmas();
            s.alarma = result[0];
            s.presupuesto = pptoObtenido;

            if (s.alarma.TIPO_ALARMA == TiposAlarmas.Pendiente)
              s.descripcionAlarma = "Pendiente (" + s.alarma.MOTIVO_SEGUIMIENTO + ")";
            else if (s.alarma.TIPO_ALARMA == TiposAlarmas.Seguimiento)
              s.descripcionAlarma = "Seguimiento";



            this.data.alarma = s;




            var alarmaObtenidaFormatada = moment(s.alarma.SIGUIENTE_RECORDATORIO).format('YYYY-MM-DD');
            this.data.alarma.SIGUIENTE_RECORDATORIO = alarmaObtenidaFormatada;


            /*                
            var date = new Date(this.alarma.alarma.SIGUIENTE_RECORDATORIO); // Or the date you'd like converted.
            var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0,16);
            this.alarma.alarma.SIGUIENTE_RECORDATORIO = isoDateTime;
            */


            //ESTIC AQUI




            //alert(JSON.stringify(this.alarma));

          });



      }
    )
  }



  marcarAlarmaComoVisualizada(_id_ppto) {
    this.http.obtenerAlarmaById(_id_ppto).then(
      (alarmaObtenida: PPTOS_Alarmas) => {

        this.programaSiguienteAlarma(alarmaObtenida);
      }
    )

  }

  programaSiguienteAlarma(alarmaObtenida) {
    var dateObj = new Date(alarmaObtenida[0].SIGUIENTE_RECORDATORIO);



    if (alarmaObtenida[0].TIPO_ALARMA == TiposAlarmas.Seguimiento)
      alarmaObtenida[0].SIGUIENTE_RECORDATORIO = momenta(dateObj, 'DD-MM-YYYY').businessAdd(3)._d;
    else if (alarmaObtenida[0].TIPO_ALARMA == TiposAlarmas.Pendiente)
      alarmaObtenida[0].SIGUIENTE_RECORDATORIO = momenta(dateObj, 'DD-MM-YYYY').businessAdd(1)._d;



    //alert(alarmaObtenida[0].SIGUIENTE_RECORDATORIO);
    alarmaObtenida[0].SIGUIENTE_RECORDATORIO = moment(alarmaObtenida[0].SIGUIENTE_RECORDATORIO).add(1, "hour").format('YYYY-MM-DD')

    var alarmaObtenidaFormatada = moment(alarmaObtenida[0].SIGUIENTE_RECORDATORIO).format('DD-MM-YYYY');

    alarmaObtenida[0].VISUALIZADA = "false";

    this.http.actualizarAlarma(alarmaObtenida[0]).then(
      () => {
        this.notifyService.showMessage(
          "Alarma programada para el día " + alarmaObtenidaFormatada,
          "Success",
          false
        );
        this.cancelaDialogo();
        //   this.inicializaAlarmas();
      }
    );
  }

  abrirDialogAccionesRealizadas(_id_cliente: number) {

    const dialogRef = this.dialog.open(AccionesRealizadas, {
      width: "75%",
      data: {
        tituloModal: "Acciones realizadas",
        id_cliente: _id_cliente,
        accionesRealizadas: new Array<CRM_AccionesRealizadas>()
      },
    });


    dialogRef.afterClosed().subscribe((result) => {
       


      if (result) {

        this.http.eliminarAccionesRealizadasSegunCliente(_id_cliente).then(
          () => {
            //alert(JSON.stringify(result));
            result.accionesRealizadas.forEach(element => {

              this.http.guardarAccionRealizada(element).then(
                () => {

                }
              )
            });

            this.notifyService.showMessage(
              "Operación efectuada correctamente",
              "Success",
              false
            );
          }
        )

      }
    });


  }






  inicializaNumeroAlarmas() {

    this.http.obtenerNumeroAlarmasDiarias(Date.now()).then(
      (result) => {
        this.shared.numeroAlarmas = result[0]["TOTAL"];
      }
    )


  }


  abrirdialgoDefinicionAlarma(_id_ppto: number) {

    var tituloModal = "Definición alarma";

    const dialogRef = this.dialog.open(DefinicionAlarmaPresupuesto, {
      data: {
        tituloModal: tituloModal,
        id_ppto: _id_ppto
        //id_cliente: _id_cliente,
        //accionesRealizadas: new Array<CRM_AccionesRealizadas>()
      },
      disableClose: false
    });


    dialogRef.afterClosed().subscribe((result) => {
       


      if (result) {


        var dateObj = new Date();
        var alarma = new PPTOS_Alarmas();
        if (result.estadoFinalizacionPpto == "S") //ALARMA SEGUIMIENTO
        {

          alarma.ID_PPTO = _id_ppto;
          alarma.TIPO_ALARMA = TiposAlarmas.Seguimiento;
          alarma.VISUALIZADA = false;
          alarma.SIGUIENTE_RECORDATORIO = momenta(dateObj, 'DD-MM-YYYY').businessAdd(3)._d;

        }
        else {
          alarma.ID_PPTO = _id_ppto;
          alarma.TIPO_ALARMA = TiposAlarmas.Pendiente;
          alarma.VISUALIZADA = false;
          alarma.SIGUIENTE_RECORDATORIO = momenta(dateObj, 'DD-MM-YYYY').businessAdd(1)._d;
          alarma.MOTIVO_SEGUIMIENTO = result["motivoDenegacionPpto"];
        }

        alarma.SIGUIENTE_RECORDATORIO = moment(alarma.SIGUIENTE_RECORDATORIO).add(1, "hour").format('YYYY-MM-DD');

        var alarmaObtenidaFormatada = moment(alarma.SIGUIENTE_RECORDATORIO).format('DD-MM-YYYY');

        this.http.actualizarAlarma(alarma).then(
          () => {
            this.notifyService.showMessage("Operación efectuada correctamente", "Success", false);
            this.notifyService.showMessage("Alarma programada para el día: " + alarmaObtenidaFormatada, "Success", false);
            //  this.inicializaAlarmas();
            this.cancelaDialogo();
          }
        )



        //this.router.navigate(['grid-presupuestos']);     


      }
    });


  }

  cancelaDialogo(): void {
    this.dialogRef.close();
  }



  ngOnInit() {


  }

  abrirDialogFinalizacionPpto(_id_ppto: number) {




    const dialogRef = this.dialog.open(FinalizacionPresupuesto, {
      width: "50%",
      data: {
        tituloModal: "Finalización presupuesto",
        motivoDenegacionPpto: "",
        estadoFinalizacionPpto: EstadoPresupuesto.Aceptado,
        id_ppto: _id_ppto
        //id_cliente: _id_cliente,
        //accionesRealizadas: new Array<CRM_AccionesRealizadas>()
      },
    });


    dialogRef.afterClosed().subscribe((result) => {
       


      if (result) {
        this.spinner.show();

        this.finalizarPpto(result.id_ppto, result.estadoFinalizacionPpto, result.motivoDenegacionPpto);
      }

    })
  }

  finalizarPpto(_id_ppto, estado, motivoDenegacionPpto = "") {


    if (confirm("Estás seguro? ")) {

      this.finalizarPptoConfirmado(_id_ppto, estado, motivoDenegacionPpto);
      this.http.eliminarAlarma(_id_ppto).then(
        () => {
          this.inicializaNumeroAlarmas();
        }
      );



    }
    else
      this.spinner.hide();

  }

  finalizarPptoConfirmado(_id_ppto, estado, motivoDenegacionPpto) {
    this.http.modificarEstadoPpto(_id_ppto, estado, motivoDenegacionPpto).then(
      (result) => {

        this.http.obtenerPresupuesto(_id_ppto).then(
          (pptoObtenido) => {
            var s = new CRM_AccionesRealizadas();
            s.ID_CLIENTE = pptoObtenido["ID_CLIENTE"];
            var date = new Date(); // Or the date you'd like converted.
            var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
            s.FECHA = isoDateTime;
            if (estado == EstadoPresupuesto.Aceptado)
              s.DESCRIPCION = "Presupuesto " + pptoObtenido["NUMERACION"] + " aceptado.";
            else if (estado == EstadoPresupuesto.Denegado)
              s.DESCRIPCION = "Presupuesto " + pptoObtenido["NUMERACION"] + " denegado (MOTIVO: " + motivoDenegacionPpto + ")";

            this.http.guardarAccionRealizada(s).then(
              () => {
                if (estado == EstadoPresupuesto.Aceptado)
                  this.finalizaBorradoresConMismaNumeracion(_id_ppto, pptoObtenido["NUMERACION"]);

                this.spinner.hide();
                this.notifyService.showMessage("Operación efectuada correctamente", "Success", false);
                this.cancelaDialogo();

              }
            );
          }
        )


      }, (err) => {
        this.spinner.hide();
        this.notifyService.showMessage("Error efectuando operación", "Error", true);
        this.spinner.hide();
      }
    )
  }

  finalizaBorradoresConMismaNumeracion(_idPpto, _numeracion: string) {
    var numeracionFormatada = _numeracion.substring(0, 5);
    this.http.obtenerPresupuestosConMismaNumeracion(numeracionFormatada).then(
      (result: PPTOS[]) => {
        result.forEach(element => {

          if (Number(_idPpto) != Number(element.ID_PPTO)) {
            if (element.ESTADO != EstadoPresupuesto.Denegado) {

              this.http.modificarEstadoPpto(element.ID_PPTO, EstadoPresupuesto.Denegado, "Aceptado otro presupuesto: " + _numeracion).then(
                (result) => {

                  this.http.eliminarAlarma(element.ID_PPTO).then(
                    (alarmaBorrar) => {


                      this.http.obtenerPresupuesto(element.ID_PPTO).then(
                        (pptoObtenido) => {
                          var s = new CRM_AccionesRealizadas();
                          s.ID_CLIENTE = pptoObtenido["ID_CLIENTE"];
                          var date = new Date(); // Or the date you'd like converted.
                          var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
                          s.FECHA = isoDateTime;
                          s.DESCRIPCION = "Presupuesto " + pptoObtenido["NUMERACION"] + " denegado (MOTIVO: " + "Aceptado otro presupuesto: " + _numeracion + ")";

                          this.http.guardarAccionRealizada(s);
                        }
                      )

                    }
                  )

                }

              )
            }

          }
        });
      }
    )
  }


  verPPTO(_id_ppto) {

    localStorage.setItem('id_ppto', _id_ppto);
    localStorage.setItem('accion', 'editar');
    this.router.navigate(['creacion-presupuesto']);
    this.dialogRef.close();
  }


  evaluaSiEsPermitidoGuardar() {

    /*
    if ((this.data.articulo.MODELO.toString().trim().length > 0) 
    && (this.data.articulo.ID_FAMILIA > 0)
    && (this.data.articulo.ID_MARCA > 0)
    && (this.data.articulo.POTENCIA.toString().trim().length > 0)
    )
        this.guardarDeshabilitado = false;
    else
        this.guardarDeshabilitado = true;
    */

  }

}

