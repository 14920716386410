import { Component, OnInit, ViewChild } from "@angular/core";

import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { HttpService } from "src/app/services/http/http.service";

import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NotificationService } from "src/app/services/notification/notification.service";

import {
  AngularGridInstance,
  Column,
  ExtensionName,
  FieldType,
  Filters,
  Formatter,
  GridOption,
} from "angular-slickgrid";

//import { Contacto } from '@shared/contacto.entity';

import { MNT_Articulos } from "@shared/MNT.articulos.entity";
import { MNT_Marcas } from "@shared/MNT.marcas.entity";
import { CreacionEdicionArticulo } from "./creacion-edicion-articulo.component";
import { MNT_Familias } from "@shared/MNT.familias.entity";

@Component({
  selector: "grid-articulos",
  templateUrl: "./grid-articulos.component.html",
  styleUrls: ["./grid-articulos.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" }),
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"),
      ),
    ]),
  ],
})
export class GridArticulosComponent implements OnInit {
  pageReady = false;
  tituloModal = "";
  filtradoGrd = "";

  displayedColumns: string[] = ["NOMBRE", "editarGrupo"];
  angularGrid: AngularGridInstance;

  columnDefinitions1: Column[];
  columnDefinitionsHiddenDefault: Column[];
  dataset1: any[] = [];
  gridOptions1: GridOption;

  familias: MNT_Familias[] = [];

  rowData = [];
  filtrado = new Array<MNT_Articulos>();
  public articulos: Array<MNT_Articulos>;
  marcas = new Array<MNT_Marcas>();
  source: Array<any> = [];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  constructor(
    public router: Router,
    public http: HttpService,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    public dialog: MatDialog,
  ) {
    this.http.obtenerTodasLasFamilias().then((familias: MNT_Familias[]) => {
      this.familias = familias;
    }).catch((e) => console.log(e));
  }

  async ngOnInit() {
    const actionFormatter: Formatter = () => {
      return `<div class="fake-hyperlink">Acciones <i class="fa fa-caret-down"></i></div>`;
    };

    const familiaFormatter: Formatter = (
      _,
      __,
      value,
    ) => {
      if (value.toString().trim().length <= 0) {
        return "";
      } else {
        const nombre = this.familias.find((f) => f.ID_FAMILIA === value).NOMBRE;
        return nombre ? nombre : "OTROS";
      }
    };

    this.columnDefinitions1 = [
      {
        id: "familia",
        nameKey: "familia",
        field: "ID_FAMILIA",
        name: "Familia",
        formatter: familiaFormatter,
        sortable: true,
        filterable: true,
        maxWidth: 150,
        filter: {
          collection: [
            { value: "", label: "" },
            { value: "1", label: "PANELES" },
            { value: "2", labelKey: "INVERSORES" },
            { value: "3", labelKey: "MEDIDORES" },
            { value: "4", labelKey: "SOLARBLOCK" },
          ],
          model: Filters.singleSelect,
          filterOptions: {
            autoDropWidth: true,
          },
        },
      },
      {
        id: "marca",
        name: "Marca",
        field: "NOMBRE_MARCA",
        width: 75,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "modelo",
        field: "MODELO",
        name: "Modelo",
        width: 170,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "potencia",
        name: "Potencia",
        field: "POTENCIA",
        width: 40,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "carac_1",
        name: "Característica 1",
        field: "CARAC_1",
        sortable: true,
        filterable: true,
        width: 160,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "carac_2",
        name: "Característica 2",
        field: "CARAC_2",
        sortable: true,
        filterable: true,
        width: 160,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      /*
      {
        id: "imagen",
        name: "Imagen",
        field: "IMAGEN",
        sortable: false,
        filterable: false,
        width: 160,
        formatter: imagenFormatter,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      */
      {
        id: "acciones",
        name: "Acciones",
        field: "acciones",
        columnGroup: "Acciones",
        excludeFromExport: true,
        formatter: actionFormatter,
        cellMenu: {
          hideCloseButton: false,
          width: 200,
          menuUsabilityOverride: () => {
            return true;
          },
          commandItems: [
            { command: "editar", title: "Editar", positionOrder: 60 },
            { divider: true, command: "", positionOrder: 65 },
            {
              command: "eliminar",
              title: "Eliminar",
              cssClass: "red",
              positionOrder: 66,
            },
          ],
        },
      },
    ];

    this.gridOptions1 = {
      columnPicker: {
        hideForceFitButton: true,
        hideSyncResizeButton: true,
        onColumnsChanged: (e, args) => {
          console.log(
            "Column selection changed from Column Picker, visible columns: ",
            args.columns,
          );
        },
      },
      autoEdit: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 28,
      //enableAutoResize: true,
      forceFitColumns: true,
      enableGridMenu: true,
      autoHeight: true,
      // enable the filtering but hide the user filter row since we use our own single filter
      enableFiltering: true,
      showHeaderRow: true, // hide the filter row (header row)

      alwaysShowVerticalScroll: false,
      enableColumnPicker: true,
      enableRowSelection: true,
      enablePagination: true,
      pagination: {
        pageSizes: [15, 30, 50, 100],
        pageSize: 15,
      },
      enableCellMenu: true,
      /*
          autoResize: {
            containerId: 'container',
            sidePadding: 5
          },
          */
      rowSelectionOptions: {
        // True (Single Selection), False (Multiple Selections)
        selectActiveRow: false,
      },
      enableAutoResize: true,
      enableCellNavigation: true,
      enableCheckboxSelector: true,
      multiSelect: false,
      enableSorting: true,
      excelExportOptions: {
        exportWithFormatter: true,
        customColumnWidth: 15,
        columnHeaderStyle: { font: { bold: true, italic: true } },
      },

      enableContextMenu: true,

      cellMenu: {
        onCommand: (e, args) => this.executeCommand(e, args),
        onOptionSelected: (e, args) => {
          const dataContext = args && args.dataContext;
          if (dataContext && dataContext.hasOwnProperty("completed")) {
            dataContext.completed = args.item.option;
            this.angularGrid.gridService.updateItem(dataContext);
          }
        },
        onBeforeMenuClose: (e, args) =>
          console.log("Cell Menu is closing", args),
      },
    };

    this.spinner.show();
    await Promise.all([
      this.inicializaListadoMarcas(),
      this.inicializaListadoArticulos(),
    ]);
    this.spinner.hide();
    this.pageReady = true;
  }

  onSelectedRowsChanged(args) {
    if (Array.isArray(args.rows)) {
      args.rows.map((idx) => {
      });
    }
  }

  executeCommand(e, args) {
    const command = args.command;
    const dataContext = args.dataContext;

    switch (command) {
      case "editar":
        this.editarArticulo(dataContext);
        break;
      case "eliminar":
        this.eliminarArticulo(dataContext.id);
        break;
    }
  }

  editarArticulo(_articulo) {
    this.abrirDialog(true, _articulo);
  }

  abrirDialog(_edicion = false, _articulo: MNT_Articulos) {
    var _art = new MNT_Articulos(0, 0, 0, "", "", "", "");
    var textoCambiarImagen = "";

    new Promise((resolve) => {
      if (_edicion) {
        this.tituloModal = "Editar artículo";
        textoCambiarImagen = "Cambiar imagen";
        this.http.obtenerArticulo(_articulo.ID_ARTICULO).then(
          (art: MNT_Articulos) => {
            _art = art;
            resolve(this);
          },
        );
        // _art = _articulo;
      } else {
        this.tituloModal = "Nuevo artículo";
        textoCambiarImagen = "Asignar imagen";
        resolve(this);
      }
    }).then(
      () => {
        const dialogRef = this.dialog.open(CreacionEdicionArticulo, {
          width: "75%",
          data: {
            tituloModal: this.tituloModal,
            textoOperacionesImagen: textoCambiarImagen,
            edicion: _edicion,
            articulo: _art,
            imagenArticulo: new FormData(),
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            if (result.edicion) {
              //UPDATE

              this.spinner.show();

              this.http.actualizarArticulo(result.articulo).then((resultat) => {
                console.log(
                  "Artículo actualizado correctamente" +
                    JSON.stringify(resultat),
                );
                this.notifyService.showMessage(
                  "Artículo actualizado correctamente",
                  "Success",
                  false,
                );
                this.inicializaListadoArticulos();
                this.spinner.hide();
              });
            } //CREATE
            else {
              /*

              const formData: FormData = new FormData();
              formData.append('image', file, file.name);
              this.http.upload(formData).then(
                (result) => {
                  alert(JSON.stringify(result));
                }
              )
              */

              /*this.spinner.show();

              this.http.upload(result.imagenArticulo).then(
                (result) => {
                  alert(JSON.stringify(result));
                }
              )
              */

              this.http.guardarArticulo(result.articulo).then((resultat) => {
                console.log(
                  "Artículo creado correctamente" + JSON.stringify(resultat),
                );
                this.notifyService.showMessage(
                  "Artículo creado correctamente",
                  "Success",
                  false,
                );
                this.inicializaListadoArticulos();
                this.spinner.hide();
              });
            }
          }
        });
      },
    );
  }

  eliminarArticulo(_id_articulo) {
    if (confirm("Estás seguro? ")) {
      this.spinner.show();
      this.http.eliminarArticulo(_id_articulo).then(
        () => {
          this.spinner.hide();
          this.notifyService.showMessage(
            "Operación efectuada correctamente",
            "Success",
            false,
          );
          this.inicializaListadoArticulos();
        },
        () => {
          this.spinner.hide();
          this.notifyService.showMessage(
            "Error efectuando operación",
            "Error",
            true,
          );
          this.spinner.hide();
        },
      );
    }
  }

  angularGridReady(angularGrid: AngularGridInstance) {
    this.angularGrid = angularGrid;
  }

  get cellMenuInstance(): any {
    return (
      (this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.cellMenu,
        )) ||
      {}
    );
  }

  get contextMenuInstance(): any {
    return (
      (this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.contextMenu,
        )) ||
      {}
    );
  }

  async inicializaListadoArticulos() {
    this.http.obtenerTodosArticulos().then(
      (result: Array<MNT_Articulos>) => {
        this.articulos = result;
        //this.pptos = result;

        this.filtrar(this.filtradoGrd);
      },
      () => {
        this.filtrar(this.filtradoGrd);
      },
    );
  }

  async inicializaListadoMarcas() {
    this.http.obtenerTodasMarcas().then(
      (result: Array<MNT_Marcas>) => {
        /*
              this.articulos = result;
                //this.pptos = result;
              */
        this.marcas = result;
      },
      () => {
        this.filtrar(this.filtradoGrd);
      },
    );
  }

  nuevoArticulo() {
    this.abrirDialog(false, new MNT_Articulos());
  }

  filtrar(valor) {
    this.filtrado = this.articulos.filter((art) =>
      art.MODELO.toString()
        .toLowerCase()
        .includes(valor.toString().toLowerCase())
    );

    this.rowData = this.filtrado;
    this.source = this.filtrado;

    //slickgrid
    // this.slick.beginUpdate();
    var datosFormatados = [];
    return new Promise((resolve) => {
      for (var i = 0; i < this.rowData.length; i++) {
        var nombreMarca = "";
        for (var z = 0; z <= this.marcas.length - 1; z++) {
          if (this.marcas[z].ID_MARCA == this.rowData[i].ID_MARCA) {
            nombreMarca = this.marcas[z].NOMBRE;
          }
        }
        //nombre_usuario_asignado
        datosFormatados[i] = {
          id: this.rowData[i].ID_ARTICULO,
          ID_ARTICULO: this.rowData[i].ID_ARTICULO,
          ID_FAMILIA: this.rowData[i].ID_FAMILIA,
          ID_MARCA: this.rowData[i].ID_MARCA,
          NOMBRE_MARCA: nombreMarca,
          MODELO: this.rowData[i].MODELO,
          POTENCIA: this.rowData[i].POTENCIA,
          CARAC_1: this.rowData[i].CARAC_1,
          CARAC_2: this.rowData[i].CARAC_2,
          IMAGEN: this.rowData[i].IMAGEN,
        };

        if (i == this.rowData.length - 1) resolve(this);
      }
    }).then(() => {
      this.dataset1 = datosFormatados;
    });
  }

  float2int(value) {
    return value | 0;
  }
}
