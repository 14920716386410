
 import { PrimaryGeneratedColumn, Column, Entity, PrimaryColumn } from 'typeorm';

 @Entity('mnt_tipos_contrato_articulos')
export class MNT_TiposContratoArticulos
{

    
    @PrimaryColumn() ID_TIPO_CONTRATO:number; 
    @PrimaryColumn() ID_ARTICULO:number; 
    @Column() CANTIDAD:number; 

    
    
    constructor(
        _id_tipo_contrato=0, 
        _id_articulo=0,
        _cantidad=0        
    )
    {

        this.ID_TIPO_CONTRATO = +_id_tipo_contrato;
        this.ID_ARTICULO = +_id_articulo; 
        this.CANTIDAD = +_cantidad;
    }

    
    
}