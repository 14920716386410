<mat-toolbar class="mat-elevation-z6" color="primary" style="width: 100%">
  <div [ngStyle]="{ display: screenWidth > 840 ? 'none' : 'block' }">
    <button mat-button type="button" md-button (click)="toggleSidenav(sidenav)">
      <i class="fas fa-bars"></i>
    </button>
  </div>

  <app-header style="width: 100%"></app-header>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    [mode]="screenWidth > 840 ? 'side' : 'over'"
    [opened]="screenWidth > 840"
    [fixedInViewport]="true"
    [fixedTopGap]="64"
  >
    <app-sidebar></app-sidebar>
  </mat-sidenav>

  <div class="mainContent">
    <!-- <xng-breadcrumb #parent style="display: flex; align-items: center">
      <span class="breadcrumb" *ngFor="let route of parent.breadcrumbs">
        <a
          mat-button
          *ngIf="!route.terminal"
          href=""
          [routerLink]="[route.url]"
          >{{ route.displayName }}</a
        >
        <a mat-button *ngIf="route.terminal">{{ route.displayName }}</a>
      </span>
    </xng-breadcrumb> -->
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
