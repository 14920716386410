import { Column, Entity, PrimaryColumn } from "typeorm";

@Entity("pptos_consumos_introduccion_manual_industrial")
export class PPTOS_ConsumosIntroduccionManualIndustrial {
  @PrimaryColumn()
  ID_PPTO: number;
  @PrimaryColumn()
  MES: number;
  @Column({ type: "decimal" })
  P1: number;
  @Column({ type: "decimal" })
  P2: number;
  @Column({ type: "decimal" })
  P3: number;
  @Column({ type: "decimal" })
  P4: number;
  @Column({ type: "decimal" })
  P5: number;
  @Column({ type: "decimal" })
  P6: number;

  constructor(
    _id_ppto: number = 0,
    _mes: number = 0,
    _p1: number = 0,
    _p2: number = 0,
    _p3: number = 0,
    _p4: number = 0,
    _p5: number = 0,
    _p6: number = 0,
  ) {
    this.ID_PPTO = +_id_ppto;
    this.MES = +_mes;
    this.P1 = +_p1;
    this.P2 = +_p2;
    this.P3 = +_p3;
    this.P4 = +_p4;
    this.P5 = +_p5;
    this.P6 = +_p6;
  }
}
