<section class="login-main fondoLogin">
    <div class="simple-login-container fondoLogin ">
        <h2>Iniciar sesión</h2>
        <article>
        </article>

        <div class="row">
            <div class="col-md-12 margenNombre">
                <input type="text" name="username" [(ngModel)]="username" (keydown.enter)="ingresar()" class="form-control" placeholder="Nombre de usuario">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 form-group">
                <input type="password" name="password" [(ngModel)]="password" placeholder="Contraseña" (keydown.enter)="ingresar()" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 form-group">
                <button class="btn btn-block btn-login" (keyup.enter)="ingresar()" text="Entrar" (click)="ingresar()">Entrar</button>
            </div>
        </div>
        <!--<h6>{{txtError}}</h6>-->

    </div>

    <div class="fixed-bottom d-flex justify-content-center">
        <img src="../../../assets/images/logo_black.png">
    </div>


</section>
