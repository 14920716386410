
 import { PrimaryGeneratedColumn, Column, Entity, PrimaryColumn } from 'typeorm';

 @Entity('pptos_generacion_consumos_industrial')
export class PPTOS_GeneracionConsumosIndustrial
{

    
    @PrimaryColumn() ID_PPTO:number;         
    @Column() CONSUMO_ANUAL:string;     
    @Column() CONSUMO_MENSUAL_FACTURA_P1:string;     
    @Column() CONSUMO_MENSUAL_FACTURA_P2:string;     
    @Column() CONSUMO_MENSUAL_FACTURA_P3:string;     
    

    
    constructor(        
        _id_ppto:number=0,
        _consumo_anual:string="0",
        _consumo_mensual_factura_p1:string="0",
        _consumo_mensual_factura_p2:string="0",
        _consumo_mensual_factura_p3:string="0"
    )
    {

        this.ID_PPTO = _id_ppto;
        this.CONSUMO_ANUAL = _consumo_anual;
        this.CONSUMO_MENSUAL_FACTURA_P1 = _consumo_mensual_factura_p1;
        this.CONSUMO_MENSUAL_FACTURA_P2 = _consumo_mensual_factura_p2;
        this.CONSUMO_MENSUAL_FACTURA_P3 = _consumo_mensual_factura_p3;        
    }

    
    
}