<h1 mat-dialog-title style="text-align: center;">{{ data.tituloModal }}</h1>
<hr />
<div mat-dialog-content style="margin:0px;width:100%">

    <div class="row" *ngFor="let item of this.data.accionesRealizadas" style="width:100%">
        
        <div class="col-md-4">
            
            <mat-form-field style="width:100%;margin-top:17px;">                
                <input matInput type="datetime-local" placeholder="Fecha" [(ngModel)]="item.FECHA">
            </mat-form-field>            
          
        </div>
        <div class="col-md-7">

            <!-- 
            <mat-form-field class="example-full-width">
                <input matInput required placeholder="Descripcion" style="width:100%" [(ngModel)]="item.DESCRIPCION" name="DESCRIPCION">
            </mat-form-field>
            -->
            <mat-form-field class="example-full-width">
                <textarea matInput required placeholder="Descripción" style="width:100%" rows="2" cols="40" [(ngModel)]="item.DESCRIPCION" name="DESCRIPCION"></textarea>
              </mat-form-field>
        </div>
        <div class="col-md-1">
            <button mat-mini-fab color="primary" aria-label="Eliminar acción realizada" (click)="eliminarAccionRealizada(item)">
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
        </div>
        <hr/>
    </div>
    
    <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-2">
            <button mat-mini-fab color="primary" aria-label="Nueva acción realizada" (click)="nuevaAccionRealizada()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="col-md-5"></div>
        <!--
        <div class="col-md-4">
            <mat-form-field class="example-full-width">
                <input matInput required placeholder="Nombre" style="width:100%" [(ngModel)]="this.data.cliente.NOMBRE" name="NOMBRE" (change)="evaluaSiEsPermitidoGuardar()">
            </mat-form-field>
        </div>
        -->
    </div>

    
 
</div>


<div mat-dialog-actions style="margin:0px;width:100%">
    <div class="row col-12">
        <div class="col-3">
            <button mat-raised-button color="warn" (click)="cancelaDialogo()">Cancelar</button>
        </div>
        <div class="col-6">
           
        </div>
        <div class="col-3">
            <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial [disabled]="guardarDeshabilitado">Aceptar</button>
        </div>
    </div>


</div>