<h1 mat-dialog-title style="text-align: center;">{{ data.tituloModal }}</h1>
<hr />
<div mat-dialog-content style="margin:0px;" class="container">

    <div class="row">

        <div class="col-md-4">
            <mat-form-field class="example-full-width">
                <input matInput required placeholder="Nombre" style="width:100%" [(ngModel)]="this.data.promotor.NOMBRE" name="NOMBRE" (change)="evaluaSiEsPermitidoGuardar()">
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="example-full-width">
                <input matInput required placeholder="Correo" style="width:100%" [(ngModel)]="this.data.promotor.CORREO" name="CORREO">
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="example-full-width">
                <input matInput required placeholder="Telefono" style="width:100%" [(ngModel)]="this.data.promotor.TELEFONO" name="TELEFONO">
            </mat-form-field>
        </div>        
    </div>

    <div class="row">

        <div class="col-md-4">
            <mat-form-field class="example-full-width">
                <input matInput required placeholder="Direccion" style="width:100%" [(ngModel)]="this.data.promotor.DIRECCION" name="DIRECCION">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field class="example-full-width">
                <input matInput required placeholder="Municipio" style="width:100%" [(ngModel)]="this.data.promotor.MUNICIPIO" name="MUNICIPIO">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field class="example-full-width">
                <input matInput required placeholder="Provincia" style="width:100%" [(ngModel)]="this.data.promotor.PROVINCIA" name="PROVINCIA">
            </mat-form-field>
        </div>        
    </div>

    <div class="row">
        <div class="col-md-12">
            
            <mat-form-field>          
                <mat-label>Estado</mat-label>      
                <mat-select placeholder="Estado" style="width:350px;" #singleSelect matInput [(ngModel)]="this.data.promotor.ESTADO" name="ESTADO" (selectionChange)="evaluaSiEsPermitidoGuardar()">                
                  <mat-option *ngFor="let estado of estados" [value]="estado.key">
                    {{estado.value}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
            
        </div>

       
    </div>
 
</div>


<div mat-dialog-actions>
    <div class="row col-12">
        <div class="col-3">
            <button mat-raised-button color="warn" (click)="cancelaDialogo()">Cancelar</button>
        </div>
        <div class="col-6"></div>
        <div class="col-3">
            <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial [disabled]="guardarDeshabilitado">Aceptar</button>
        </div>
    </div>


</div>