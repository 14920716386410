import { Input, OnChanges, SimpleChanges } from "@angular/core";
import { ViewChild } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { HttpService } from "src/app/services/http/http.service";
import { NotificationService } from "src/app/services/notification/notification.service";
import { CreacionPresupuestoComponent } from "./creacion-presupuesto.component";

import { VTR_ConsumoEstimado } from "@shared/virtual/VTR.consumo_estimado.entity";

import { MNT_Articulos } from "@shared/MNT.articulos.entity";
import { MNT_Marcas } from "@shared/MNT.marcas.entity";

import { EstadoPresupuesto, PPTOS } from "@shared/pptos.entity";

import * as moment from "moment";

const momenta = require("moment-business-days");

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from "ng-apexcharts";

// @ts-ignore
import ApexCharts from "apexcharts";
import { ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { SharedService } from "src/app/services/shared/shared.service";
import {
  CRM_Clientes,
  EstadoClientes,
  ProcedenciaClientes,
} from "@shared/CRM.clientes.entity";

import { DefinicionAlarmaPresupuesto } from "./definicion-alarma-ppto.component";
import { MatDialog } from "@angular/material/dialog";
import { PPTOS_Alarmas, TiposAlarmas } from "@shared/PPTOS.alarmas.entity";
import { CRM_AccionesRealizadas } from "@shared/CRM.acciones_realizadas.entity";
import { MNT_Familias } from "@shared/MNT.familias.entity";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  colors: string[];
  legend: ApexLegend;
  fill: ApexFill;
};

@Component({
  selector: "resumen-ppto",
  templateUrl: "./resumen-ppto.component.html",
  styleUrls: ["./resumen-ppto.css"],
})
export class ResumenPptoComponent implements OnInit, OnChanges {
  public ngOnChanges(changes: SimpleChanges) {
    //if (!changes["firstC"])
    if ("numPresupuesto" in changes) {
      if (!changes["numPresupuesto"]["firstChange"]) {
        this.numPresupuesto = changes["numPresupuesto"]["currentValue"];
        this.generaNombreDocumentoPDF();
      }
    } else if ("direccion" in changes) {
      if (!changes["direccion"]["firstChange"]) {
        this.direccion = changes["direccion"]["currentValue"];
        this.generaNombreDocumentoPDF();
      }
    } else if ("nombreCliente" in changes) {
      if (!changes["nombreCliente"]["firstChange"]) {
        this.nombreCliente = changes["nombreCliente"]["currentValue"];
        this.generaNombreDocumentoPDF();
      }
    } else if ("potenciaKit" in changes) {
      if (!changes["potenciaKit"]["firstChange"]) {
        this.potenciaKit = changes["potenciaKit"]["currentValue"];
        this.generaNombreDocumentoPDF();
      }
    } else if ("poblacion" in changes) {
      if (!changes["poblacion"]["firstChange"]) {
        this.poblacion = changes["poblacion"]["currentValue"];
        this.generaNombreDocumentoPDF();
      }
    } else if ("consumoAnualTotal" in changes) {
      if (!changes["consumoAnualTotal"]["firstChange"]) {
        this.consumoAnualTotal = changes["consumoAnualTotal"]["currentValue"];
        this.formataElementosParaVisualizacion();
        this.calculaConsumosEstimados();
        this.calculaPorcentajeSobreConsumoEstimado();
      }
    } else if ("fechaPpto" in changes) {
      if (!changes["fechaPpto"]["firstChange"]) {
        this.fechaPpto = changes["fechaPpto"]["currentValue"];
        this.formataFecha();
      }
    } else if ("idCliente" in changes) {
      if (!changes["idCliente"]["firstChange"]) {
        this.idCliente = changes["idCliente"]["currentValue"];
      }
    } else if ("correoCliente" in changes) {
      if (!changes["correoCliente"]["firstChange"]) {
        this.correoCliente = changes["correoCliente"]["currentValue"];
      }
    } else if ("imagenGoogleMaps" in changes) {
      if (!changes["imagenGoogleMaps"]["firstChange"]) {
        this.imagenGoogleMaps = changes["imagenGoogleMaps"]["currentValue"];
      }
    } else if ("tipoContrato" in changes) {
      if (!changes["tipoContrato"]["firstChange"]) {
        this.tipoContrato = changes["tipoContrato"]["currentValue"];
      }
    } else if ("telefono" in changes) {
      if (!changes["telefono"]["firstChange"]) {
        this.telefono = changes["telefono"]["currentValue"];
      }
    } else if ("viviendaHabitual" in changes) {
      if (!changes["viviendaHabitual"]["firstChange"]) {
        this.viviendaHabitual = changes["viviendaHabitual"]["currentValue"];
        this.calculaImporteFormasDePago();
      }
    } else if ("incluirFormaDePago" in changes) {
      if (!changes["incluirFormaDePago"]["firstChange"]) {
        this.incluirFormaDePago = changes["incluirFormaDePago"]["currentValue"];
        this.calculaImporteFormasDePago();
      }
    } else if ("incluirTramitaciones" in changes) {
      if (!changes["incluirTramitaciones"]["firstChange"]) {
        this.incluirTramitaciones =
          changes["incluirTramitaciones"]["currentValue"];
      }
    } else if ("orientacion" in changes) {
      if (!changes["orientacion"]["firstChange"]) {
        this.orientacion = changes["orientacion"]["currentValue"];
      }
    } else if ("picoCubierta" in changes) {
      if (!changes["picoCubierta"]["firstChange"]) {
        this.picoCubierta = changes["picoCubierta"]["currentValue"];
      }
    } else if ("precioFinal" in changes) {
      if (!changes["precioFinal"]["firstChange"]) {
        this.precioFinal = changes["precioFinal"]["currentValue"];
        this.formataValoracionEconomica();
        this.formataElementosParaVisualizacion();
        this.calculaFinanciacion();
      }
    } else if ("importeAdicional" in changes) {
      if (!changes["importeAdicional"]["firstChange"]) {
        this.importeAdicional = changes["importeAdicional"]["currentValue"];
        this.formataValoracionEconomica();
        this.calculaFinanciacion();
      }
    } else if ("textoImporteAdicional" in changes) {
      if (!changes["textoImporteAdicional"]["firstChange"]) {
        this.textoImporteAdicional =
          changes["textoImporteAdicional"]["currentValue"];
      }
    } else if ("tipoKit" in changes) {
      if (!changes["tipoKit"]["firstChange"]) {
        this.tipoKit = changes["tipoKit"]["currentValue"];
        this.id_kit = this.tipoKit["ID_TIPO_CONTRATO"];
        this.geocodePVGIS().catch((e) => console.log(e));
        this.inicializaElementosSegunTipoContrato();
        this.generaNombreDocumentoPDF();
      }
    } else if ("trabajosARealizar" in changes) {
      if (!changes["trabajosARealizar"]["firstChange"]) {
        this.trabajosARealizar = changes["trabajosARealizar"]["currentValue"];
        this.inicializaTrabajosARealizar();
      }
    } else if ("potenciaBateriaSeleccionada" in changes) {
      if (!changes["potenciaBateriaSeleccionada"]["firstChange"]) {
        this.potenciaBateriaSeleccionada =
          changes["potenciaBateriaSeleccionada"]["currentValue"];
      }
    } else if ("tarifaDe6Canales" in changes) {
      if (!changes["tarifaDe6Canales"]["firstChange"]) {
        this.tarifaDe6Canales = changes["tarifaDe6Canales"]["currentValue"];
      }
    }
  }

  public onChangeTrabajosARealizar({ editor }) {
    this.trabajosARealizar = editor.getData();
    this.inicializaTrabajosARealizar();
  }

  public model = {
    editorData: "<p>Hello, world!</p>",
  };

  @ViewChild("chartValle")
  chartValle: ElementRef;
  @ViewChild("chartPunta")
  chartPunta: ElementRef;
  @ViewChild("chartLlano")
  chartLlano: ElementRef;

  @ViewChild("chart1")
  chart: ChartComponent;
  public chartOptionsComparacionPunta: Partial<ChartOptions>;
  public chartOptionsComparacionLlano: Partial<ChartOptions>;
  public chartOptionsComparacionValle: Partial<ChartOptions>;

  ocultarPDFGenerado = true;

  @ViewChild(CreacionPresupuestoComponent)
  creacionPpto;
  //Pasar objetos entre pantallas
  @Input()
  poblacion = "";
  @Input()
  direccion = "";

  @Input()
  consumoAnualTotal = "";
  @Input()
  nombreCliente = "";
  @Input()
  fechaPpto = "";
  @Input()
  idCliente = "";
  @Input()
  correoCliente = "";
  @Input()
  numPresupuesto = "";
  @Input()
  imagenGoogleMaps = "";
  @Input()
  tipoContrato = "";
  @Input()
  telefono = "";
  @Input()
  viviendaHabitual = "";
  @Input()
  orientacion = "";
  @Input()
  picoCubierta = "";
  @Input()
  precioFinal = "";
  @Input()
  importeAdicional = "";
  @Input()
  textoImporteAdicional = "";
  @Input()
  tipoKit = "";
  @Input()
  trabajosARealizar = "";
  @Input()
  potenciaBateriaSeleccionada = "";
  @Input()
  tipoPPTO = "";
  @Input()
  tarifaDe6Canales = "";
  @Input()
  incluirFormaDePago: boolean;
  @Input()
  incluirTramitaciones: boolean;

  potencia = 0;

  importeLegalizacionExcedenteElectrico = 640;

  formaDePagoFirmaContrato = 0;
  formaDePagoInicioDeObra = 0;
  formaDePagoPuestaEnMarcha = 0;

  id_kit = 0;

  initOpts;
  initOptsMinimizado;
  options;
  optionsAmortizacionIndustrial;

  arrFormatadoPVGIS = [];
  arrFormatadoSinDecimalesPVGIS = [];
  arrFormatadoValleSinDecimalesPVGIS = [];
  arrFormatadoLlanoSinDecimalesPVGIS = [];
  arrFormatadoPuntaSinDecimalesPVGIS = [];
  arrRepercusionesEconomicasPuntas = [];
  arrRepercusionesEconomicasLlanos = [];
  arrRepercusionesEconomicasValles = [];
  arrTotalRepercusionesEconomicas = [];

  totalPVGIS = 0;
  totalVallePVGIS = 0;
  totalLlanoPVGIS = 0;
  totalPuntaPVGIS = 0;
  totalRepercusionesEconomicasPuntas = 0;
  totalRepercusionesEconomicasLlanos = 0;
  totalRepercusionesEconomicasValles = 0;
  totalRepercusionesEconomicas = 0;
  totalRepercusionesEconomicasFormatado;
  totalRepercusiones25a;
  cuotaFinanciada84Formatado;

  totalPorcentajeAhorroPunta = 0;
  totalPorcentajeAhorroLlano = 0;
  totalPorcentajeAhorroValle = 0;

  articulosKit = [];
  panelKit = new MNT_Articulos();
  marcaKit = new MNT_Marcas();
  rutaImagenPanelKit = "";
  rutaImagenInversorKit = "";
  fitxasTecnicasKits = "";

  PPTOGuardar = new PPTOS();
  accion = "";

  elementosTrabajosARealiar = [];

  precioFinalParaVisualizacion;
  consumoAnualTotalFormatado;

  valoracionEconomica = "";

  fechaPreFormatada;

  consumosEstimados: VTR_ConsumoEstimado = new VTR_ConsumoEstimado();

  @ViewChild("print")
  print: ElementRef;
  public datosFinanciacionSinBateria = Array<
    {
      global: boolean;
      plazo: number;
      importeMultiplicador1: string;
      importeMultiplicador2: string;
    }
  >();
  public datosFinanciacionConBateria = Array<
    {
      global: boolean;
      plazo: number;
      importeMultiplicador1: string;
      importeMultiplicador2: string;
    }
  >();
  public valoresMultiplicador1DatosFinanciadosSinBateria = [];
  public valoresMultiplicador2DatosFinanciadosSinBateria = [];
  public valoresMultiplicador1DatosFinanciadosConBateria = [];
  public valoresMultiplicador2DatosFinanciadosConBateria = [];

  public numeroPanelesSegunKit = 0;
  public potenciaKit = 0;
  public nombreCortoMarca = "";
  public potenciaPanelKit = "";
  public totalPVGISFormatado;
  public tipoPanelKit = "";

  public potenciaInversor = "";
  public modeloInversor = "";
  public porcentajeSobreConsumoEstimado;

  consumosCargados = false;

  public nombreDocumentoPDF = "";
  public kitSeleccionadoTieneSolarBlock = false;

  familias: MNT_Familias[] = [];

  constructor(
    public http: HttpService,
    private notifyService: NotificationService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public shared: SharedService,
    public dialog: MatDialog,
  ) {
    this.http.obtenerTodasLasFamilias().then((familias: MNT_Familias[]) => {
      this.familias = familias;
    });
  }

  public triggerPrint(): void {
    //if (localStorage.getItem('accion') == "PDF")
    //this.print.nativeElement.click();
  }

  guardarPPTO(origen) {
    this.PPTOGuardar.ID_PPTO_MANTENIMIENTO_ENLAZADO = 0;
    this.PPTOGuardar.NUMERACION = this.numPresupuesto;
    this.PPTOGuardar.ID_TIPO_CONTRATO = this.id_kit;
    this.PPTOGuardar.NOMBRE_APELLIDOS_CLIENTE = this.nombreCliente;
    this.PPTOGuardar.MAIL = this.correoCliente;
    this.PPTOGuardar.TELEFONO = this.telefono;
    this.PPTOGuardar.DIRECCION = this.direccion;
    this.PPTOGuardar.VIVIENDA_HABITUAL = +this.viviendaHabitual;
    this.PPTOGuardar.POBLACION = this.poblacion;
    this.PPTOGuardar.CONSUMO_ANUAL = +this.consumoAnualTotal;
    this.PPTOGuardar.ESTADO = EstadoPresupuesto.Pendiente;
    this.PPTOGuardar.ORIENTACION = this.orientacion;
    this.PPTOGuardar.PICO_DE_CUBIERTA = this.picoCubierta;
    this.PPTOGuardar.MOTIVO_DENEGACION = "";
    this.PPTOGuardar.FECHA_REVISION_PPTO = new Date().toISOString();
    this.PPTOGuardar.INCLUIR_FORMA_PAGO = +this.incluirFormaDePago;
    this.PPTOGuardar.INCLUIR_TRAMITACIONES = +this.incluirTramitaciones;
    this.fechaPpto = this.fechaPreFormatada.getFullYear() + "/" +
      ("0" + (this.fechaPreFormatada.getMonth() + 1)).slice(-2) + "/" +
      this.fechaPreFormatada.getDate();

    if (
      (localStorage.getItem("accion") == "editar") ||
      (localStorage.getItem("accion") == "PDF")
    ) {
      this.fechaPreFormatada = new Date();
      this.fechaPpto = this.fechaPreFormatada.getFullYear() + "/" +
        ("0" + (this.fechaPreFormatada.getMonth() + 1)).slice(-2) + "/" +
        this.fechaPreFormatada.getDate();
      this.PPTOGuardar.FECHA_PPTO = this.fechaPpto;
    } else {
      this.fechaPreFormatada = new Date();
      this.fechaPpto = this.fechaPreFormatada.getFullYear() + "/" +
        ("0" + (this.fechaPreFormatada.getMonth() + 1)).slice(-2) + "/" +
        this.fechaPreFormatada.getDate();
      this.PPTOGuardar.FECHA_PPTO = this.fechaPpto;
    }

    this.PPTOGuardar.TIPO_CONTRATO = this.tipoContrato;
    this.PPTOGuardar.IMAGEN_GOOGLE_MAPS = this.imagenGoogleMaps;
    this.PPTOGuardar.TEXTO_ADDICIONAL = this.textoImporteAdicional;
    this.PPTOGuardar.IMPORTE_ADDICIONAL = +this.importeAdicional;
    this.PPTOGuardar.PRECIO_FINAL = +this.precioFinal;
    this.PPTOGuardar.TRABAJOS_A_REALIZAR = this.trabajosARealizar;

    this.PPTOGuardar.TIPO_PPTO = "D";
    this.PPTOGuardar.POTENCIA_PICO_I = 0;
    this.PPTOGuardar.INCLINACION_PVGIS_I = 0;
    this.PPTOGuardar.AZIMUTH_PVGIS_I = 0;
    this.PPTOGuardar.PERDIDAS_PVGIS_I = 0;
    this.PPTOGuardar.COORDENADAS_LAT_PVGIS_I = "";
    this.PPTOGuardar.COORDENADAS_LON_PVGIS_I = "";
    this.PPTOGuardar.INVERSOR_I = 0;
    this.PPTOGuardar.NUMERO_INVERSORES_I = 0;
    this.PPTOGuardar.PANEL_I = 0;
    this.PPTOGuardar.NUMERO_PANELES_I = 0;
    this.PPTOGuardar.SOLARBLOCK_I = 0;
    this.PPTOGuardar.POTENCIA_BATERIA = this.potenciaBateriaSeleccionada;

    if (origen != "impression") {
      this.spinner.show();
    }

    if (this.idCliente == "NUEVO") {
      var clienteGuardar = new CRM_Clientes();
      clienteGuardar.NOMBRE = this.nombreCliente.toUpperCase();
      clienteGuardar.CORREO = this.correoCliente;
      clienteGuardar.TELEFONO = this.telefono;
      clienteGuardar.DIRECCION = this.direccion.toUpperCase();
      clienteGuardar.MUNICIPIO = this.poblacion.toUpperCase();
      clienteGuardar.PROVINCIA = ""; //99
      clienteGuardar.PROCEDENCIA = ProcedenciaClientes.Nuevo_PPTO; //Nuevo PPTO
      clienteGuardar.ESTADO = EstadoClientes.Tiene_Borradores_PPTO;

      this.http.guardarCliente(clienteGuardar).then(
        (clienteGuardado) => {
          var s = new CRM_AccionesRealizadas();
          s.ID_CLIENTE = clienteGuardado["identifiers"][0]["ID_CLIENTE"];
          var date = new Date(); // Or the date you'd like converted.
          var isoDateTime = new Date(
            date.getTime() - (date.getTimezoneOffset() * 60000),
          ).toISOString().slice(0, 16);
          s.FECHA = isoDateTime;
          s.DESCRIPCION = "Nuevo cliente dado de alta";
          this.http.guardarAccionRealizada(s);

          this.PPTOGuardar.ID_CLIENTE =
            +clienteGuardado["identifiers"][0]["ID_CLIENTE"];
          this.http.guardarPPTO(this.PPTOGuardar).then(
            (result) => {
              //

              var z = new CRM_AccionesRealizadas();
              z.ID_CLIENTE = this.PPTOGuardar.ID_CLIENTE;
              var date = new Date(); // Or the date you'd like converted.
              var isoDateTime = new Date(
                date.getTime() - (date.getTimezoneOffset() * 60000),
              ).toISOString().slice(0, 16);
              z.FECHA = isoDateTime;
              z.DESCRIPCION = "Borrador presupuesto creado: " +
                this.PPTOGuardar.NUMERACION;
              this.http.guardarAccionRealizada(z);

              this.abrirDialogDefinicionAlarmaPpto(
                result["identifiers"][0]["ID_PPTO"],
              );

              if (origen != "impression") {
                /*
                this.notifyService.showMessage("Operación efectuada correctamente", "Success",false);
                this.spinner.hide();
                this.router.navigate(['grid-presupuestos']);
                //this.router.navigate(['grid-presupuestos']);
                */
              }
            },
            (err) => {
              if (origen != "impression") {
                this.notifyService.showMessage(
                  "Error efectuando operación",
                  "Error",
                  true,
                );
                this.spinner.hide();
              }
            },
          );
        },
      );
    } else {
      this.PPTOGuardar.ID_CLIENTE = +this.idCliente;
      if (
        (localStorage.getItem("accion") == "editar") ||
        (localStorage.getItem("accion") == "PDF")
      ) {
        this.PPTOGuardar.ID_PPTO = +localStorage.getItem("id_ppto");

        this.http.actualizarPPTO(this.PPTOGuardar).then(
          (result) => {
            //

            var z = new CRM_AccionesRealizadas();
            z.ID_CLIENTE = this.PPTOGuardar.ID_CLIENTE;
            var date = new Date(); // Or the date you'd like converted.
            var isoDateTime = new Date(
              date.getTime() - (date.getTimezoneOffset() * 60000),
            ).toISOString().slice(0, 16);
            z.FECHA = isoDateTime;
            z.DESCRIPCION = "Borrador presupuesto actualizado: " +
              this.PPTOGuardar.NUMERACION;
            this.http.guardarAccionRealizada(z);

            if (origen != "impression") {
              this.notifyService.showMessage(
                "Operación efectuada correctamente",
                "Success",
                false,
              );
              this.spinner.hide();
              //this.router.navigate(['grid-presupuestos']);
            }
          },
          (err) => {
            if (origen != "impression") {
              this.notifyService.showMessage(
                "Error efectuando operación",
                "Error",
                true,
              );
              this.spinner.hide();
            }
          },
        );
      } else {
        this.http.guardarPPTO(this.PPTOGuardar).then(
          (result) => {
            //

            var z = new CRM_AccionesRealizadas();
            z.ID_CLIENTE = this.PPTOGuardar.ID_CLIENTE;
            var date = new Date(); // Or the date you'd like converted.
            var isoDateTime = new Date(
              date.getTime() - (date.getTimezoneOffset() * 60000),
            ).toISOString().slice(0, 16);
            z.FECHA = isoDateTime;
            z.DESCRIPCION = "Borrador presupuesto creado: " +
              this.PPTOGuardar.NUMERACION;
            this.http.guardarAccionRealizada(z);

            this.abrirDialogDefinicionAlarmaPpto(
              result["identifiers"][0]["ID_PPTO"],
            );
            /*
            if (origen != 'impression')
            {
              this.notifyService.showMessage("Operación efectuada correctamente", "Success",false);
              this.spinner.hide();
              this.router.navigate(['grid-presupuestos']);
              //this.router.navigate(['grid-presupuestos']);
            }
            */
          },
          (err) => {
            if (origen != "impression") {
              this.notifyService.showMessage(
                "Error efectuando operación",
                "Error",
                true,
              );
              this.spinner.hide();
            }
          },
        );
      }
    }
  }

  abrirDialogDefinicionAlarmaPpto(_id_ppto: number) {
    var tituloModal = "Definición alarma";

    const dialogRef = this.dialog.open(DefinicionAlarmaPresupuesto, {
      width: "50%",
      data: {
        tituloModal: tituloModal,
        id_ppto: _id_ppto,
        //id_cliente: _id_cliente,
        //accionesRealizadas: new Array<CRM_AccionesRealizadas>()
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        var dateObj = new Date();
        var alarma = new PPTOS_Alarmas();
        if (result.estadoFinalizacionPpto == "S") { //ALARMA SEGUIMIENTO
          alarma.ID_PPTO = _id_ppto;
          alarma.TIPO_ALARMA = TiposAlarmas.Seguimiento;
          alarma.VISUALIZADA = false;
          alarma.SIGUIENTE_RECORDATORIO =
            momenta(dateObj, "DD-MM-YYYY").businessAdd(3)._d;
        } else {
          alarma.ID_PPTO = _id_ppto;
          alarma.TIPO_ALARMA = TiposAlarmas.Pendiente;
          alarma.VISUALIZADA = false;
          alarma.SIGUIENTE_RECORDATORIO =
            momenta(dateObj, "DD-MM-YYYY").businessAdd(1)._d;
          alarma.MOTIVO_SEGUIMIENTO = result["motivoDenegacionPpto"];
        }

        alarma.SIGUIENTE_RECORDATORIO = moment(alarma.SIGUIENTE_RECORDATORIO)
          .add(1, "hour").format("YYYY-MM-DD");

        var alarmaObtenidaFormatada = moment(alarma.SIGUIENTE_RECORDATORIO)
          .format("DD-MM-YYYY");

        this.http.guardarAlarma(alarma).then(
          () => {
            this.notifyService.showMessage(
              "Operación efectuada correctamente",
              "Success",
              false,
            );
            this.notifyService.showMessage(
              "Alarma programada para el día: " + alarmaObtenidaFormatada,
              "Success",
              false,
            );
            this.spinner.hide();
            this.router.navigate(["grid-presupuestos"]);
          },
        );

        //this.router.navigate(['grid-presupuestos']);
      }
    });
  }

  inicializaElementos() {
    this.accion = "";
    this.precioFinalParaVisualizacion = 0;
    this.consumoAnualTotalFormatado = 0;

    this.elementosTrabajosARealiar = [];

    this.ocultarPDFGenerado = true;

    this.potencia = 0;
    this.importeLegalizacionExcedenteElectrico = 640;
    this.id_kit = 0;
    this.arrFormatadoPVGIS = [];
    this.arrFormatadoSinDecimalesPVGIS = [];
    this.arrFormatadoValleSinDecimalesPVGIS = [];
    this.arrFormatadoLlanoSinDecimalesPVGIS = [];
    this.arrFormatadoPuntaSinDecimalesPVGIS = [];
    this.arrRepercusionesEconomicasPuntas = [];
    this.arrRepercusionesEconomicasLlanos = [];
    this.arrRepercusionesEconomicasValles = [];
    this.arrTotalRepercusionesEconomicas = [];

    this.totalPVGISFormatado = "";
    this.totalPVGIS = 0;
    this.totalVallePVGIS = 0;
    this.totalLlanoPVGIS = 0;
    this.totalPuntaPVGIS = 0;
    this.totalRepercusionesEconomicasPuntas = 0;
    this.totalRepercusionesEconomicasLlanos = 0;
    this.totalRepercusionesEconomicasValles = 0;
    this.totalRepercusionesEconomicas = 0;
    this.totalRepercusionesEconomicasFormatado;
    this.totalRepercusiones25a;
    this.cuotaFinanciada84Formatado;

    this.totalPorcentajeAhorroPunta = 0;
    this.totalPorcentajeAhorroLlano = 0;
    this.totalPorcentajeAhorroValle = 0;

    this.articulosKit = [];
    this.panelKit = new MNT_Articulos();
    this.marcaKit = new MNT_Marcas();
    this.rutaImagenPanelKit = "";
    this.rutaImagenInversorKit = "";
    this.fitxasTecnicasKits = "";

    this.PPTOGuardar = new PPTOS();
    this.accion = "";

    this.elementosTrabajosARealiar = [];

    this.consumoAnualTotalFormatado;

    this.valoracionEconomica = "";

    this.fechaPreFormatada;

    this.consumosEstimados = new VTR_ConsumoEstimado();

    this.datosFinanciacionSinBateria = Array<
      {
        global: boolean;
        plazo: number;
        importeMultiplicador1: string;
        importeMultiplicador2: string;
      }
    >();
    this.datosFinanciacionConBateria = Array<
      {
        global: boolean;
        plazo: number;
        importeMultiplicador1: string;
        importeMultiplicador2: string;
      }
    >();
    this.valoresMultiplicador1DatosFinanciadosSinBateria = [];
    this.valoresMultiplicador2DatosFinanciadosSinBateria = [];
    this.valoresMultiplicador1DatosFinanciadosConBateria = [];
    this.valoresMultiplicador2DatosFinanciadosConBateria = [];

    this.numeroPanelesSegunKit = 0;
    this.potenciaKit = 0;
    this.nombreCortoMarca = "";
    this.potenciaPanelKit = "";
    this.tipoPanelKit = "";
    this.potenciaInversor = "";
    this.modeloInversor = "";

    this.porcentajeSobreConsumoEstimado;
    this.consumosCargados = false;

    this.formataFecha();
  }

  formataFecha() {
    this.fechaPreFormatada = new Date(this.fechaPpto);
    this.fechaPpto = this.fechaPreFormatada.getDate() + "/" +
      ("0" + (this.fechaPreFormatada.getMonth() + 1)).slice(-2) + "/" +
      this.fechaPreFormatada.getFullYear();
  }

  inicializaTrabajosARealizar() {
    this.elementosTrabajosARealiar = [];

    this.trabajosARealizar.split("<li>").forEach((element) => {
      if (element != "<ul>") {
        this.elementosTrabajosARealiar.push(
          element.replace("</li>", "").replace("</ul>", ""),
        );
        //
      }
    });
  }

  ngOnInit() {
    this.inicializaElementos();

    this.accion = localStorage.getItem("accion");
    var id_ppto = "";

    this.inicializaTrabajosARealizar();

    new Promise((resolve, reject) => {
      //alert(this.consumoAnualTotal);

      if ((this.accion == "PDF") || (this.accion == "editar")) {
        id_ppto = localStorage.getItem("id_ppto");
        this.id_kit = this.tipoKit["ID_TIPO_CONTRATO"];
        resolve(this);
      } else if (this.accion = "nuevo") {
        this.id_kit = this.tipoKit["ID_TIPO_CONTRATO"];
        resolve(this);
      }
    }).then(
      () => {
        //**************************** aqui
        this.formataElementosParaVisualizacion();

        this.spinner.show();
        try {
          // this.geocodePVGIS();
        } catch (error) {
        }

        try {
          this.formataValoracionEconomica();
        } catch (error) {
        }

        try {
          this.calculaFinanciacion();
        } catch (error) {
        }

        try {
          //      this.calculaConsumosEstimados();
        } catch (error) {
        }

        this.consumosCargados = true;

        //fins aqui

        this.inicializaElementosSegunTipoContrato();
      },
    ).catch((e) => console.log(e));
  }

  inicializaElementosSegunTipoContrato() {
    this.http.obtenerTipoContrato(this.id_kit).then(
      (resultPotencia) => {
        this.potenciaKit = resultPotencia["POTENCIA"].toString().replace(
          ".",
          ",",
        );

        this.generaNombreDocumentoPDF();

        //OBTENEMOS DATOS KITS
        this.http.obtenerArticulosSegunTipoContrato(this.id_kit).then(
          (result: MNT_Articulos[]) => {
            for (var i = 0; i <= result.length - 1; i++) {
              this.http.obtenerArticulo(result[i]["ID_ARTICULO"]).then(
                (resultArt: MNT_Articulos) => {
                  this.articulosKit.push(resultArt);

                  if (resultArt["ID_FAMILIA"] === 1) {
                    this.http.obtenerArticulosSegunTipoContrato(this.id_kit)
                      .then(
                        (result: MNT_Articulos[]) => {
                          result.forEach((element) => {
                            if (
                              element.ID_ARTICULO == resultArt["ID_ARTICULO"]
                            ) {
                              this.numeroPanelesSegunKit = element["CANTIDAD"];
                            }
                          });
                        },
                      );

                    this.panelKit = resultArt;
                    this.rutaImagenPanelKit = resultArt.IMAGEN;
                    /*
                    if (this.doesFileExist("../../../assets/images/" + resultArt.MODELO + "/I.jpg"))
                        this.rutaImagenPanelKit = "../../../assets/images/" + resultArt.MODELO + "/I.jpg";
                      */

                    //this.fitxasTecnicasKits = '<img style="z-index:-999;" src="../../../assets/images/' + resultArt.MODELO + '/F.jpg" /><br /><img style="z-index:-999;" src="../../../assets/images/' + resultArt.MODELO + '/F-2.jpg" />';
                    if (
                      this.doesFileExist(
                        "../../../assets/images/" + resultArt.MODELO + "/F.jpg",
                      )
                    ) {
                      this.fitxasTecnicasKits = this.fitxasTecnicasKits +
                        '<table class="page"><tbody><tr><td colspan="3"> <br/><br/><br/><br/><img width="1700px;" height="2177px;" style="z-index:-999;" src="../../../assets/images/' +
                        resultArt.MODELO + '/F.jpg" /></td></tr></table>';
                    }

                    if (
                      this.doesFileExist(
                        "../../../assets/images/" + resultArt.MODELO +
                        "/F-2.jpg",
                      )
                    ) {
                      this.fitxasTecnicasKits = this.fitxasTecnicasKits +
                        '<table class="page"><tbody><tr><td colspan="3"> <br/><br/><br/><br/><img width="1700px;" height="2177px;" style="z-index:-999;" src="../../../assets/images/' +
                        resultArt.MODELO +
                        '/F-2.jpg" />                    </td></tr></table>  ';
                    }

                    this.potenciaPanelKit = resultArt.POTENCIA;
                    this.tipoPanelKit = resultArt.CARAC_1;

                    this.http.obtenerMarca(resultArt.ID_MARCA).then(
                      (resultMarca: MNT_Marcas) => {
                        this.marcaKit = resultMarca;
                        this.nombreCortoMarca = this.marcaKit.NOMBRE.replace(
                          "Trina Solar Tallmax",
                          "TRINA SOLAR",
                        ).replace("Trina Solar Vertex", "TRINA SOLAR");
                      },
                    );
                  }

                  const familiasInversores = this.familias.filter((familia) =>
                    familia.NOMBRE.toLowerCase().includes("inversor")
                  );
                  if (
                    familiasInversores.find((fam: MNT_Familias) =>
                      fam.ID_FAMILIA === resultArt["ID_FAMILIA"]
                    )
                  ) {
                    this.potenciaInversor = resultArt.POTENCIA;
                    this.modeloInversor = resultArt.MODELO;

                    if (
                      this.doesFileExist(
                        "../../../assets/images/" + resultArt.MODELO + "/F.jpg",
                      )
                    ) {
                      this.fitxasTecnicasKits = this.fitxasTecnicasKits +
                        '<table class="page"><tbody><tr><td colspan="3"> <br/><br/><br/><br/><img width="1700px;" height="2177px;" style="z-index:-999;" src="../../../assets/images/' +
                        resultArt.MODELO + '/F.jpg" /></td></tr></table>';
                    }

                    if (
                      this.doesFileExist(
                        "../../../assets/images/" + resultArt.MODELO +
                        "/F-2.jpg",
                      )
                    ) {
                      this.fitxasTecnicasKits = this.fitxasTecnicasKits +
                        '<table class="page"><tbody><tr><td colspan="3"> <br/><br/><br/><br/><img width="1700px;" height="2177px;" style="z-index:-999;" src="../../../assets/images/' +
                        resultArt.MODELO + '/F-2.jpg" /></td></tr></table>';
                    }

                    this.rutaImagenInversorKit = resultArt.IMAGEN;
                    /*
                  if (this.doesFileExist('../../../assets/images/' + resultArt.MODELO + '/I.jpg'))
                    this.rutaImagenInversorKit = "../../../assets/images/" + resultArt.MODELO + "/I.jpg";
                    */
                  } else if (
                    (resultArt["ID_FAMILIA"] == 3) ||
                    (resultArt["ID_FAMILIA"] == 4)
                  ) {
                    if (
                      this.doesFileExist(
                        "../../../assets/images/" + resultArt.MODELO + "/F.jpg",
                      )
                    ) {
                      this.fitxasTecnicasKits = this.fitxasTecnicasKits +
                        '<table class="page"><tbody><tr><td colspan="3"> <br/><br/><br/><br/><img width="1700px;" height="2177px;" style="z-index:-999;" src="../../../assets/images/' +
                        resultArt.MODELO + '/F.jpg" /></td></tr></table>';
                    }

                    if (
                      this.doesFileExist(
                        "../../../assets/images/" + resultArt.MODELO +
                        "/F-2.jpg",
                      )
                    ) {
                      this.fitxasTecnicasKits = this.fitxasTecnicasKits +
                        '<table class="page"><tbody><tr><td colspan="3"> <br/><br/><br/><br/><img width="1700px;" height="2177px;" style="z-index:-999;" src="../../../assets/images/' +
                        resultArt.MODELO + '/F-2.jpg" /></td></tr></table>';
                    }
                    //this.fitxasTecnicasKits = this.fitxasTecnicasKits + '<img src="../../../assets/images/' + resultArt.MODELO + '/F.jpg" /><br /><img src="../../../assets/images/' + resultArt.MODELO + '/F-2.jpg" />';

                    if (resultArt["ID_FAMILIA"] == 4) {
                      this.kitSeleccionadoTieneSolarBlock = true;
                    }
                  }

                  //
                },
              );
            }
          },
        );

        //this.obtenerInforme();

        if (this.accion == "PDF") {
          setTimeout(() => {
            this.print.nativeElement.click();
          }, 1900);
        }
      },
    );
  }

  formataValoracionEconomica() {
    this.valoracionEconomica =
      '<table><tr><td colspan="3"><p>El suministro e instalación de los materiales y servicios anteriormente detallados dan como resultado la siguiente valoración económica:</p><p style="text-align:center">TOTAL PRESUPUESTO ' +
      (+this.precioFinal + this.importeAdicional) +
      ' €+IVA* <br/></p><i style="text-align:center"> * IVA: 10% para vivienda habitual y 21% en el resto de casos.</i><hr /></td></tr><tr><td colspan="3"><table><tr><td> CUOTA FINANCIADA </td><td> XXX €</td></tr><tr><td> PORCENTAJE DE AUTOCONSUMO MÁXIMO </td><td>45 %</td></tr></table></td></tr><tr><td colspan="3"><h4>RECUERDE: AUMENTE SU AUTOSUFICIENCIA INSTALANDO UNA BATERÍA</h4></td></tr><tr><td colspan="3"><b> OPCIONES </b></td></tr><tr><td colspan="3"><b>- Realización de Proyecto para la solicitud de la reducción de IBI municipal. (SEGÚN MUNICIPIO)</b> </td></tr><tr><td colspan="3"><b>- Proyecto para la legalización de inyección a Red del excedente eléctrico de producción. 640.00€</b></td></tr><tr><td colspan="3"><b>- Suministro e instalación de Batería Luna 15 kW</b></td></tr><tr><td colspan="3"><br/><br/></td></tr><tr><td colspan="3"><h4>No se incluye</h4></td></tr><tr><td><p>- Licencia de Obra menor.                         </p><p>- Todo lo no especificado de forma clara y directa. </p></td><td></td>                    </tr><tr><td colspan="3"><h4>FORMA DE PAGO: </h4><br/><br/></td></tr><tr><td colspan="2"><p><b>50% a la firma del contrato.</b></p><p><b>30% Inicio de Obra.</b></p><p><b>20% Puesta en marcha. </b></p></td><td><p><b>Firma: </b></p><br /><div style="   width: 270px;height: 120px;border-left: 1px solid black;border-right: 1px solid black;border-top: 1px solid black;border-bottom: 1px solid black;"></div>        </td></tr></table>';
  }

  formataElementosParaVisualizacion() {
    this.precioFinalParaVisualizacion = this.shared
      .formataNumeroParaVisualizacion(this.precioFinal, 0, ",", ".");
    this.consumoAnualTotalFormatado = this.shared
      .formataNumeroParaVisualizacion(this.consumoAnualTotal, 0, ",", ".");

    try {
      this.calculaImporteFormasDePago();
    } catch (error) {
    }
  }

  calculaImporteFormasDePago() {
    if (this.incluirFormaDePago) {
      var precioFinalIVA = 0;
      var precioFinal = 0;
      if (!isNaN(+this.precioFinal)) {
        precioFinal = +this.precioFinal;
      }

      //IF ES VIVIENDA HABITUAL O NO
      //alert((precioFinal + "+ (+" + this.precioFinal + "*1.21))"));
      if (!this.viviendaHabitual) {
        precioFinalIVA = +(+this.precioFinal * 1.21).toFixed(2);
      } else {
        precioFinalIVA = +(+this.precioFinal * 1.1).toFixed(2);
      }

      this.formaDePagoFirmaContrato = +((precioFinalIVA * 50) / 100).toFixed(2);
      this.formaDePagoInicioDeObra = +((precioFinalIVA * 30) / 100).toFixed(2);
      this.formaDePagoPuestaEnMarcha = +((precioFinalIVA * 20) / 100).toFixed(
        2,
      );

      this.formaDePagoFirmaContrato = this.shared
        .formataNumeroParaVisualizacion(
          this.formaDePagoFirmaContrato,
          2,
          ",",
          ".",
        );
      this.formaDePagoInicioDeObra = this.shared.formataNumeroParaVisualizacion(
        this.formaDePagoInicioDeObra,
        2,
        ",",
        ".",
      );
      this.formaDePagoPuestaEnMarcha = this.shared
        .formataNumeroParaVisualizacion(
          this.formaDePagoPuestaEnMarcha,
          2,
          ",",
          ".",
        );
    }
  }

  generaNombreDocumentoPDF() {
    this.nombreDocumentoPDF = this.numPresupuesto + " " +
      this.direccion.toUpperCase() + " " + this.nombreCliente.toUpperCase() +
      " " + this.poblacion.toUpperCase() + " " + this.potenciaKit + " kW";
  }

  doesFileExist(urlToFile) {
    var xhr = new XMLHttpRequest();
    xhr.open("HEAD", urlToFile, false);
    xhr.send();

    //
    if (xhr.status == 404) {
      return false;
    } else {
      return true;
    }
  }

  previsualizacionPDF() {
    this.ocultarPDFGenerado = false;

    /*

    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', '');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
    */
  }

  ngAfterViewInit() {
    this.calculaConsumosEstimados().then(
      () => {
        this.chartOptionsComparacionPunta = {
          series: [
            {
              name: "Producción energía sistema FV fijo",
              data: this.arrFormatadoPuntaSinDecimalesPVGIS,
            },
            {
              name: "Producción actual estimada",
              data: this.consumosEstimados.datosEstimadosPunta,
            },
          ],
          chart: {
            id: "chartPunta",
            toolbar: {
              show: false,
            },
            type: "area",
            height: 225,
            width: 500,
            zoom: {
              enabled: false,
            },
          },
          //colors: ["#008FFB", "#00E396", "#CED4DC"],
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: "gradient",
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0.8,
            },
          },
          legend: {
            show: false,
          },

          xaxis: {
            type: "category",
            categories: [
              "E",
              "F",
              "M",
              "A",
              "M",
              "J",
              "J",
              "A",
              "S",
              "O",
              "N",
              "D",
            ],
          },
        };

        // var a = new ApexCharts(
        //   this.chartPunta.nativeElement,
        //   this.chartOptionsComparacionPunta,
        // );
        // a.render();

        this.chartOptionsComparacionLlano = {
          series: [
            {
              name: "Producción energía sistema FV fijo",
              data: this.arrFormatadoLlanoSinDecimalesPVGIS,
            },
            {
              name: "Producción actual estimada",
              data: this.consumosEstimados.datosEstimadosLlano,
            },
          ],
          chart: {
            id: "chartLlano",
            toolbar: {
              show: false,
            },
            type: "area",
            height: 225,
            width: 500,
            zoom: {
              enabled: false,
            },
          },

          dataLabels: {
            enabled: false,
          },
          fill: {
            type: "gradient",
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0.8,
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            type: "category",
            categories: [
              "E",
              "F",
              "M",
              "A",
              "M",
              "J",
              "J",
              "A",
              "S",
              "O",
              "N",
              "D",
            ],
          },
        };

        // var b = new ApexCharts(
        //   this.chartLlano.nativeElement,
        //   this.chartOptionsComparacionLlano,
        // );
        // b.render();

        this.chartOptionsComparacionValle = {
          series: [
            {
              name: "Producción energía sistema FV fijo",
              data: this.arrFormatadoValleSinDecimalesPVGIS,
            },
            {
              name: "Producción actual estimada",
              data: this.consumosEstimados.datosEstimadosValle,
            },
          ],
          chart: {
            id: "chartValle",
            toolbar: {
              show: false,
            },
            type: "area",
            height: 225,
            width: 500,
            zoom: {
              enabled: false,
            },
          },
          //colors: ["#008FFB", "#00E396", "#CED4DC"],
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: "gradient",
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0.8,
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            type: "category",
            categories: [
              "E",
              "F",
              "M",
              "A",
              "M",
              "J",
              "J",
              "A",
              "S",
              "O",
              "N",
              "D",
            ],
          },
        };

        // var c = new ApexCharts(
        //   this.chartValle.nativeElement,
        //   this.chartOptionsComparacionValle,
        // );
        // c.render();
      },
    );
  }

  calculaFinanciacion() {
    this.datosFinanciacionSinBateria = Array<
      {
        global: boolean;
        plazo: number;
        importeMultiplicador1: string;
        importeMultiplicador2: string;
      }
    >();
    this.datosFinanciacionConBateria = Array<
      {
        global: boolean;
        plazo: number;
        importeMultiplicador1: string;
        importeMultiplicador2: string;
      }
    >();
    this.valoresMultiplicador1DatosFinanciadosSinBateria = [];
    this.valoresMultiplicador2DatosFinanciadosSinBateria = [];
    this.valoresMultiplicador1DatosFinanciadosConBateria = [];
    this.valoresMultiplicador2DatosFinanciadosConBateria = [];

    this.datosFinanciacionSinBateria.push(
      {
        global: true,
        plazo: 0,
        importeMultiplicador1: (+this.precioFinal * 1.1).toFixed(2),
        importeMultiplicador2: (+this.precioFinal * 1.21).toFixed(2),
      },
    );

    this.valoresMultiplicador1DatosFinanciadosSinBateria.push(
      +this.datosFinanciacionSinBateria[0]["importeMultiplicador1"],
    );
    this.valoresMultiplicador2DatosFinanciadosSinBateria.push(
      +this.datosFinanciacionSinBateria[0]["importeMultiplicador2"],
    );

    this.datosFinanciacionSinBateria.push(
      {
        global: false,
        plazo: 60,
        importeMultiplicador1:
          (+this.datosFinanciacionSinBateria[0]["importeMultiplicador1"] *
            0.02025309).toFixed(2),
        importeMultiplicador2:
          (+this.datosFinanciacionSinBateria[0]["importeMultiplicador2"] *
            0.02025282).toFixed(2),
      },
      {
        global: false,
        plazo: 66,
        importeMultiplicador1:
          (+this.datosFinanciacionSinBateria[0]["importeMultiplicador1"] *
            0.018754).toFixed(2),
        importeMultiplicador2:
          (+this.datosFinanciacionSinBateria[0]["importeMultiplicador2"] *
            0.01875344).toFixed(2),
      },
      {
        global: false,
        plazo: 72,
        importeMultiplicador1:
          (+this.datosFinanciacionSinBateria[0]["importeMultiplicador1"] *
            0.01750939).toFixed(2),
        importeMultiplicador2:
          (+this.datosFinanciacionSinBateria[0]["importeMultiplicador2"] *
            0.01750939).toFixed(2),
      },
      {
        global: false,
        plazo: 78,
        importeMultiplicador1:
          (+this.datosFinanciacionSinBateria[0]["importeMultiplicador1"] *
            0.01645807).toFixed(2),
        importeMultiplicador2:
          (+this.datosFinanciacionSinBateria[0]["importeMultiplicador2"] *
            0.01645807).toFixed(2),
      },
      {
        global: false,
        plazo: 84,
        importeMultiplicador1:
          (+this.datosFinanciacionSinBateria[0]["importeMultiplicador1"] *
            0.01556112).toFixed(2),
        importeMultiplicador2:
          (+this.datosFinanciacionSinBateria[0]["importeMultiplicador2"] *
            0.0155607).toFixed(2),
      },
    );

    this.cuotaFinanciada84Formatado = this.shared
      .formataNumeroParaVisualizacion(
        +this.datosFinanciacionSinBateria[0]["importeMultiplicador1"] *
        0.01556112,
        2,
        ",",
        ".",
      );
    for (var i = 1; i <= this.datosFinanciacionSinBateria.length - 1; i++) {
      this.valoresMultiplicador1DatosFinanciadosSinBateria.push(
        +this.datosFinanciacionSinBateria[i]["importeMultiplicador1"],
      );
      this.valoresMultiplicador2DatosFinanciadosSinBateria.push(
        +this.datosFinanciacionSinBateria[i]["importeMultiplicador2"],
      );
    }

    this.datosFinanciacionConBateria.push(
      {
        global: true,
        plazo: 0,
        importeMultiplicador1:
          (+(this.precioFinal + this.importeAdicional) * 1.1).toFixed(2),
        importeMultiplicador2:
          (+(this.precioFinal + this.importeAdicional) * 1.21).toFixed(2),
      },
    );

    this.valoresMultiplicador1DatosFinanciadosConBateria.push(
      +this.datosFinanciacionConBateria[0]["importeMultiplicador1"],
    );
    this.valoresMultiplicador2DatosFinanciadosConBateria.push(
      +this.datosFinanciacionConBateria[0]["importeMultiplicador2"],
    );

    this.datosFinanciacionConBateria.push(
      {
        global: false,
        plazo: 60,
        importeMultiplicador1:
          (+this.datosFinanciacionConBateria[0]["importeMultiplicador1"] *
            0.02025309).toFixed(2),
        importeMultiplicador2:
          (+this.datosFinanciacionConBateria[0]["importeMultiplicador2"] *
            0.02025282).toFixed(2),
      },
      {
        global: false,
        plazo: 66,
        importeMultiplicador1:
          (+this.datosFinanciacionConBateria[0]["importeMultiplicador1"] *
            0.018754).toFixed(2),
        importeMultiplicador2:
          (+this.datosFinanciacionConBateria[0]["importeMultiplicador2"] *
            0.01875344).toFixed(2),
      },
      {
        global: false,
        plazo: 72,
        importeMultiplicador1:
          (+this.datosFinanciacionConBateria[0]["importeMultiplicador1"] *
            0.01750939).toFixed(2),
        importeMultiplicador2:
          (+this.datosFinanciacionConBateria[0]["importeMultiplicador2"] *
            0.01750939).toFixed(2),
      },
      {
        global: false,
        plazo: 78,
        importeMultiplicador1:
          (+this.datosFinanciacionConBateria[0]["importeMultiplicador1"] *
            0.01645807).toFixed(2),
        importeMultiplicador2:
          (+this.datosFinanciacionConBateria[0]["importeMultiplicador2"] *
            0.01645807).toFixed(2),
      },
      {
        global: false,
        plazo: 84,
        importeMultiplicador1:
          (+this.datosFinanciacionConBateria[0]["importeMultiplicador1"] *
            0.01556112).toFixed(2),
        importeMultiplicador2:
          (+this.datosFinanciacionConBateria[0]["importeMultiplicador2"] *
            0.0155607).toFixed(2),
      },
    );

    for (var i = 1; i <= this.datosFinanciacionSinBateria.length - 1; i++) {
      this.valoresMultiplicador1DatosFinanciadosConBateria.push(
        +this.datosFinanciacionConBateria[i]["importeMultiplicador1"],
      );
      this.valoresMultiplicador2DatosFinanciadosConBateria.push(
        +this.datosFinanciacionConBateria[i]["importeMultiplicador2"],
      );
    }

    //
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }

  async obtenerInforme() {
    /*
    await this.http.obtenerInformebyOpciones(this.opciones).then(
      (result:INV_Informe) => {
        this.informe = result;


      },(err) => {
        this.notifyService.showMessage("Error obteniendo informe.", "Error",true);

      }
    )
    */
  }

  async geocodePVGIS() {
    return new Promise((resolve, reject) => {
      var s = [];
      var arrFormatado = [];

      this.potencia = this.tipoKit["POTENCIA"];

      this.http.geocoder(
        encodeURIComponent(this.direccion) + "+" +
        encodeURIComponent(this.poblacion) + "+Spain",
      ).then(
        (resultgeocoder: any) => {
          let latCoordIndustrial = "42";
          let lonCoordIndustrial = "2";
          if (resultgeocoder.items && resultgeocoder.items.length > 0) {
            latCoordIndustrial = resultgeocoder.items[0].position.lat;
            lonCoordIndustrial = resultgeocoder.items[0].position.lng;
          }
          this.http.pvgis(0, latCoordIndustrial, lonCoordIndustrial, this.potencia, 15).then(
            (result) => {
              //
              s = result["outputs"]["monthly"]["fixed"];

              let iteracion = 0;
              s.forEach((element) => {
                //

                this.totalPVGIS = Math.round(this.totalPVGIS + element.E_m);

                //Generacion - LLANO - VALLE

                this.arrFormatadoSinDecimalesPVGIS.push(
                  Math.round(element.E_m),
                );

                this.arrFormatadoValleSinDecimalesPVGIS.push(
                  Math.round((Math.round(element.E_m / 30)) * 8),
                );

                this.arrFormatadoPVGIS.push(element.E_m);

                //     <td>{{ (this.arrFormatadoSinDecimalesPVGIS[6]-this.arrFormatadoValleSinDecimalesPVGIS[6])*0.45}}</td>

                this.arrFormatadoLlanoSinDecimalesPVGIS.push(
                  Math.round(
                    (element.E_m -
                      Math.round((Math.round(element.E_m / 30)) * 8)) * 0.45,
                  ),
                );

                this.arrFormatadoPuntaSinDecimalesPVGIS.push(
                  Math.round(
                    element.E_m -
                    ((element.E_m -
                      Math.round((Math.round(element.E_m / 30)) * 8)) *
                      0.45) -
                    ((Math.round(element.E_m / 30)) * 8),
                  ),
                );

                iteracion++;
                //FIN ITERACIONES ARRAYS
                if (iteracion == (s.length)) {
                  if (
                    (isNaN(this.totalPVGIS)) || (!isFinite(this.totalPVGIS))
                  ) {
                    this.totalPVGIS = 0;
                  }

                  this.totalPVGISFormatado = this.shared
                    .formataNumeroParaVisualizacion(
                      this.totalPVGIS,
                      0,
                      ",",
                      ".",
                    );

                  this.arrFormatadoValleSinDecimalesPVGIS.forEach((element) => {
                    this.arrRepercusionesEconomicasValles.push(
                      Math.round((element * 0.12) * 100) / 100,
                    );
                  });

                  this.totalVallePVGIS = Math.round((this.totalPVGIS / 30) * 8);
                  this.arrFormatadoLlanoSinDecimalesPVGIS.forEach((element) => {
                    this.totalLlanoPVGIS = this.totalLlanoPVGIS +
                      Math.round(element);
                    this.arrRepercusionesEconomicasLlanos.push(
                      Math.round((element * 0.2) * 100) / 100,
                    );
                  });

                  this.arrFormatadoPuntaSinDecimalesPVGIS.forEach((element) => {
                    this.totalPuntaPVGIS = this.totalPuntaPVGIS +
                      Math.round(element);
                    this.arrRepercusionesEconomicasPuntas.push(
                      Math.round((element * 0.28) * 100) / 100,
                    );
                  });

                  this.arrRepercusionesEconomicasPuntas.forEach((element) => {
                    this.totalRepercusionesEconomicasPuntas =
                      this.totalRepercusionesEconomicasPuntas +
                      +(Math.round(element));
                  });

                  this.arrRepercusionesEconomicasLlanos.forEach((element) => {
                    this.totalRepercusionesEconomicasLlanos =
                      this.totalRepercusionesEconomicasLlanos +
                      +(Math.round(element));
                  });

                  //

                  this.arrRepercusionesEconomicasValles.forEach((element) => {
                    this.totalRepercusionesEconomicasValles =
                      this.totalRepercusionesEconomicasValles +
                      +(Math.round(element));
                  });

                  for (var i = 0; i <= 11; i++) {
                    this.arrTotalRepercusionesEconomicas.push(
                      (this.arrRepercusionesEconomicasPuntas[i] +
                        this.arrRepercusionesEconomicasLlanos[i] +
                        this.arrRepercusionesEconomicasValles[i]).toFixed(2),
                    );
                  }

                  this.arrTotalRepercusionesEconomicas.forEach((element) => {
                    this.totalRepercusionesEconomicas =
                      this.totalRepercusionesEconomicas +
                      +(Math.round(element));
                  });

                  if (
                    (isNaN(this.totalRepercusionesEconomicas)) ||
                    (!isFinite(this.totalRepercusionesEconomicas))
                  ) {
                    this.totalRepercusionesEconomicas = 0;
                  }

                  this.totalRepercusionesEconomicasFormatado = this.shared
                    .formataNumeroParaVisualizacion(
                      this.totalRepercusionesEconomicas,
                      0,
                      "",
                      ".",
                    );
                  this.totalRepercusiones25a = this.shared
                    .formataNumeroParaVisualizacion(
                      +Math.round(this.totalRepercusionesEconomicas * 25),
                      0,
                      "",
                      ".",
                    );
                  //(this.totalPVGIS/30)*8 | number: '1.0-0'

                  //this.mergeOption = { series: this.arrFormatadoPVGIS };
                  /*eccharts */
                  this.initOpts = {
                    renderer: "svg",
                    width: 1100,
                    height: 600,
                  };

                  this.initOptsMinimizado = {
                    renderer: "canvas",
                    width: 100,
                    height: 100,
                  };

                  this.options = {
                    title: {
                      id: "title",
                      show: true,
                      text: "Producción de energía mensual del sistema FV fijo",
                    },
                    color: ["#3398DB"],
                    tooltip: {
                      trigger: "axis",
                      axisPointer: {
                        type: "shadow",
                      },
                    },
                    grid: {
                      left: "3%",
                      right: "4%",
                      bottom: "3%",
                      containLabel: true,
                    },
                    xAxis: [
                      {
                        type: "category",
                        data: [
                          "Ene",
                          "Feb",
                          "Mar",
                          "Abr",
                          "May",
                          "Jun",
                          "Jul",
                          "Ago",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dic",
                        ],
                        axisTick: {
                          alignWithLabel: true,
                        },
                      },
                    ],
                    yAxis: [{
                      type: "value",
                    }],
                    yAxisLabel: "aaa",
                    showYAxisLabel: true,
                    series: [{
                      name: "Counters",
                      type: "bar",
                      barWidth: "60%",
                      data: this.arrFormatadoPVGIS,
                      label: {
                        normal: {
                          show: true,
                          position: "top",
                        },
                      },
                    }],
                  };

                  this.optionsAmortizacionIndustrial = {
                    title: {
                      id: "title",
                      show: false,
                      text: "",
                    },
                    color: ["#FFE17E"],
                    tooltip: {
                      trigger: "axis",
                      axisPointer: {
                        type: "shadow",
                      },
                    },
                    grid: {
                      left: "3%",
                      right: "4%",
                      bottom: "3%",
                      containLabel: true,
                    },
                    xAxis: [
                      {
                        type: "category",
                        data: [
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "10",
                          "11",
                          "12",
                          "13",
                          "14",
                          "15",
                          "16",
                          "17",
                          "18",
                          "19",
                          "20",
                          "21",
                          "22",
                          "23",
                          "24",
                        ],
                        axisTick: {
                          alignWithLabel: true,
                        },
                      },
                    ],
                    yAxis: [{
                      type: "value",
                    }],
                    yAxisLabel: "aaa",
                    showYAxisLabel: true,
                    series: [{
                      name: "Counters",
                      type: "bar",
                      barWidth: "60%",
                      data: [
                        -50000,
                        -30000,
                        -15000,
                        -200,
                        -2,
                        50,
                        90,
                        130,
                        170,
                        400,
                        700,
                        750,
                        950,
                        1000,
                        1057,
                        1200,
                        1352,
                        1822,
                        10002,
                        30022,
                        60222,
                        90222,
                        160222,
                        360222,
                      ],
                      label: {
                        normal: {
                          show: true,
                          position: "top",
                        },
                      },
                    }],
                  };

                  try {
                    this.calculaPorcentajeSobreConsumoEstimado();
                  } catch (error) {
                  }

                  //totalpvgis
                  resolve(this);

                  this.spinner.hide();
                }
                //
              });
            },
          ), (err) => {
            console.log(err);
            this.spinner.hide();
          };
        },
      );
    });
  }

  calculaPorcentajeSobreConsumoEstimado() {
    var result = (this.totalPVGIS / +this.consumoAnualTotal) * 100;
    if ((isNaN(result)) || (!isFinite(result))) {
      result = 0;
    }

    this.porcentajeSobreConsumoEstimado = this.shared
      .formataNumeroParaVisualizacion(result, 1, ",", ".");
    //alert(this.porcentajeSobreConsumoEstimado);
  }

  /*
  async obtenerInforme(){
    await this.http.nomDeFuncionHttp(paràmete/objete a passar).then(
      (resultat:TipusObjecteResultat) => {
        objecteAAssignarResultat = resultat;
      },(err) => { //SI PETA
        this.notifyService.showMessage("Error obteniendo informe.", "Error",true);
      }
    )
  }




  */

  async calculaConsumosEstimados() {
    return new Promise(async (resolve, _) => {
      try {
        await this.geocodePVGIS();
        this.consumosEstimados.consumoAnualTotal = +this.consumoAnualTotal;

        for (var i = 0; i <= 11; i++) {
          if ((i >= 4) && (i <= 7)) {
            //=(potencia-(potencia/14)*8)/4
            var resultado = Math.round(
              (+this.consumoAnualTotal - (+this.consumoAnualTotal / 14) * 8) /
              4,
            );
            this.consumosEstimados.consumos[i] = resultado;
          } else {
            this.consumosEstimados.consumos[i] = Math.round(
              +this.consumoAnualTotal / 14,
            );
          }
        }

        this.consumosEstimados.datosEstimadosPunta = [];
        this.consumosEstimados.datosEstimadosLlano = [];
        this.consumosEstimados.datosEstimadosValle = [];

        this.consumosEstimados.consumos.forEach((element) => {
          this.consumosEstimados.datosEstimadosPunta.push(
            Math.round(
              element * 0.4,
            ),
          );

          this.consumosEstimados.datosEstimadosLlano.push(
            Math.round(
              element * 0.45,
            ),
          );

          this.consumosEstimados.datosEstimadosValle.push(
            Math.round(
              element * 0.15,
            ),
          );
        });

        this.consumosEstimados.consumoAnualEstimadoPunta = 0;
        this.consumosEstimados.consumoAnualEstimadoLlano = 0;
        this.consumosEstimados.consumoAnualEstimadoValle = 0;

        for (var i = 0; i <= 11; i++) {
          this.consumosEstimados.consumoAnualEstimadoPunta =
            this.consumosEstimados.consumoAnualEstimadoPunta +
            this.consumosEstimados.datosEstimadosPunta[i];
          this.consumosEstimados.consumoAnualEstimadoLlano =
            this.consumosEstimados.consumoAnualEstimadoLlano +
            this.consumosEstimados.datosEstimadosLlano[i];
          this.consumosEstimados.consumoAnualEstimadoValle =
            this.consumosEstimados.consumoAnualEstimadoValle +
            this.consumosEstimados.datosEstimadosValle[i];
        }

        //

        var totalPVGISPunta = eval(
          this.arrFormatadoPuntaSinDecimalesPVGIS.join("+"),
        );
        var totalDatosPunta = eval(
          this.consumosEstimados.datosEstimadosPunta.join("+"),
        );

        //

        this.totalPorcentajeAhorroPunta =
          +((totalPVGISPunta / totalDatosPunta) * 100);

        if (
          (isNaN(this.totalPorcentajeAhorroPunta)) ||
          (!isFinite(this.totalPorcentajeAhorroPunta))
        ) {
          this.totalPorcentajeAhorroPunta = 0;
        }

        this.totalPorcentajeAhorroPunta = this.shared
          .formataNumeroParaVisualizacion(
            this.totalPorcentajeAhorroPunta,
            2,
            ",",
            ".",
          );

        //   this.totalPorcentajeAhorroPunta = +(Math.round(totalPVGISPunta/totalDatosPunta)*100);
        //

        /*
        var chartLlano = new ApexCharts(document.querySelector('#chartLlano'), this.chartOptionsComparacionLlano);
        chartLlano.render();
        chartLlano.updateSeries(   [
            {
              name: "Producción energía sistema FV fijo",
              data: this.arrFormatadoLlanoSinDecimalesPVGIS
            },
            {
              name: "Producción actual estimada",
              data: this.consumosEstimados.datosEstimadosLlano
            }

          ]);
          */

        var totalPVGISLlano = eval(
          this.arrFormatadoLlanoSinDecimalesPVGIS.join("+"),
        );
        var totalDatosLlano = eval(
          this.consumosEstimados.datosEstimadosLlano.join("+"),
        );

        //
        this.totalPorcentajeAhorroLlano =
          +((totalPVGISLlano / totalDatosLlano) * 100);

        if (
          (isNaN(this.totalPorcentajeAhorroLlano)) ||
          (!isFinite(this.totalPorcentajeAhorroLlano))
        ) {
          this.totalPorcentajeAhorroLlano = 0;
        }

        this.totalPorcentajeAhorroLlano = this.shared
          .formataNumeroParaVisualizacion(
            this.totalPorcentajeAhorroLlano,
            2,
            ",",
            ".",
          );
        /*
          var chartValle = new ApexCharts(document.querySelector('#chartValle'), this.chartOptionsComparacionValle);
          chartValle.render();
          chartValle.updateSeries(   [
            {
              name: "Producción energía sistema FV fijo",
              data: this.arrFormatadoValleSinDecimalesPVGIS
            },
            {
              name: "Producción actual estimada",
              data: this.consumosEstimados.datosEstimadosValle
            }

          ]);
          */

        var totalPVGISValle = eval(
          this.arrFormatadoValleSinDecimalesPVGIS.join("+"),
        );
        var totalDatosValle = eval(
          this.consumosEstimados.datosEstimadosValle.join("+"),
        );
        // this.totalPorcentajeAhorroValle = +(Math.round(totalPVGISValle/totalDatosValle)*100 / 100).toFixed(2);

        this.totalPorcentajeAhorroValle =
          +((totalPVGISValle / totalDatosValle) * 100);

        if (
          (isNaN(this.totalPorcentajeAhorroValle)) ||
          (!isFinite(this.totalPorcentajeAhorroValle))
        ) {
          this.totalPorcentajeAhorroValle = 0;
        }

        this.totalPorcentajeAhorroValle = this.shared
          .formataNumeroParaVisualizacion(
            this.totalPorcentajeAhorroValle,
            2,
            ",",
            ".",
          );

        resolve(this);
      } catch (error) {
        return null;
      }
    });
  }

  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
}
