import { Component, OnInit, ViewChild } from "@angular/core";

import { NgxSpinnerService } from "ngx-spinner";
import {
  Router,
} from "@angular/router";
import { HttpService } from "src/app/services/http/http.service";

import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NotificationService } from "src/app/services/notification/notification.service";
//import { INV_CreacionEdicionCliente } from './INV_creacion-edicion-cliente.component';

import {
  AngularGridInstance,
  Column,
  ExtensionName,
  FieldType,
  Filters,
  Formatter,
  GridOption,
} from "angular-slickgrid";

//import { Contacto } from '@shared/contacto.entity';

import { CRM_Promotores } from "@shared/CRM.promotores.entity";
import { CreacionEdicionPromotor } from "./creacion-edicion-promotor.component";

@Component({
  selector: "grid-promotores",
  templateUrl: "./grid-promotores.component.html",
  styleUrls: ["./grid-promotores.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class GridPromotoresComponent implements OnInit {
  pageReady = false;
  tituloModal = "";
  filtradoGrd = "";

  displayedColumns: string[] = ["NOMBRE", "editarGrupo"];
  angularGrid: AngularGridInstance;

  columnDefinitions1: Column[];
  columnDefinitionsHiddenDefault: Column[];
  dataset1: any[] = [];
  gridOptions1: GridOption;

  rowData = [];
  filtrado = new Array<CRM_Promotores>();
  public promotores: Array<CRM_Promotores>;

  source: Array<any> = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public router: Router,
    public http: HttpService,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    public dialog: MatDialog) { }

  async ngOnInit() {


    const actionFormatter: Formatter = (
    ) => {
      return `<div class="fake-hyperlink">Acciones <i class="fa fa-caret-down"></i></div>`;
    };

    const estadoFormatter: Formatter = (
      row,
      cell,
      value) => {
      if (value.toString().trim().length <= 0) {
        return "";
      } else {
        if (value == "I") return "Interesado";
        else if (value == "N") return "No interesado";
        else if (value == "P") return "Pendiente contacto";
        else if (value == "U") return "No definido";
        else return "";
      }
    };

    this.columnDefinitions1 = [
      {
        id: "id",
        field: "id",
        name: "ID",
        sortable: true,
        filterable: true,
        maxWidth: 75,
      },

      {
        id: "estado",
        nameKey: "estado",
        field: "ESTADO",
        name: "Estado",
        formatter: estadoFormatter,
        sortable: true,
        filterable: true,
        maxWidth: 150,
        filter: {
          collection: [
            { value: "", label: "" },
            { value: "I", label: "Interesado" },
            { value: "N", labelKey: "No interesado" },
            { value: "P", labelKey: "Pendiente contacto" },
            { value: "U", labelKey: "No definido" },
          ],
          model: Filters.singleSelect,
          filterOptions: {
            autoDropWidth: true,
          },
        },
      },

      {
        id: "nombre",
        name: "Nombre",
        field: "NOMBRE",
        width: 250,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "correo",
        field: "CORREO",
        name: "Correo",
        width: 170,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      /*
      {
        id: "telefono",
        name: "Telefono",
        field: "TELEFONO",
        width: 40,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      */
      {
        id: "direccion",
        name: "Direccion",
        field: "DIRECCION",
        sortable: true,
        filterable: true,
        width: 160,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "municipio",
        name: "Municipio",
        field: "MUNICIPIO",
        sortable: true,
        filterable: true,
        width: 160,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      /*
      {
        id: "provincia",
        name: "Provincia",
        field: "PROVINCIA",
        sortable: true,
        filterable: true,
        width: 160,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      */
      {
        id: "acciones",
        name: "Acciones",
        field: "acciones",
        columnGroup: "Acciones",
        excludeFromExport: true,
        formatter: actionFormatter,
        cellMenu: {
          hideCloseButton: false,
          width: 200,
          menuUsabilityOverride: () => {
            return true;
          },
          commandItems: [
            { command: "editar", title: "Editar", positionOrder: 60 },
            { divider: true, command: "", positionOrder: 65 },
            {
              command: "eliminar",
              title: "Eliminar",
              cssClass: "red",
              positionOrder: 66,
            },
          ],
        },
      },
    ];

    this.gridOptions1 = {
      columnPicker: {
        hideForceFitButton: true,
        hideSyncResizeButton: true,
        onColumnsChanged: (e, args) => {
          console.log(
            "Column selection changed from Column Picker, visible columns: ",
            args.columns
          );
        },
      },
      autoEdit: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 28,
      //enableAutoResize: true,
      forceFitColumns: true,
      enableGridMenu: true,
      autoHeight: true,
      // enable the filtering but hide the user filter row since we use our own single filter
      enableFiltering: true,
      showHeaderRow: true, // hide the filter row (header row)

      alwaysShowVerticalScroll: false,
      enableColumnPicker: true,
      enableRowSelection: true,
      enablePagination: true,
      pagination: {
        pageSizes: [15, 30, 50, 100],
        pageSize: 15,
      },
      enableCellMenu: true,
      /*
          autoResize: {
            containerId: 'container',
            sidePadding: 5
          },
          */
      rowSelectionOptions: {
        // True (Single Selection), False (Multiple Selections)
        selectActiveRow: false,
      },
      enableAutoResize: true,
      enableCellNavigation: true,
      enableCheckboxSelector: true,
      multiSelect: false,
      enableSorting: true,
      excelExportOptions: {
        exportWithFormatter: true,
        customColumnWidth: 15,
        columnHeaderStyle: { font: { bold: true, italic: true } },
      },

      enableContextMenu: true,

      cellMenu: {
        onCommand: (e, args) => this.executeCommand(e, args),
        onOptionSelected: (e, args) => {
          const dataContext = args && args.dataContext;
          if (dataContext && dataContext.hasOwnProperty("completed")) {
            dataContext.completed = args.item.option;
            this.angularGrid.gridService.updateItem(dataContext);
          }
        },
        onBeforeMenuClose: (e, args) =>
          console.log("Cell Menu is closing", args),
      },
    };

    await this.inicializaListadoPromotores();
    this.pageReady = true;
  }

  onSelectedRowsChanged(args) {
    if (Array.isArray(args.rows)) {
      args.rows.map((idx) => {
         
      });
    }
  }

  executeCommand(e, args) {
    const command = args.command;
    const dataContext = args.dataContext;

     

    switch (command) {
      case "editar":
        this.editarArticulo(dataContext);
        break;
      case "eliminar":
        this.eliminarPromotor(dataContext.id);
        break;
    }
  }

  editarArticulo(_articulo) {
    this.abrirDialog(true, _articulo);
  }

  abrirDialog(_edicion = false, _promotor: CRM_Promotores) {
    var _prom = new CRM_Promotores(0, "", "", "", "", "", "", "");


    if (_edicion) {
      this.tituloModal = "Editar promotor";
      _prom = _promotor;
    } else {
      this.tituloModal = "Nuevo promotor";
    }

    const dialogRef = this.dialog.open(CreacionEdicionPromotor, {
      width: "75%",
      data: {
        tituloModal: this.tituloModal,
        edicion: _edicion,
        promotor: _prom,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
       

      if (result) {
        if (result.edicion) {

          //UPDATE
           
           
          this.spinner.show();

          this.http.actualizarPromotor(result.promotor).then((resultat) => {
            console.log(
              "Promotor actualizado correctamente" + JSON.stringify(resultat)
            );
            this.notifyService.showMessage(
              "Promotor actualizado correctamente",
              "Success",
              false
            );
            this.inicializaListadoPromotores();
            this.spinner.hide();
          });

        } //CREATE
        else {
           
          this.spinner.show();

          this.http.guardarPromotor(result.promotor).then((resultat) => {
            console.log(
              "Promotor creado correctamente" + JSON.stringify(resultat)
            );
            this.notifyService.showMessage(
              "Promotor creado correctamente",
              "Success",
              false
            );
            this.inicializaListadoPromotores();
            this.spinner.hide();
          });
        }

      }
    });

  }

  eliminarPromotor(_id_promotor) {
    if (confirm("Estás seguro? ")) {
      this.spinner.show();
      this.http.eliminarPromotor(_id_promotor).then(
        () => {
          this.spinner.hide();
          this.notifyService.showMessage(
            "Operación efectuada correctamente",
            "Success",
            false
          );
          this.inicializaListadoPromotores();
        },
        () => {
          this.spinner.hide();
          this.notifyService.showMessage(
            "Error efectuando operación",
            "Error",
            true
          );
          this.spinner.hide();
        }
      );
    }
  }

  angularGridReady(angularGrid: AngularGridInstance) {
    this.angularGrid = angularGrid;
  }

  get cellMenuInstance(): any {
    return (
      (this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.cellMenu
        )) ||
      {}
    );
  }

  get contextMenuInstance(): any {
    return (
      (this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.contextMenu
        )) ||
      {}
    );
  }

  async inicializaListadoPromotores() {
    this.http.obtenerTodosPromotores().then(
      (result: Array<CRM_Promotores>) => {
        this.promotores = result;
        //this.pptos = result;

        this.spinner.hide();
        this.filtrar(this.filtradoGrd);
      },
      () => {
         
        this.spinner.hide();
        this.filtrar(this.filtradoGrd);
      }
    );
  }

  nuevoPromotor() {
    this.abrirDialog(false, new CRM_Promotores());
  }

  filtrar(valor) {
    this.filtrado = this.promotores;

    this.rowData = this.filtrado;
    this.source = this.filtrado;

    //slickgrid
    // this.slick.beginUpdate();
    var datosFormatados = [];
    return new Promise((resolve) => {
      for (var i = 0; i < this.rowData.length; i++) {

        //nombre_usuario_asignado
        datosFormatados[i] = {
          id: this.rowData[i].ID_PROMOTOR,
          NOMBRE: this.rowData[i].NOMBRE,
          CORREO: this.rowData[i].CORREO,
          TELEFONO: this.rowData[i].TELEFONO,
          DIRECCION: this.rowData[i].DIRECCION,
          MUNICIPIO: this.rowData[i].MUNICIPIO,
          PROVINCIA: this.rowData[i].PROVINCIA,
          ESTADO: this.rowData[i].ESTADO
        };

        if (i == this.rowData.length - 1) resolve(this);
      }
    }).then(() => {
      this.dataset1 = datosFormatados;
    });
  }

  float2int(value) {
    return value | 0;
  }
}
