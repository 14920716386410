import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { SharedService } from "../shared/shared.service";
import { PPTOS } from "@shared/pptos.entity";
import { MNT_Articulos } from "@shared/MNT.articulos.entity";
import { MNT_TiposContrato } from "@shared/MNT.tipos_contrato.entity";
import { MNT_TiposContratoArticulos } from "@shared/MNT.tipos_contrato_articulos.entity";
import { MNT_Familias } from "@shared/MNT.familias.entity";
import { MNT_Marcas } from "@shared/MNT.marcas.entity";
import { PPTOS_PreciosKWHIndustrial } from "@shared/PPTOS.precios_kwh_industrial.entity";
import { PPTOS_ConsumosIntroduccionManualIndustrial } from "@shared/PPTOS.consumos_introduccion_manual_industrial.entity";
import { CRM_Clientes } from "@shared/CRM.clientes.entity";
import { CRM_Promotores } from "@shared/CRM.promotores.entity";
import { PPTOS_GeneracionConsumosIndustrial } from "@shared/PPTOS.generacion_consumos_industrial.entity";
import { CRM_AccionesRealizadas } from "@shared/CRM.acciones_realizadas.entity";
import { PPTOS_Alarmas } from "@shared/PPTOS.alarmas.entity";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(public http: HttpClient, public shared: SharedService) {}

  //end pasar objetos entre pantallas

  async login(username: string, password: string) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "auth/login", {
        username: username,
        password: password,
      })
        .subscribe((resp: any) => {
          //

          localStorage.removeItem("token");
          localStorage.setItem("token", resp.access_token);

          localStorage.removeItem("loggedUsername");
          localStorage.setItem("loggedUsername", username);

          resolve(true);
        });
    });
  }

  async obtenerProfile() {
    return new Promise((resolve) => {
      this.http.get(this.shared.urlWebServices + "profile")
        .subscribe((respProfile: any) => {
          //
          resolve(respProfile);
        });
    });
  }

  /* *******************************USUARIOS  **********************/

  /**
   * @param _nombre
   * @param _password
   * @param _privilegios
   */
  async crearUsuario(_nombre: string, _password: string, _privilegios: string) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "Usuarios/create", {
        nombre: _nombre,
        contrasena: _password,
        rol: _privilegios,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  /**
   * @param _id_usuario
   */
  async borrarUsuario(_id_usuario: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "usuarios/delete", {
        codigo: _id_usuario,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  /**
   * @param _id_usuario
   * @param _nombre
   * @param _password
   * @param _privilegios
   */
  async actualizarUsuario(
    _id_usuario: string,
    _nombre: string,
    _password: string,
    _privilegios: string,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "Usuarios/update", {
        id_usuario: _id_usuario,
        nombre: _nombre,
        contrasena: _password,
        rol: _privilegios,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  /** */
  async obtenerUsuarios() {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "Usuarios/index", {})
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerTodosTiposContrato() {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "TiposContrato/index", {})
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerTodasLasFamilias() {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "familias/index", {})
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerTipoContrato(_id_tipo_contrato: number) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "TiposContrato/getTipoContrato",
        { id_tipo_contrato: _id_tipo_contrato },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerArticulosSegunTipoContrato(_id_tipo_contrato: number) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices +
          "TiposContratoArticulos/getArticulosSegunTipoContrato",
        { id_tipo_contrato: _id_tipo_contrato },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerArticulosPanel() {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "Articulos/getPaneles", {})
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerPreciosKWHIndustrialSegunPpto(_id_ppto: number) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "PreciosKWHIndustrial/getSegunIdPPTO",
        { id_ppto: _id_ppto },
      ).subscribe({
        next: (data) => {
          if (data === null) {
            console.error("Error al obtener precios KWH industrial");
            return null;
          }
          resolve(data);
        },
        error: (error) => {
          console.error(error);
          return null;
        },
      });
    });
  }

  async obtenerGeneracionConsumosIndustrialSegunPpto(_id_ppto: number) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices +
          "GeneracionConsumosIndustrial/getSegunIdPPTO",
        { id_ppto: _id_ppto },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerConsumosIntroduccionManualSegunPPTO(_id_ppto: number) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices +
          "ConsumosIntroduccionManualIndustrial/getSegunIdPPTO",
        { id_ppto: _id_ppto },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerArticulosInversores() {
    const familias = await new Promise((resolve) => {
      this.http.get(
        this.shared.urlWebServices + "Familias/getFamiliasInversores",
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });

    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "Articulos/getInversores", {
        familias,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerArticulo(_id_articulo: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "Articulos/getarticulo", {
        id_articulo: _id_articulo,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerMotivoDenegacionSegunPpto(_id_ppto: number) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "pptos/getMotivoDenegacionPpto",
        { id_ppto: _id_ppto },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerAccionesRealizadasSegunCliente(_id_cliente: number) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "AccionesRealizadas/getSegunIdCliente",
        { id_cliente: _id_cliente },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerCliente(_id_cliente: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "clientes/getcliente", {
        id_cliente: _id_cliente,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async getPPTOSegunCliente(_id_cliente: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "pptos/getPPTOByCliente", {
        id_cliente: _id_cliente,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async getNumeracionNuevoPPTOByYear(_year: string) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "pptos/getNumeracionNuevoPPTOByYear",
        { year: _year },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerMarca(_id_marca: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "marcas/getmarca", {
        id_marca: _id_marca,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async pvgis(
    _azimuth: any,
    _lat: any,
    _lon: any,
    _potencia: any,
    _inclinacion = 15,
    _perdidas = 16,
  ) {
    try {
      return new Promise((resolve) => {
        this.http.post(this.shared.urlWebServices + "pvgis/obtener", {
          azimuth: _azimuth,
          lat: _lat,
          lon: _lon,
          loss: 16,
          potencia: _potencia,
          inclinacion: _inclinacion,
        })
          .subscribe((resp: any) => {
            resolve(resp);
          });
      });
    } catch (error) {
      return null;
    }
  }

  async geocoder(_searchtext: any) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "geocoder/obtener", {
        searchtext: _searchtext,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarPpto(_id_ppto: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "pptos/delete", {
        id_ppto: _id_ppto,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarPreciosKWHIndustrial(_id_ppto: number) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "PreciosKWHIndustrial/deleteByIdPPTO",
        { id_ppto: _id_ppto },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarConsumosIntroduccionManualIndustrial(_id_ppto: number) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices +
          "ConsumosIntroduccionManualIndustrial/deleteByIdPPTO",
        { id_ppto: _id_ppto },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarGeneracionConsumosIndustrial(_id_ppto: number) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices +
          "GeneracionConsumosIndustrial/deleteByIdPPTO",
        { id_ppto: _id_ppto },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarAlarma(_id_ppto: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "alarmas/deleteByIdPPTO", {
        id_ppto: _id_ppto,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarAccionesRealizadasSegunCliente(_id_cliente: number) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "AccionesRealizadas/deleteByIdCliente",
        { id_cliente: _id_cliente },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarArticulo(_id_articulo: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "articulos/delete", {
        id_articulo: _id_articulo,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarKit(_id_tipo_contrato: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "tiposcontrato/delete", {
        id_tipo_contrato: _id_tipo_contrato,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarFamilia(_id_familia: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "familias/delete", {
        id_familia: _id_familia,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarMarca(_id_marca: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "marcas/delete", {
        id_marca: _id_marca,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarCliente(_id_cliente: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "clientes/delete", {
        id_cliente: _id_cliente,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarPromotor(_id_promotor: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "promotores/delete", {
        id_promotor: _id_promotor,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async modificarEstadoPpto(
    _id_ppto: number,
    _estado: any,
    _motivoDenegacionPpto: any,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "pptos/cambiarEstado", {
        id_ppto: _id_ppto,
        estado: _estado,
        motivoDenegacionPpto: _motivoDenegacionPpto,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerTodosPresupuestos() {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "pptos/index", {})
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerTodosArticulos() {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "articulos/index", {})
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerTodosPromotores() {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "promotores/index", {})
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerTodasMarcas() {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "marcas/index", {})
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerTodosLosClientes() {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "clientes/index", {})
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerPresupuesto(_id_ppto: number) {
    try {
      return new Promise((resolve) => {
        this.http.post(this.shared.urlWebServices + "pptos/getppto", {
          id_ppto: _id_ppto,
        })
          .subscribe((resp: any) => {
            resolve(resp);
          });
      });
    } catch (error) {
      return null;
    }
  }

  async obtenerAlarmaById(_id_ppto: number) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "Alarmas/getSegunIdPPTO", {
        id_ppto: _id_ppto,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerTodasLasAlarmas() {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "Alarmas/index", {})
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerAlarmasDiarias(_fecha: any) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "alarmas/getAlarmasDiarias", {
        fecha: _fecha,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerNumeroAlarmasDiarias(_fecha: any) {
    try {
      return new Promise((resolve) => {
        this.http.post(
          this.shared.urlWebServices + "alarmas/getNumeroAlarmasDiarias",
          { fecha: _fecha },
        )
          .subscribe((resp: any) => {
            resolve(resp);
          });
      });
    } catch (error) {
      return null;
    }
  }

  async obtenerPresupuestosConMismaNumeracion(_numeracion: any) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "pptos/getPptosConMismaNumeracion",
        { numeracion: _numeracion },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async establecerAlarmaVisualizada(
    _id_ppto: number,
    _tipo_alarma: any,
    _visualizada: any,
    _siguiente_recordatorio: any,
  ) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "alarmas/establecerAlarmaVisualizada",
        {
          id_ppto: _id_ppto,
          tipo_alarma: _tipo_alarma,
          visualizada: _visualizada,
          siguiente_recordatorio: _siguiente_recordatorio,
        },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async actualizarArticulo(
    _articulo: MNT_Articulos,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "articulos/update", {
        id_articulo: _articulo.ID_ARTICULO,
        id_familia: _articulo.ID_FAMILIA,
        id_marca: _articulo.ID_MARCA,
        modelo: _articulo.MODELO,
        potencia: _articulo.POTENCIA,
        carac_1: _articulo.CARAC_1,
        carac_2: _articulo.CARAC_2,
        imagen: _articulo.IMAGEN,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async actualizarAlarma(
    _alarma: PPTOS_Alarmas,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "alarmas/update", {
        id_ppto: _alarma.ID_PPTO,
        tipo_alarma: _alarma.TIPO_ALARMA,
        visualizada: _alarma.VISUALIZADA.toString(),
        siguiente_recordatorio: _alarma.SIGUIENTE_RECORDATORIO,
        motivo_seguimiento: _alarma.MOTIVO_SEGUIMIENTO,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async actualizarPromotor(
    _promotor: CRM_Promotores,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "promotores/update", {
        id_promotor: _promotor["id"],
        nombre: _promotor.NOMBRE,
        correo: _promotor.CORREO,
        telefono: _promotor.TELEFONO,
        direccion: _promotor.DIRECCION,
        municipio: _promotor.MUNICIPIO,
        provincia: _promotor.PROVINCIA,
        estado: _promotor.ESTADO,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async actualizarFamilia(
    _familia: MNT_Familias,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "familias/update", {
        id_familia: _familia["id"],
        nombre: _familia.NOMBRE,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async actualizarMarca(
    _marca: MNT_Marcas,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "marcas/update", {
        id_marca: _marca["id"],
        nombre: _marca.NOMBRE,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async actualizarCliente(
    _cliente: CRM_Clientes,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "clientes/update", {
        id_cliente: _cliente["id"],
        numero_ppto: 0,
        nombre: _cliente.NOMBRE,
        correo: _cliente.CORREO,
        telefono: _cliente.TELEFONO,
        direccion: _cliente.DIRECCION,
        municipio: _cliente.MUNICIPIO,
        provincia: _cliente.PROVINCIA,
        procedencia: _cliente.PROCEDENCIA,
        id_promotor_procedencia: 0,
        estado: _cliente.ESTADO,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async actualizarPPTO(
    _ppto: PPTOS,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "pptos/update", {
        id_ppto: _ppto.ID_PPTO,
        id_ppto_mantenimiento_enlazado: _ppto.ID_PPTO_MANTENIMIENTO_ENLAZADO,
        numeracion: _ppto.NUMERACION,
        id_tipo_contrato: _ppto.ID_TIPO_CONTRATO,
        nombre_apellidos_cliente: _ppto.NOMBRE_APELLIDOS_CLIENTE,
        mail: _ppto.MAIL,
        telefono: _ppto.TELEFONO,
        direccion: _ppto.DIRECCION,
        vivienda_habitual: _ppto.VIVIENDA_HABITUAL,
        poblacion: _ppto.POBLACION,
        consumo_anual: _ppto.CONSUMO_ANUAL,
        estado: _ppto.ESTADO,
        orientacion: _ppto.ORIENTACION,
        pico_de_cubierta: _ppto.PICO_DE_CUBIERTA,
        fecha_revision_ppto: _ppto.FECHA_REVISION_PPTO,
        fecha_ppto: _ppto.FECHA_PPTO,
        tipo_contrato: _ppto.TIPO_CONTRATO,
        imagen_google_maps: _ppto.IMAGEN_GOOGLE_MAPS,
        texto_addicional: _ppto.TEXTO_ADDICIONAL,
        importe_addicional: _ppto.IMPORTE_ADDICIONAL,
        precio_final: _ppto.PRECIO_FINAL,
        trabajos_a_realizar: _ppto.TRABAJOS_A_REALIZAR,
        tipo_ppto: _ppto.TIPO_PPTO,
        potencia_pico_i: _ppto.POTENCIA_PICO_I,
        inclinacion_pvgis_i: _ppto.INCLINACION_PVGIS_I,
        azimuth_pvgis_i: _ppto.AZIMUTH_PVGIS_I,
        perdidas_pvgis_i: _ppto.PERDIDAS_PVGIS_I,
        coordenadas_lat_pvgis_i: _ppto.COORDENADAS_LAT_PVGIS_I,
        coordenadas_lon_pvgis_i: _ppto.COORDENADAS_LON_PVGIS_I,
        inversor_i: _ppto.INVERSOR_I,
        numero_inversores_i: _ppto.NUMERO_INVERSORES_I,
        panel_i: _ppto.PANEL_I,
        numero_paneles_i: _ppto.NUMERO_PANELES_I,
        solarblock_i: _ppto.SOLARBLOCK_I,
        potencia_bateria: _ppto.POTENCIA_BATERIA,
        id_cliente: _ppto.ID_CLIENTE,
        motivo_denegacion: _ppto.MOTIVO_DENEGACION,
        incluir_forma_pago: _ppto.INCLUIR_FORMA_PAGO,
        incluir_tramitaciones: _ppto.INCLUIR_TRAMITACIONES,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async actualizarPreciosKWHIndustrial(
    _ppto: PPTOS_PreciosKWHIndustrial,
  ) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "PreciosKWHIndustrial/update",
        {
          id_ppto: _ppto.ID_PPTO,
          precio_p1_i: _ppto.PRECIO_P1_I,
          precio_p2_i: _ppto.PRECIO_P2_I,
          precio_p3_i: _ppto.PRECIO_P3_I,
          precio_p4_i: _ppto.PRECIO_P4_I,
          precio_p5_i: _ppto.PRECIO_P5_I,
          precio_p6_i: _ppto.PRECIO_P6_I,
          potencia_p1_i: _ppto.POTENCIA_P1_I,
          potencia_p2_i: _ppto.POTENCIA_P2_I,
          potencia_p3_i: _ppto.POTENCIA_P3_I,
          potencia_p4_i: _ppto.POTENCIA_P4_I,
          potencia_p5_i: _ppto.POTENCIA_P5_I,
          potencia_p6_i: _ppto.POTENCIA_P6_I,
        },
      ).subscribe({
        next(resp) {
          resolve(resp);
        },
        error(msg) {
          console.log(msg);
        },
      });
    });
  }

  async actualizarGeneracionConsumosIndustrial(
    _ppto: PPTOS_GeneracionConsumosIndustrial,
  ) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "GeneracionConsumosIndustrial/update",
        {
          id_ppto: _ppto.ID_PPTO,
          consumo_mensual_factura_p1: _ppto.CONSUMO_MENSUAL_FACTURA_P1,
          consumo_mensual_factura_p2: _ppto.CONSUMO_MENSUAL_FACTURA_P2,
          consumo_mensual_factura_p3: _ppto.CONSUMO_MENSUAL_FACTURA_P3,
          consumo_anual: _ppto.CONSUMO_ANUAL,
        },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async actualizarConsumosIntroduccionManualIndustrial(
    _ppto: PPTOS_ConsumosIntroduccionManualIndustrial,
  ) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices +
          "ConsumosIntroduccionManualIndustrial/update",
        {
          id_ppto: _ppto.ID_PPTO,
          mes: _ppto.MES,
          p1: _ppto.P1,
          p2: _ppto.P2,
          p3: _ppto.P3,
          p4: _ppto.P4,
          p5: _ppto.P5,
          p6: _ppto.P6,
        },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  //guardarGeneracionConsumosIndustrial
  async guardarGeneracionConsumosIndustrial(
    _ppto: PPTOS_GeneracionConsumosIndustrial,
  ) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "GeneracionConsumosIndustrial/create",
        {
          id_ppto: _ppto.ID_PPTO,
          consumo_anual: _ppto.CONSUMO_ANUAL,
          consumo_mensual_factura_p1: _ppto.CONSUMO_MENSUAL_FACTURA_P1,
          consumo_mensual_factura_p2: _ppto.CONSUMO_MENSUAL_FACTURA_P2,
          consumo_mensual_factura_p3: _ppto.CONSUMO_MENSUAL_FACTURA_P3,
        },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async guardarConsumosIntroduccionManualIndustrial(
    _ppto: PPTOS_ConsumosIntroduccionManualIndustrial,
  ) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices +
          "ConsumosIntroduccionManualIndustrial/create",
        {
          id_ppto: _ppto.ID_PPTO,
          mes: _ppto.MES,
          p1: _ppto.P1,
          p2: _ppto.P2,
          p3: _ppto.P3,
          p4: _ppto.P4,
          p5: _ppto.P5,
          p6: _ppto.P6,
        },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async guardarPreciosKWHIndustrial(
    _ppto: PPTOS_PreciosKWHIndustrial,
  ) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "PreciosKWHIndustrial/create",
        {
          id_ppto: _ppto.ID_PPTO,
          precio_p1_i: _ppto.PRECIO_P1_I,
          precio_p2_i: _ppto.PRECIO_P2_I,
          precio_p3_i: _ppto.PRECIO_P3_I,
          precio_p4_i: _ppto.PRECIO_P4_I,
          precio_p5_i: _ppto.PRECIO_P5_I,
          precio_p6_i: _ppto.PRECIO_P6_I,
          potencia_p1_i: _ppto.POTENCIA_P1_I,
          potencia_p2_i: _ppto.POTENCIA_P2_I,
          potencia_p3_i: _ppto.POTENCIA_P3_I,
          potencia_p4_i: _ppto.POTENCIA_P4_I,
          potencia_p5_i: _ppto.POTENCIA_P5_I,
          potencia_p6_i: _ppto.POTENCIA_P6_I,
        },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async guardarPPTO(
    _ppto: PPTOS,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "pptos/create", {
        id_ppto_mantenimiento_enlazado: _ppto.ID_PPTO_MANTENIMIENTO_ENLAZADO,
        numeracion: _ppto.NUMERACION,
        id_tipo_contrato: _ppto.ID_TIPO_CONTRATO,
        nombre_apellidos_cliente: _ppto.NOMBRE_APELLIDOS_CLIENTE,
        mail: _ppto.MAIL,
        telefono: _ppto.TELEFONO,
        direccion: _ppto.DIRECCION,
        vivienda_habitual: _ppto.VIVIENDA_HABITUAL,
        poblacion: _ppto.POBLACION,
        consumo_anual: _ppto.CONSUMO_ANUAL,
        estado: _ppto.ESTADO,
        orientacion: _ppto.ORIENTACION,
        pico_de_cubierta: _ppto.PICO_DE_CUBIERTA,
        fecha_revision_ppto: _ppto.FECHA_REVISION_PPTO,
        fecha_ppto: _ppto.FECHA_PPTO,
        tipo_contrato: _ppto.TIPO_CONTRATO,
        imagen_google_maps: _ppto.IMAGEN_GOOGLE_MAPS,
        texto_addicional: _ppto.TEXTO_ADDICIONAL,
        importe_addicional: _ppto.IMPORTE_ADDICIONAL,
        precio_final: _ppto.PRECIO_FINAL,
        trabajos_a_realizar: _ppto.TRABAJOS_A_REALIZAR,
        tipo_ppto: _ppto.TIPO_PPTO,
        potencia_pico_i: _ppto.POTENCIA_PICO_I,
        inclinacion_pvgis_i: _ppto.INCLINACION_PVGIS_I,
        azimuth_pvgis_i: _ppto.AZIMUTH_PVGIS_I,
        perdidas_pvgis_i: _ppto.PERDIDAS_PVGIS_I,
        coordenadas_lat_pvgis_i: _ppto.COORDENADAS_LAT_PVGIS_I,
        coordenadas_lon_pvgis_i: _ppto.COORDENADAS_LON_PVGIS_I,
        inversor_i: _ppto.INVERSOR_I,
        numero_inversores_i: _ppto.NUMERO_INVERSORES_I,
        panel_i: _ppto.PANEL_I,
        numero_paneles_i: _ppto.NUMERO_PANELES_I,
        solarblock_i: _ppto.SOLARBLOCK_I,
        potencia_bateria: _ppto.POTENCIA_BATERIA,
        id_cliente: _ppto.ID_CLIENTE,
        motivo_denegacion: _ppto.MOTIVO_DENEGACION,
        incluir_forma_pago: _ppto.INCLUIR_FORMA_PAGO,
        incluir_tramitaciones: _ppto.INCLUIR_TRAMITACIONES,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async guardarCliente(
    _cliente: CRM_Clientes,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "clientes/create", {
        numero_ppto: _cliente.NUMERO_PPTO,
        nombre: _cliente.NOMBRE,
        correo: _cliente.CORREO,
        telefono: _cliente.TELEFONO,
        direccion: _cliente.DIRECCION,
        municipio: _cliente.MUNICIPIO,
        provincia: _cliente.PROVINCIA,
        procedencia: _cliente.PROCEDENCIA,
        id_promotor_procedencia: _cliente.ID_PROMOTOR_PROCEDENCIA,
        estado: _cliente.ESTADO,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async guardarKit(
    _tipo: MNT_TiposContrato,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "tiposcontrato/create", {
        tipo: _tipo.TIPO,
        nombre: _tipo.NOMBRE,
        descripcion: _tipo.DESCRIPCION,
        potencia: _tipo.POTENCIA,
        habito_consumo: _tipo.HABITO_CONSUMO,
        rol: _tipo.ROL,
        tramos_tarifa_i: _tipo.TRAMOS_TARIFA_I,
        precio_venta: _tipo.PRECIO_VENTA,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async actualizarKit(
    _tipo: MNT_TiposContrato,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "tiposcontrato/update", {
        id_tipo_contrato: _tipo.ID_TIPO_CONTRATO,
        tipo: _tipo.TIPO,
        nombre: _tipo.NOMBRE,
        descripcion: _tipo.DESCRIPCION,
        potencia: _tipo.POTENCIA,
        habito_consumo: _tipo.HABITO_CONSUMO,
        rol: _tipo.ROL,
        tramos_tarifa_i: _tipo.TRAMOS_TARIFA_I,
        precio_venta: _tipo.PRECIO_VENTA,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async eliminarArticulosSegunKit(
    _id_tipo_contrato: number,
  ) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "TiposContratoArticulos/deleteByKit",
        {
          id_tipo_contrato: _id_tipo_contrato,
        },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async guardarArticuloKit(
    _tipo: MNT_TiposContratoArticulos,
  ) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "tiposcontratoarticulos/create",
        {
          id_tipo_contrato: _tipo.ID_TIPO_CONTRATO,
          id_articulo: _tipo.ID_ARTICULO,
          cantidad: _tipo.CANTIDAD,
        },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async guardarPromotor(
    _promotor: CRM_Promotores,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "promotores/create", {
        nombre: _promotor.NOMBRE,
        correo: _promotor.CORREO,
        telefono: _promotor.TELEFONO,
        direccion: _promotor.DIRECCION,
        municipio: _promotor.MUNICIPIO,
        provincia: _promotor.PROVINCIA,
        estado: _promotor.ESTADO,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async guardarArticulo(
    _articulo: MNT_Articulos,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "articulos/create", {
        id_familia: _articulo.ID_FAMILIA,
        id_marca: _articulo.ID_MARCA,
        modelo: _articulo.MODELO,
        potencia: _articulo.POTENCIA,
        carac_1: _articulo.CARAC_1,
        carac_2: _articulo.CARAC_2,
        imagen: _articulo.IMAGEN,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async guardarAccionRealizada(
    _accionRealizada: CRM_AccionesRealizadas,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "AccionesRealizadas/create", {
        id_cliente: _accionRealizada.ID_CLIENTE,
        fecha: _accionRealizada.FECHA,
        descripcion: _accionRealizada.DESCRIPCION,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async guardarAlarma(
    _alarma: PPTOS_Alarmas,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "alarmas/create", {
        id_ppto: _alarma.ID_PPTO,
        tipo_alarma: _alarma.TIPO_ALARMA,
        visualizada: _alarma.VISUALIZADA.toString(),
        siguiente_recordatorio: _alarma.SIGUIENTE_RECORDATORIO,
        motivo_seguimiento: _alarma.MOTIVO_SEGUIMIENTO,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async guardarFamilia(
    _familia: MNT_Familias,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "familias/create", {
        nombre: _familia.NOMBRE,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async guardarMarca(
    _marca: MNT_Marcas,
  ) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "marcas/create", {
        nombre: _marca.NOMBRE,
      })
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async upload(_file: any) {
    //
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "uploader/test2", _file)
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  //estic aqui parsejar el
  async PDFToJPEG(_file: any) {
    return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + "ConvertAPI/test2", { _file })
        .subscribe((resp: any) => {
          alert(JSON.stringify(resp));
          resolve(resp);
        });
    });
  }

  async obtenerImagenLocalConvertidaABase64(pathImage: any) {
    return new Promise((resolve) => {
      this.http.get(pathImage, { responseType: "blob" })
        .subscribe((res) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            var base64data = reader.result;
            resolve(base64data);
          };

          reader.readAsDataURL(res);
        });
    });
  }

  async obtenerPrecioVentaKit(_id_tipo_contrato: number) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "tiposcontrato/getprecioventa",
        { id_tipo_contrato: _id_tipo_contrato },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async obtenerPresupuestoBateria(id: number, potencia: string) {
    return new Promise((resolve) => {
      this.http.post(
        this.shared.urlWebServices + "pptos/getPptoBateria",
        { id: id, potencia: potencia },
      )
        .subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }
}
