import { Component, OnInit, ViewChild } from "@angular/core";

import { NgxSpinnerService } from "ngx-spinner";
import {
  Router,
} from "@angular/router";
import { HttpService } from "src/app/services/http/http.service";

import { MatDialog, } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NotificationService } from "src/app/services/notification/notification.service";
//import { INV_CreacionEdicionCliente } from './INV_creacion-edicion-cliente.component';

import {
  AngularGridInstance,
  Column,
  ExtensionName,
  FieldType,
  Filters,
  Formatter,
  GridOption,
} from "angular-slickgrid";

//import { Contacto } from '@shared/contacto.entity';

import { MNT_Marcas } from "@shared/MNT.marcas.entity";
import { MNT_TiposContrato } from "@shared/MNT.tipos_contrato.entity";
import { CreacionEdicionKit } from "./creacion-edicion-kit.component";
import { MNT_TiposContratoArticulos } from "@shared/MNT.tipos_contrato_articulos.entity";

@Component({
  selector: "grid-kits",
  templateUrl: "./grid-kits.component.html",
  styleUrls: ["./grid-kits.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class GridKitsComponent implements OnInit {
  pageReady = false;
  tituloModal = "";
  filtradoGrd = "";

  displayedColumns: string[] = ["NOMBRE", "editarGrupo"];
  angularGrid: AngularGridInstance;

  columnDefinitions1: Column[];
  columnDefinitionsHiddenDefault: Column[];
  dataset1: any[] = [];
  gridOptions1: GridOption;

  rowData = [];
  filtrado = new Array<MNT_TiposContrato>();
  public kits: Array<MNT_TiposContrato>;
  marcas = new Array<MNT_Marcas>();
  source: Array<any> = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public router: Router,
    public http: HttpService,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    public dialog: MatDialog) { }

  async ngOnInit() {
    const actionFormatter: Formatter = (
    ) => {
      return `<div class="fake-hyperlink">Acciones <i class="fa fa-caret-down"></i></div>`;
    };

    const tipoFormatter: Formatter = (
      row,
      cell,
      value) => {
      if (value.toString().trim().length <= 0) {
        return "";
      } else {
        if (value == "D") return "DOMÉSTICO";
        else if (value == "I") return "INDUSTRIAL";
      }
    };

    const potenciaFormatter: Formatter = (
      row,
      cell,
      value) => {
      if (value.toString().trim().length <= 0) {
        return "";
      } else return value + " kW";
    };


    const precioVentaFormatter: Formatter = (
      row,
      cell,
      value) => {
      if (value) {
        if (value.toString().trim().length <= 0) {
          return "";
        } else return value + " €";
      }
      else
        return "";

    };

    /*
        const estadoFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
      
          if (value.toString().trim().length <= 0) {
            return '';
          }
          else 
          {
              
              if (value == "P")
                return 'Pendiente revisión';
              else if (value == "F")
                return 'Finalizado'; 
              else 
                return 'No definido';
          }
    
          
        };
        */

    this.columnDefinitions1 = [
      {
        id: "tipo",
        nameKey: "tipo",
        field: "TIPO",
        name: "Tipo",
        formatter: tipoFormatter,
        sortable: true,
        filterable: true,
        maxWidth: 150,
        filter: {
          collection: [
            { value: "", label: "" },
            { value: "D", label: "DOMÉSTICO" },
            { value: "I", labelKey: "INDUSTRIAL" },
          ],
          model: Filters.singleSelect,
          filterOptions: {
            autoDropWidth: true,
          },
        },
      },
      {
        id: "nombre",
        name: "Nombre",
        field: "NOMBRE",
        width: 175,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "potencia",
        field: "POTENCIA",
        name: "Potencia",
        width: 70,
        sortable: true,
        formatter: potenciaFormatter,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "precio_venta",
        field: "PRECIO_VENTA",
        name: "Precio de venta",
        width: 70,
        sortable: true,
        formatter: precioVentaFormatter,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "acciones",
        name: "Acciones",
        field: "acciones",
        columnGroup: "Acciones",
        excludeFromExport: true,
        formatter: actionFormatter,
        cellMenu: {
          hideCloseButton: false,
          width: 200,
          menuUsabilityOverride: () => {
            return true;
          },
          commandItems: [
            { command: "editar", title: "Editar", positionOrder: 60 },
            { divider: true, command: "", positionOrder: 65 },
            {
              command: "eliminar",
              title: "Eliminar",
              cssClass: "red",
              positionOrder: 66,
            },
          ],
        },
      },
    ];

    this.gridOptions1 = {
      columnPicker: {
        hideForceFitButton: true,
        hideSyncResizeButton: true,
        onColumnsChanged: (e, args) => {
          console.log(
            "Column selection changed from Column Picker, visible columns: ",
            args.columns
          );
        },
      },
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 28,
      //enableAutoResize: true,
      forceFitColumns: true,
      enableGridMenu: true,
      autoHeight: true,
      // enable the filtering but hide the user filter row since we use our own single filter
      enableFiltering: true,
      showHeaderRow: true, // hide the filter row (header row)

      alwaysShowVerticalScroll: false,
      enableColumnPicker: true,
      enableRowSelection: true,
      enablePagination: true,
      pagination: {
        pageSizes: [15, 30, 50, 100],
        pageSize: 15,
      },
      enableCellMenu: true,
      /*
      autoResize: {
        containerId: 'container',
        sidePadding: 5
      },
      */
      rowSelectionOptions: {
        // True (Single Selection), False (Multiple Selections)
        selectActiveRow: false,
      },
      enableAutoResize: true,
      enableCellNavigation: true,
      enableCheckboxSelector: true,
      multiSelect: false,
      enableSorting: true,
      excelExportOptions: {
        exportWithFormatter: true,
        customColumnWidth: 15,
        columnHeaderStyle: { font: { bold: true, italic: true } },
      },

      enableContextMenu: true,

      cellMenu: {
        onCommand: (e, args) => this.executeCommand(e, args),
        onOptionSelected: (e, args) => {
          const dataContext = args && args.dataContext;
          if (dataContext && dataContext.hasOwnProperty("completed")) {
            dataContext.completed = args.item.option;
            this.angularGrid.gridService.updateItem(dataContext);
          }
        },
        onBeforeMenuClose: (e, args) =>
          console.log("Cell Menu is closing", args),
      },
    };

    await this.inicializaListadoKits();
    this.spinner.hide();
    this.pageReady = true;

    // this.inicializaListadoGruposAD();
    // this.filtrar('');
  }

  onSelectedRowsChanged(args) {
    if (Array.isArray(args.rows)) {
      args.rows.map((idx) => {
         
      });
    }
  }

  executeCommand(e, args) {
    const command = args.command;
    const dataContext = args.dataContext;

    /*
     

    var equipo = new INV_Equipos(dataContext.id_equipo,dataContext.id_sede,
      dataContext.nombre,dataContext.dominio,dataContext.id_usuario_asignado,dataContext.cpu_generacion,dataContext.cpu_caracteristicas,
      dataContext.ram,dataContext.hd_tipo,dataContext.hd_capacidad,dataContext.so,dataContext.tipo,dataContext.n_serie,dataContext.fabricante,dataContext.modelo,
      dataContext.fecha_alta,dataContext.ip_fija,dataContext.notas_privadas);

      
           
       
    */
     
    switch (command) {
      case "editar":
        this.editarKit(dataContext.id);
        break;
      case "eliminar":
        this.eliminarKit(dataContext.id);
        break;
    }
  }

  editarKit(_id) {
    /*
      localStorage.setItem('id_ppto',_id_ppto);
      localStorage.setItem('accion','editar');
      this.router.navigate(['creacion-presupuesto']);  
      */

    this.http.obtenerTipoContrato(_id).then(
      (result: MNT_TiposContrato) => {
        this.abrirDialog(true, result);
      }
    )

  }

  abrirDialog(_edicion = false, _kit: MNT_TiposContrato) {

    var _art = new MNT_TiposContrato(0, "", "", "", 0, "", 0, 0);
    var artAsignados = new Array<interficieArticulosAsignados>();

    /*
       let i: interficieArticulosAsignados = {
                      id: event.source.value["ID_ARTICULO"], 
                      nombre: element.MODELO,
                      cantidad: 0
                    }
    */
    new Promise((resolve) => {


      if (_edicion) {
        this.tituloModal = "Editar Kit";
        _art = _kit;
        resolve(this);

      }
      else {
        this.tituloModal = "Nuevo Kit";
        resolve(this);
      }
    }).then(
      () => {
         
        const dialogRef = this.dialog.open(CreacionEdicionKit, {
          width: '75%',
          data: { tituloModal: this.tituloModal, edicion: _edicion, kit: _art, articulosAsignados: artAsignados }
        });


        dialogRef.afterClosed().subscribe(result => {



           
          if (result) {


            if (result.edicion) //UPDATE
            {

              this.spinner.show();

              result.kit.ROL = 1;
              result.kit.TRAMOS_TARIFA_I = 2;


              this.http.actualizarKit(result.kit).then(
                (resultat) => {

                  this.http.eliminarArticulosSegunKit(result.kit.ID_TIPO_CONTRATO).then(
                    () => {
                      result.articulosAsignados[0].forEach(element => {


                        let articulo = new MNT_TiposContratoArticulos();
                        articulo.ID_TIPO_CONTRATO = result.kit.ID_TIPO_CONTRATO;
                        articulo.ID_ARTICULO = element.id;
                        articulo.CANTIDAD = element.cantidad;

                         
                        this.http.guardarArticuloKit(articulo);
                        //this.http.guardarArticuloKit
                      });
                    }
                  )


                   
                  this.notifyService.showMessage("Kit actualizado correctamente", "Success", false);
                  this.inicializaListadoKits();
                  this.spinner.hide();
                })


            }
            else //CREATE
            {

              this.spinner.show();

              result.kit.ROL = 1;
              result.kit.TRAMOS_TARIFA_I = 2;

               

              this.http.guardarKit(result.kit).then(
                (resultat) => {

                  result.articulosAsignados[0].forEach(element => {


                    let articulo = new MNT_TiposContratoArticulos();
                    articulo.ID_TIPO_CONTRATO = resultat["identifiers"][0]["ID_TIPO_CONTRATO"];
                    articulo.ID_ARTICULO = element.id;
                    articulo.CANTIDAD = element.cantidad;

                     
                    this.http.guardarArticuloKit(articulo);
                    //this.http.guardarArticuloKit
                  });

                   
                  this.notifyService.showMessage("Kit creado correctamente", "Success", false);
                  this.inicializaListadoKits();
                  this.spinner.hide();
                })

            }



          }



        });
      }
    );



  }

  eliminarKit(_id_articulo) {
    if (confirm("Estás seguro? ")) {
      this.spinner.show();
      this.http.eliminarKit(_id_articulo).then(
        () => {
          this.spinner.hide();
          this.notifyService.showMessage(
            "Operación efectuada correctamente",
            "Success",
            false
          );
          this.inicializaListadoKits();
        },
        () => {
          this.spinner.hide();
          this.notifyService.showMessage(
            "Error efectuando operación",
            "Error",
            true
          );
          this.spinner.hide();
        }
      );
    }
  }

  angularGridReady(angularGrid: AngularGridInstance) {
    this.angularGrid = angularGrid;
  }

  get cellMenuInstance(): any {
    return (
      (this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.cellMenu
        )) ||
      {}
    );
  }

  get contextMenuInstance(): any {
    return (
      (this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.contextMenu
        )) ||
      {}
    );
  }

  async inicializaListadoKits() {
    this.http.obtenerTodosTiposContrato().then(
      (result: Array<MNT_TiposContrato>) => {
        this.kits = result;
        //this.pptos = result;

        this.filtrar(this.filtradoGrd);
      },
      () => {
         
        this.filtrar(this.filtradoGrd);
      }
    );
  }


  nuevoKit() {
    this.abrirDialog(false, new MNT_TiposContrato());
  }

  filtrar(valor) {

    this.filtrado = this.kits;

    this.rowData = this.filtrado;
    this.source = this.filtrado;

    //slickgrid
    // this.slick.beginUpdate();
    var datosFormatados = [];
    return new Promise((resolve) => {
      for (var i = 0; i < this.rowData.length; i++) {


        //nombre_usuario_asignado
        datosFormatados[i] = {
          id: this.rowData[i].ID_TIPO_CONTRATO,
          TIPO: this.rowData[i].TIPO,
          NOMBRE: this.rowData[i].NOMBRE,
          POTENCIA: this.rowData[i].POTENCIA,
          PRECIO_VENTA: this.rowData[i].PRECIO_VENTA
        };

        if (i == this.rowData.length - 1) resolve(this);
      }
    }).then(() => {
      this.dataset1 = datosFormatados;
    });
  }

  float2int(value) {
    return value | 0;
  }
}

interface interficieArticulosAsignados {
  id: number;
  nombre: string
  cantidad: number
}
