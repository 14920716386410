<h1>Usuarios</h1>
<!-- NUEVO USUARIO-->
<div class="row" style="padding-bottom: 20px;">
    
</div>
<!-- LISTADO USUARIOS-->
<div [hidden]="!this.usuarios || this.usuarios.length == 0">
    
    <div class="row">
        <div class="col-3">
            <div class="col-md-2 col-lg-2 col-xxxl-2 col-md-offset-8 ">
                <button mat-raised-button color="primary" (click)="abrirDialog()">
              <i class="fas fa-plus"></i>
              <span>  Nuevo usuario</span>
            </button>
            </div>
        </div>
        <div class="col-6"></div>
      
        <div class="col-3">
            <!-- <mat-form-field> -->
                <input matInput (keyup)="filtrar($event.target.value)" placeholder="Buscar">
            <!-- </mat-form-field> -->
        </div>
    </div>
    
    <table matSort mat-table [dataSource]="this.dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="CODIGO">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Código </th>
            <td mat-cell *matCellDef="let usuario"> {{usuario.ID_USUARIO}} </td>
        </ng-container>
        <ng-container matColumnDef="NOMBRE">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let usuario"> {{usuario.NOMBRE}} </td>
        </ng-container>
        
        <ng-container matColumnDef="ROL">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> ROL </th>
            <td mat-cell *matCellDef="let usuario"> 
                <p *ngIf="usuario.ROL == 0">Usuario limitado</p> 
                <p *ngIf="usuario.ROL == 1">Administrador</p>
            </td>
        </ng-container>
    
        
        <ng-container matColumnDef="editarUsuario">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="columnaIconos">Acciones</th>
            <td mat-cell *matCellDef="let element" class="columnaIconos">
                <button (click)="abrirDialog(true,element)" mat-mini-fab color="primary" class="float-center" matTooltip="Editar usuario">
                    <i class="fas fa-pencil-alt"></i>
                </button>              
                <span class="separadorIconos"></span>
                <button (click)="eliminarUsuario(element.ID_USUARIO)" mat-mini-fab color="warn" class="float-center" matTooltip="Eliminar usuario">
                <i class="fas fa-trash"></i>
                </button>

            </td>
        </ng-container>

        
        <!-- NO ENS CAL L'EXPANDED JA QUE TOTES LES DADES JA HI CABEN EN UNA SOLA LÍNIA
        <ng-container matColumnDef="expandedDetail">
            
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    
                    <div class="row" style="padding-top: 15px; padding-bottom: 15px; padding-left: 20px;">
                        <div class="col-md-3">
                            <h5>Usuario</h5>
                            <span><strong>Código:</strong> {{element.codigo}}</span><br>
                            <span><strong>Nombre:</strong> {{element.nombre}}</span><br>                            
                            <hr class="mostrarMobil">

                        </div>
                        
                    </div>

                </div>
            </td>
    
        </ng-container>
        -->
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row elementoSeleccionable" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
    
    </table>

    <!-- <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator> -->
    

</div>

