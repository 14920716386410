import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { MatDialog } from '@angular/material/dialog';
import { EdicionAlerta } from 'src/app/shared/alertas/edicion-alerta.component';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import { PPTOS_Alarmas } from '@shared/PPTOS.alarmas.entity';
import * as moment from 'moment';
import { interficieAlarmas } from 'src/app/services/shared/shared.service';
import { SharedService } from '../../services/shared/shared.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']

})
export class CalendarioComponent {

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    aspectRatio: 3,
    //dateClick: this.handleDateClick.bind(this), // bind is important!
    eventClick: () => { },
    events: [],
    locale: 'es',
    firstDay: 1,
    expandRows: true,
    windowResize: function () {
      //    alert('The calendar has adjusted to a window resize. Current view: ' + arg.view.type);
    },
    handleWindowResize: true,
    plugins: [dayGridPlugin]
  };

  paginaCargada = false;
  alarmas = Array<interficieLlaveValorCalendario>();

  constructor(
    public router: Router,
    public http: HttpService,
    public dddialog: MatDialog,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    public shared: SharedService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();

    this.inicializaAlarmasCalendario().then(
      () => {
        this.actualizaTabla()
      }
    )
    setInterval(this.actualizaTabla, 5000);
  }

  actualizaTabla() {
    this.calendarOptions = {
      ...this.calendarOptions,
      events: this.shared?.alarmasCalendario || [],
      eventClick: this.handleEventClick?.bind(this) || (() => { }),
    };
    this.cd?.detectChanges();
    this.spinner?.hide();
    this.paginaCargada = true;
  }

  async inicializaAlarmasCalendario() {
    this.shared.alarmasCalendario = [];
    return new Promise((resolve) => {
      this.http.obtenerTodasLasAlarmas().then(
        (result: PPTOS_Alarmas[]) => {
          result.forEach(element => {

            this.http.obtenerPresupuesto(element.ID_PPTO).then(
              (presupuestoObtenido) => {

                let l: interficieLlaveValorCalendario = {
                  title: "PPTO " + presupuestoObtenido["NUMERACION"] + " " + presupuestoObtenido["NOMBRE_APELLIDOS_CLIENTE"],
                  date: moment(element["SIGUIENTE_RECORDATORIO"]).add(1, "hour").format('YYYY-MM-DD'),
                  id_ppto: element.ID_PPTO
                }
                this.shared.alarmasCalendario.push(l);

                if (this.shared.alarmasCalendario.length == result.length)
                  resolve(this);
              }
            )
          });
        }
      )

    })
  }

  abrirDialogEdicionAlerta(_id_ppto) {

    var _alarma: interficieAlarmas = new interficieAlarmas();


    const dialogRef = this.dialog.open(EdicionAlerta, {
      width: "75%",
      data: {
        tituloModal: "Editar alerta",
        id_ppto: _id_ppto,
        alarma: _alarma
      },
    });


    dialogRef.afterClosed().subscribe((result) => {
       


      if (result) {


        var alarma = new PPTOS_Alarmas();
        alarma = result["alarma"].alarma;
        alarma.SIGUIENTE_RECORDATORIO = result["alarma"]["SIGUIENTE_RECORDATORIO"];
        this.http.actualizarAlarma(alarma).then(
          () => {
            this.inicializaAlarmasCalendario().then(
              () => {
                this.cd.detectChanges();
                this.calendarOptions = {
                  ...this.calendarOptions,
                  events: this.shared.alarmasCalendario,
                  eventClick: this.handleEventClick.bind(this),
                };
                this.inicializaNumeroAlarmas();
              }
            );
          }
        )
      }
      else {
        this.inicializaAlarmasCalendario().then(
          () => {
            this.cd.detectChanges();
            this.calendarOptions = {
              ...this.calendarOptions,
              events: this.shared.alarmasCalendario,
              eventClick: this.handleEventClick.bind(this),
            };
            this.inicializaNumeroAlarmas();

          }
        );
      }
    });
  }

  handleEventClick(info) {
    this.abrirDialogEdicionAlerta(info["event"]["extendedProps"]["id_ppto"])
  }

  handleDateClick() {
    //alert('date click! ');
  }

  inicializaNumeroAlarmas() {

    this.http.obtenerNumeroAlarmasDiarias(Date.now()).then(
      (result) => {
        this.shared.numeroAlarmas = result[0]["TOTAL"];
      }
    )


  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

interface interficieLlaveValorCalendario {
  title: string;
  date: string;
  id_ppto: number;
}