<div id="previsualizacion" [hidden]="!this.ocultarPDFGenerado">
  <table style="vertical-align: middle">
    <tr style="vertical-align: middle">
      <td>
        <button
          printTitle="{{ this.nombreDocumentoPDF }}"
          mat-fab
          color="primary"
          printSectionId="print-section"
          [useExistingCss]="true"
          mat-raised-button
          color="primary"
          ngxPrint
          style="z-index: 10000; top: 0"
        >
          <mat-icon>print</mat-icon>
        </button>
      </td>
    </tr>
    <tr style="vertical-align: middle">
      <td>
        <button
          mat-fab
          style="
            z-index: 10000;
            top: 10%;
            background-color: cadetblue;
            color: white;
            display: none;
          "
          (click)="this.ngOnInit(); this.ngAfterViewInit()"
        >
          <mat-icon>refresh</mat-icon>
        </button>
      </td>
    </tr>
    <tr style="vertical-align: middle">
      <td>
        <button
          mat-raised-button
          color="warn"
          (click)="this.guardarPPTO('none')"
          style="z-index: 9999"
        >
          Guardar
        </button>
      </td>
    </tr>
  </table>

  <h3>Previsualización datos generados</h3>
  <table>
    <tr>
      <td colspan="3">
        <p style="text-align: center">
          <b> Consumo estimado de la vivienda: </b>
        </p>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <table>
          <thead>
            <tr>
              <td>Mes</td>
              <td>CONSUMO</td>
              <td>PUNTA</td>
              <td>LLANO</td>
              <td>VALLE</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Enero</td>
              <td>
                <input [(ngModel)]="this.consumosEstimados.consumos[0]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosPunta[0]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosLlano[0]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosValle[0]"
                />
              </td>
            </tr>
            <tr>
              <td>Febrero</td>
              <td>
                <input [(ngModel)]="this.consumosEstimados.consumos[1]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosPunta[1]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosLlano[1]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosValle[1]"
                />
              </td>
            </tr>
            <tr>
              <td>Marzo</td>
              <td>
                <input [(ngModel)]="this.consumosEstimados.consumos[2]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosPunta[2]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosLlano[2]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosValle[2]"
                />
              </td>
            </tr>
            <tr>
              <td>Abril</td>
              <td>
                <input [(ngModel)]="this.consumosEstimados.consumos[3]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosPunta[3]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosLlano[3]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosValle[3]"
                />
              </td>
            </tr>
            <tr>
              <td>Mayo</td>
              <td>
                <input [(ngModel)]="this.consumosEstimados.consumos[4]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosPunta[4]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosLlano[4]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosValle[4]"
                />
              </td>
            </tr>
            <tr>
              <td>Junio</td>
              <td>
                <input [(ngModel)]="this.consumosEstimados.consumos[5]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosPunta[5]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosLlano[5]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosValle[5]"
                />
              </td>
            </tr>
            <tr>
              <td>Julio</td>
              <td>
                <input [(ngModel)]="this.consumosEstimados.consumos[6]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosPunta[6]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosLlano[6]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosValle[6]"
                />
              </td>
            </tr>
            <tr>
              <td>Agosto</td>
              <td>
                <input [(ngModel)]="this.consumosEstimados.consumos[7]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosPunta[7]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosLlano[7]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosValle[7]"
                />
              </td>
            </tr>
            <tr>
              <td>Septiembre</td>
              <td>
                <input [(ngModel)]="this.consumosEstimados.consumos[8]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosPunta[8]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosLlano[8]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosValle[8]"
                />
              </td>
            </tr>
            <tr>
              <td>Octubre</td>
              <td>
                <input [(ngModel)]="this.consumosEstimados.consumos[9]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosPunta[9]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosLlano[9]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosValle[9]"
                />
              </td>
            </tr>
            <tr>
              <td>Noviembre</td>
              <td>
                <input [(ngModel)]="this.consumosEstimados.consumos[10]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosPunta[10]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosLlano[10]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosValle[10]"
                />
              </td>
            </tr>
            <tr>
              <td>Diciembre</td>
              <td>
                <input [(ngModel)]="this.consumosEstimados.consumos[11]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosPunta[11]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosLlano[11]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.consumosEstimados.datosEstimadosValle[11]"
                />
              </td>
            </tr>
            <tr>
              <td><b> Consumo anual total (kWh) </b></td>
              <td>
                <b>
                  <input [(ngModel)]="this.consumoAnualTotal" />
                </b>
              </td>
              <td>
                <b>
                  <input
                    [(ngModel)]="
                      this.consumosEstimados.consumoAnualEstimadoPunta
                    "
                  />
                </b>
              </td>
              <td>
                <b>
                  <input
                    [(ngModel)]="
                      this.consumosEstimados.consumoAnualEstimadoLlano
                    "
                  />
                </b>
              </td>
              <td>
                <b>
                  <input
                    [(ngModel)]="
                      this.consumosEstimados.consumoAnualEstimadoValle
                    "
                  />
                </b>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <hr />
      </td>
    </tr>

    <tr>
      <td colspan="3">
        <p style="text-align: center"><b> Datos generados por PVGIS </b></p>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <table>
          <thead>
            <tr>
              <td><b>Enero</b></td>
              <td><b>Febrero</b></td>
              <td><b>Marzo</b></td>
              <td><b>Abril</b></td>
              <td><b>Mayo</b></td>
              <td><b>Junio</b></td>
              <td><b>Julio</b></td>
              <td><b>Agosto</b></td>
              <td><b>Sep.</b></td>
              <td><b>Octubre</b></td>
              <td><b>Nov.</b></td>
              <td><b>Dic.</b></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[0]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[1]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[2]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[3]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[4]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[5]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[6]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[7]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[8]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[9]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[10]"
                  style="width: 60px"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[11]"
                  style="width: 60px"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <br />
        <hr />
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <p style="text-align: center"><b> Cálculo de amortización </b></p>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <table>
          <thead>
            <tr>
              <td><b>Mes</b></td>
              <td><b>Generacion</b></td>
              <td><b>PUNTA</b></td>
              <td><b>LLANO</b></td>
              <td><b>VALLE</b></td>
            </tr>
            <tr>
              <td><b>Enero</b></td>
              <td>
                <input [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[0]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoPuntaSinDecimalesPVGIS[0]"
                />
              </td>
              <td>
                <input [(ngModel)]="arrFormatadoLlanoSinDecimalesPVGIS[0]" />
              </td>
              <td>
                <input [(ngModel)]="arrFormatadoValleSinDecimalesPVGIS[0]" />
              </td>
            </tr>
            <tr>
              <td><b>Febrero</b></td>
              <td>
                <input [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[1]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoPuntaSinDecimalesPVGIS[1]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoLlanoSinDecimalesPVGIS[1]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoValleSinDecimalesPVGIS[1]"
                />
              </td>
            </tr>
            <tr>
              <td><b>Marzo</b></td>
              <td>
                <input [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[2]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoPuntaSinDecimalesPVGIS[2]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoLlanoSinDecimalesPVGIS[2]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoValleSinDecimalesPVGIS[2]"
                />
              </td>
            </tr>
            <tr>
              <td><b>Abril</b></td>
              <td>
                <input [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[3]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoPuntaSinDecimalesPVGIS[3]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoLlanoSinDecimalesPVGIS[3]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoValleSinDecimalesPVGIS[3]"
                />
              </td>
            </tr>
            <tr>
              <td><b>Mayo</b></td>
              <td>
                <input [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[4]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoPuntaSinDecimalesPVGIS[4]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoLlanoSinDecimalesPVGIS[4]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoValleSinDecimalesPVGIS[4]"
                />
              </td>
            </tr>
            <tr>
              <td><b>Junio</b></td>
              <td>
                <input [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[5]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoPuntaSinDecimalesPVGIS[5]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoLlanoSinDecimalesPVGIS[5]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoValleSinDecimalesPVGIS[5]"
                />
              </td>
            </tr>
            <tr>
              <td><b>Julio</b></td>
              <td>
                <input [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[6]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoPuntaSinDecimalesPVGIS[6]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoLlanoSinDecimalesPVGIS[6]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoValleSinDecimalesPVGIS[6]"
                />
              </td>
            </tr>
            <tr>
              <td><b>Agosto</b></td>
              <td>
                <input [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[7]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoPuntaSinDecimalesPVGIS[7]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoLlanoSinDecimalesPVGIS[7]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoValleSinDecimalesPVGIS[7]"
                />
              </td>
            </tr>
            <tr>
              <td><b>Septiembre</b></td>
              <td>
                <input [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[8]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoPuntaSinDecimalesPVGIS[8]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoLlanoSinDecimalesPVGIS[8]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoValleSinDecimalesPVGIS[8]"
                />
              </td>
            </tr>
            <tr>
              <td><b>Octubre</b></td>
              <td>
                <input [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[9]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoPuntaSinDecimalesPVGIS[9]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoLlanoSinDecimalesPVGIS[9]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoValleSinDecimalesPVGIS[9]"
                />
              </td>
            </tr>
            <tr>
              <td><b>Noviembre</b></td>
              <td>
                <input [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[10]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoPuntaSinDecimalesPVGIS[10]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoLlanoSinDecimalesPVGIS[10]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoValleSinDecimalesPVGIS[10]"
                />
              </td>
            </tr>
            <tr>
              <td><b>Diciembre</b></td>
              <td>
                <input [(ngModel)]="this.arrFormatadoSinDecimalesPVGIS[11]" />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoPuntaSinDecimalesPVGIS[11]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoLlanoSinDecimalesPVGIS[11]"
                />
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrFormatadoValleSinDecimalesPVGIS[11]"
                />
              </td>
            </tr>
            <tr>
              <td><b>Generación anual total (kWh)</b></td>
              <td>
                <input [(ngModel)]="this.totalPVGIS" />
              </td>
              <td>
                <input [(ngModel)]="this.totalPuntaPVGIS" />
              </td>
              <td>
                <input [(ngModel)]="this.totalLlanoPVGIS" />
              </td>
              <td>
                <input [(ngModel)]="this.totalVallePVGIS" />
              </td>
            </tr>
          </thead>
        </table>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <br />
        <hr />
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <p style="text-align: center"><b> Financiación </b></p>
      </td>
    </tr>
    <tr>
      <td>
        <br />
        <p style="text-align: center">Instalación fotovoltaica con baterias</p>
        <!-- <div class="precio-baterias-row"> -->
        <!--   <p style="margin-right: 1rem;">Precio baterias</p> -->
        <!--   <input [(ngModel)]="this.importeAdicional" /> -->
        <!-- </div> -->
        <hr />
        <table width="100%">
          <tr>
            <td>
              <p><b>PLAZO</b></p>
            </td>
            <td colspan="2" style="text-align: center">
              <b>IMPORTE</b>
            </td>
          </tr>
          <tr>
            <td>60</td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador1DatosFinanciadosConBateria[1]
                "
              />€
            </td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador2DatosFinanciadosConBateria[1]
                "
              />€
            </td>
          </tr>
          <tr>
            <td>66</td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador1DatosFinanciadosConBateria[2]
                "
              />€
            </td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador2DatosFinanciadosConBateria[2]
                "
              />€
            </td>
          </tr>
          <tr>
            <td>72</td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador1DatosFinanciadosConBateria[3]
                "
              />€
            </td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador2DatosFinanciadosConBateria[3]
                "
              />€
            </td>
          </tr>
          <tr>
            <td>78</td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador1DatosFinanciadosConBateria[4]
                "
              />€
            </td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador2DatosFinanciadosConBateria[4]
                "
              />€
            </td>
          </tr>
          <tr>
            <td>84</td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador1DatosFinanciadosConBateria[5]
                "
              />€
            </td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador2DatosFinanciadosConBateria[5]
                "
              />€
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <b>
                <input
                  [(ngModel)]="
                    this.valoresMultiplicador1DatosFinanciadosConBateria[0]
                  "
                />€
              </b>
            </td>
            <td>
              <b>
                <input
                  [(ngModel)]="
                    this.valoresMultiplicador2DatosFinanciadosConBateria[0]
                  "
                />€
              </b>
            </td>
          </tr>
        </table>
      </td>
      <td></td>
      <td>
        <br />
        <p style="text-align: center">Instalación fotovoltaica</p>
        <br />
        <br />
        <hr />
        <table width="100%">
          <tr>
            <td>
              <p><b>PLAZO</b></p>
            </td>
            <td colspan="2" style="text-align: center">
              <b>IMPORTE</b>
            </td>
          </tr>
          <tr>
            <td>60</td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador1DatosFinanciadosSinBateria[1]
                "
              />€
            </td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador2DatosFinanciadosSinBateria[1]
                "
              />€
            </td>
          </tr>
          <tr>
            <td>66</td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador1DatosFinanciadosSinBateria[2]
                "
              />€
            </td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador2DatosFinanciadosSinBateria[2]
                "
              />€
            </td>
          </tr>
          <tr>
            <td>72</td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador1DatosFinanciadosSinBateria[3]
                "
              />€
            </td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador2DatosFinanciadosSinBateria[3]
                "
              />€
            </td>
          </tr>
          <tr>
            <td>78</td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador1DatosFinanciadosSinBateria[4]
                "
              />€
            </td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador2DatosFinanciadosSinBateria[4]
                "
              />€
            </td>
          </tr>
          <tr>
            <td>84</td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador1DatosFinanciadosSinBateria[5]
                "
              />€
            </td>
            <td>
              <input
                [(ngModel)]="
                  this.valoresMultiplicador2DatosFinanciadosSinBateria[5]
                "
              />€
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <b>
                <input
                  [(ngModel)]="
                    this.valoresMultiplicador1DatosFinanciadosSinBateria[0]
                  "
                />€
              </b>
            </td>
            <td>
              <b>
                <input
                  [(ngModel)]="
                    this.valoresMultiplicador2DatosFinanciadosSinBateria[0]
                  "
                />€
              </b>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr style="display: none">
      <td colspan="3">
        <br />
        <hr />
        <p style="text-align: center"><b> % Ahorrado </b></p>
      </td>
    </tr>
    <tr style="display: none">
      <td>
        <b> TARIFA PUNTA </b>
      </td>
      <td>
        <b> TARIFA LLANO </b>
      </td>
      <td>
        <b> TARIFA VALLE </b>
      </td>
    </tr>
    <tr style="display: none">
      <td>
        <input [(ngModel)]="this.totalPorcentajeAhorroPunta" />
      </td>
      <td>
        <input [(ngModel)]="this.totalPorcentajeAhorroLlano" />
      </td>
      <td>
        <input [(ngModel)]="this.totalPorcentajeAhorroValle" />
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <br />
        <hr />
        <p style="text-align: center">
          <b> Repercusión económica </b>
        </p>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <!-- aqui -->
        <table>
          <thead>
            <tr>
              <td>
                <b>Mes</b>
              </td>
              <td>
                <b>TOTAL</b>
              </td>
              <td>
                <b>PUNTA</b>
              </td>
              <td>
                <b>LLANO</b>
              </td>
              <td>
                <b>VALLE</b>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>Enero</b>
              </td>
              <td>
                <input [(ngModel)]="this.arrTotalRepercusionesEconomicas[0]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasPuntas[0]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasLlanos[0]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasValles[0]" />
                €
              </td>
            </tr>
            <tr>
              <td>
                <b>Febrero</b>
              </td>
              <td>
                <input [(ngModel)]="this.arrTotalRepercusionesEconomicas[1]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasPuntas[1]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasLlanos[1]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasValles[1]" />
                €
              </td>
            </tr>
            <tr>
              <td>
                <b>Marzo</b>
              </td>
              <td>
                <input [(ngModel)]="this.arrTotalRepercusionesEconomicas[2]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasPuntas[2]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasLlanos[2]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasValles[2]" />
                €
              </td>
            </tr>
            <tr>
              <td>
                <b>Abril</b>
              </td>
              <td>
                <input [(ngModel)]="this.arrTotalRepercusionesEconomicas[3]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasPuntas[3]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasLlanos[3]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasValles[3]" />
                €
              </td>
            </tr>
            <tr>
              <td>
                <b>Mayo</b>
              </td>
              <td>
                <input [(ngModel)]="this.arrTotalRepercusionesEconomicas[4]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasPuntas[4]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasLlanos[4]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasValles[4]" />
                €
              </td>
            </tr>
            <tr>
              <td>
                <b>Junio</b>
              </td>
              <td>
                <input [(ngModel)]="this.arrTotalRepercusionesEconomicas[5]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasPuntas[5]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasLlanos[5]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasValles[5]" />
                €
              </td>
            </tr>
            <tr>
              <td>
                <b>Julio</b>
              </td>
              <td>
                <input [(ngModel)]="this.arrTotalRepercusionesEconomicas[6]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasPuntas[6]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasLlanos[6]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasValles[6]" />
                €
              </td>
            </tr>
            <tr>
              <td>
                <b>Agosto</b>
              </td>
              <td>
                <input [(ngModel)]="this.arrTotalRepercusionesEconomicas[7]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasPuntas[7]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasLlanos[7]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasValles[7]" />
                €
              </td>
            </tr>
            <tr>
              <td>
                <b>Septiembre</b>
              </td>
              <td>
                <input [(ngModel)]="this.arrTotalRepercusionesEconomicas[8]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasPuntas[8]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasLlanos[8]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasValles[8]" />
                €
              </td>
            </tr>
            <tr>
              <td>
                <b>Octubre</b>
              </td>
              <td>
                <input [(ngModel)]="this.arrTotalRepercusionesEconomicas[9]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasPuntas[9]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasLlanos[9]" />
                €
              </td>
              <td>
                <input [(ngModel)]="this.arrRepercusionesEconomicasValles[9]" />
                €
              </td>
            </tr>
            <tr>
              <td>
                <b>Noviembre</b>
              </td>
              <td>
                <input [(ngModel)]="this.arrTotalRepercusionesEconomicas[10]" />
                €
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrRepercusionesEconomicasPuntas[10]"
                />
                €
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrRepercusionesEconomicasLlanos[10]"
                />
                €
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrRepercusionesEconomicasValles[10]"
                />
                €
              </td>
            </tr>
            <tr>
              <td>
                <b>Diciembre</b>
              </td>
              <td>
                <input [(ngModel)]="this.arrTotalRepercusionesEconomicas[11]" />
                €
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrRepercusionesEconomicasPuntas[11]"
                />
                €
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrRepercusionesEconomicasLlanos[11]"
                />
                €
              </td>
              <td>
                <input
                  [(ngModel)]="this.arrRepercusionesEconomicasValles[11]"
                />
                €
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <b>AHORRO</b>
              </td>
              <td>
                <b>
                  <input [(ngModel)]="this.totalRepercusionesEconomicas" /> €
                </b>
              </td>
              <td>
                <b>
                  <input
                    [(ngModel)]="this.totalRepercusionesEconomicasPuntas"
                  />
                  €
                </b>
              </td>
              <td>
                <b>
                  <input
                    [(ngModel)]="this.totalRepercusionesEconomicasLlanos"
                  />
                  €
                </b>
              </td>
              <td>
                <b>
                  <input
                    [(ngModel)]="this.totalRepercusionesEconomicasValles"
                  />
                  €
                </b>
              </td>
            </tr>
          </tfoot>
        </table>
        <!-- fins aqui -->
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <br />
        <hr />
        <p style="text-align: center">
          <b> Trabajos a realizar </b>
        </p>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <!-- <ckeditor data="this.model.editorData" [editor]="Editor"></ckeditor>-->
        <ckeditor
          [(ngModel)]="this.trabajosARealizar"
          (change)="onChangeTrabajosARealizar($event)"
        ></ckeditor>
      </td>
    </tr>
  </table>

  <div *ngIf="this.accion == 'PDF'">
    <button
      #print
      printTitle="report"
      printSectionId="print-section"
      ngxPrint
      hidden="true"
      [useExistingCss]="true"
    ></button>
  </div>

  <!--
    <div (click)="test();">

    </div>
    -->
</div>

<div id="print-section" [hidden]="this.ocultarPDFGenerado">
  <div *ngIf="this.tipoPPTO == 'D'">
    <table>
      <tr>
        <td colspan="3">
          <img
            src="../../../assets/images/PPTO_2/portada_domestico.png"
            style="z-index: 999; position: relative; width: 100%; top: 0px"
          />
        </td>
      </tr>
      <tr>
        <td style="text-align: left"></td>
        <td></td>
        <td>
          <div
            style="top: 1330px; left: 150px; position: absolute; z-index: 99999"
          >
            <h2>PRESUPUESTO: Nº {{ this.numPresupuesto }}</h2>
            <h3>EQUIPO {{ this.potenciaKit }} KW</h3>
            <h4>
              {{ this.fechaPpto }} <br />
              {{ this.direccion }}, {{ this.poblacion }} <br />
              {{ this.nombreCliente | uppercase }}
            </h4>
          </div>
        </td>
      </tr>
      <!-- <tr>
                <td colspan="3">
                    <img src="../../../assets/images/PPTO/3.PNG" style="position:relative;z-index:3;width:100%"/>
                </td>
            </tr>
            -->
    </table>

    <!--<img src="../../../assets/images/coverInforme.png" class="membrete">-->
    <div class="page-header" style="text-align: left">
      <img
        src="../../../assets/images/membrete_tecnogeca.jpg"
        class="membrete"
        style="
          width: 900px;
          height: 125px;
          margin-left: 100px;
          margin-top: 100px;
          margin-bottom: 100px;
          position: relative;
          z-index: -9999;
        "
      />
    </div>

    <!--
        <div class="page-footer">
            <img src="../../../assets/images/Membrete_footer.JPG" class="membrete" style="position:relative;z-index:1;">
        <br>
        </div>
        -->
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 4px"
              src="../../../assets/images/PPTO_2/index_domestico.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/presentacion_domestico.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/resumen_domestico.png"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              style="
                top: 7990px;
                left: 660px;
                position: absolute;
                z-index: 99999;
              "
            >
              <!-- top:7888px;left:598px -->
              <h1
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 51px;
                "
              >
                <b>{{ this.numeroPanelesSegunKit }}</b>
              </h1>
            </div>
            <div
              style="
                top: 7985px;
                left: 828px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 57px;
                "
              >
                <b>{{ this.potenciaKit }} KW</b>
              </h1>
            </div>
            <div
              style="
                top: 7988px;
                left: 1094px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 51px;
                "
              >
                <b>{{ this.nombreCortoMarca }}</b>
              </h1>
            </div>
            <div
              style="
                top: 8327px;
                left: 518px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #ffe17e;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 69px;
                "
              >
                <b>{{ this.precioFinalParaVisualizacion }} €</b>
              </h1>
              <!-- total presupuesto-->
            </div>
            <div
              style="
                top: 8397px;
                left: 560px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #ffe17e;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 48px;
                "
              >
                <b>+ IVA</b>
              </h1>
            </div>
            <div
              style="
                top: 8327px;
                left: 1075px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #ffe17e;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 69px;
                "
              >
                <b>
                  {{ this.cuotaFinanciada84Formatado }}
                </b>
              </h1>
              <!-- cuota financiada-->
            </div>
            <div
              style="
                top: 8395px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #ffe17e;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 48px;
                "
              >
                <b>€/mes</b>
              </h1>
            </div>
            <div
              style="
                top: 8647px;
                left: 525px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 47px;
                "
              >
                <b>{{ this.totalRepercusionesEconomicasFormatado }} €</b>
              </h1>
              <!-- ahorro anual -->
            </div>
            <div
              style="
                top: 8647px;
                left: 1098px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 47px;
                "
              >
                <b>{{ this.totalRepercusiones25a }} €</b>
              </h1>
              <!-- ahorro 25 años -->
            </div>
            <div style="top: 8981px; left: 610px; position: absolute">
              <h1
                style="
                  color: #ffffff;
                  font-family: 'Helvetica Neue LT Std';

                  font-size: 63px;
                "
              >
                <b>{{ this.totalPVGISFormatado }}</b>
              </h1>
              <!-- producción total anual -->
            </div>
            <div
              style="
                top: 9043px;
                left: 650px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #ffffff;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 50px;
                "
              >
                <b>kWh</b>
              </h1>
            </div>
            <div
              style="
                top: 9008px;
                left: 999px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h1
                style="
                  color: #ffffff;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 62px;
                "
              >
                <b>{{ this.porcentajeSobreConsumoEstimado }} %</b>
              </h1>
              <!-- porcentaje sobre su consumo -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/decision_domestico.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <div
              style="
                top: 12650px;
                left: 800px;
                position: absolute;
                z-index: 99999;
              "
            >
              <!-- top 12614 left 750 -->
              <h3>{{ this.direccion }}</h3>
            </div>
            <div
              style="
                top: 12699px;
                left: 800px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.poblacion }}</h3>
            </div>
            <div
              style="
                top: 12988px;
                left: 1060px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.tipoContrato }}</h3>
            </div>
            <div
              style="
                top: 13034px;
                left: 1060px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.consumoAnualTotalFormatado }}</h3>
            </div>
            <div
              class="card-container"
              style="
                top: 13431px;
                left: 202px;
                position: absolute;
                z-index: 99999;
              "
              *ngIf="this.tipoPPTO == 'D'"
            >
              <img
                src="{{ this.imagenGoogleMaps }}"
                alt="Selected Image"
                height="400"
                width="570"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div *ngIf="this.kitSeleccionadoTieneSolarBlock">
              <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
                style="margin-top: 3px"
                src="../../../assets/images/PPTO_2/ubicacion_domestico.png"
              />
            </div>
            <div *ngIf="!this.kitSeleccionadoTieneSolarBlock">
              <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
                style="margin-top: 3px"
                src="../../../assets/images/PPTO_2/ubicacion_domestico_sin_solarblock.png"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/consumos_estimados_domestico.png"
            />
          </td>
        </tr>
        <tr>
          <td style="text-align: left"></td>
          <td></td>
          <td>
            <div
              style="
                top: 15715px;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <!-- top 15681 left 635-->
              <h4>{{ this.consumosEstimados.consumos[0] }}</h4>
            </div>
            <div
              style="
                top: 15715px;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosPunta[0] }}</h4>
            </div>
            <div
              style="
                top: 15715px;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosLlano[0] }}</h4>
            </div>
            <div
              style="
                top: 15715px;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosValle[0] }}</h4>
            </div>
            <div
              style="
                top: 15758px;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.consumos[1] }}</h4>
            </div>
            <div
              style="
                top: 15758px;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosPunta[1] }}</h4>
            </div>
            <div
              style="
                top: 15758px;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosLlano[1] }}</h4>
            </div>
            <div
              style="
                top: 15758px;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosValle[1] }}</h4>
            </div>

            <div
              style="
                top: 15799px;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.consumos[2] }}</h4>
            </div>
            <div
              style="
                top: 15799px;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosPunta[2] }}</h4>
            </div>
            <div
              style="
                top: 15799px;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosLlano[2] }}</h4>
            </div>
            <div
              style="
                top: 15799px;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosValle[2] }}</h4>
            </div>
            <div
              style="
                top: 15839px;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.consumos[3] }}</h4>
            </div>
            <div
              style="
                top: 15839px;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosPunta[3] }}</h4>
            </div>
            <div
              style="
                top: 15839px;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosLlano[3] }}</h4>
            </div>
            <div
              style="
                top: 15839px;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosValle[3] }}</h4>
            </div>
            <div
              style="
                top: 15879;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.consumos[4] }}</h4>
            </div>
            <div
              style="
                top: 15879;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosPunta[4] }}</h4>
            </div>
            <div
              style="
                top: 15879;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosLlano[4] }}</h4>
            </div>
            <div
              style="
                top: 15879;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosValle[4] }}</h4>
            </div>
            <div
              style="
                top: 15918;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.consumos[5] }}</h4>
            </div>
            <div
              style="
                top: 15918;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosPunta[5] }}</h4>
            </div>
            <div
              style="
                top: 15918;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosLlano[5] }}</h4>
            </div>
            <div
              style="
                top: 15918;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosValle[5] }}</h4>
            </div>
            <div
              style="
                top: 15961;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.consumos[6] }}</h4>
            </div>
            <div
              style="
                top: 15961;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosPunta[6] }}</h4>
            </div>
            <div
              style="
                top: 15961;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosLlano[6] }}</h4>
            </div>
            <div
              style="
                top: 15961;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosValle[6] }}</h4>
            </div>
            <div
              style="
                top: 16002;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.consumos[7] }}</h4>
            </div>
            <div
              style="
                top: 16002;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosPunta[7] }}</h4>
            </div>
            <div
              style="
                top: 16002;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosLlano[7] }}</h4>
            </div>
            <div
              style="
                top: 16002;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosValle[7] }}</h4>
            </div>
            <div
              style="
                top: 16041;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.consumos[8] }}</h4>
            </div>
            <div
              style="
                top: 16041;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosPunta[8] }}</h4>
            </div>
            <div
              style="
                top: 16041;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosLlano[8] }}</h4>
            </div>
            <div
              style="
                top: 16041;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosValle[8] }}</h4>
            </div>
            <div
              style="
                top: 16076;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.consumos[9] }}</h4>
            </div>
            <div
              style="
                top: 16076;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosPunta[9] }}</h4>
            </div>
            <div
              style="
                top: 16076;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosLlano[9] }}</h4>
            </div>
            <div
              style="
                top: 16076;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosValle[9] }}</h4>
            </div>

            <div
              style="
                top: 16117px;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.consumos[10] }}</h4>
            </div>
            <div
              style="
                top: 16117px;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosPunta[10] }}</h4>
            </div>
            <div
              style="
                top: 16117px;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosLlano[10] }}</h4>
            </div>
            <div
              style="
                top: 16117px;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosValle[10] }}</h4>
            </div>
            <div
              style="
                top: 16158px;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.consumos[11] }}</h4>
            </div>
            <div
              style="
                top: 16158px;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosPunta[11] }}</h4>
            </div>
            <div
              style="
                top: 16158px;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosLlano[11] }}</h4>
            </div>
            <div
              style="
                top: 16158px;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.consumosEstimados.datosEstimadosValle[11] }}</h4>
            </div>
            <div
              style="
                top: 16210px;
                left: 690px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b> {{ this.consumoAnualTotal }} </b>
              </h4>
            </div>
            <div
              style="
                top: 16210;
                left: 895px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b> {{ this.consumosEstimados.consumoAnualEstimadoPunta }} </b>
              </h4>
            </div>
            <div
              style="
                top: 16210px;
                left: 1097px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b> {{ this.consumosEstimados.consumoAnualEstimadoLlano }} </b>
              </h4>
            </div>
            <div
              style="
                top: 16210;
                left: 1320px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>
                <b> {{ this.consumosEstimados.consumoAnualEstimadoValle }} </b>
              </h4>
            </div>

            <div
              style="
                top: 15477px;
                left: 192px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 31px;
                "
              >
                <b>{{ this.consumoAnualTotalFormatado }} kWh</b>
              </h4>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/descripcion_instalacion_domestico.png"
            />
          </td>
        </tr>
        <tr>
          <td style="text-align: left"></td>
          <td></td>
          <td>
            <div
              style="
                top: 17300px;
                left: 970px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.potenciaKit }} KW</h4>
            </div>

            <div
              style="
                top: 17336px;
                left: 665px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.numeroPanelesSegunKit }}</h4>
            </div>
            <div
              style="
                top: 17336px;
                left: 1215px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.nombreCortoMarca }}</h4>
            </div>

            <div
              style="
                top: 17380px;
                left: 650px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.potenciaPanelKit }}</h4>
            </div>
            <div
              style="
                top: 17380px;
                left: 1215px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.tipoPanelKit }}</h4>
            </div>
            <div
              style="
                top: 17425px;
                left: 650px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.potenciaInversor }}</h4>
            </div>
            <div
              style="
                top: 17425px;
                left: 1215px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.modeloInversor }}</h4>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              style="
                top: 17700px;
                left: 300px;
                position: absolute;
                z-index: 99999;
              "
            >
              <!-- 40 -->
              <echarts [initOpts]="initOpts" [options]="options"></echarts>
            </div>
            <div
              style="
                top: 18559px;
                left: 235px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[0] }}</h4>
            </div>
            <div
              style="
                top: 18559px;
                left: 350px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[1] }}</h4>
            </div>
            <div
              style="
                top: 18559px;
                left: 465px;
                position: absolute;
                z-index: 99999;
              "
            >
              <!-- 20 -->
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[2] }}</h4>
            </div>
            <div
              style="
                top: 18559px;
                left: 580px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[3] }}</h4>
            </div>
            <div
              style="
                top: 18559px;
                left: 700px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[4] }}</h4>
            </div>
            <div
              style="
                top: 18559px;
                left: 820px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[5] }}</h4>
            </div>
            <div
              style="
                top: 18559px;
                left: 935px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[6] }}</h4>
            </div>
            <div
              style="
                top: 18559px;
                left: 1045px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[7] }}</h4>
            </div>
            <div
              style="
                top: 18559px;
                left: 1166px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[8] }}</h4>
            </div>
            <div
              style="
                top: 18559px;
                left: 1285px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[9] }}</h4>
            </div>
            <div
              style="
                top: 18559px;
                left: 1400px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[10] }}</h4>
            </div>
            <div
              style="
                top: 18559px;
                left: 1515px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4>{{ this.arrFormatadoSinDecimalesPVGIS[11] }}</h4>
            </div>
            <div
              style="
                top: 18807px;
                left: 470px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2>{{ this.totalPVGIS }} kWh</h2>
            </div>
            <div
              style="
                top: 18807px;
                left: 1245px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2>{{ this.porcentajeSobreConsumoEstimado }} %</h2>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/materiales_domestico.png"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              style="
                top: 19840px;
                left: 185px;
                position: absolute;
                z-index: 99999;
                margin-right: 700px;
              "
            >
              <p
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-size: 31px;
                  text-align: justify;
                "
              >
                Módulo solar <b>Monocristalino con tecnología PERC </b>de marca
                <b>{{ this.marcaKit.NOMBRE }}</b> con una potencia de
                {{ this.panelKit.POTENCIA }}.<br />Reconocimiento
                <b>Tier 1,</b> que solo se concede a los fabricantes pioneros a
                nivel mundial, especializados en paneles de alta calidad.
                Seleccionamos este módulo dado su robustez, garantías y servicio
                postventa.
              </p>

              <div
                style="
                  top: -150px;
                  left: 940px;
                  position: absolute;
                  z-index: 99999;
                "
              >
                <img
                  height="500"
                  width="550"
                  src="{{ this.rutaImagenPanelKit }}"
                />
              </div>
            </div>

            <div
              style="
                top: 20430px;
                left: 185px;
                position: absolute;
                z-index: 99999;
                margin-right: 700px;
              "
            >
              <p
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-size: 31px;
                  text-align: justify;
                "
              >
                Huawei presenta un inversor robusto, fiable, galardonado con el
                premio al mejor equipo solar en el año 2023 repitiendo ese galardón en 2024.
                Destaca por tu elevado rendimiento, su
                capacidad para incorporar baterias
                y su versatilidad de udoen todos los casos.
              </p>

              <div
                style="
                  top: -180px;
                  left: 940px;
                  position: absolute;
                  z-index: 99999;
                "
              >
                <img
                  height="400"
                  width="450"
                  src="{{ this.rutaImagenInversorKit }}"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/control_energia_domestico.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page" *ngIf="this.tipoPPTO == 'D'">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/amortizacion_domestico.png"
            />
          </td>
        </tr>
        <tr>
          <!--
                        amplada: 192
                        alçada 41
                    -->
          <td colspan="3">
            <!-- 22012, 640-->
            <div
              style="
                top: 24484px;
                left: 685px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoSinDecimalesPVGIS[0] }}</h3>
            </div>
            <div
              style="
                top: 24484px;
                left: 897px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoPuntaSinDecimalesPVGIS[0] }}</h3>
            </div>
            <div
              style="
                top: 24484px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoLlanoSinDecimalesPVGIS[0] }}</h3>
            </div>
            <div
              style="
                top: 24484px;
                left: 1311px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoValleSinDecimalesPVGIS[0] }}</h3>
            </div>
            <div
              style="
                top: 24525px;
                left: 685px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoSinDecimalesPVGIS[1] }}</h3>
            </div>
            <div
              style="
                top: 24525px;
                left: 897px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoPuntaSinDecimalesPVGIS[1] }}</h3>
            </div>
            <div
              style="
                top: 24525px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoLlanoSinDecimalesPVGIS[1] }}</h3>
            </div>
            <div
              style="
                top: 24525px;
                left: 1311px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoValleSinDecimalesPVGIS[1] }}</h3>
            </div>
            <div
              style="
                top: 24566px;
                left: 685px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoSinDecimalesPVGIS[2] }}</h3>
            </div>
            <div
              style="
                top: 24566px;
                left: 897px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoPuntaSinDecimalesPVGIS[2] }}</h3>
            </div>
            <div
              style="
                top: 24566px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoLlanoSinDecimalesPVGIS[2] }}</h3>
            </div>
            <div
              style="
                top: 24566px;
                left: 1311px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoValleSinDecimalesPVGIS[2] }}</h3>
            </div>
            <div
              style="
                top: 24607px;
                left: 685px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoSinDecimalesPVGIS[3] }}</h3>
            </div>
            <div
              style="
                top: 24607px;
                left: 897px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoPuntaSinDecimalesPVGIS[3] }}</h3>
            </div>
            <div
              style="
                top: 24607px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoLlanoSinDecimalesPVGIS[3] }}</h3>
            </div>
            <div
              style="
                top: 24607px;
                left: 1311px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoValleSinDecimalesPVGIS[3] }}</h3>
            </div>
            <div
              style="
                top: 24646px;
                left: 685px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoSinDecimalesPVGIS[4] }}</h3>
            </div>
            <div
              style="
                top: 24646px;
                left: 897px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoPuntaSinDecimalesPVGIS[4] }}</h3>
            </div>
            <div
              style="
                top: 24646px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoLlanoSinDecimalesPVGIS[4] }}</h3>
            </div>
            <div
              style="
                top: 24646px;
                left: 1311px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoValleSinDecimalesPVGIS[4] }}</h3>
            </div>
            <div
              style="
                top: 24683px;
                left: 685px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoSinDecimalesPVGIS[5] }}</h3>
            </div>
            <div
              style="
                top: 24683px;
                left: 897px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoPuntaSinDecimalesPVGIS[5] }}</h3>
            </div>
            <div
              style="
                top: 24683px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoLlanoSinDecimalesPVGIS[5] }}</h3>
            </div>
            <div
              style="
                top: 24683px;
                left: 1311px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoValleSinDecimalesPVGIS[5] }}</h3>
            </div>

            <div
              style="
                top: 24723px;
                left: 685px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoSinDecimalesPVGIS[6] }}</h3>
            </div>
            <div
              style="
                top: 24723px;
                left: 897px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoPuntaSinDecimalesPVGIS[6] }}</h3>
            </div>
            <div
              style="
                top: 24723px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoLlanoSinDecimalesPVGIS[6] }}</h3>
            </div>
            <div
              style="
                top: 24723px;
                left: 1311px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoValleSinDecimalesPVGIS[6] }}</h3>
            </div>
            <div
              style="
                top: 24764px;
                left: 685px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoSinDecimalesPVGIS[7] }}</h3>
            </div>
            <div
              style="
                top: 24764px;
                left: 897px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoPuntaSinDecimalesPVGIS[7] }}</h3>
            </div>
            <div
              style="
                top: 24764px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoLlanoSinDecimalesPVGIS[7] }}</h3>
            </div>
            <div
              style="
                top: 24764px;
                left: 1311px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoValleSinDecimalesPVGIS[7] }}</h3>
            </div>
            <div
              style="
                top: 24803px;
                left: 685px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoSinDecimalesPVGIS[8] }}</h3>
            </div>
            <div
              style="
                top: 24803px;
                left: 897px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoPuntaSinDecimalesPVGIS[8] }}</h3>
            </div>
            <div
              style="
                top: 24803px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoLlanoSinDecimalesPVGIS[8] }}</h3>
            </div>
            <div
              style="
                top: 24803px;
                left: 1311px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoValleSinDecimalesPVGIS[8] }}</h3>
            </div>
            <div
              style="
                top: 24844px;
                left: 685px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoSinDecimalesPVGIS[9] }}</h3>
            </div>
            <div
              style="
                top: 24844px;
                left: 897px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoPuntaSinDecimalesPVGIS[9] }}</h3>
            </div>
            <div
              style="
                top: 24844px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoLlanoSinDecimalesPVGIS[9] }}</h3>
            </div>
            <div
              style="
                top: 24844px;
                left: 1311px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoValleSinDecimalesPVGIS[9] }}</h3>
            </div>
            <div
              style="
                top: 24883px;
                left: 685px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoSinDecimalesPVGIS[10] }}</h3>
            </div>
            <div
              style="
                top: 24883px;
                left: 897px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoPuntaSinDecimalesPVGIS[10] }}</h3>
            </div>
            <div
              style="
                top: 24883px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoLlanoSinDecimalesPVGIS[10] }}</h3>
            </div>
            <div
              style="
                top: 24883px;
                left: 1311px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoValleSinDecimalesPVGIS[10] }}</h3>
            </div>
            <div
              style="
                top: 24926px;
                left: 685px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoSinDecimalesPVGIS[11] }}</h3>
            </div>
            <div
              style="
                top: 24926px;
                left: 897px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoPuntaSinDecimalesPVGIS[11] }}</h3>
            </div>
            <div
              style="
                top: 24926px;
                left: 1099px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoLlanoSinDecimalesPVGIS[11] }}</h3>
            </div>
            <div
              style="
                top: 24926px;
                left: 1311px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrFormatadoValleSinDecimalesPVGIS[11] }}</h3>
            </div>
            <div
              style="
                top: 24969px;
                left: 680px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2>{{ this.totalPVGIS }}</h2>
            </div>
            <div
              style="
                top: 24969px;
                left: 892px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2>{{ this.totalPuntaPVGIS }}</h2>
            </div>
            <div
              style="
                top: 24969px;
                left: 1089px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2>{{ this.totalLlanoPVGIS }}</h2>
            </div>
            <div
              style="
                top: 24969px;
                left: 1300px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2>{{ this.totalVallePVGIS }}</h2>
            </div>
            <div
              style="
                top: 25060;
                left: 150px;
                position: absolute;
                z-index: 99999;
              "
            >
              <div #chartPunta id="chartPunta">
                <apx-chart
                  [series]="chartOptionsComparacionPunta?.series"
                  [chart]="chartOptionsComparacionPunta?.chart"
                  [xaxis]="chartOptionsComparacionPunta?.xaxis"
                  [dataLabels]="chartOptionsComparacionPunta?.dataLabels"
                  [yaxis]="chartOptionsComparacionPunta?.yaxis"
                  [colors]="chartOptionsComparacionPunta?.colors"
                  [legend]="chartOptionsComparacionPunta?.legend"
                  [fill]="chartOptionsComparacionPunta?.fill"
                ></apx-chart>
              </div>
            </div>

            <div
              style="
                top: 25060;
                left: 645px;
                position: absolute;
                z-index: 99999;
              "
            >
              <div #chartLlano id="chartLlano">
                <apx-chart
                  [series]="chartOptionsComparacionLlano?.series"
                  [chart]="chartOptionsComparacionLlano?.chart"
                  [xaxis]="chartOptionsComparacionLlano?.xaxis"
                  [dataLabels]="chartOptionsComparacionLlano?.dataLabels"
                  [yaxis]="chartOptionsComparacionLlano?.yaxis"
                  [colors]="chartOptionsComparacionLlano?.colors"
                  [legend]="chartOptionsComparacionLlano?.legend"
                  [fill]="chartOptionsComparacionLlano?.fill"
                ></apx-chart>
              </div>
            </div>

            <div
              style="
                top: 25060;
                left: 1130px;
                position: absolute;
                z-index: 99999;
              "
            >
              <div #chartValle id="chartValle">
                <apx-chart
                  [series]="chartOptionsComparacionValle?.series"
                  [chart]="chartOptionsComparacionValle?.chart"
                  [xaxis]="chartOptionsComparacionValle?.xaxis"
                  [dataLabels]="chartOptionsComparacionValle?.dataLabels"
                  [yaxis]="chartOptionsComparacionValle?.yaxis"
                  [colors]="chartOptionsComparacionValle?.colors"
                  [legend]="chartOptionsComparacionValle?.legend"
                  [fill]="chartOptionsComparacionValle?.fill"
                ></apx-chart>
              </div>
            </div>

            <!-- % ahorro -->
            <div
              style="
                top: 25328px;
                left: 528px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.totalPorcentajeAhorroPunta }}%</h3>
            </div>
            <div
              style="
                top: 25328px;
                left: 1018px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.totalPorcentajeAhorroLlano }}%</h3>
            </div>
            <div
              style="
                top: 25328px;
                left: 1503px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.totalPorcentajeAhorroValle }}%</h3>
            </div>
            <!-- repercusion economica -->
            <!-- 23027, 620 -->
            <div
              style="
                top: 25516px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrTotalRepercusionesEconomicas[0] }} €</h3>
            </div>
            <div
              style="
                top: 25516px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasPuntas[0] }} €</h3>
            </div>
            <div
              style="
                top: 25516px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasLlanos[0] }} €</h3>
            </div>
            <div
              style="
                top: 25516px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasValles[0] }} €</h3>
            </div>
            <div
              style="
                top: 25558px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrTotalRepercusionesEconomicas[1] }} €</h3>
            </div>
            <div
              style="
                top: 25558px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasPuntas[1] }} €</h3>
            </div>
            <div
              style="
                top: 25558px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasLlanos[1] }} €</h3>
            </div>
            <div
              style="
                top: 25558px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasValles[1] }} €</h3>
            </div>
            <div
              style="
                top: 25598px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrTotalRepercusionesEconomicas[2] }} €</h3>
            </div>
            <div
              style="
                top: 25598px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasPuntas[2] }} €</h3>
            </div>
            <div
              style="
                top: 25598px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasLlanos[2] }} €</h3>
            </div>
            <div
              style="
                top: 25598px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasValles[2] }} €</h3>
            </div>
            <div
              style="
                top: 25638px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrTotalRepercusionesEconomicas[3] }} €</h3>
            </div>
            <div
              style="
                top: 25638px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasPuntas[3] }} €</h3>
            </div>
            <div
              style="
                top: 25638px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasLlanos[3] }} €</h3>
            </div>
            <div
              style="
                top: 25638px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasValles[3] }} €</h3>
            </div>
            <div
              style="
                top: 25679px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrTotalRepercusionesEconomicas[4] }} €</h3>
            </div>
            <div
              style="
                top: 25679px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasPuntas[4] }} €</h3>
            </div>
            <div
              style="
                top: 25679px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasLlanos[4] }} €</h3>
            </div>
            <div
              style="
                top: 25679px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasValles[4] }} €</h3>
            </div>
            <div
              style="
                top: 25719px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrTotalRepercusionesEconomicas[5] }} €</h3>
            </div>
            <div
              style="
                top: 25719px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasPuntas[5] }} €</h3>
            </div>
            <div
              style="
                top: 25719px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasLlanos[5] }} €</h3>
            </div>
            <div
              style="
                top: 25719px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasValles[5] }} €</h3>
            </div>
            <div
              style="
                top: 25760px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrTotalRepercusionesEconomicas[6] }} €</h3>
            </div>
            <div
              style="
                top: 25760px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasPuntas[6] }} €</h3>
            </div>
            <div
              style="
                top: 25760px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasLlanos[6] }} €</h3>
            </div>
            <div
              style="
                top: 25760px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasValles[6] }} €</h3>
            </div>
            <div
              style="
                top: 25799px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrTotalRepercusionesEconomicas[7] }} €</h3>
            </div>
            <div
              style="
                top: 25799px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasPuntas[7] }} €</h3>
            </div>
            <div
              style="
                top: 25799px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasLlanos[7] }} €</h3>
            </div>
            <div
              style="
                top: 25799px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasValles[7] }} €</h3>
            </div>
            <div
              style="
                top: 25836px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrTotalRepercusionesEconomicas[8] }} €</h3>
            </div>
            <div
              style="
                top: 25836px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasPuntas[8] }} €</h3>
            </div>
            <div
              style="
                top: 25836px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasLlanos[8] }} €</h3>
            </div>
            <div
              style="
                top: 25836px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasValles[8] }} €</h3>
            </div>
            <div
              style="
                top: 25876px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrTotalRepercusionesEconomicas[9] }} €</h3>
            </div>
            <div
              style="
                top: 25876px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasPuntas[9] }} €</h3>
            </div>
            <div
              style="
                top: 25876px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasLlanos[9] }} €</h3>
            </div>
            <div
              style="
                top: 25876px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasValles[9] }} €</h3>
            </div>

            <div
              style="
                top: 25914px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrTotalRepercusionesEconomicas[10] }} €</h3>
            </div>
            <div
              style="
                top: 25914px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasPuntas[10] }} €</h3>
            </div>
            <div
              style="
                top: 25914px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasLlanos[10] }} €</h3>
            </div>
            <div
              style="
                top: 25914px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasValles[10] }} €</h3>
            </div>

            <div
              style="
                top: 25954px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrTotalRepercusionesEconomicas[11] }} €</h3>
            </div>
            <div
              style="
                top: 25954px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasPuntas[11] }} €</h3>
            </div>
            <div
              style="
                top: 25954px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasLlanos[11] }} €</h3>
            </div>
            <div
              style="
                top: 25954px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h3>{{ this.arrRepercusionesEconomicasValles[11] }} €</h3>
            </div>

            <div
              style="
                top: 26003px;
                left: 670px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2>{{ this.totalRepercusionesEconomicas }} €</h2>
            </div>
            <div
              style="
                top: 26003px;
                left: 870px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2>{{ this.totalRepercusionesEconomicasPuntas }} €</h2>
            </div>
            <div
              style="
                top: 26003px;
                left: 1095px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2>{{ this.totalRepercusionesEconomicasLlanos }} €</h2>
            </div>
            <div
              style="
                top: 26003px;
                left: 1290px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2>{{ this.totalRepercusionesEconomicasValles }} €</h2>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/trabajos_domestico.png"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              style="
                top: 26729px;
                left: 150px;
                border-right: 240px;
                position: absolute;
                z-index: 99999;
                margin-right: 120px;
              "
            >
              <div *ngFor="let item of elementosTrabajosARealiar">
                <h3
                  style="color: #38424b; font-family: 'Helvetica Neue LT Std'"
                  [innerHTML]="item"
                ></h3>
              </div>
            </div>
            <!--
                            <div style="top:24210px;left:150px;border-right: 240px;position:absolute;z-index:99999;margin-right:120px;" *ngFor="let item of elementosTrabajosARealiar">
                                <div style="top:20px;">
                                    <li><p [innerHTML]="item"></p></li><br/><br/><br/>
                                </div>


                            </div>
                            -->
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td
            colspan="3"
            *ngIf="this.incluirFormaDePago && !this.incluirTramitaciones"
          >
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/valoracion_economica_domestico_forma_pago.png"
            />
          </td>
          <td
            colspan="3"
            *ngIf="this.incluirFormaDePago && this.incluirTramitaciones"
          >
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/valoracion_economica_domestico_forma_pago_tramitaciones.png"
            />
          </td>
          <td
            colspan="3"
            *ngIf="!this.incluirFormaDePago && !this.incluirTramitaciones"
          >
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/valoracion_economica_domestico_sin_forma_pago.png"
            />
          </td>
          <td
            colspan="3"
            *ngIf="!this.incluirFormaDePago && this.incluirTramitaciones"
          >
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/valoracion_economica_domestico_sin_forma_pago_tramitaciones.png"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <!-- 26820, 1080-->
            <div
              style="
                top: 29264px;
                left: 1175px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 36px;
                "
              >
                <b>{{ this.precioFinal }} + IVA*</b>
              </h2>
            </div>
            <div
              style="
                top: 29401px;
                left: 1270px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 31px;
                "
              >
                {{ this.cuotaFinanciada84Formatado }} €
              </h2>
            </div>
            <div
              style="
                top: 29445px;
                left: 1270px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 31px;
                "
              >
                {{ this.porcentajeSobreConsumoEstimado }} %
              </h2>
            </div>
            <div
              style="
                top: 29809px;
                left: 1500px;
                position: absolute;
                z-index: 99999;
              "
              *ngIf="!this.incluirTramitaciones"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 29px;
                "
              >
                {{ this.importeLegalizacionExcedenteElectrico }} €
              </h2>
            </div>
            <div
              style="
                top: 29862px;
                left: 802px;
                position: absolute;
                z-index: 99999;
              "
              *ngIf="!this.incluirTramitaciones"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 29px;
                "
              >
                {{ this.potenciaBateriaSeleccionada }}
              </h2>
            </div>
            <div
              style="
                top: 29861px;
                left: 1407px;
                position: absolute;
                z-index: 99999;
              "
              *ngIf="!this.incluirTramitaciones"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 29px;
                "
              >
                {{ this.importeAdicional }} € + IVA
              </h2>
            </div>

            <div
              style="
                top: 29908px;
                left: 802px;
                position: absolute;
                z-index: 99999;
              "
              *ngIf="this.incluirTramitaciones"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 29px;
                "
              >
                {{ this.potenciaBateriaSeleccionada }}
              </h2>
            </div>
            <div
              style="
                top: 29907;
                left: 1407px;
                position: absolute;
                z-index: 99999;
              "
              *ngIf="this.incluirTramitaciones"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 29px;
                "
              >
                {{ this.importeAdicional }} € + IVA
              </h2>
            </div>
            <!-- FORMAS DE PAGO-->
            <div
              style="
                top: 30141;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 29px;
                "
                *ngIf="this.incluirFormaDePago"
              >
                {{ this.formaDePagoFirmaContrato }} €
              </h4>
            </div>
            <div
              style="
                top: 30178;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 29px;
                "
                *ngIf="this.incluirFormaDePago"
              >
                {{ this.formaDePagoInicioDeObra }} €
              </h4>
            </div>
            <div
              style="
                top: 30214;
                left: 900px;
                position: absolute;
                z-index: 99999;
              "
            >
              <h4
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 29px;
                "
                *ngIf="this.incluirFormaDePago"
              >
                {{ this.formaDePagoPuestaEnMarcha }} €
              </h4>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/financiacion_domestico.png"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <!-- instalación fotovoltaica --><!-- 29270, 785-->
            <div
              style="
                top: 31738px;
                left: 835px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionSinBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 34px;
                "
              >
                <p *ngIf="item.global == true">
                  <b>{{ item.importeMultiplicador1 }} €</b>
                </p>
              </h2>
            </div>
            <div
              style="
                top: 31738px;
                left: 1155px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionSinBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 34px;
                "
              >
                <p *ngIf="item.global == true">
                  <b>{{ item.importeMultiplicador2 }} €</b>
                </p>
              </h2>
            </div>
            <div
              style="
                top: 31787px;
                left: 835px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionSinBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 60">
                  {{ item.importeMultiplicador1 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 31787px;
                left: 1155px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionSinBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 60">
                  {{ item.importeMultiplicador2 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 31832px;
                left: 835px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionSinBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 66">
                  {{ item.importeMultiplicador1 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 31832px;
                left: 1155px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionSinBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 66">
                  {{ item.importeMultiplicador2 }} €
                </p>
              </h2>
            </div>

            <div
              style="
                top: 31880px;
                left: 835px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionSinBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 72">
                  {{ item.importeMultiplicador1 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 31880px;
                left: 1155px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionSinBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 72">
                  {{ item.importeMultiplicador2 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 31929px;
                left: 835px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionSinBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 78">
                  {{ item.importeMultiplicador1 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 31929px;
                left: 1155px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionSinBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 78">
                  {{ item.importeMultiplicador2 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 31973px;
                left: 835px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionSinBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 84">
                  {{ item.importeMultiplicador1 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 31973px;
                left: 1155px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionSinBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 84">
                  {{ item.importeMultiplicador2 }} €
                </p>
              </h2>
            </div>
            <!-- instalación fotovoltaica con baterias -->
            <div
              style="
                top: 32193px;
                left: 825px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionConBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 34px;
                "
              >
                <p *ngIf="item.global == true">
                  <b>{{ item.importeMultiplicador1 }} €</b>
                </p>
              </h2>
            </div>
            <div
              style="
                top: 32193px;
                left: 1145px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionConBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 34px;
                "
              >
                <p *ngIf="item.global == true">
                  <b>{{ item.importeMultiplicador2 }} €</b>
                </p>
              </h2>
            </div>
            <div
              style="
                top: 32237px;
                left: 835px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionConBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 60">
                  {{ item.importeMultiplicador1 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 32237px;
                left: 1155px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionConBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 60">
                  {{ item.importeMultiplicador2 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 32283px;
                left: 835px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionConBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 66">
                  {{ item.importeMultiplicador1 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 32283px;
                left: 1155px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionConBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 66">
                  {{ item.importeMultiplicador2 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 32330px;
                left: 835px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionConBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 72">
                  {{ item.importeMultiplicador1 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 32330px;
                left: 1155px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionConBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 72">
                  {{ item.importeMultiplicador2 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 32376px;
                left: 835px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionConBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 78">
                  {{ item.importeMultiplicador1 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 32376px;
                left: 1155px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionConBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 78">
                  {{ item.importeMultiplicador2 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 32423px;
                left: 835px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionConBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 84">
                  {{ item.importeMultiplicador1 }} €
                </p>
              </h2>
            </div>
            <div
              style="
                top: 32423px;
                left: 1155px;
                position: absolute;
                z-index: 99999;
              "
              *ngFor="let item of datosFinanciacionConBateria"
            >
              <h2
                style="
                  color: #38424b;
                  font-family: 'Helvetica Neue LT Std';
                  font-weight: 'bold';
                  font-size: 32px;
                "
              >
                <p *ngIf="item.global == false && item.plazo == 84">
                  {{ item.importeMultiplicador2 }} €
                </p>
              </h2>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/servicios_domestico.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/clientes_satisfechos_domestico.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><img
              style="margin-top: 3px"
              src="../../../assets/images/PPTO_2/documentos_adicionales_domestico.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div [innerHTML]="this.fitxasTecnicasKits"></div>
    <table class="page">
      <tbody>
        <tr>
          <td colspan="3">
            <img
              width="1700px;"
              height="2377px;"
              src="../../../assets/images/PPTO_2/cierre_domestico.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
