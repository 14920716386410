
 import { PrimaryGeneratedColumn, Column, Entity, PrimaryColumn } from 'typeorm';

 
export class VTR_ConsumoEstimado
{

    public consumoAnualTotal:number=0; 
    public consumoAnualEstimadoPunta:number=0;
    public consumoAnualEstimadoLlano:number=0;
    public consumoAnualEstimadoValle:number=0;
    public consumos=[]; 
    public datosEstimadosPunta=[];
    public datosEstimadosLlano=[]; 
    public datosEstimadosValle=[];

    /*
    constructor(
        _consumoAnualTotal:number=0,
        _consumos:[], 
        _datosEstimadosPunta:[], 
        _datosEstimadosLlano:[], 
        _datosEstimadosValle:[]        
    )
    {

        this.consumoAnualTotal = +_consumoAnualTotal; 
        this.consumos = _consumos; 
        this.datosEstimadosPunta = _datosEstimadosPunta; 
        this.datosEstimadosLlano = _datosEstimadosLlano; 
        this.datosEstimadosValle = _datosEstimadosValle;
        
    }
    */
    constructor(){
        this.consumoAnualTotal=0; 
        this.consumoAnualEstimadoPunta=0;
        this.consumoAnualEstimadoLlano=0;
        this.consumoAnualEstimadoValle=0;
        this.consumos=[]; 
        this.datosEstimadosPunta=[];
        this.datosEstimadosLlano=[]; 
        this.datosEstimadosValle=[];
    }

    
    
}