import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification/notification.service';




@Component({
    selector: 'definicion-alarma-ppto',
    templateUrl: 'definicion-alarma-ppto.html',
    styleUrls: ['./definicion-alarma-ppto.css'],
  })
  export class DefinicionAlarmaPresupuesto {
        
    guardarDeshabilitado=true;    
    
    motivosDenegacion: interficieLlaveValor[] = [];
    rutaImagen;
    muestraCampoOtrosMotivos=false;
    /*estadoFinalizacionPpto=EstadoPresupuesto.Aceptado;
    motivoDenegacionPpto="";
    */
        
    constructor(
      public dialogRef: MatDialogRef<DefinicionAlarmaPresupuesto>,
      
      @Inject(MAT_DIALOG_DATA) public data,      
      public http:HttpService,
      public dialog: MatDialog,
      private spinner: NgxSpinnerService,
      private notifyService : NotificationService,
      ) {
        
        this.data.estadoFinalizacionPpto = "S";
        //dialogRef.disableClose = true;
        this.spinner.show();
        this.evaluaSiEsPermitidoGuardar();
        this.inicializaSelector();     
        this.spinner.hide();

      }

    cancelaDialogo(): void {
      this.dialogRef.close();
    }

     

    ngOnInit()
    {                  
      
    }
    

    inicializaSelector()
    {
          //HABITOS CONSUMO
          let i: interficieLlaveValor = {
            id: "F",
            valor: "Falta documentación"
          }
          let v: interficieLlaveValor = {
            id: "N",
            valor: "No disponible"
          }
          let zw: interficieLlaveValor = {
            id: "O",
            valor: "Otros"
          }
          
          
          this.motivosDenegacion.push(i);
          this.motivosDenegacion.push(v);          
          this.motivosDenegacion.push(zw);          


    }
   
    evaluaCambioMotivoDenegacion(valor)
    {
      

      if (valor == "Otros")
      {
        this.muestraCampoOtrosMotivos = true;
        this.data.motivoDenegacionPpto = "";
      }        
      else 
      {
          this.muestraCampoOtrosMotivos = false;
          this.data.motivoDenegacionPpto = valor;
      }
        
      
    }

    evaluaSiEsPermitidoGuardar()
    {

      /*
      if ((this.data.articulo.MODELO.toString().trim().length > 0) 
      && (this.data.articulo.ID_FAMILIA > 0)
      && (this.data.articulo.ID_MARCA > 0)
      && (this.data.articulo.POTENCIA.toString().trim().length > 0)
      )
          this.guardarDeshabilitado = false;
      else
          this.guardarDeshabilitado = true;
      */

          this.guardarDeshabilitado = false;
    }

  }
  interface interficieLlaveValor {
    id:string; 
    valor: string
  }
