import {RouterModule, Routes} from '@angular/router';

import { LoginComponent } from './login/login.component';
import { NoPageFoundComponent } from './pages/no-page-found/no-page-found.component';



const appRoutes: Routes = [

  {path: 'login', component: LoginComponent},
  {path: '**', component: NoPageFoundComponent}
];

export const APP_ROUTES = RouterModule.forRoot( appRoutes, {useHash: true} );
