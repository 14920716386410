import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menu: any = [
    {
      titulo: 'PPTOS',
      icono: 'fas fa-file-invoice',
      url: '/grid-presupuestos'
      /*submenu:[
        {titulo: 'Dashboard', url: '/dashboard'},
        {titulo: 'ProgressBar', url: '/progress'},
        {titulo: 'Graficas', url: '/graficas1'},
        {titulo: 'Promesas', url: '/promesas'},
      ]*/
    },
    /*{
      titulo: 'Clientes',
      icono: 'fa fa-check',
      url: '/clientes'
    },
    */ 
    {
      titulo: 'Artículos',
      icono: 'fas fa-solar-panel',
      url: '/grid-articulos'
    },
    {
      titulo: 'Kits',
      icono: 'fas fa-puzzle-piece',
      url: '/grid-kits'
    },
    {
      titulo: 'Familias',
      icono: 'fas fa-object-group',
      url: '/grid-familias'
    },
    {
      titulo: 'Marcas',
      icono: 'fas fa-copyright',
      url: '/grid-marcas'
    },
    {
      titulo: 'Usuarios',
      icono: 'fas fa-users',
      url: '/usuarios'
    },
    {
      titulo: 'Clientes',
      icono: 'fas fa-book',
      url: '/grid-clientes'
    },
    {
      titulo: 'Promotores',
      icono: 'fas fa-address-book',
      url: '/grid-promotores'
    },
    {
      titulo: 'Calendario',
      icono: 'fas fa-calendar',
      url: '/app-calendario'
    }
    /*    
    {
      titulo: 'Promotores',
      icono: 'fas fa-users',
      url: '/grid-promotores'
    }
    */
    /*
    {
      titulo: 'Projects',
      icono: 'fa fa-check',
      url: '/projects'
    }*/
  ];

  constructor() { }
}
