import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { CRM_Clientes } from '@shared/CRM.clientes.entity';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
    selector: 'creacion-edicion-cliente',
    templateUrl: 'creacion-edicion-cliente.html',
    styleUrls: ['./creacion-edicion-cliente.css'],
  })
  export class CreacionEdicionCliente {
        
    guardarDeshabilitado=true;    

    procedencias: interficieLlaveValor[] = [];
    estados: interficieLlaveValor[] = [];
    clientes = new Array<CRM_Clientes>();
    
    rutaImagen:string = "";
        
    constructor(
      public dialogRef: MatDialogRef<CreacionEdicionCliente>,
      @Inject(MAT_DIALOG_DATA) public data,
      public http:HttpService,
      public dialog: MatDialog,
      private spinner: NgxSpinnerService,
      private notifyService : NotificationService,
      ) {}

    cancelaDialogo(): void {
      this.dialogRef.close();
    }



    ngOnInit()
    {
            
        this.spinner.show();        
        this.inicializaProcedencias();
        this.inicializaEstados();
        this.evaluaSiEsPermitidoGuardar();
        this.spinner.hide();

    }

    inicializaEstados()
    {

        let i: interficieLlaveValor = {
          key: "V",
          value: "Vendido"
        }
        this.estados.push(i);

        let p: interficieLlaveValor = {
          key: "E",
          value: "Presupuesto entregado"
        }        
        this.estados.push(p);

        let l: interficieLlaveValor = {
          key: "S",
          value: "Seguimiento"
        }        
        this.estados.push(l);

        let f: interficieLlaveValor = {
          key: "I",
          value: "Interesado"
        }        
        this.estados.push(f);

        let o: interficieLlaveValor = {
          key: "N",
          value: "No interesado"
        }        
        this.estados.push(p);

        let n: interficieLlaveValor = {
          key: "P",
          value: "Pendiente documentación"
        }        
        this.estados.push(n);

        let x: interficieLlaveValor = {
          key: "C",
          value: "Pendiente contacto"
        }        
        this.estados.push(x);

        let z: interficieLlaveValor = {
          key: "D",
          value: "Desestimado"
        }        
        this.estados.push(z);

        let q: interficieLlaveValor = {
          key: "B",
          value: "Borrador presupuesto generado"
        }        
        this.estados.push(q);

    }

    inicializaProcedencias()
    {

        let i: interficieLlaveValor = {
          key: "C",
          value: "Contacto Web"
        }
        this.procedencias.push(i);

        let p: interficieLlaveValor = {
          key: "P",
          value: "Captación propia"
        }        
        this.procedencias.push(p);

        let l: interficieLlaveValor = {
          key: "L",
          value: "Llamada oficinas"
        }        
        this.procedencias.push(l);

        let f: interficieLlaveValor = {
          key: "F",
          value: "Flyer"
        }        
        this.procedencias.push(f);

        let o: interficieLlaveValor = {
          key: "O",
          value: "Promotor"
        }        
        this.procedencias.push(p);

        let n: interficieLlaveValor = {
          key: "N",
          value: "Nuevo PPTO"
        }        
        this.procedencias.push(n);

    }

    evaluaSiEsPermitidoGuardar()
    {

 
      if ((this.data.cliente.NOMBRE.toString().trim().length > 0) && (this.data.cliente.PROCEDENCIA.toString().trim().length > 0) && (this.data.cliente.ESTADO.toString().trim().length > 0))       
          this.guardarDeshabilitado = false;
      else 
          this.guardarDeshabilitado = true;
  
    }

    

  }
  interface interficieLlaveValor {
    key:string; 
    value: string
  }