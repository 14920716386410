
    <div *ngFor="let menu of _sidebar.menu">
      <button style="width:100%"
        mat-button
        routerLink="{{ menu.url }}"
        routerLinkActive="active"
        
      >
      <ng-container *ngIf="menu.icono">
        <i class="fas fa-{{menu.icono}}"></i>       
      </ng-container>
      {{   menu.titulo }}
      </button>


    </div>

    
    <button mat-icon-button valign="bottom" (click)="signOut()" style="width:100%">
       Cerrar sesión
    </button>

