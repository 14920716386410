
 import { PrimaryGeneratedColumn, Column, Entity, PrimaryColumn } from 'typeorm';

 @Entity('crm_acciones_realizadas')
export class CRM_AccionesRealizadas
{

    
    @PrimaryGeneratedColumn() ID:number;         
    @PrimaryColumn() ID_CLIENTE:number;
    @Column() FECHA:string; 
    @Column() DESCRIPCION:string;
    
    
    constructor(        
        _id=0,
        _id_cliente="",
        _fecha="",
        _descripcion=""
    )
    {
        this.ID = +_id,
        this.ID_CLIENTE = +_id_cliente,
        this.FECHA = _fecha,
        this.DESCRIPCION = _descripcion        
    }

    
    
}
