import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService} from '../services/http/http.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public username:string = "";
  public password:string = "";
  //http: HttpClient;
  fact$: Observable<string>;
  constructor( public router: Router,public http:HttpService,private spinner: NgxSpinnerService) {
 
       

   }

  ngOnInit() {
  

  }  

  

  ingresar(){
  
      this.spinner.show();
      this.http.login(this.username,this.password).then(
        (result) => {
          if (result)        
          {
              this.spinner.hide();
              this.router.navigate(['grid-presupuestos']);             
          }
            
        }
      )

  }
  
}
