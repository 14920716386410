import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';

@Component({
    selector: 'creacion-edicion-usuario',
    templateUrl: 'creacion-edicion-usuario.html',
  })
  export class CreacionEdicionUsuario {

    repetirPasswordVisible=false;
    contrasenaRepetida="";
    guardarDeshabilitado=false;
    nuevasContrasenasCoinciden=true;

    constructor(
      public dialogRef: MatDialogRef<CreacionEdicionUsuario>,
      @Inject(MAT_DIALOG_DATA) public data) {}

    cancelaDialogo(): void {
      this.dialogRef.close();
    }

    repetirPwd()
    {


        if (this.data.usuario.CONTRASENA.toString().trim().length > 0)
        {
          this.repetirPasswordVisible = true;
          this.nuevasContrasenasCoinciden = true;
        }
        else {
            this.repetirPasswordVisible=false;
        }

    }

    ngOnInit()
    {
        this.evaluaSiEsPermitidoGuardar();
    }

    evaluaSiContrasenasCoinciden()
    {
      if (this.data.usuario.CONTRASENA == this.contrasenaRepetida){
          this.nuevasContrasenasCoinciden = true;
      } else {
        this.nuevasContrasenasCoinciden = false;
      }
    }

    evaluaSiEsPermitidoGuardar()
    {

      if ((this.data.usuario.NOMBRE.toString().trim().length > 0) && (this.nuevasContrasenasCoinciden))
      {
          if (!this.data.edicion)
          {
            if (this.data.usuario.CONTRASENA.length == 0) {
                this.guardarDeshabilitado = true;
            } else {
              this.evaluaSiContrasenasCoinciden();
              if (!this.nuevasContrasenasCoinciden) {
                this.guardarDeshabilitado = true;
              } else {
              this.guardarDeshabilitado = false;
              }
            }

          }else {
              this.evaluaSiContrasenasCoinciden();
              if (!this.nuevasContrasenasCoinciden){
                this.guardarDeshabilitado = true;
              }
              else {
                this.guardarDeshabilitado = false;
              }
          }


      } else {
          this.guardarDeshabilitado = true;
      }
    }

  }
