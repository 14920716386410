import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public http:HttpService) { }

  ngOnInit() {

    // 

    this.http.obtenerProfile().then(
      (result) => {
         
      }
    )

  }


}
