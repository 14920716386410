import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { PAGES_ROUTES } from './pages.routes';

import { FullCalendarModule } from '@fullcalendar/angular';


import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';

import { MatBadgeModule } from '@angular/material/badge';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';

//GRIDS
import { AngularSlickgridModule } from 'angular-slickgrid';

import { NgApexchartsModule } from "ng-apexcharts";

import { DatePipe } from '@angular/common';
import { BreadcrumbModule } from "xng-breadcrumb";
import { NgxPrintModule } from 'ngx-print';


import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
// Import the Canvas renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer
} from 'echarts/renderers';
//import 'echarts/theme/macarons.js';


import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CKEditorModule } from 'ckeditor4-angular';

import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PagesComponent } from './pages.component';
import { UsuariosComponent } from './Usuarios/usuarios.component';
import { MatTableModule } from '@angular/material/table';
import { CreacionEdicionUsuario } from './Usuarios/creacion-edicion-usuario.component';
import { GridPromotoresComponent } from './Promotores/grid-promotores.component';
import { CreacionEdicionPromotor } from './Promotores/creacion-edicion-promotor.component';
import { GridPresupuestosComponent } from './Presupuestos/grid-presupuestos.component';
import { ResumenPptoIndustrialComponent } from './Presupuestos/resumen-ppto-industrial.component';
import { ResumenPptoComponent } from './Presupuestos/resumen-ppto.component';
import { FinalizacionPresupuesto } from './Presupuestos/finalizacion-ppto.component';
import { DefinicionAlarmaPresupuesto } from './Presupuestos/definicion-alarma-ppto.component';
import { CreacionPresupuestoComponent } from './Presupuestos/creacion-presupuesto.component';
import { GridMarcasComponent } from './Marcas/grid-marcas.component';
import { CreacionEdicionMarca } from './Marcas/creacion-edicion-marca.component';
import { GridKitsComponent } from './Kits/grid-kits.component';
import { CreacionEdicionKit } from './Kits/creacion-edicion-kit.component';
import { GridFamiliasComponent } from './Familias/grid-familias.component';
import { CreacionEdicionFamilia } from './Familias/creacion-edicion-familia.component';
import { GridClientesComponent } from './Clientes/grid-clientes.component';
import { CreacionEdicionCliente } from './Clientes/creacion-edicion-cliente.component';
import { AccionesRealizadas } from './Clientes/acciones-realizadas.component';
import { CalendarioComponent } from './Calendario/calendario.component';
import { GridArticulosComponent } from './Articulos/grid-articulos.component';
import { CreacionEdicionArticulo } from './Articulos/creacion-edicion-articulo.component';
import { EdicionAlerta } from '../shared/alertas/edicion-alerta.component';
import { Alertas } from '../shared/alertas/alertas.component';

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer]
);


@NgModule({
  declarations: [
    PagesComponent,
    UsuariosComponent,
    CreacionEdicionUsuario,
    GridPromotoresComponent,
    CreacionEdicionPromotor,
    GridPresupuestosComponent,
    ResumenPptoIndustrialComponent,
    ResumenPptoComponent,
    FinalizacionPresupuesto,
    DefinicionAlarmaPresupuesto,
    CreacionPresupuestoComponent,
    GridMarcasComponent,
    CreacionEdicionMarca,
    GridKitsComponent,
    CreacionEdicionKit,
    GridFamiliasComponent,
    CreacionEdicionFamilia,
    GridClientesComponent,
    CreacionEdicionCliente,
    AccionesRealizadas,
    CalendarioComponent,
    GridArticulosComponent,
    CreacionEdicionArticulo,
    EdicionAlerta,
    Alertas
  ],
  imports: [
    CKEditorModule,
    SharedModule,
    CommonModule,
    PAGES_ROUTES,
    FormsModule,
    MatTableModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatToolbarModule,
    MatExpansionModule,
    MatButtonModule,
    MatCardModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatSortModule,
    MatIconModule,
    MatNativeDateModule,
    MatButtonModule,
    MatTooltipModule,
    MatTabsModule,
    MatGridListModule,
    MatDialogModule,
    MatListModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    BreadcrumbModule,
    MatDatepickerModule,
    MatRadioModule,
    MatRippleModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    NgxPrintModule,
    MatBadgeModule,
    NgxMatSelectSearchModule,
    NgxEchartsModule.forRoot({ echarts }),
    NgApexchartsModule,
    FullCalendarModule,
    BreadcrumbModule,
    AngularSlickgridModule.forRoot({
      enableAutoResize: true,
      autoResize: {
        containerId: 'demo-container',
        sidePadding: 10
      }
    }),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    DatePipe
  ],
})

export class PagesModule { }
