import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { MNT_Articulos } from '@shared/MNT.articulos.entity';
import { MNT_TiposContratoArticulos } from '@shared/MNT.tipos_contrato_articulos.entity';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'creacion-edicion-kit',
    templateUrl: 'creacion-edicion-kit.html',
    styleUrls: ['./creacion-edicion-kit.css'],
  })
  export class CreacionEdicionKit {
        
    guardarDeshabilitado=true;    
    options: interficieArticulosAsignados[] = [{
      id:0,
      nombre: 'aa',
      cantidad:2
    }];
    selected = new Array<MNT_Articulos>();


    rutaImagen:string = "";
    habitosConsumo: interficieLlaveValor[] = [];
    tipoKit: interficieLlaveValor[] = [];
    articulos = new Array<MNT_Articulos>();
    articulosFiltrados = new Array<MNT_Articulos>();
    articuloSeleccionado = new MNT_Articulos();
    articulosAsignadosAKit = new Array<interficieArticulosAsignados>();
    public articulosFilterCtrl: UntypedFormControl = new UntypedFormControl();
    protected _onDestroy = new Subject<void>();
        
    constructor(
      public dialogRef: MatDialogRef<CreacionEdicionKit>,
      @Inject(MAT_DIALOG_DATA) public data,
      public http:HttpService,
      public dialog: MatDialog,
      private spinner: NgxSpinnerService) {}

    cancelaDialogo(): void {
      this.dialogRef.close();
    }


    ngOnDestroy() {
       
      this.data.articulosAsignados.push(this.articulosAsignadosAKit);
       
      this._onDestroy.next();
      this._onDestroy.complete();
      
    }

    ngOnInit()
    {

      
       
      


        this.spinner.show();
        this.inicializaSelectores();
        this.obtieneTodosLosArticulos();
        
        
        this.evaluaSiEsPermitidoGuardar();

        
        //this.selected.push(this.articulosFiltrados[1]);
        
        
         

        this.spinner.hide();

    }

    obtieneTodosLosArticulos()
    {

        this.http.obtenerTodosArticulos().then(
          (articulosObtenidos:Array<MNT_Articulos>) => {
              this.articulos = articulosObtenidos;

              this.obtieneArticulosAsociadosAKit();
              /*
              this.data.articulosAsignados.forEach(element => {
                this.articulos.forEach(elementArt => {
                    if (elementArt.ID_ARTICULO == element.id)
                    {
                        element.nombre = elementArt.MODELO; 
                        this.articulosAsignadosAKit.push(element);
                         
                        //this.articuloSeleccionado = elementArt;
                       // this.selected.push(elementArt);
                        
                    }
                });
              });
              */
              this.filtrarArticulos();

              this.spinner.hide();
              this.articulosFilterCtrl.valueChanges
              .pipe(takeUntil(this._onDestroy))
              .subscribe(() => {
                this.filtrarArticulos();
              });
          }
        )

    }

    protected filtrarArticulos() {

       
      if (!this.articulos) {
        return;
      }
      // get the search keyword
      let search = this.articulosFilterCtrl.value;
      if (!search) {
        this.articulosFiltrados = this.articulos;
        
       // console.log("ARTICULO FILTRADO " + JSON.stringify(this.articuloSeleccionado)) 
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the banks
      this.articulosFiltrados = this.articulos.filter(tipo => tipo.MODELO.toLowerCase().indexOf(search) > -1);

      
  
    }

    cambioArticulosAsignados(event)
    {
         
        
        if(event.isUserInput) {
          
          if (event.source.selected)
          {
            new Promise((resolve) => {

              var iteracion = 0;
              this.articulos.forEach(element => {
                if (element.ID_ARTICULO == event.source.value["ID_ARTICULO"])
                {
                    let i: interficieArticulosAsignados = {
                      id: event.source.value["ID_ARTICULO"], 
                      nombre: element.MODELO,
                      cantidad: 0
                    }
                    
                    this.articulosAsignadosAKit.push(i);

                                      
                    
                }

                iteracion++;
                if(iteracion == this.articulos.length)
                  resolve(this);
              });  
              
            }).then(
              () => {
              
                
              }
            );  
          }          
          else 
          {
              
              
              for(var i = 0; i <= this.articulosAsignadosAKit.length-1;i++)
              {
                  if (this.articulosAsignadosAKit[i].id == event.source.value["ID_ARTICULO"])
                  {
                    this.articulosAsignadosAKit.splice(i, 1);    
                  }
              }

              
              
              /*
              for(var i = 0; i <= this.articulosAsignadosAKit.length-1;i++)
              {
                  if (this.articulosAsignadosAKit[i] == event.source.value)
                    this.articulosAsignadosAKit = this.articulosAsignadosAKit.filter(item => item.id !== event.source.value);
              }            
              */
          }

             
          // 
        }
    }

    
    obtieneArticulosAsociadosAKit()
    {
      
        this.http.obtenerArticulosSegunTipoContrato(this.data.kit.ID_TIPO_CONTRATO).then(
          (articulosKits:Array<MNT_TiposContratoArticulos>) => {
            //alert("ARTICULOS KITS " + JSON.stringify(articulosKits));

            var elementosSeleccionados:Array<MNT_Articulos> = []; 

            new Promise((resolve) => {

            if (this.articulos.length == 0)
              resolve(this); 

            articulosKits.forEach(element => {

              this.articulos.forEach(articulo => {
                if (articulo.ID_ARTICULO == element.ID_ARTICULO)
                {
                  
                    let i: interficieArticulosAsignados = {
                      id: element.ID_ARTICULO, 
                      nombre: articulo.MODELO,
                      cantidad: element.CANTIDAD
                    }

                   // this.selected.push(this.articulos[0]);
                   // alert(element.CANTIDAD);  
      
                    this.articulosAsignadosAKit.push(i);
                    elementosSeleccionados.push(articulo);
                          
                }
              }); 
              
              if (this.articulosAsignadosAKit.length == articulosKits.length)
                resolve(this);
            });

          }).then(
            () => {
         
              this.selected = elementosSeleccionados;
            }
          );
   
          }
        )
        //obtenerArticulosSegunTipoContrato

    }
    

    inicializaSelectores()
    {
          //HABITOS CONSUMO
          let i: interficieLlaveValor = {
            id: "I",
            valor: "Invierno"
          }
          let v: interficieLlaveValor = {
            id: "V",
            valor: "Verano"
          }
          
          this.habitosConsumo.push(i);
          this.habitosConsumo.push(v);

          let d: interficieLlaveValor = {
            id: "D",
            valor: "Doméstico"
          }
          let ii: interficieLlaveValor = {
            id: "I",
            valor: "Industrial"
          }

          this.tipoKit.push(d);
          this.tipoKit.push(ii);

    }
    
    evaluaSiEsPermitidoGuardar()
    {

      this.guardarDeshabilitado = false;
      /*
      if ((this.data.articulo.MODELO.toString().trim().length > 0) 
      && (this.data.articulo.ID_FAMILIA > 0)
      && (this.data.articulo.ID_MARCA > 0)
      && (this.data.articulo.POTENCIA.toString().trim().length > 0)
      )
          this.guardarDeshabilitado = false;
      else
          this.guardarDeshabilitado = true;

          */

  }

  }

  interface interficieLlaveValor {
    id:string; 
    valor: string
  }

  
  interface interficieArticulosAsignados {
    id:number; 
    nombre: string
    cantidad:number
  }
