<!--<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">-->
<!-- SPINNER LEFT I BAR-->
<div
  class="full-height"
  style="position: relative"
  fxFlex="70"
  fxFlex.lg="60"
  fxFlex.md="50"
  fxFlex.sm="100"
  fxFlex.xs="100"
>
  <div style="position: relative">
    <!-- <ngx-progress [overlay]="true" [showSpinner]="true" [barHeight]="2" [spinnerDiameter]="40"></ngx-progress> -->
    <table></table>
  </div>
</div>
<!-- SPINNER OVERLAY-->
<ngx-spinner type="ball-atom">
  <p style="color: white">Cargando...</p>
</ngx-spinner>
<router-outlet></router-outlet>
