import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { INV_DatosComponent } from './INV_Datos/INV_datos.component';
import { UsuariosComponent } from './Usuarios/usuarios.component';
import { NgModule } from '@angular/core';
import { CreacionPresupuestoComponent } from './Presupuestos/creacion-presupuesto.component';
import { ResumenPptoComponent } from './Presupuestos/resumen-ppto.component';
import { ResumenPptoIndustrialComponent } from './Presupuestos/resumen-ppto-industrial.component';
import { GridPresupuestosComponent } from './Presupuestos/grid-presupuestos.component';
import { GridArticulosComponent } from './Articulos/grid-articulos.component';
import { GridKitsComponent } from './Kits/grid-kits.component';
import { GridPromotoresComponent } from './Promotores/grid-promotores.component';
import { GridFamiliasComponent } from './Familias/grid-familias.component';
import { GridMarcasComponent } from './Marcas/grid-marcas.component';
import { GridClientesComponent } from './Clientes/grid-clientes.component';
import { CalendarioComponent } from './Calendario/calendario.component';

const pagesRoutes: Routes = [
  {
    path: '',
    /*data: {
      breadcrumb: 'Home'
    },
    */
    component: PagesComponent,
    children: [      
      {
        path: 'home', 
        component: HomeComponent,
        /*data: {
          breadcrumb: 'Inicio'
        },*/          
      },
      
      {
          path: 'usuarios',
          component: UsuariosComponent,            
      },
      
            
      
    {
      path: 'sede',
      component: INV_DatosComponent,    
   
    },
    {
      path: 'creacion-presupuesto',
      component: CreacionPresupuestoComponent
    },
    {
      path: 'grid-articulos',
      component: GridArticulosComponent
    },
    {
      path: 'grid-kits',
      component: GridKitsComponent
    },
    {
      path: 'grid-familias',
      component: GridFamiliasComponent
    },
    {
      path: 'grid-marcas',
      component: GridMarcasComponent
    },
    {
      path: 'grid-promotores',
      component: GridPromotoresComponent
    },
    {
      path: 'grid-presupuestos',
      component: GridPresupuestosComponent
    },
    {
      path: 'resumen-ppto',
      component: ResumenPptoComponent

    },
    {
      path: 'resumen-ppto-industrial',
      component: ResumenPptoIndustrialComponent
    },
    {
      path: 'grid-clientes',
      component: GridClientesComponent
    },
    {
      path: 'grid-promotores',
      component: GridPromotoresComponent
    },
    {
      path: 'app-calendario',
      component: CalendarioComponent
    },
    /*
    {
      path: 'clientes',
      component: Clientes
    },*/
    
    /*{
          path: 'clientes',
          component: INV_ClientesComponent,
          data: {
              breadcrumb: 'Clientes'
          },    
          
              children: [                    
                    {                      
                      path: ':cliente', component: INV_SedesComponent, data: { breadcrumb: 'Sede'},
                      children: [{
                        path: ':sede', component: INV_DatosComponent, data: { breadcrumb: 'Editar sede'},
                      }] 
                      
                  },
                    //{path: 'sedes', component: INV_SedesComponent, data: { breadcrumb: 'Repo22'}},
                    
              ]
              
      },
      */
      /*
      {
        path: 'prova',
        component: INV_DatosSedeComponent,
        data: { breadcrumb: 'asfsfes'}

      },
      */
      {
        path: '',
        redirectTo: '/home', 
        pathMatch: 'full',
        /*data: {
          breadcrumb: 'Home'
        },*/   
      },
    ]
  }
];

export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );



