
 import { PrimaryGeneratedColumn, Column, Entity, PrimaryColumn } from 'typeorm';

 @Entity('pptos_alarmas')
export class PPTOS_Alarmas
{

    
    @PrimaryColumn() ID_PPTO:number;         
    @Column() TIPO_ALARMA:string;     
    @Column() VISUALIZADA:boolean;     
    @Column() SIGUIENTE_RECORDATORIO:string;     
    @Column() MOTIVO_SEGUIMIENTO:string;

    
    constructor(        
        _id_ppto=0,
        _tipo_alarma="",
        _visualizada=false,
        _siguiente_recordatorio="",
        _motivo_seguimiento=""
    )
    {

        this.ID_PPTO = +_id_ppto;
        this.TIPO_ALARMA = _tipo_alarma,
        this.VISUALIZADA = _visualizada,
        this.SIGUIENTE_RECORDATORIO = _siguiente_recordatorio,
        this.MOTIVO_SEGUIMIENTO = _motivo_seguimiento      
        
    }

    
    
}
export enum TiposAlarmas {
    Seguimiento = "S",
    Pendiente = "P",
    Desactivada = "D"
}