<h1 mat-dialog-title style="text-align: center">{{ data.tituloModal }}</h1>

<div mat-dialog-content style="margin: 0px">
  <div
    class="row"
    style="
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    "
  >
    <div class="col-md-1">
      <b>PPTO</b>
    </div>
    <div class="col-md-1">
      <b>Tipo alarma</b>
    </div>
    <div class="col-md-2">
      <b>Cliente</b>
    </div>
    <div class="col-md-2">
      <b>Fecha</b>
    </div>
    <div class="col-md-6">
      <b>Acciones</b>
    </div>
  </div>

  <div
    class="row"
    style="
      margin-top: 0.4rem;
      margin-bottom: 0.7rem;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    "
  >
    <div class="col-md-1">{{this.data.alarma.presupuesto.NUMERACION}}</div>
    <div class="col-md-1">{{this.data.alarma.descripcionAlarma}}</div>
    <div class="col-md-2">
      {{this.data.alarma.presupuesto.NOMBRE_APELLIDOS_CLIENTE}}
      ({{data.alarma.presupuesto.TELEFONO}})
    </div>
    <div class="col-md-2">
      <input
        placeholder="Fecha"
        matInput
        (dateChange)="evaluaCambio($event.value)"
      />
    </div>
    <div class="col-md-6">
      <button
        mat-raised-button
        style="margin-right: 30px"
        (click)="marcarAlarmaComoVisualizada(this.data.alarma.presupuesto.ID_PPTO)"
      >
        Posponer
      </button>
      <button
        mat-raised-button
        style="margin-right: 30px; background-color: yellowgreen"
        (click)="abrirdialgoDefinicionAlarma(this.data.alarma.presupuesto.ID_PPTO)"
      >
        Cambiar tipo alarma
      </button>
      <button
        mat-raised-button
        style="margin-right: 30px"
        color="primary"
        (click)="verPPTO(this.data.alarma.presupuesto.ID_PPTO)"
      >
        Ver PPTO
      </button>
      <button
        mat-raised-button
        style="background-color: #eecb1f"
        (click)="abrirDialogAccionesRealizadas(this.data.alarma.presupuesto.ID_CLIENTE)"
      >
        Acciones cliente
      </button>
    </div>

    <div class="col-md-10"></div>
    <div class="col-md-1">
      <button
        mat-raised-button
        style="background-color: #6790ff"
        (click)="abrirDialogFinalizacionPpto(this.data.alarma.presupuesto.ID_PPTO)"
      >
        Finalizar ppto
      </button>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <div class="row col-md-12" style="text-align: center">
    <div class="col-md-12">
      <button mat-raised-button color="primary" cdkFocusInitial>Aceptar</button>
    </div>
  </div>
</div>
