import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';

import { MatDialog, } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-datos',
  templateUrl: './INV_datos.component.html',
  styleUrls: ['./INV_datos.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class INV_DatosComponent implements OnInit {


  public clienteSeleccionado = "";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public router: Router,
    public http: HttpService,
    public dialog: MatDialog,
  ) { }

  async ngOnInit() {




  }

  clickCambioSede() {
    //alert("ha entrat");
    //this.route.snapshot.params['sede'] = null;


    window.location.href = "/#/" + this.router.url.split('/')[1] + '/' + this.router.url.split('/')[2];

    //alert(this.router.url.split('/')[0]);

  }






}
