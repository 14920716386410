import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { CRM_AccionesRealizadas } from '@shared/CRM.acciones_realizadas.entity';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
    selector: 'acciones-realizadas',
    templateUrl: 'acciones-realizadas.html',
    styleUrls: ['./acciones-realizadas.css'],
  })
  export class AccionesRealizadas {
        
    guardarDeshabilitado=true;    

    procedencias: interficieLlaveValor[] = [];
    estados: interficieLlaveValor[] = [];
    //accionesRealizadas = new Array<CRM_AccionesRealizadas>();
    //clientes = new Array<CRM_Clientes>();
    
    rutaImagen:string = "";
        
    constructor(
      public dialogRef: MatDialogRef<AccionesRealizadas>,
      @Inject(MAT_DIALOG_DATA) public data,
      public http:HttpService,
      public dialog: MatDialog,
      private spinner: NgxSpinnerService,
      private notifyService : NotificationService,
      ) {

        this.data.accionesRealizadas = new Array<CRM_AccionesRealizadas>();
        this.spinner.show();        
        this.obtenerAccionesRealizadasSegunCliente();
        this.evaluaSiEsPermitidoGuardar();
        

      }

    cancelaDialogo(): void {
      this.dialogRef.close();
    }



    ngOnInit()
    {
            
 

    }

    nuevaAccionRealizada()
    {
        var elm = new CRM_AccionesRealizadas();
        var date = new Date(); // Or the date you'd like converted.
        var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0,16);        
        elm.FECHA = isoDateTime;
        elm.ID_CLIENTE = this.data.id_cliente;
        
        this.data.accionesRealizadas.push(elm);
    }

    eliminarAccionRealizada(elemento)
    {
      //this.foo_objects = this.foo_objects.filter(obj => obj !== foo_object);
      this.data.accionesRealizadas = this.data.accionesRealizadas.filter(obj => obj !== elemento);
    }

    obtenerAccionesRealizadasSegunCliente()
    {
        
      this.http.obtenerCliente(this.data.id_cliente).then(
        (clienteObtenido) => {
          this.data.tituloModal = "Acciones realizadas " + clienteObtenido["NOMBRE"] + " (" + clienteObtenido["TELEFONO"] + ")";

          this.http.obtenerAccionesRealizadasSegunCliente(this.data.id_cliente).then(
            (result:Array<CRM_AccionesRealizadas>) => {
              
              result.forEach(element => {
                //alert(moment(new Date(element.FECHA)).format('DD/MM/YYYY HH:mm'));
                  //var tzoffset = (new Date(element.FECHA)).getTimezoneOffset() * 60000; //offset in milliseconds
                  //var localISOTime =  new Date(element.FECHA.toISO-tzoffset) tzoffset.toISOString().slice(0, -1);
                
                  var date = new Date(element.FECHA); // Or the date you'd like converted.
                  var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0,16);
                  element.FECHA = isoDateTime;
                  
  
                //element.FECHA =  new Date(element.FECHA).toISOString().slice(0, -1);
                //element.FECHA = moment(new Date(element.FECHA)).format();
                this.data.accionesRealizadas.push(element);
              });
              //this.accionesRealizadas = result;
              this.spinner.hide();
            }
          )
          
        }
      )
                
    }
   


    evaluaSiEsPermitidoGuardar()
    {

      /*
      if ((this.data.cliente.NOMBRE.toString().trim().length > 0) && (this.data.cliente.PROCEDENCIA.toString().trim().length > 0) && (this.data.cliente.ESTADO.toString().trim().length > 0))       
          this.guardarDeshabilitado = false;
      else 
          this.guardarDeshabilitado = true;

        */
        this.guardarDeshabilitado = false;
    }

    

  }
  interface interficieLlaveValor {
    key:string; 
    value: string
  }