<h1 mat-dialog-title style="text-align: center">{{ data.tituloModal }}</h1>

<div mat-dialog-content style="margin: 0px">
  <div
    class="row"
    style="
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    "
  >
    <div class="col-md-1">
      <b>PPTO</b>
    </div>
    <div class="col-md-2">
      <b>Tipo alarma</b>
    </div>
    <div class="col-md-2">
      <b>Cliente</b>
    </div>
    <div class="col-md-7">
      <b>Acciones</b>
    </div>
  </div>
  <div *ngFor="let item of this.alarmas" style="width: 100%">
    <div
      class="row"
      style="
        margin-top: 0.4rem;
        margin-bottom: 0.7rem;
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      "
    >
      <div class="col-md-1">{{item.presupuesto.NUMERACION}}</div>
      <div class="col-md-2">{{item.descripcionAlarma}}</div>
      <div class="col-md-2">
        {{item.presupuesto.NOMBRE_APELLIDOS_CLIENTE}}
        ({{item.presupuesto.TELEFONO}})
      </div>
      <div class="col-md-7">
        <button
          mat-raised-button
          style="margin-right: 30px"
          (click)="marcarAlarmaComoVisualizada(item.alarma.ID_PPTO)"
        >
          Posponer
        </button>
        <button
          mat-raised-button
          style="margin-right: 30px; background-color: yellowgreen"
          (click)="abrirdialgoDefinicionAlarma(item.alarma.ID_PPTO)"
        >
          Cambiar tipo alarma
        </button>
        <button
          mat-raised-button
          style="margin-right: 30px"
          color="primary"
          (click)="verPPTO(item.alarma.ID_PPTO)"
        >
          Ver ppto
        </button>
        <button
          mat-raised-button
          style="background-color: #eecb1f; margin-right: 30px"
          (click)="abrirDialogAccionesRealizadas(item.presupuesto.ID_CLIENTE)"
        >
          Acciones cliente
        </button>
        <button
          mat-raised-button
          style="background-color: #6790ff"
          (click)="abrirDialogFinalizacionPpto(item.alarma.ID_PPTO)"
        >
          Finalizar ppto
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!this.hayAlarmas" style="text-align: center">
    <i>No hay alarmas activas para hoy. </i>
  </div>
</div>
<br /><br />
<div mat-dialog-actions>
  <div class="row col-md-12" style="text-align: center">
    <div class="col-md-12">
      <button mat-raised-button color="warn" (click)="cancelaDialogo()">
        Cerrar
      </button>
    </div>
  </div>
</div>
