
 import { PrimaryGeneratedColumn, Column, Entity, PrimaryColumn } from 'typeorm';

 @Entity('crm_clientes')
export class CRM_Clientes
{
    
    @PrimaryGeneratedColumn() ID_CLIENTE:number;             
    @Column() NUMERO_PPTO:number; 
    @Column() NOMBRE:string; 
    @Column() CORREO:string;
    @Column() TELEFONO:string;
    @Column() DIRECCION:string;
    @Column() MUNICIPIO:string;
    @Column() PROVINCIA:string;
    @Column() PROCEDENCIA:string;
    @Column() ID_PROMOTOR_PROCEDENCIA:number;
    @Column() ESTADO:string;

    
    constructor(
        _id_cliente=0,
        _numero_ppto=0,
        _nombre="",
        _correo="",
        _telefono="",
        _direccion="",
        _municipio="",
        _provincia="",
        _procedencia="",
        _id_promotor_procedencia=0,
        _estado=""
    )
    {
        this.ID_CLIENTE = +_id_cliente;
        this.NUMERO_PPTO = +_numero_ppto;
        this.NOMBRE = _nombre;
        this.CORREO = _correo;
        this.TELEFONO = _telefono;
        this.DIRECCION = _direccion;
        this.MUNICIPIO = _municipio;
        this.PROVINCIA = _provincia;
        this.PROCEDENCIA = _procedencia;
        this.ID_PROMOTOR_PROCEDENCIA = +_id_promotor_procedencia;
        this.ESTADO = _estado;
    }

    
    
}
export enum ProcedenciaClientes {
    Contacto_Web = "C",
    Captacion_Propia = "P",
    Llamada_Oficinas = "L",
    Flyer = "F",
    Promotor = "O",
    Nuevo_PPTO = "N"
}

export enum EstadoClientes {     
    Vendido = "V",
    Presupuesto_Entregado = "E",
    Seguimiento = "S",
    Interesado = "I",
    No_Interesado = "N",
    Pendiente_Documentacion = "P",
    Pendiente_Contacto = "C",
    Desestimado = "D",
    Tiene_Borradores_PPTO = "B"
}