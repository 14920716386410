
 import { PrimaryGeneratedColumn, Column, Entity, PrimaryColumn } from 'typeorm';

 @Entity('usuarios')
export class Usuarios
{

    
    @PrimaryColumn() ID_USUARIO: number;    
    @Column() NOMBRE: string;
    @Column() CONTRASEÑA: string;
    @Column() ROL: number;

    constructor(
        _id_usuario:number=0,
        _nombre:string="",
        _contraseña:string=""        ,
        _rol:number=0
    )
    {
        this.ID_USUARIO = +_id_usuario;
        this.NOMBRE = _nombre; 
        this.CONTRASEÑA = _contraseña;      
        this.ROL = +_rol;   
    }

    
    
}