<mat-toolbar color="primary">
    <span><img width="60%" src="../../../assets/images/logo.png" /></span>
  


    <button mat-mini-fab aria-label="Alertas" style="left:55%;background-color: #e6bb00;position:absolute" mat-raised-button color="primary" matBadge="{{this.shared.numeroAlarmas}}" matBadgePosition="before" matBadgeColor="accent" (click)="abrirDialog()">
      <mat-icon>alarm</mat-icon>
    </button>

    <span style="  flex: 1 1 auto;">
        
    </span>

    
        


    <span style="color:black">{{  username  }}</span>

    
        
    
    
    
  </mat-toolbar>