
<section class="login-main fondoLogin">
    <div class="simple-login-container fondoLogin ">
        <h1>Error 404</h1>

    </div>

    <div class="fixed-bottom d-flex justify-content-center">
        <img src="../../../assets/images/logo.png">
    </div>


</section>