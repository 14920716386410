import { Injectable } from "@angular/core";
import { PPTOS_Alarmas } from "@shared/PPTOS.alarmas.entity";
import { PPTOS } from "@shared/pptos.entity";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  public urlWebServices = environment.apiUrl;
  public numeroAlarmas = 0;
  public alarmasCalendario = [];
  constructor() {}

  formataNumeroParaVisualizacion(
    number: any,
    decimals: any,
    dec_point: any,
    thousands_point: any,
  ) {
    if (number == null || !isFinite(number)) {
      throw new TypeError("number is not valid");
    }

    if (!decimals) {
      var len = number.toString().split(".").length;
      decimals = len > 1 ? len : 0;
    }

    if (!dec_point) {
      dec_point = ".";
    }

    if (!thousands_point) {
      thousands_point = ",";
    }

    number = parseFloat(number).toFixed(decimals);

    number = number.replace(".", dec_point);

    var splitNum = number.split(dec_point);
    splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
    number = splitNum.join(dec_point);

    return number;
  }
}

export interface BalancesFinancierosIndustrial {
  ANO: number;
  INGRESO: number;
  BALANCE: number;
}
export class interficieAlarmas {
  alarma: PPTOS_Alarmas;
  presupuesto: PPTOS;
  descripcionAlarma: String;

  constructor() {
    this.alarma = new PPTOS_Alarmas();
    this.presupuesto = new PPTOS();
    this.descripcionAlarma = "";
  }
}
export interface ConsumosManualesIndustrial {
  Mes: string;
  P1: string;
  P2: string;
  P3: string;
  P4: string;
  P5: string;
  P6: string;
  PORCENTAJE_USADO_P1: number;
  PORCENTAJE_USADO_P2: number;
  PORCENTAJE_USADO_P3: number;
  PORCENTAJE_USADO_P4: number;
  PORCENTAJE_USADO_P5: number;
  PORCENTAJE_USADO_P6: number;
  HS_P1: number;
  HS_P2: number;
  HS_P3: number;
  HS_P4: number;
  HS_P5: number;
  HS_P6: number;
  TOTAL_CONSUMO: number;
  TOTAL_HORARIO_SOLAR: number;
  PRECIO_KWH_P1: string;
  PRECIO_KWH_P2: string;
  PRECIO_KWH_P3: string;
  PRECIO_KWH_P4: string;
  PRECIO_KWH_P5: string;
  PRECIO_KWH_P6: string;
  POTENCIA_P1: string;
  POTENCIA_P2: string;
  POTENCIA_P3: string;
  POTENCIA_P4: string;
  POTENCIA_P5: string;
  POTENCIA_P6: string;
}
