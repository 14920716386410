
 import { PrimaryGeneratedColumn, Column, Entity, PrimaryColumn } from 'typeorm';

 @Entity('crm_promotores')
export class CRM_Promotores
{

    
    @PrimaryGeneratedColumn() ID_PROMOTOR:number;             
    @Column() NOMBRE:string; 
    @Column() CORREO:string;
    @Column() TELEFONO:string;
    @Column() DIRECCION:string;
    @Column() MUNICIPIO:string;
    @Column() PROVINCIA:string;
    @Column() ESTADO:string;

    
    constructor(
        _id_promotor=0,
        _nombre="",
        _correo="",
        _telefono="",
        _direccion="",
        _municipio="",
        _provincia="",
        _estado=""
    )
    {

        this.ID_PROMOTOR = +_id_promotor;
        this.NOMBRE = _nombre;
        this.CORREO = _correo;
        this.TELEFONO = _telefono;
        this.DIRECCION = _direccion;
        this.MUNICIPIO = _municipio;
        this.PROVINCIA = _provincia;
        this.ESTADO = _estado;
    }

    
    
}

export enum TipoPromotores {
    Interesado = "I",
    No_Interesado = "N",
    Pendiente_Contacto = "P",
    No_definido = 'U'
}