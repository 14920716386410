
 import { PrimaryGeneratedColumn, Column, Entity, PrimaryColumn } from 'typeorm';

 @Entity('mnt_marcas')
export class MNT_Marcas
{

    
    @PrimaryGeneratedColumn() ID_MARCA:number;     
    @Column() NOMBRE:string;     

    
    constructor(        
        _id_marca:number=0,        
        _nombre:string="",        
    )
    {

        this.ID_MARCA = +_id_marca; 
        this.NOMBRE = _nombre;          
    }

    
    
}