import { Component, OnInit, ViewChild } from "@angular/core";

import { NgxSpinnerService } from "ngx-spinner";
import {
  Router,
} from "@angular/router";
import { HttpService } from "src/app/services/http/http.service";

import { MatDialog, } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NotificationService } from "src/app/services/notification/notification.service";
//import { INV_CreacionEdicionCliente } from './INV_creacion-edicion-cliente.component';

import {
  AngularGridInstance,
  Column,
  ExtensionName,
  FieldType,
  Formatter,
  GridOption,
} from "angular-slickgrid";

//import { Contacto } from '@shared/contacto.entity';

import { MNT_Marcas } from "@shared/MNT.marcas.entity";
import { CreacionEdicionMarca } from "./creacion-edicion-marca.component";

@Component({
  selector: "grid-marcas",
  templateUrl: "./grid-marcas.component.html",
  styleUrls: ["./grid-marcas.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class GridMarcasComponent implements OnInit {
  pageReady = false;
  tituloModal = "";
  filtradoGrd = "";

  displayedColumns: string[] = ["NOMBRE", "editarGrupo"];
  angularGrid: AngularGridInstance;

  columnDefinitions1: Column[];
  columnDefinitionsHiddenDefault: Column[];
  dataset1: any[] = [];
  gridOptions1: GridOption;

  rowData = [];
  filtrado = new Array<MNT_Marcas>();
  public marcas: Array<MNT_Marcas>;

  source: Array<any> = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public router: Router,
    public http: HttpService,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    public dialog: MatDialog) { }

  async ngOnInit() {

    const actionFormatter: Formatter = (
    ) => {
      return `<div class="fake-hyperlink">Acciones <i class="fa fa-caret-down"></i></div>`;
    };



    this.columnDefinitions1 = [
      {
        id: "id",
        field: "id",
        name: "ID",
        sortable: true,
        filterable: true,
        //maxWidth: 75,
      },
      {
        id: "nombre",
        name: "Nombre",
        field: "NOMBRE",
        //width: 150,
        sortable: true,
        filterable: true,
        type: FieldType.string,
        filter: {
          enableTrimWhiteSpace: true,
        },
      },
      {
        id: "acciones",
        name: "Acciones",
        field: "acciones",
        columnGroup: "Acciones",
        excludeFromExport: true,
        formatter: actionFormatter,
        cellMenu: {
          hideCloseButton: false,
          //width: 200,
          menuUsabilityOverride: () => {
            return true;
          },
          commandItems: [
            { command: "editar", title: "Editar", positionOrder: 60 },
            { divider: true, command: "", positionOrder: 65 },
            {
              command: "eliminar",
              title: "Eliminar",
              cssClass: "red",
              positionOrder: 66,
            },
          ],
        },
      },
    ];

    this.gridOptions1 = {
      columnPicker: {
        hideForceFitButton: true,
        hideSyncResizeButton: true,
        onColumnsChanged: (e, args) => {
          console.log(
            "Column selection changed from Column Picker, visible columns: ",
            args.columns
          );
        },
      },
      autoEdit: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 28,
      //enableAutoResize: true,
      forceFitColumns: true,
      enableGridMenu: true,
      autoHeight: true,
      // enable the filtering but hide the user filter row since we use our own single filter
      enableFiltering: true,
      showHeaderRow: true, // hide the filter row (header row)

      alwaysShowVerticalScroll: false,
      enableColumnPicker: true,
      enableRowSelection: true,
      enablePagination: true,
      pagination: {
        pageSizes: [15, 30, 50, 100],
        pageSize: 15,
      },
      enableCellMenu: true,
      /*
          autoResize: {
            containerId: 'container',
            sidePadding: 5
          },
          */
      rowSelectionOptions: {
        // True (Single Selection), False (Multiple Selections)
        selectActiveRow: false,
      },
      enableAutoResize: true,
      enableCellNavigation: true,
      enableCheckboxSelector: true,
      multiSelect: false,
      enableSorting: true,
      excelExportOptions: {
        exportWithFormatter: true,
        customColumnWidth: 15,
        columnHeaderStyle: { font: { bold: true, italic: true } },
      },

      enableContextMenu: true,

      cellMenu: {
        onCommand: (e, args) => this.executeCommand(e, args),
        onOptionSelected: (e, args) => {
          const dataContext = args && args.dataContext;
          if (dataContext && dataContext.hasOwnProperty("completed")) {
            dataContext.completed = args.item.option;
            this.angularGrid.gridService.updateItem(dataContext);
          }
        },
        onBeforeMenuClose: (e, args) =>
          console.log("Cell Menu is closing", args),
      },
    };

    await this.inicializaListadoMarcas();
    this.pageReady = true;
  }

  onSelectedRowsChanged(args) {
    if (Array.isArray(args.rows)) {
      args.rows.map((idx) => {
         
      });
    }
  }

  executeCommand(e, args) {
    const command = args.command;
    const dataContext = args.dataContext;

     
    switch (command) {
      case "editar":
        this.editarMarca(dataContext);
        break;
      case "eliminar":
        this.eliminarMarca(dataContext.id);
        break;
    }
  }

  editarMarca(_marca) {
    this.abrirDialog(true, _marca);
  }

  abrirDialog(_edicion = false, _marca: MNT_Marcas) {

    var _mar = new MNT_Marcas(0, "");

    if (_edicion) {
      this.tituloModal = "Editar marca";
      _mar = _marca;
    } else {
      this.tituloModal = "Nueva marca";
    }

    const dialogRef = this.dialog.open(CreacionEdicionMarca, {
      width: "75%",
      data: {
        tituloModal: this.tituloModal,
        edicion: _edicion,
        marca: _mar,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
       


      if (result) {
        if (result.edicion) {
          //UPDATE
           
           
          this.spinner.show();

          this.http.actualizarMarca(result.marca).then((resultat) => {
            console.log(
              "Marca actualizada correctamente" + JSON.stringify(resultat)
            );
            this.notifyService.showMessage(
              "Marca actualizada correctamente",
              "Success",
              false
            );
            this.inicializaListadoMarcas();
            this.spinner.hide();
          });
        } //CREATE
        else {
           
          this.spinner.show();

          this.http.guardarMarca(result.marca).then((resultat) => {
            console.log(
              "Marca creada correctamente" + JSON.stringify(resultat)
            );
            this.notifyService.showMessage(
              "Marca creada correctamente",
              "Success",
              false
            );
            this.inicializaListadoMarcas();
            this.spinner.hide();
          });
        }
      }
    });
  }

  eliminarMarca(_id_marca) {
    if (confirm("Estás seguro? ")) {
      this.spinner.show();
      this.http.eliminarMarca(_id_marca).then(
        () => {
          this.spinner.hide();
          this.notifyService.showMessage(
            "Operación efectuada correctamente",
            "Success",
            false
          );
          this.inicializaListadoMarcas();
        },
        () => {
          this.spinner.hide();
          this.notifyService.showMessage(
            "Error efectuando operación",
            "Error",
            true
          );
          this.spinner.hide();
        }
      );
    }
  }

  angularGridReady(angularGrid: AngularGridInstance) {
    this.angularGrid = angularGrid;
  }

  get cellMenuInstance(): any {
    return (
      (this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.cellMenu
        )) ||
      {}
    );
  }

  get contextMenuInstance(): any {
    return (
      (this.angularGrid &&
        this.angularGrid.extensionService.getSlickgridAddonInstance(
          ExtensionName.contextMenu
        )) ||
      {}
    );
  }

  async inicializaListadoMarcas() {
    this.http.obtenerTodasMarcas().then(
      (result: Array<MNT_Marcas>) => {
        this.marcas = result;
        //this.pptos = result;

        this.spinner.hide();
        this.filtrar(this.filtradoGrd);
      },
      () => {
         
        this.spinner.hide();
        this.filtrar(this.filtradoGrd);
      }
    );
  }

  nuevaMarca() {
    this.abrirDialog(false, new MNT_Marcas());
  }

  filtrar(valor) {
    this.filtrado = this.marcas.filter((art) =>
      art.NOMBRE.toString()
        .toLowerCase()
        .includes(valor.toString().toLowerCase())
    );

    this.rowData = this.filtrado;
    this.source = this.filtrado;

    //slickgrid
    // this.slick.beginUpdate();
    var datosFormatados = [];
    return new Promise((resolve) => {
      for (var i = 0; i < this.rowData.length; i++) {

        //nombre_usuario_asignado
        datosFormatados[i] = {
          id: this.rowData[i].ID_MARCA,
          NOMBRE: this.rowData[i].NOMBRE
        };

        if (i == this.rowData.length - 1) resolve(this);
      }
    }).then(() => {
      this.dataset1 = datosFormatados;
    });
  }

  float2int(value) {
    return value | 0;
  }
}
