
 import { PrimaryGeneratedColumn, Column, Entity, PrimaryColumn } from 'typeorm';

 @Entity('pptos_precios_kwh_industrial')
export class PPTOS_PreciosKWHIndustrial
{

    
    @PrimaryColumn() ID_PPTO:number;     
    
    @Column() PRECIO_P1_I:string;     
    @Column() PRECIO_P2_I:string;     
    @Column() PRECIO_P3_I:string;     
    @Column() PRECIO_P4_I:string;     
    @Column() PRECIO_P5_I:string;     
    @Column() PRECIO_P6_I:string;     
    @Column() POTENCIA_P1_I:string;     
    @Column() POTENCIA_P2_I:string;     
    @Column() POTENCIA_P3_I:string;     
    @Column() POTENCIA_P4_I:string;     
    @Column() POTENCIA_P5_I:string;     
    @Column() POTENCIA_P6_I:string;     

    
    constructor(        
        _id_ppto:number=0,
        _precio_p1_i:string="0",
        _precio_p2_i:string="0",
        _precio_p3_i:string="0",
        _precio_p4_i:string="0",
        _precio_p5_i:string="0",
        _precio_p6_i:string="0",
        _potencia_p1_i:string="0",
        _potencia_p2_i:string="0",
        _potencia_p3_i:string="0",
        _potencia_p4_i:string="0",
        _potencia_p5_i:string="0",
        _potencia_p6_i:string="0"
    )
    {

        this.ID_PPTO = _id_ppto;
        this.PRECIO_P1_I = _precio_p1_i;
        this.PRECIO_P2_I = _precio_p2_i;
        this.PRECIO_P3_I = _precio_p3_i;
        this.PRECIO_P4_I = _precio_p4_i;
        this.PRECIO_P5_I = _precio_p5_i;
        this.PRECIO_P6_I = _precio_p6_i;
        this.POTENCIA_P1_I = _potencia_p1_i;
        this.POTENCIA_P2_I = _potencia_p2_i;
        this.POTENCIA_P3_I = _potencia_p3_i;
        this.POTENCIA_P4_I = _potencia_p4_i;
        this.POTENCIA_P5_I = _potencia_p5_i;
        this.POTENCIA_P6_I = _potencia_p6_i;
    }

    
    
}