import { Column, Entity, PrimaryColumn, PrimaryGeneratedColumn } from "typeorm";

@Entity("pptos")
export class PPTOS {
  @PrimaryGeneratedColumn()
  ID_PPTO: number;
  @PrimaryColumn()
  ID_PPTO_MANTENIMIENTO_ENLAZADO: number;
  @PrimaryColumn()
  NUMERACION: string;
  @PrimaryColumn()
  ID_TIPO_CONTRATO: number;
  @Column()
  NOMBRE_APELLIDOS_CLIENTE: string;
  @Column()
  MAIL: string;
  @Column()
  TELEFONO: string;
  @Column()
  DIRECCION: string;
  @Column()
  VIVIENDA_HABITUAL: number;
  @Column()
  POBLACION: string;
  @Column({ type: "decimal" })
  CONSUMO_ANUAL: number;
  @Column()
  ESTADO: string;
  @Column()
  ORIENTACION: string;
  @Column()
  PICO_DE_CUBIERTA: string;
  @Column()
  FECHA_REVISION_PPTO: string;
  @Column()
  FECHA_PPTO: string;
  @Column()
  TIPO_CONTRATO: string;
  @Column()
  IMAGEN_GOOGLE_MAPS: string;
  @Column()
  TEXTO_ADDICIONAL: string;
  @Column({ type: "decimal" })
  IMPORTE_ADDICIONAL: number;
  @Column({ type: "decimal" })
  PRECIO_FINAL: number;
  @Column()
  TRABAJOS_A_REALIZAR: string;
  @Column()
  TIPO_PPTO: string;
  @Column()
  POTENCIA_BATERIA: string;
  @Column({ type: "decimal" })
  POTENCIA_PICO_I: number;
  @Column()
  INCLINACION_PVGIS_I: number;
  @Column({ type: "decimal" })
  AZIMUTH_PVGIS_I: number;
  @Column({ type: "decimal" })
  PERDIDAS_PVGIS_I: number;
  @Column()
  COORDENADAS_LAT_PVGIS_I: string;
  @Column()
  COORDENADAS_LON_PVGIS_I: string;
  @Column()
  INVERSOR_I: number;
  @Column()
  NUMERO_INVERSORES_I: number;
  @Column()
  PANEL_I: number;
  @Column()
  NUMERO_PANELES_I: number;
  @Column()
  SOLARBLOCK_I: number;
  @Column()
  ID_CLIENTE: number;
  @Column()
  MOTIVO_DENEGACION: string;
  @Column()
  INCLUIR_FORMA_PAGO: number;
  @Column()
  INCLUIR_TRAMITACIONES: number;

  constructor(
    _id_ppto: number = 0,
    _id_ppto_mantenimiento_enlazado: number = 0,
    _numeracion: string = "",
    _id_tipo_contrato: number = 0,
    _nombre_apellidos_cliente: string = "",
    _mail: string = "",
    _telefono: string = "",
    _direccion: string = "",
    _vivienda_habitual: number = 0,
    _poblacion: string = "",
    _consumo_anual: number = 0,
    _estado: string = "",
    _orientacion: string = "",
    _pico_de_cubierta: string = "",
    _fecha_revision_ppto: string = "",
    _fecha_ppto: string = "",
    _tipo_contrato: string = "",
    _imagen_google_maps: string = "",
    _texto_addicional: string = "",
    _importe_addicional: number = 0,
    _precio_final: number = 0,
    _trabajos_a_realizar: string = "",
    _tipo_ppto: string = "",
    _potencia_pico_i: number = 0,
    _inclinacion_pvgis_i: number = 0,
    _azimuth_pvgis_i: number = 0,
    _perdidas_pvgis_i: number = 0,
    _coordenadas_lat_pvgis_i: string = "",
    _coordenadas_lon_pvgis_i: string = "",
    _inversor_i: number = 0,
    _numero_inversores_i: number = 0,
    _panel_i: number = 0,
    _numero_paneles_i: number = 0,
    _solarblock_i: number = 0,
    _potencia_bateria: string = "",
    _id_cliente: number = 0,
    _motivo_denegacion: string = "",
    _incluir_forma_pago: number = 0,
    _incluir_tramitaciones: number = 0,
  ) {
    this.ID_PPTO = +_id_ppto;
    this.ID_PPTO_MANTENIMIENTO_ENLAZADO = +_id_ppto_mantenimiento_enlazado;
    this.NUMERACION = _numeracion;
    this.ID_TIPO_CONTRATO = +_id_tipo_contrato;
    this.ID_CLIENTE = +_id_cliente;
    this.NOMBRE_APELLIDOS_CLIENTE = _nombre_apellidos_cliente;
    this.MAIL = _mail;
    this.TELEFONO = _telefono;
    this.DIRECCION = _direccion;
    this.VIVIENDA_HABITUAL = _vivienda_habitual;
    this.POBLACION = _poblacion;
    this.CONSUMO_ANUAL = +_consumo_anual;
    this.ESTADO = _estado;
    this.ORIENTACION = _orientacion;
    this.PICO_DE_CUBIERTA = _pico_de_cubierta;
    this.FECHA_REVISION_PPTO = _fecha_revision_ppto;
    this.FECHA_PPTO = _fecha_ppto;
    this.TIPO_CONTRATO = _tipo_contrato;
    this.IMAGEN_GOOGLE_MAPS = _imagen_google_maps;
    this.TEXTO_ADDICIONAL = _texto_addicional;
    this.IMPORTE_ADDICIONAL = +_importe_addicional;
    this.PRECIO_FINAL = +_precio_final;
    this.TRABAJOS_A_REALIZAR = _trabajos_a_realizar;
    this.TIPO_PPTO = _tipo_ppto;
    this.POTENCIA_PICO_I = +_potencia_pico_i;
    this.INCLINACION_PVGIS_I = +_inclinacion_pvgis_i;
    this.AZIMUTH_PVGIS_I = +_azimuth_pvgis_i;
    this.PERDIDAS_PVGIS_I = +_perdidas_pvgis_i;
    this.COORDENADAS_LAT_PVGIS_I = _coordenadas_lat_pvgis_i;
    this.COORDENADAS_LON_PVGIS_I = _coordenadas_lon_pvgis_i;
    this.INVERSOR_I = +_inversor_i;
    this.NUMERO_INVERSORES_I = +_numero_inversores_i;
    this.PANEL_I = +_panel_i;
    this.NUMERO_PANELES_I = +_numero_paneles_i;
    this.SOLARBLOCK_I = +_solarblock_i;
    this.POTENCIA_BATERIA = _potencia_bateria;
    this.MOTIVO_DENEGACION = _motivo_denegacion;
    this.INCLUIR_FORMA_PAGO = _incluir_forma_pago;
    this.INCLUIR_TRAMITACIONES = _incluir_tramitaciones;
  }
}
export enum EstadoPresupuesto {
  Pendiente = "P",
  Aceptado = "A",
  Denegado = "D",
}
