
 import { PrimaryGeneratedColumn, Column, Entity } from 'typeorm';

 @Entity('mnt_familias')
export class MNT_Familias
{

    
    @PrimaryGeneratedColumn() ID_FAMILIA:number;     
    @Column() NOMBRE:string;     

    
    constructor(        
        _id_familia:number=0,        
        _nombre:string="",        
    )
    {

        this.ID_FAMILIA = +_id_familia; 
        this.NOMBRE = _nombre;          
    }

    
    
}
